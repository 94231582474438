import React from 'react';
import styled from 'styled-components';
import { InputWithIcon, RippleLoader } from '.'
import { BasicInfoIcon } from '../Assets/CreateCampaign';
const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 10px;

`

const Select = styled.div`
    display: grid;
    grid-row-gap: 12px;
    font-size: 12px;
    padding: 8px 8px;
    grid-template-columns: 1fr;
    -webkit-box-shadow: 0px 0px 5px -2px rgba(20,20,20,1);
    -moz-box-shadow: 0px 0px 5px -2px rgba(20,20,20,1);
    box-shadow: 0px 0px 5px -2px rgba(20,20,20,1);
    position: relative;
    max-height:100px;
    overflow-y:scroll;
` 

const BasicSelect = ({ setOption, icon, onChange, value, options = [], placeholder = '' }) => {
    return (
        <Wrapper>
            <InputWithIcon value={value} placeholder={placeholder} icon={icon} onChange={onChange} disabled={true} />
            <Select>
                {options.map(option => <div style={{ cursor:'pointer' }} onClick={() => setOption(option)}> {option} </div> )}
            </Select>
        </Wrapper>
    )
}

export default BasicSelect;
import React, { useState } from 'react';
import styled from 'styled-components';
import { Question, UserInput, Button, Text, FlexStartFlexEnd } from '../../index';
const Wrapper = styled.div`
    display: grid;
    grid-row-gap: 24px;
`
const Step2 = ({ setStep, step, realStep, modelDetails, setDetails, rSC }) => {
    if (step !== realStep) return null;
    const [error, setError] = useState('');
    return (
        <Wrapper>
            <Question step={step} question={'What is your last name?'} />
            <UserInput
                value={modelDetails.lastName}
                callback={(value) => {
                    setDetails({
                        ...modelDetails,
                        lastName: value
                    })
                }} />
            <Text color={'red'}> {error} </Text>
            <FlexStartFlexEnd>
                <Button onClick={() => {
                    if (modelDetails.lastName) {
                        setStep(step + 1)
                    }
                    else {
                        setError('Last name is required.')
                    }

                }} width={'120px'}> Next </Button>
                {rSC()}
            </FlexStartFlexEnd>

        </Wrapper>
    )
}

export default Step2;
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import { ProximaNovaSBold,  TradeGothicNormal, TradeGothicNormalItalic, TradeGothicSemiBoldNormal, TradeGothicSemiBoldItalic, TradeGothicBoldNormal, TradeGothicBoldItalic, FaktNormal } from './fonts'
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from 'react';
import styled from 'styled-components';
import { Text, Button } from '../TypeForm/index';
import { WaistUp, FullLength, Headshot, SideProfile } from './Assets/index';
// import { WaistUp, CloseUp, FullLength, SideProfile  } from './Assets/index';
const Wrapper = styled.div`
    display: grid;
    grid-auto-rows: min-content;
    grid-row-gap: 24px;
    padding: ${props => props.padding || '0px 0px 60px 0px'};
    max-width: 800px;
    margin: 0 auto;
`

const ImagesWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 24px;
    justify-items: center;
    grid-column-gap: 12px;

    @media(max-width:900px) {

    }
`

const ImageSubWrapper = styled.div`
    display: grid;
    grid-row-gap: 24px;
`

const Image = styled.img`
    width: 375px;
    height: 450px;
    @media(max-width: 800px) {
        width: 281px;
        height: 337px;
    }
    @media(max-width:600px) {
        width: 187px;
        height: 225px;
    }
`

const ModelGuidelines = ({ cb, padding }) => {
    const textViews = ['Waist', 'Side Profile', 'Full Length', 'Close Up']
    return (
        <Wrapper padding={padding}>
            <Text textalign={'center'} fontsize={'24px'}> Model Guidelines </Text>
            <Text textalign={'center'} fontsize={'21px'}> Image Guidelines</Text>
            <Text fontsize={'21px'}> Please submit four photos of yourself: full-length, waist-up, close-up, and profile. Do your best to replicate the sample images below. These don't need to be professionally done.</Text>
            <ImagesWrapper>
                <ImageSubWrapper>
                    <Image src={WaistUp} />
                    <Text fontsize={'21px'} textalign={'center'}> Waist </Text>
                </ImageSubWrapper>
                <ImageSubWrapper>
                    <Image src={SideProfile} />
                    <Text fontsize={'21px'} textalign={'center'}> Side Profile </Text>
                </ImageSubWrapper>
                <ImageSubWrapper>
                    <Image src={FullLength} />
                    <Text fontsize={'21px'} textalign={'center'}> Full Length </Text>
                </ImageSubWrapper>
                <ImageSubWrapper>
                    <Image src={Headshot} />
                    <Text fontsize={'21px'} textalign={'center'}> Close Up </Text>
                </ImageSubWrapper>
            </ImagesWrapper>
            <Text fontsize={'21px'} textalign={'center'}> Application Process & Rules </Text>
            <Text fontsize={'21px'} >
                Thank you for your interest in applying to become a Runway Supply Model. Submitting your application is the first step in our review process. Below are some guidelines to help ensure your application is completed correctly.
            </Text>
            <Text fontsize={'21px'} >
                1) If you are 13 years of age or younger, we are sorry but you may not apply online with us.
            </Text>
            <Text fontsize={'21px'} >
                2) If you are a minor between the ages of 14 and 18 you are welcome to apply online but we ask that you please do so with your parent’s or legal guardian’s cooperation and consent.
            </Text>
            <Text fontsize={'21px'} >
                3) Please follow the guidelines on the online application and provide all of the requested information. Incomplete applications will not be reviewed.
            </Text>
            <Text fontsize={'21px'} >
                4) The application asks for photos. Guidance for how these images are to be shot is provided above and on the application. Submissions that do not meet our requirements may be not be reviewed.
            </Text>
            <Text fontsize={'21px'} >
                5) Once your application is submitted, you will receive an email and SMS confirming your submission. Note, you may not hear from us immediately so please be patient while your application is reviewed. Please do not email or call our office to find out the status of your application.
            </Text>
            <Text fontsize={'21px'} >
                6) Runway Supply considers the safety and wellbeing of aspiring models a top priority. We pride ourselves on our professionalism, transparency, and authenticity and believe it is important to advise models about unscrupulous people who prey on their ambitions. Please be aware there are certain individuals on the Internet falsely claiming to be representatives (or "scouts") of Runway Supply. If you are contacted by anyone claiming to be a representative of Runway Supply, do not respond without first verifying their identity by promptly calling us at one of our offices and we will be happy to assist. Should someone claiming to be a representative or model manager from any agency contact you, immediately alert your parents or a responsible adult. If the person claims to be from Runway Supply, please call us.
            </Text>
            <Text fontsize={'21px'} >
                Note: Runway Supply does not conduct interviews via Skype, we never request photos in the nude or in lingerie, and we never require the payment of money.
            </Text>
            <Button onClick={() => cb()}> Go To Application </Button>
        </Wrapper>
    )

}

export default ModelGuidelines;
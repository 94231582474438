import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { campaigns, create, responsive, time } from './images';
const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 200px 1fr;
    font-family: Trade Gothic;
    padding: 0px 50px;
    @media(max-width: 414px) {
        grid-template-columns: 1fr;
        padding: 0px 0px;
        max-width: 414px;
    }
`

const TextWrapper = styled.div`
    font-size: 35px;
    display: grid;
    grid-row-gap: 25px;
    height: 200px;
    align-content: center;
    @media(max-width: 414px) {
        padding: 0px 12px;

    }
`

const Text = styled.div`
    font-size: ${props => props.fontsize};
    font-weight: ${props => props.fontweight};
`

const Image = styled.img`
    object-fit: cover;
    justify-self: center;
    grid-row: 1 / 3;
    grid-column: 2;
    @media(max-width: 414px) {
        width: 414px;
        grid-column: 1;
        grid-row: inherit;
        justify-self: flex-start;
    }
`

const CircleWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 15px);
    grid-column-gap: 10px;
    grid-row: 2;
    align-content: flex-end;
    padding-bottom: 50px;
    @media(max-width: 414px) {
        grid-row: inherit;
        padding: 0px 12px;
        padding-bottom: 50px;
    }
`

const Circle = styled.div`
    width: 15px;
    height: 15px;
    background: ${props => props.selected ? '#000' : '#C4C4C4'};
    border-radius: 50%;
    cursor: pointer;

`
const Section4 = () => {
    const [images] = useState([campaigns, create, responsive, time]);
    const [headings] = useState([
        'Stay on top of everything',
        'Create Campaigns In Seconds',
        'Mobile Responsive Campaigns',
        'Save Time',
  
    ])
    const [subheadings] = useState([
        'A clear birds eye view of all your campaigns.',
        'We will never limit the number of campaigns you can run.',
        'Specify your requirements, models apply in a single click.',
        'View, filter, and select your campaign models with ease.',
    ])
    const [index, setIndex] = useState(0)
    useEffect(() => {
        setInterval(() => {
            setIndex((prevIndex) => {
                if (prevIndex === 3) return 0;
                else return prevIndex + 1;
            })

        }, 5000)
    }, [])
    return (
        <Wrapper>
            <TextWrapper>
                <Text fontweight={'bold'} fontsize={'1em'}> {headings[index]} </Text>
                <Text fontweight={'normal'} fontsize={'.5em'}> {subheadings[index]} </Text>
            </TextWrapper>
            <CircleWrapper>
                {images.map((item,i) => <Circle key={i} selected={i === index} onClick={() => setIndex(i)} />)}
            </CircleWrapper>
            <Image src={images[index]} />
        </Wrapper>
    )
}

export default Section4;
import React from "react";
import styled from "styled-components";
import axios from "axios";
import { GeniusTable } from ".";
import Footer from "../Footer";
import { HOST_NAME } from '../../Config';
const Wrapper = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  grid-row-gap: 80px;
`;
const CampaignsMarketplace = ({ ...props }) => {
  return (
    <Wrapper id="CampaignsMarketplace">
        <GeniusTable
          {...props}
          endpoint={`${HOST_NAME}/api/campaign_marketplace/get_top_campaigns`}
          title={"Top Campaigns"}
          subTitle={"Click To Apply"}
        />
        <GeniusTable
          {...props}
          endpoint={`${HOST_NAME}/api/campaign_marketplace/get_new_campaigns`}
          title={"New Campaigns"}
          subTitle={"Click To Apply"}
        />
    </Wrapper>
  );
};

export default CampaignsMarketplace;

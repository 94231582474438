import React, { Component } from 'react';
import axios from 'axios'
import Loader from './Loader';
import NotFound from './NotFound';
import ThuggerGIF from '../Assets/404thugger.gif';
class VerifyEmail extends Component {
  state = {
    loading: false,
    error: false,
    emailConfirmed: false
  }
  componentDidMount() {
    this.verifyEmail();
  }

  verifyEmail = () => {
    //this.props.endpoint
    this.setState({ loading: true })
    axios.post(this.props.endpoint, {
      token: this.props.match.params.token
    })
    .then(res => {
      this.setState({ loading: false, emailConfirmed:true })
    })
    .catch(err => {
      this.setState({ error: true,  loading: false })
    })
  }
  render() {
    const { loading, error, emailConfirmed } = this.state;
    if(loading) return <Loader />
    return (
      <div>
        {error &&  <NotFound />}
        {emailConfirmed &&
          <div style={{ display:'grid', gridTemplateRows:'calc(100vh - 70px)', justifyContent:'center' }}>
            <div style={{ display:'grid', gridTemplateColumns:'min-content auto', gridColumnGap: 5}}>

              <div style={{  gridColumn: '1 / span 2', fontSize: 24, fontWeight: 100, lineHeight: '2.4rem', letterSpacing: '.25rem',  alignSelf:'center', textAlign:'center'  }}> YOUR EMAIL HAS BEEN VERIFIED </div>
              <div style={{ gridColumn: '1 / span 2', justifySelf:'center' }}> <img src={ThuggerGIF} /> </div>
            </div>
          </div>
          }
      </div>
    )
  }
}

export default VerifyEmail;

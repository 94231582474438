import React from 'react';
import styled from 'styled-components';
import { Up, Down, UpIcon, DownIcon } from './Assets/index';

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: min-content min-content;
    grid-column-gap: 12px;
    justify-self: ${props => props.justify};
`

const StepWrapper = styled.div`
    display: grid;
    place-items: center;
    border: solid 1px #e1e1e1;
    height: 40px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 12px 0px;
`

const StepControl = ({ step, setStep, renderNext = true, renderBack = true, justify }) => {
    return (
        <Wrapper justify={justify}>
            {renderNext ? <img onClick={() => setStep(step + 1)} src={UpIcon} /> : null}
            {renderBack ? <img onClick={() => setStep(step - 1)} src={DownIcon} /> : null}
        </Wrapper>
    )
}

export default StepControl;
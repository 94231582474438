import React from 'react';
import CollageOne from '../../Assets/LandingPage/model-mission-1.png';
import CollageTwo from '../../Assets/LandingPage/model-mission-2.png';
import CollageThree from '../../Assets/LandingPage/model-mission-3.png';
import CollageFour from '../../Assets/LandingPage/model-mission-4.png';
import CollageFive from '../../Assets/LandingPage/model-mission-5.png';
import CollageSix from '../../Assets/LandingPage/model-mission-6.png';
import CollageSeven from '../../Assets/LandingPage/model-mission-7.png';
import CollageEight from '../../Assets/LandingPage/model-mission-8.png';
import CollageNine from '../../Assets/LandingPage/model-mission-9.png';
import CollageTen from '../../Assets/LandingPage/model-mission-10.png';
import CollageEleven from '../../Assets/LandingPage/model-mission-11.png';
import CollageTwelve from '../../Assets/LandingPage/model-mission-12.png';
import CollageThirteen from '../../Assets/LandingPage/model-mission-13.png';
import CollageFourteen from '../../Assets/LandingPage/model-mission-14.png';
import CollageFifteen from '../../Assets/LandingPage/model-mission-15.png';
import CollageSixteen from '../../Assets/LandingPage/model-mission-16.png';
import CollageSeventeen from '../../Assets/LandingPage/model-mission-17.png';
import CollageeEighteen from '../../Assets/LandingPage/model-mission-18.png';
import CollageeNineteen from '../../Assets/LandingPage/model-mission-19.png';
import CollageeTwenty from '../../Assets/LandingPage/model-mission-20.png';
import FadeIn from 'react-fade-in';
import Fade from 'react-reveal/Fade';
import styled from 'styled-components';
import { Wrapper } from './index';


const CollageItem = styled.div`
  grid-row: ${props => {
    const { index } = props;
    const mapRow = {
      1: 5,
      2: 8,
      3: 11,
      4: 3,
      5: 6,
      6: 9,
      7: 12,
      8: 1,
      9: 4,
      10: 7,
      11: 10,
      12: 13,
    }
    return mapRow[index]
  }};

  grid-column: ${props => {
    const { index } = props;
    const mapRow = {
      1: 1,
      2: 1,
      3: 1,
      4: 2,
      5: 2,
      6: 2,
      7: 2,
      8: 3,
      9: 3,
      10: 3,
      11: 3,
      12: 3,
    }
    return mapRow[index]
  }}
`

const CollageImage = styled.img`
  width: 72px;
  height: 115px;
`

const ModelCollageTwo = () => {
  const collageItems = [CollageThirteen, CollageFourteen, CollageFifteen, CollageSixteen, CollageSeven, CollageSeventeen, CollageeEighteen
    , CollageFive, CollageOne, CollageTen, CollageeTwenty]
  return (
    <Wrapper>
      {collageItems.map((item, index) => {
        return <CollageItem index={index + 1}> <Fade> <CollageImage src={item} /> </Fade> </CollageItem>
      })}
    </Wrapper>
  )
}

export default ModelCollageTwo


import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Logo } from './';
import useOuterClickNotifier from '../../shared/functions/useOuterClickNotifier';
import { NavItem } from './';
const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    background-color: #24292e;
    height: 90px;
    font-family: Trade Gothic;
`


const ColumnOne = styled.div`
    display: grid;
    align-items: center;
`

const ColumnTwo = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 100px);
    justify-items: center;
    grid-column-gap: 12px;
    align-items: center;

`

const ColumnThree = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
    align-items: center;
`

const BrandLogo = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: solid 1px #fff;
    object-fit: cover;
`

const Text = styled.div`
    color: ${props => props.selected ? '#F4D17A' : props.color || '#fff'}
    font-size: 20px;
    cursor: pointer;
    text-align: ${props => props.textalign};
    font-weight: bold;
`

const Modal = styled.div`
    position: absolute;
    background-color: #fff;
    height: 50px;
    width: 100px;
    top: 85px;
    right: 0px;
    display: ${props => props.open ? 'grid' : 'none'};
    align-items: center;
`

const Authenticated = ({ user, logout, history }) => {
    const [open, setOpen] = useState(false);
    const innerBoundaryRef = useRef(null);
    const [selected, setSelected] = useState('Campaigns');
    useOuterClickNotifier(
        e => setOpen(false),
        () => innerBoundaryRef.current
    );
    return (
        <Wrapper>
            <ColumnOne>
                <Logo color={'#fff'} />
            </ColumnOne>
            <ColumnTwo>
                <NavItem color={'#fff'} onClick={() =>  history.push('/campaigns')} text={'Campaigns'} setter={setSelected} setterValue={selected} />
                <NavItem color={'#fff'} onClick={() => history.push('/network')} text={'Network'} setter={setSelected} setterValue={selected} />
                <NavItem color={'#fff'} onClick={() => history.push('/profile')} text={'Profile'} setter={setSelected} setterValue={selected} />
            </ColumnTwo>

            <ColumnThree ref={innerBoundaryRef}>
                <BrandLogo onClick={() => setOpen(true)} src={user.brandLogo} />

                <Modal open={open}>
                    <Text textalign={'center'} color={'red'} onClick={() => logout()}> Logout </Text>
                </Modal>>
            </ColumnThree>
        </Wrapper>

    )
}

export default Authenticated;
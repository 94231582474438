import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { BasicInformation, PhotoSection } from './index';
import axios from 'axios'
import { TWILIO_HOST_NAME } from '../../Config';
import { getModel, getModelApplication } from './functions';
const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 270px 1fr;
    @media(max-width: 800px) {
        grid-template-columns: 1fr;
       :nth-child(2) {
            grid-row: 1;
        }
    }
`

const ModelProfile = ({ location, match, history, setValue, viewing = 'public' }) => {
    if(match.params.modelID === 'register' || match.params.modelID === 'login' || match.params.modelID === 'forgot-password' || match.params.modelD === 'create-campaign' ||  match.params.modelD === 'create-campaign-edit') return null;
    const [model, setModel] = useState(() => {

        return {};
    });
    console.log('Current model is ', model)
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function fetch() {
            if (!Object.keys(model).length) {
                try {

                    setLoading(true);
                    if (match.params.url) {
                        const response = await getModelApplication(match.params.modelID, match.params.url)
                        setModel(response.data.application)
                        console.log(response)
                    }
                    else if (match.params.modelID) {
                        const response = await getModel(match.params.modelID)
                        setModel(response.data.model)
                        console.log(response)
                    }
                }
                catch (error) {
                    console.log(error)
                }
                finally {
                    setValue('viewingModelProfile', true)
                    setLoading(false);
                }
            }

        }
        console.log("Called Get Model")
        fetch();

        return () => {
            setValue('viewingModelProfile', false)
        }

    }, [])
    if (location.pathname === '/register' || location.pathname === '/login' || location.pathname === '/forgot-password') return null;
    if (loading) return 'Loading'
    if (!Object.keys(model).length) return 'No Model Found'

    return (
        <Wrapper>
            <BasicInformation setModel={setModel} history={history} match={match} viewing={viewing} model={model} />
            <PhotoSection model={model} />
        </Wrapper>

    )
}
export default ModelProfile;
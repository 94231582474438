import React, { Component } from 'react';
import './CampaignApplicants.css';
import axios from 'axios';
import { Link, Route } from 'react-router-dom';
import PrivateRoute from '../components/PrivateRoute';
import Loader from './Loader';
import { toast } from "react-toastify";
import CampaignApplicantDetails from './CampaignApplicantDetails';
//campaign info
import CampaignsLocationIcon from "../Assets/Campaigns/campaigns-location-icon.svg";
import CampaignsDateTimeIcon from "../Assets/CreateCampaign/calendar-3.svg";
import DeadlineIcon from "../Assets/CreateCampaign/deadline-icon.svg";
import ApplicantsIcon from "../Assets/CreateCampaign/applicant-tile-icon.png";
//socials
import YoutubeIcon from "../Assets/CreateCampaign/youtube.svg";
import URLIcon from "../Assets/CreateCampaign/url-tile-icon.png";
import TwitterIcon from "../Assets/CreateCampaign/twitter.svg";
import InstagramIcon from "../Assets/CreateCampaign/instagram.svg";

import NextButton from '../Assets/CampaignApplicants/next-button.png'
import PreviousButton from '../Assets/CampaignApplicants/previous-button.png'


import ModelsTableView from './ModelsTableView'

import { HOST_NAME } from '../Config';
class CampaignApplicants extends Component {
  state = {
    applications: [],
    campaign: false,
    loading: false,
    invalidURL: false,
    selectedModels: [],
    currentApplication: '',
    upper: 10,
    lower: 0
  }
  componentDidMount() {
    document.getElementById("CampaignsContainer").style.display = "none"; // hide the regular campaigns page
    this.getCampaignAndApplicants()
  }

  componentWillUnmount() {
    document.getElementById("CampaignsContainer").style.display = "grid";
  }

  getCampaignAndApplicants = () => {
    const { endpoint } = this.props;
    this.setState({ loading: true })
    axios.post(HOST_NAME + endpoint, {
      url: this.props.match.params.url
    })
    .then(res => {
      if(res.data.applications) this.setState({ campaign: res.data.campaign, applications: res.data.applications , loading: false });
      else this.setState({ campaign: res.data.campaign, loading: false, applications: [] });
      this.props.getCampaigns();
    })
    .catch(err => {
      this.setState({ invalidURL: true })
      console.log(err)
    })
  }

  handleCheckbox = (e) => {
    let { selectedModels } = this.state;

    if(this.state[e.target.name]) {
      // they are unchecking the box in this call
      selectedModels = selectedModels.filter(id => id !== e.target.name);
    }
    else {
      selectedModels.push(e.target.name);
    }
    this.setState({ [e.target.name]: this.state[e.target.name] ? false : true, selectedModels })

  }

  hireApplicants = () => {
    const { selectedModels, applications, campaign } = this.state;
    this.applications = applications.filter(app => selectedModels.includes(app.applicantID));
    let applicationIDS = this.applications.map(item => item._id)
    if(!selectedModels.length) {
      toast.error("No models have been selected 🤖.", {
        position: toast.POSITION.BOTTOM_CENTER
      });
      return;
    }
    if(campaign.modelsHired >= campaign.hireLimit) {
      toast.warning("You have reached the hire limit for this campaign 🤖.", {
        position: toast.POSITION.BOTTOM_CENTER
      });
      return;
    }

    const user =  JSON.parse(localStorage.getItem('user'))
    this.setState({ loading: true })
    axios.post(`${HOST_NAME}/api/campaigns/hire_applicants`, {
      modelIDS: selectedModels,
      applicationIDS,
      campaignID: campaign._id,
      brandID: user._id,
      tags: campaign.tags,
      campaignTitle: campaign.title,
      brand: campaign.brand
    })
    .then(res => {
      this.getCampaignAndApplicants(); //updates the current applicants page
       // updates the campaign dashboard hire limit
      this.setState({ loading: false, selectedModels: [] }) // reset selected models state

      toast.success("We have notified the selected model(s) 🤖.", {
        position: toast.POSITION.BOTTOM_CENTER
      });
    })
    .catch(err => {
      this.setState({ loading: false })
      console.log(err)
      toast.error("Failed To Hire Model(s) 🤖.", {
        position: toast.POSITION.BOTTOM_CENTER
      });
    })

  }

  declineApplicant = (id) => {
    this.setState({ loading: true })
    axios.post(`${HOST_NAME}/api/campaigns/decline_applicant`, {
      _id: id
    })
    .then(res => {

      this.getCampaignAndApplicants();
      this.setState({ loading: false })
      toast.success("Model Declined 🤖.", {
        position: toast.POSITION.BOTTOM_CENTER
      });
    })
    .catch(err => {
      console.log(err)
      this.setState({ loading: false })
    })
  }

  next = () => {
    const { upper, lower, applications } = this.state;

    if(upper < applications.length) {
      this.setState({ upper: upper + 10, lower: lower + 10 })
    }

  }

  previous = () => {
    const { upper, lower } = this.state;

    if(upper !== 10) {
      this.setState({ upper: upper - 10, lower: lower - 10 })
    }
  }

  renderCampaignApplicantsHeader = (campaign) => {
    if(this.props.match.url.includes("/selected-applicants")) {
      return (
        <div target="Applicants-View-Header" style={{ textAlign:'center', textTransform:'uppercase' }}>
          {campaign.title} SELECTED APPLICANTS
        </div>
      )
    }
    if(this.props.match.url.includes("/campaign-applicants")) {
      return (
        <div target="Applicants-View-Header" style={{ textAlign:'center', textTransform:'uppercase' }}>
          {campaign.title} APPLICANTS
        </div>
      )
    }
  }


  render() {
    const { applications, campaign, loading, invalidURL, currentApplication, upper, lower, applicantID } = this.state;
    const { match } = this.props;

    if(invalidURL) return 'Invalid URL'
    return (

      <div style={{ backgroundColor:'#F6F8FA', minHeight: '100vh' }}>


          <div id="CampaignApplicantsUpperSection">
            <div style={{ height:170 }}>
              <img
                style={{ width: "100%", height:'100%', overflow: "hidden", objectFit: "cover" }}
                src={campaign.coverphoto}
              />

            </div>
            {this.renderCampaignApplicantsHeader(campaign)}
          </div>
          {loading ? null :
            <ModelsTableView
              {...this.state}
              hireApplicants={this.hireApplicants}
              applicantID={applicantID}
              handleCheckbox={this.handleCheckbox}
              next={this.next}
              previous={this.previous}
              upper={upper}
              lower={lower}
              data={applications}
              previous={this.previous}
              getCampaignAndApplicants={this.getCampaignAndApplicants}
              remove={this.declineApplicant}
              {...this.props}
            />
          }

      </div>
    )
  }
}

export default CampaignApplicants;

import React, { Component } from 'react';
import './CampaignApplicants.css';
import axios from 'axios';
import { Link, Route } from 'react-router-dom';
import PrivateRoute from '../components/PrivateRoute';
import Loader from './Loader';
import { toast } from "react-toastify";
import CampaignApplicantDetails from './CampaignApplicantDetails';
//campaign info
import CampaignsLocationIcon from "../Assets/Campaigns/campaigns-location-icon.png";
import CampaignsDateTimeIcon from "../Assets/CreateCampaign/calendar-icon.png";
import DeadlineIcon from "../Assets/CreateCampaign/deadline-tile-icon.png";
import ApplicantsIcon from "../Assets/CreateCampaign/applicant-tile-icon.png";
//socials
import YoutubeIcon from "../Assets/CreateCampaign/youtube-tile-icon.png";
import URLIcon from "../Assets/CreateCampaign/url-tile-icon.png";
import TwitterIcon from "../Assets/CreateCampaign/twitter-tile-icon.png";
import InstagramIcon from "../Assets/CreateCampaign/instagram-tile-icon.png";

import NextButton from '../Assets/CampaignApplicants/next-button.png'
import PreviousButton from '../Assets/CampaignApplicants/previous-button.png'
class CampaignSelectedApplicants extends Component {
  state = {
    applications: [],
    campaign: false,
    loading: false,
    invalidURL: false,
    selectedModels: [],
    currentApplication: '',
    upper: 10,
    lower: 0
  }
  componentDidMount() {
    document.getElementById("CampaignsContainer").style.display = "none"; // hide the regular campaigns page
    //document.getElementById("ViewApplicantsContainer").style.display="none"; // hide the full applicants route

    this.getCampaignAndApplicants()
  }

  componentWillUnmount() {
    document.getElementById("CampaignsContainer").style.display = "grid";
  }

  getCampaignAndApplicants = () => {
    this.setState({ loading: true })
    axios.post("/api/campaigns/get_campaign_and_hired_applicants", {
      url: this.props.match.params.url
    })
    .then(res => {
      if(res.data.applications) this.setState({ campaign: res.data.campaign, applications: res.data.applications , loading: false });
      else this.setState({ campaign: res.data.campaign, loading: false });
    })
    .catch(err => {
      this.setState({ invalidURL: true })
      console.log(err)
    })
  }

  handleCheckbox = (e) => {
    let { selectedModels } = this.state;

    if(this.state[e.target.name]) {
      // they are unchecking the box in this call
      selectedModels = selectedModels.filter(id => id !== e.target.name);
    }
    else {
      selectedModels.push(e.target.name);
    }
    this.setState({ [e.target.name]: this.state[e.target.name] ? false : true, selectedModels })

  }

  hireApplicants = () => {
    const { selectedModels, applications, campaign } = this.state;
    this.applications = applications.filter(app => selectedModels.includes(app.applicantID));
    let applicationIDS = this.applications.map(item => item._id)
    if(!selectedModels.length) {
      toast.error("No models have been selected 🤖.", {
        position: toast.POSITION.BOTTOM_CENTER
      });
      return;
    }
    if(campaign.modelsHired >= campaign.hireLimit) {
      toast.warning("You have reached the hire limit for this campaign 🤖.", {
        position: toast.POSITION.BOTTOM_CENTER
      });
      return;
    }

    const user =  JSON.parse(localStorage.getItem('user'))
    this.setState({ loading: true })
    axios.post('/api/campaigns/hire_applicants', {
      modelIDS: selectedModels,
      applicationIDS,
      campaignID: campaign._id,
      brandID: user._id,
      tags: campaign.tags
    })
    .then(res => {
      this.getCampaignAndApplicants(); //updates the current applicants page
      this.props.getCampaigns(); // updates the campaign dashboard hire limit
      this.setState({ loading: false, selectedModels: [] })

      toast.success("Model Hired 🤖.", {
        position: toast.POSITION.BOTTOM_CENTER
      });
    })
    .catch(err => {
      this.setState({ loading: false })
      console.log(err)
      toast.error("Failed To Hire Model 🤖.", {
        position: toast.POSITION.BOTTOM_CENTER
      });
    })

  }

  declineApplicant = (id) => {
    this.setState({ loading: true })
    axios.post("/api/campaigns/decline_applicant", {
      _id: id
    })
    .then(res => {

      this.getCampaignAndApplicants()
      this.setState({ loading: false })
      toast.success("Model Declined 🤖.", {
        position: toast.POSITION.BOTTOM_CENTER
      });
    })
    .catch(err => {
      console.log(err)
      this.setState({ loading: false })
    })
  }

  next = () => {
    const { upper, lower, applications } = this.state;
    if(upper < applications.length) {
      this.setState({ upper: upper + 10, lower: lower + 10 })
    }

  }

  previous = () => {
    const { upper, lower } = this.state;
    if(upper !== 10) {
      this.setState({ upper: upper - 10, lower: lower - 10 })
    }
  }


  render() {
    const { applications, campaign, loading, invalidURL, currentApplication, upper, lower } = this.state;
    const { match } = this.props;

    if(invalidURL) return 'Invalid URL'
    return (

      <div>
      { applications.length ?  <PrivateRoute
        path={`${match.path}/:applicationID`}
        component={CampaignApplicantDetails}
        applications={applications}
        campaign={campaign}
        previousURL={this.props.match.url}
        getCampaignAndApplicants={this.getCampaignAndApplicants}
        /> : null
      }

        <div id="SelectedApplicantsContainer" className="ViewApplicantsContainer" onClick={() => {
          let modal = document.getElementById(`${this.state.currentApplication}-modal`)
  
          if(modal && modal.style.display === 'grid') {
            modal.style.display = 'none';
            this.setState({ currentApplication: '' })
          }

        }}>
          <div style={{ gridRow:1, gridColumn: '1 / -1' }}>
            <img
              style={{ width: "100%", height:'100%', overflow: "hidden", objectFit: "cover" }}
              src={campaign.coverphoto}
            />

          </div>
          <div target="Applicants-View-Header" style={{ gridRow: 3, gridColumn:'1 / -1', textAlign:'center' }}>
            {campaign.title} MODELS
          </div>

          <div className="Campaign-Applicants-Campaign-Details" >
            <div style={{  display:'grid', gridTemplateColumns:'30px auto'}}>
              <div style={{ gridRow:1, }}> <img src={CampaignsLocationIcon} style={{ width: 18, height: 20 }}/> </div>
              <div style={{ gridRow: 1, }}> {campaign.location} </div>
            </div>
            <div style={{  display:'grid', gridTemplateColumns:'30px auto' }}>
              <div style={{ gridRow:1, }}> <img src={CampaignsDateTimeIcon} style={{ width: 18, height: 20 }}/> </div>
              <div style={{ gridRow: 1, }}> {new Date(campaign.startDate).toLocaleDateString()} </div>
            </div>
            <div  style={{ display:'grid', gridTemplateColumns:'30px auto' }}>
              <div style={{  }}> <img src={DeadlineIcon} style={{ width: 18, height: 20 }}/> </div>
              <div style={{ whiteSpace:'no-wrap' }}> {new Date(campaign.startDate).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})} </div>
            </div>
            <div className="Campaign-Applicants-Deadline" style={{  }}>
              <div style={{  }}> <img src={DeadlineIcon} style={{ width: 18, height: 20 }}/> </div>
              <div style={{  }}> Deadline {new Date(campaign.deadline).toLocaleDateString()} </div>
            </div>
            <div className="Campaign-Applicants-Hire-Limit" style={{  }}>
              <div style={{  }}> <img src={ApplicantsIcon} style={{ width: 20, height: 15 }}/> </div>
              <div style={{  }}> Hire Limit: {campaign.hireLimit} </div>
            </div>
          </div>



          <div style={{ gridColumn:'2 / span 13', justifySelf:'flex-end', gridRow: 6, display:'grid' , gridTemplateColumns:'50px 70px',  }}>

          </div>

          <div className="Campaign-Applicants-Table-Headers" style={{ marginBottom: 10 }}>
            <div className="Campaign-Applicants-Full-Name-Header" style={{ gridRow: 2 }}> Full Name </div>
            <div className="Campaign-Applicants-Date-Applied-Header" style={{ gridRow: 2 }}> Date Applied </div>
            <div style={{ gridRow: 2 }} className="Campaign-Applicants-Table-Campaign-Count"> Campaigns </div>
            <div style={{ gridRow: 2 }} className="Campaign-Applicants-Table-City"> City </div>
            <div style={{ gridRow: 2 }}> Gender </div>
            <div className="Campaign-Applicants-Socials-Header" style={{ gridRow: 2 }}> Socials </div>
            <div className="NextAndPreviousSection" style={{ }}>
              <div style={{ gridColumn: 1 }}> <img className="Campaign-Applicants-PreviousButtonSize" onClick={this.previous} src={PreviousButton} /> </div>
              <div style={{ gridColumn: 3 }} >  <img className="CampaignApplicants-NextButtonSize" onClick={this.next} src={NextButton} />  </div>
            </div>
          </div>

          <div className="Campaign-Applicants-Models-Container" style={{ }}>
            {applications.length && applications.map((application,index) => {
              const {
                firstName,
                lastName,
                createdAt,
                totalApplications,
                city,
                email,
                dob,
                fullLength,
                sideProfile,
                closeUp,
                waistUp,
                twitter, youtube, instagram, website,
                applicationStatus,
                gender,
                profilePicture
              } = application;


              if(firstName && index >= lower && index < upper) {
                return (
                  <div key={application._id} className="Campaign-Applicants-Table" style={{ }}>
                    <div style={{ alignSelf:'center', width:30,   }}>
                      <label style={{ width:'100%', height:'100%' }}>
                        <input onChange={(e) => this.handleCheckbox(e)}
                        name={`${application.applicantID}`}
                        checked={this.state[`${application.applicantID}`] || applicationStatus === "hired"}
                        type="checkbox" disabled={applicationStatus === "hired"} style={{  }} />
                      </label>
                    </div>
                    <div className="Campaign-Applicants-Model-NEP" style={{  display:'grid', gridTemplateRows: '30px 30px', gridTemplateColumns:'50px auto' }}>
                      <div className="Campaign-Applicants-Full-Name" style={{ }}> {`${firstName} ${lastName}`}  </div>
                      <div className="Campaign-Applicants-Email" style={{ gridColumn: 2, gridRow: 2,  alignSelf:'center' }}> {email} </div>
                      <div style={{ alignSelf:'center', gridColumn:'1 / span 2', gridRow: '1 / span 2' }}>
                        <img style={{ borderRadius: '50%', height:40, width:40}} src={profilePicture ? profilePicture : fullLength ? fullLength : sideProfile ? sideProfile : closeUp ? closeUp : waistUp ? waistUp : null} />
                      </div>
                    </div>
                    <div className="Campaign-Applicants-Date-Applied" style={{ alignSelf:'center' }}> {new Date(createdAt).toLocaleDateString()}  </div>
                    <div  className="Campaign-Applicants-Table-Campaign-Count" style={{ alignSelf:'center' }}> 4 </div>
                    <div style={{ alignSelf:'center' }}> {city} </div>
                    <div className="Campaign-Applicants-Gender" style={{ alignSelf:'center' }}> {gender === "male" ? 'M' : 'F'} </div>
                    <div className="Campaign-Applicants-Socials-Section" style={{ }}>
                      {twitter && <a href={`https://twitter.com/${twitter}`} rel="noopener noreferrer" target="_blank"> <img className="Campaign-Applicant-Social-Icon" src={TwitterIcon} /> </a>}
                      {youtube && <a href={`https://youtube.com/user/${youtube}`} rel="noopener noreferrer" target="_blank"> <img className="Campaign-Applicant-Social-Icon"  src={YoutubeIcon} /> </a>}
                      {instagram && <a href={`https://instagram.com/${instagram}`} rel="noopener noreferrer" target="_blank"> <img className="Campaign-Applicant-Social-Icon" src={InstagramIcon} /> </a> }
                      {website && <a href={website} rel="noopener noreferrer" target="_blank"> <img className="Campaign-Applicant-Social-Icon" src={URLIcon} /> </a>}
                    </div>
                    <div  className="Campaign-Applicants-Options-Section" style={{ alignSelf:'center', justifySelf:'center' }}>
                      <div onClick={() => {
                        this.setState({ currentApplication: application._id }, () => {
                          const currentApp = document.getElementById(`${application._id}-modal`);
                          currentApp.style.display === "grid" ? currentApp.style.display = "none" : currentApp.style.display = "grid";
                        })
                      }} > ••• </div>
                      <div className="Campaign-Applicants-Modal-Styles" style={{  }} id={`${application._id}-modal`}>
                        <Link to={`/campaigns/selected-applicants/${this.props.match.params.url}/${application._id}`}> <div className="Campaign-Applicants-Model-Details-Option" style={{ }} onClick={() => console.log()}> Details </div> </Link>
                        <div className="Campaign-Applicants-Model-Decline-Option" style={{  }} onClick={() => this.declineApplicant(application._id)}> Decline </div>
                      </div>
                    </div>
                  </div>
                )
              }
            })}
          </div>
          <div style={{ gridRow: 8, gridColumn: '2 / span 14', justifySelf:'flex-end' }}>
            <button onClick={this.hireApplicants} style={{ width:100, height:36, backgroundColor:'#05CCA0', color:'white' }}> Select </button>
          </div>
          {loading ? <Loader /> : !applications.length ? <div style={{ gridColumn: '1 / span 16'}}> No applicants yet </div> : null}
        </div>
      </div>
    )
  }
}

export default CampaignSelectedApplicants;

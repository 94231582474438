import React from 'react';
import styled from 'styled-components';


const Wrapper = styled.div`
    display: grid;
    background-color: #000;
    font-family: Fakt;
    width: 139px;
    height: 80px;
    cursor: pointer;
`
const Text = styled.div`
    color: #fff;
    text-align: center;
    align-self: center;
    font-weight: normal;
    font-size: 18px;
`

const Signup = ({ history }) => {
    return (
        <Wrapper onClick={() => history.push('/register')}>
            <Text > Sign Up </Text>
        </Wrapper>
    )
}

export default Signup;
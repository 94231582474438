import React, { Component, useEffect } from 'react';
import axios from 'axios';
import './CreateCampaign.css';

import "react-datepicker/dist/react-datepicker.css";
import 'rc-slider/assets/index.css';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from '../Loader'
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css'; // see installation section above for versions of NPM older than 3.0.
import { HOST_NAME, AWS_SERVICES_HOST_NAME, BRAND_SERVICES_HOST_NAME } from '../../Config';
import CalendarIcon from "../../Assets/CreateCampaign/calendar-3.svg"
import CampaignsLocationIcon from '../../Assets/Campaigns/campaigns-location-icon.svg';
import uuid from 'uuid/v4';
import BasicInformation from './BasicInformation';
import ModelSpecifications from './ModelSpecifications'
import ApplicantSettings from './ApplicantSettings'
import { URLIcon } from '../../Assets/CreateCampaign'
import LeftSection from './LeftSection'
import { LocationAutoCompleteInput } from '../../shared'
import styled from 'styled-components';
import { uploadModelRelease } from './functions';
import { Route } from 'react-router-dom'
const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-auto-rows: min-content;

  @media(max-width: 600px) {
    grid-template-columns: 1fr;
  }
`
class CreateCampaign extends Component {
  state = {
    page: 1,
    title: '',
    description: null,
    startDate: null,
    endDate: '',
    location: '',
    height: [40, 60],
    gender: '',
    build: '',
    tags: [],
    profiles: [],
    photos: [],
    applicationLimit: '',
    hireLimit: '',
    deadline: null,
    deadlineTimestamp: null,
    preview: '',
    coverPhotoFile: '',
    currentCropData: null,
    coverphoto: '',
    file: false,
    sessionToken: uuid(),
    geocode: {},
    modelRelease: {
      name: '',
      file: null,
    },
    releaseURL: ''
  }

  coverPhotoRef = React.createRef();

  componentDidMount() {
    if (this.props.match.params.url) {
      this.getCampaignDataByURL()
    }
  }

  componentWillUnmount() {
    if (this.props.match.params.url) {

    }
  }



  getCampaignDataByURL = () => {
    const { url } = this.props.match.params;
    this.setState({ loading: true })
    axios.post(`${BRAND_SERVICES_HOST_NAME}/get_campaign_by_url_no_increment`, { url })
      .then(res => {
        const { campaign } = res.data;

        const {
          height,
          tags,
          profiles,
          photos,
          coverphoto,
          title,
          description,
          startDate,
          location,
          gender,
          build,
          applicationLimit,
          hireLimit,
          deadline,
          geocode,
          endDate,
          releaseURL,
        } = campaign;
        if (height[0] === `4'10`) height[0] = 0;
        if (height[0] === `5'2`) height[0] = 20;
        if (height[0] === `5'6`) height[0] = 40;
        if (height[0] === `5'10`) height[0] = 60;
        if (height[0] === `6'2`) height[0] = 80;
        if (height[0] === `6'6`) height[0] = 100;
        if (height[1] === `4'10`) height[1] = 0;
        if (height[1] === `5'2`) height[1] = 20;
        if (height[1] === `5'6`) height[1] = 40;
        if (height[1] === `5'10`) height[1] = 60;
        if (height[1] === `6'2`) height[1] = 80;
        if (height[1] === `6'6`) height[1] = 100;
        // convert date from string back to real date for datepicker
        var startDateToDate = new Date(startDate);
        var deadlineToDate = new Date(deadline);
        var endDateToDate;
        if (endDate) endDateToDate = new Date(endDate);
        else endDateToDate = new Date();
        this.setState({
          campaign,
          loading: false,
          height,
          tags,
          profiles,
          photos,
          preview: coverphoto,
          coverphoto,
          title,
          description,
          startDate: startDateToDate,
          location,
          gender,
          build,
          applicationLimit,
          hireLimit,
          deadline: deadlineToDate,
          geocode,
          endDate: endDateToDate,
          releaseURL
        });
        let cropper = document.getElementById('coverPhotoCropper')
        if (cropper) {
          cropper.style.display = 'block';
        }
      })
      .catch(err => {
        this.setState({ loading: false })
        console.log(err);
        const { message } = err.response.data;
        if (message) {
          toast.error(message + " 🤖.", {
            position: toast.POSITION.BOTTOM_CENTER
          });
        }
      })
  }


  handleDate = (date, key) => {
    this.setState({
      [key]: date,
      [key + 'Timestamp']: new Date(date).getTime()
    });
  }

  onSliderChange = (value) => {
    document.getElementById('height-all').style.backgroundColor = 'rgba(255, 255, 255, 0.01)';
    if (value[0] === 0 && value[1] === 100) document.getElementById('height-all').style.backgroundColor = 'rgb(255, 255, 255)';

    this.setState({
      height: value
    });
  }

  setModelSpec = (e) => {
    let value = e.currentTarget.id.split(/\s*\-\s*/g);
    const { build, gender, height, tags } = this.state;
    if (value[0] === "gender") {
      let ids = ['gender-all', 'gender-male', 'gender-female'];
      if (value[1] === "all" && gender !== 'all') {
        this.setState({ gender: 'all' })
        ids.map((id) => {
          if (id !== 'gender-all') {
            document.getElementById(id).style.backgroundColor = 'rgba(255, 255, 255, 0.01)';
          }
        })
      }
      if (value[1] === "male" && gender !== 'male') {
        this.setState({ gender: 'male' })
        ids.map((id) => {
          if (id !== 'gender-male') {
            document.getElementById(id).style.backgroundColor = 'rgba(255, 255, 255, 0.01)';
          }
        })
      }
      if (value[1] === "female" && gender !== 'female') {
        this.setState({ gender: 'female' })
        ids.map((id) => {
          if (id !== 'gender-female') {
            document.getElementById(id).style.backgroundColor = 'rgba(255, 255, 255, 0.01)';
          }
        })
      }
    }

    if (value[0] === "build") {
      let ids = ['build-all', 'build-slim', 'build-athletic', 'build-plus'];
      if (value[1] === "all" && build !== 'all') {
        this.setState({ build: 'all' })
        ids.map((id) => {
          if (id !== 'build-all') {
            document.getElementById(id).style.backgroundColor = 'rgba(255, 255, 255, 0.01)';
          }
        })
      }
      if (value[1] === "slim" && build !== 'slim') {
        this.setState({ build: 'slim' })
        ids.map((id) => {
          if (id !== 'build-slim') {
            document.getElementById(id).style.backgroundColor = 'rgba(255, 255, 255, 0.01)';
          }
        })
      }
      if (value[1] === "athletic" && build !== 'athletic') {
        this.setState({ build: 'athletic' })
        ids.map((id) => {
          if (id !== 'build-athletic') {
            document.getElementById(id).style.backgroundColor = 'rgba(255, 255, 255, 0.01)';
          }
        })
      }
      if (value[1] === "plus" && build !== 'plus') {
        this.setState({ build: 'plus' })
        ids.map((id) => {
          if (id !== 'build-plus') {
            document.getElementById(id).style.backgroundColor = 'rgba(255, 255, 255, 0.01)';
          }
        })
      }
    }

    if (value[0] === "height") {
      let ids = ['height-all'];

      if (height[1] === 100 && height[0] === 0 && document.getElementById('height-all').style.backgroundColor !== 'rgb(255, 255, 255)') {
        document.getElementById('height-all').style.backgroundColor = 'rgba(255, 255, 255)';

        return;
      }
      else if (height[1] === 100 && height[0] === 0 && document.getElementById('height-all').style.backgroundColor === 'rgb(255, 255, 255)') {
        document.getElementById('height-all').style.backgroundColor = 'rgba(255, 255, 255, 0.01)';
        this.setState({ height: [40, 60] })
        return;
      }

      if (value[1] === "all" && (height[0] !== 0 || height[1] !== 100)) {
        this.setState({ height: [0, 100] })
        document.getElementById('height-all').style.backgroundColor = 'rgba(255, 255, 255, 0.01)';

      }
      else {
        this.setState({ height: [40, 60] })
      }
    }

    if (value[0] === "tags") {
      let ids = ['tags-runway', 'tags-photoshoot', 'tags-underwear', 'tags-swimsuit', 'tags-parts']

      if (value[1] === "runway" && !tags.includes('runway')) this.setState((state) => ({ tags: state.tags.concat(['runway']) }))
      else if (value[1] === "runway") this.setState((state) => ({ tags: state.tags.filter(tag => tag !== 'runway') }))

      if (value[1] === "photoshoot" && !tags.includes('photoshoot')) this.setState((state) => ({ tags: state.tags.concat(['photoshoot']) }))
      else if (value[1] === "photoshoot") this.setState((state) => ({ tags: state.tags.filter(tag => tag !== 'photoshoot') }))

      if (value[1] === "underwear" && !tags.includes('underwear')) this.setState((state) => ({ tags: state.tags.concat(['underwear']) }))
      else if (value[1] === "underwear") this.setState((state) => ({ tags: state.tags.filter(tag => tag !== 'underwear') }))

      if (value[1] === "swimsuit" && !tags.includes('swimsuit')) this.setState((state) => ({ tags: state.tags.concat(['swimsuit']) }))
      else if (value[1] === "swimsuit") this.setState((state) => ({ tags: state.tags.filter(tag => tag !== 'swimsuit') }))

      if (value[1] === "parts" && !tags.includes('parts')) this.setState((state) => ({ tags: state.tags.concat(['parts']) }))
      else if (value[1] === "parts") this.setState((state) => ({ tags: state.tags.filter(tag => tag !== 'parts') }))
    }


    let div = document.getElementById(e.currentTarget.id);
    div.style.backgroundColor = div.style.backgroundColor == 'rgba(255, 255, 255, 0.01)' ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.01)';

  }

  setApplicationSettings = (e) => {
    let value = e.currentTarget.id.split(/\s*\-\s*/g);
    const { profiles, photos } = this.state;
    if (value[0] === "profiles") {
      let ids = ['profiles-url', 'profiles-instagram', 'profiles-youtube', 'profiles-twitter']

      if (value[1] === "url" && !profiles.includes('url')) this.setState((state) => ({ profiles: state.profiles.concat(['url']) }))
      else if (value[1] === "url") this.setState((state) => ({ profiles: state.profiles.filter(profile => profile !== 'url') }))

      if (value[1] === "instagram" && !profiles.includes('instagram')) this.setState((state) => ({ profiles: state.profiles.concat(['instagram']) }))
      else if (value[1] === "instagram") this.setState((state) => ({ profiles: state.profiles.filter(profile => profile !== 'instagram') }))

      if (value[1] === "youtube" && !profiles.includes('youtube')) this.setState((state) => ({ profiles: state.profiles.concat(['youtube']) }))
      else if (value[1] === "youtube") this.setState((state) => ({ profiles: state.profiles.filter(profile => profile !== 'youtube') }))

      if (value[1] === "twitter" && !profiles.includes('twitter')) this.setState((state) => ({ profiles: state.profiles.concat(['twitter']) }))
      else if (value[1] === "twitter") this.setState((state) => ({ profiles: state.profiles.filter(profile => profile !== 'twitter') }))

    }

    if (value[0] === "photos") {
      let ids = ['photos-fulllength', 'photos-waistup', 'photos-closeup', 'photos-sideprofile', 'photos-video']

      if (value[1] === "fulllength" && !photos.includes('fulllength')) this.setState((state) => ({ photos: state.photos.concat(['fulllength']) }))
      else if (value[1] === "fulllength") this.setState((state) => ({ photos: state.photos.filter(photo => photo !== 'fulllength') }))

      if (value[1] === "waistup" && !photos.includes('waistup')) this.setState((state) => ({ photos: state.photos.concat(['waistup']) }))
      else if (value[1] === "waistup") this.setState((state) => ({ photos: state.photos.filter(photo => photo !== 'waistup') }))

      if (value[1] === "closeup" && !photos.includes('closeup')) this.setState((state) => ({ photos: state.photos.concat(['closeup']) }))
      else if (value[1] === "closeup") this.setState((state) => ({ photos: state.photos.filter(photo => photo !== 'closeup') }))

      if (value[1] === "sideprofile" && !photos.includes('sideprofile')) this.setState((state) => ({ photos: state.photos.concat(['sideprofile']) }))
      else if (value[1] === "sideprofile") this.setState((state) => ({ photos: state.photos.filter(photos => photos !== 'sideprofile') }))

      if (value[1] === "video" && !photos.includes('video')) this.setState((state) => ({ photos: state.photos.concat(['video']) }))
      else if (value[1] === "video") this.setState((state) => ({ photos: state.photos.filter(photos => photos !== 'video') }))

    }

    let div = document.getElementById(e.currentTarget.id);
    div.style.backgroundColor = div.style.backgroundColor == 'rgba(255, 255, 255, 0.01)' ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.01)';

  }


  handleFileUpload = (event) => {
    this.setState({ file: event.target.files });
    if (event.target.files && event.target.files[0]) {
      let cropper = document.getElementById('coverPhotoCropper')
      if (cropper) {
        cropper.style.display = 'block';
      }
      this.setState({
        preview: URL.createObjectURL(event.target.files[0])
      });
    }
  }

  setInputValue = (key, value) => this.setState({ [key]: value })

  handleNextPage = (page) => {
    /* if(this.state.page === 1) {
      const currentCropData = this.coverPhotoRef.current.getData();
      this.setState({ currentCropData })
    }
    Trying to persist the exact croppage of the photo will get back to this, currently the full-size preview photo persists
     */
    this.setState((state) => ({ page: state.page + 1 }))
    window.scrollTo(0, 0)
    const { url } = this.props.match.params || null;
    if (url) this.props.history.push(`/create-campaign-edit/${url}/${page}`)
    else this.props.history.push(`/create-campaign/${page}`)
  }

  handlePreviousPage = (page) => {
    this.setState((state) => ({ page: state.page - 1 }), () => {
      if (this.state.page === 1 && this.state.file) {
        let cropper = document.getElementById('coverPhotoCropper')
        if (cropper) {
          cropper.style.display = 'block';
        }
      }
    })
    console.log('Handle previous page called the page we need to go to is ' + page)
    window.scrollTo(0, 0);
    const { url } = this.props.match.params || null;
    if (url) this.props.history.push(`/create-campaign-edit/${url}/${page}`)
    else this.props.history.push(`/create-campaign/${page}`)
  }

  checkClientErrors = async (values) => {
    const map = {
      0: 'Cover Photo',
      1: 'Description',
      2: 'Location',
      3: 'Gender',
      4: 'Model Build',
      5: 'Tags',
      8: 'Applicant Limit',
      9: 'Hire Limit',
      10: 'Title',
      11: 'Please select a location from the dropdown menu 🤖.',
    }
    for (let i = 0; i < values.length; i += 1) {
      if (i === 11) {
        if (!Object.keys(values[i].length > 0)) {
          return {
            message: map[i]
          }
        }
      }
      else if (i === 5) {
        if (!values[i].length) return {
          message: `Missing ${map[i]} 🤖.`,
        }
      }
      else if (!values[i] && (i !== 6 || i !== 7)) return {
        message: `Missing ${map[i]} 🤖.`,
      }
    }
    return false;
  }

  submitCampaign = async () => {
    const { title, description, startDate,
      location, gender, build, height, tags, profiles, photos,
      applicationLimit, hireLimit, deadline, file, preview, coverphoto, geocode, endDate
    } = this.state;
    const user = JSON.parse(localStorage.getItem('user'))

    const { url } = this.props.match.params || null;
    console.log(url)
    const checkCoverPhoto = url ? coverphoto : file
    let exit = await this.checkClientErrors([checkCoverPhoto, description, location, gender, build, tags, profiles, photos, applicationLimit, hireLimit, title, geocode])
    if (exit) {
      toast.error(exit.message, {
        position: toast.POSITION.BOTTOM_CENTER
      });
      return;
    }

    if (!url || (url && this.state.file)) {
      const formData = new FormData();
      console.log('appending', this.state.file[0])
      formData.append('file', this.state.file[0]);

      let response;
      try {
        this.setState({ loading: true })
        response = await axios.post(`${AWS_SERVICES_HOST_NAME}/create_signed_url_cover_photo`, {
          "brandname": user.brandname,
          "filename": file[0].name,
          "fileType": file[0].type
        })
        const { url: signedURL, getURL } = response.data;
        const upload = await axios.put(signedURL, file[0], {
          headers: {
            "Content-Type": file[0].type,
            "x-amz-acl": "public-read"
          }
        })
  
        const Location = getURL;
        await this.submitAPICall(Location)
        this.setState({ loading: false })
        this.props.history.push("/campaigns")
        toast.success("Campaign Successfully Created 🤖.", {
          position: toast.POSITION.BOTTOM_CENTER
        });
        this.props.getCampaigns()
      }
      catch (error) {
        console.log(error)
      }
      return;
    }

    // THIS MEANS THE USER IS EDITING AND ISNT UPLOADING A NEW COVERPHOTO
    try {
      this.setState({ loading: true })
      await this.submitAPICall(this.state.coverphoto);
      this.setState({ loading: false })
      this.props.history.push("/campaigns")
      const message = url ? "Campaign Successfully Edited" : "Campaign Successfully Created 🤖."
      toast.success(message, {
        position: toast.POSITION.BOTTOM_CENTER
      });
      this.props.getCampaigns()
    }
    catch (error) {
      toast.error(JSON.stringify(error.response), {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }

  submitAPICall = async (Location) => {
    const { height, title, description, startDate, location, gender, build, tags, profiles, photos, applicationLimit, hireLimit, deadline, deadlineTimestamp, geocode, endDate } = this.state;
    const user = JSON.parse(localStorage.getItem('user'))
    const { url } = this.props.match.params || null;
    let convertedHeights = [];
    convertedHeights[0] = Math.floor(((height[0] / 5 + 58) * 10) / 10 / 12) + "'" + Math.floor((((height[0] / 5 + 58) * 10) / 10) % 12);
    convertedHeights[1] = Math.floor(((height[1] / 5 + 58) * 10) / 10 / 12) + "'" + Math.floor((((height[1] / 5 + 58) * 10) / 10) % 12);
    const postEndpoint = url ? '/edit_campaign' : '/create_campaign';
    const { modelRelease } = this.state;
    let releaseURL = null;
    if (modelRelease.file) {

      const modelReleaseEndpoint = await axios.post(`${AWS_SERVICES_HOST_NAME}/createSignedUrlModelRelease`, {
        "brandname": user.brandname,
        "filename": modelRelease.name,
        "fileType": "pdf"
      });

      const { url: signedURL, getURL } = modelReleaseEndpoint.data;
      releaseURL = getURL;

      const upload = await axios.put(signedURL, modelRelease.file, {
        headers: {
          "Content-Type": modelRelease.file.type,
          "x-amz-acl": "public-read"
        }
      })

    } else {
      releaseURL = this.state.releaseURL
    }

    //await uploadModelRelease(modelReleaseSignedURL, modelRelease.file)

    await axios.post(`${BRAND_SERVICES_HOST_NAME}${postEndpoint}`, {
      email: user.email,
      coverphoto: Location,
      title,
      description,
      startDate: startDate.toString(),
      endDate: endDate.toString(),
      location,
      gender,
      build,
      height: convertedHeights,
      tags,
      profiles,
      photos,
      applicationLimit,
      hireLimit,
      deadline: deadline.toString(),
      deadlineTimestamp: new Date(deadline).getTime(),
      uid: user._id,
      brand: user.brandname,
      link: url,
      geocode,
      releaseURL,
    })
  }

  _crop = () => {
    if (this.coverPhotoRef.current && typeof this.coverPhotoRef.current.getCroppedCanvas === "function" && this.state.coverPhotoFile)
      this.coverPhotoRef.current.getCroppedCanvas().toBlob((b) => {
        let file = new File([b], "coverPhotoFile")
        this.setState({ coverPhotoFile: file })
      })

  }


  render() {
    const { releaseURL, modelRelease, sessionToken, endDate, coverphoto, currentCropData, coverPhotoFile, loading, title, description, startDate, location, page, height, gender, tags, build, profiles, photos, deadline, applicationLimit, hireLimit, preview } = this.state;
    if (loading) return <Loader />

    const isEditing = this.props.match.params.url ? true : false;
    const basePath = isEditing ? '/create-campaign-edit/:url/' : '/create-campaign/';
    const renderPage = () => {
      const { page } = this.props.match.params;
      if(page === "1") {
        return  <BasicInformation endDate={endDate} match={this.props.match} sessionToken={sessionToken} coverphoto={coverphoto} currentCropData={currentCropData} coverPhotoFile={coverPhotoFile} _crop={this._crop} coverPhotoRef={this.coverPhotoRef} history={this.props.history} preview={preview} handleFileUpload={this.handleFileUpload}
        submitFile={this.submitFile} title={title} description={description} startDate={startDate} location={location} setInputValue={this.setInputValue} handleDate={this.handleDate} handleNextPage={this.handleNextPage} />
      }
      else if (page === "2") {
        return         <ModelSpecifications
        setInputValue={this.setInputValue} onSliderChange={this.onSliderChange} setModelSpec={this.setModelSpec} handlePreviousPage={this.handlePreviousPage} height={height}
        gender={gender} tags={tags} build={build} handleNextPage={this.handleNextPage}
      />
      }
      else if (page === "3") {
        return <ApplicantSettings releaseURL={releaseURL} modelRelease={modelRelease} submitCampaign={this.submitCampaign} applicationLimit={applicationLimit} hireLimit={hireLimit} setInputValue={this.setInputValue} handleDate={this.handleDate} deadline={deadline} profiles={profiles} photos={photos} handlePreviousPage={this.handlePreviousPage} setApplicationSettings={this.setApplicationSettings} />
        
      }
    }
    return (
      <Wrapper>
        <LeftSection />
        {renderPage()}

      </Wrapper>
    )

  }
}







/*
  Testing Purposes for page two
  <div style={{ gridRow: '10 / span 3', gridColumn: 12, width: 200 }}>
   <div>
     {gender ? <div> Gender: {gender} </div> : <div> No Gender Chosen </div>}
     {build ? <div> Build: {build} </div> : <div> No Build Chosen </div>}
     { height &&
       <div>
         <div> Heights </div>
         <div> {Math.floor(((height[0]/5 + 58) * 10) / 10 / 12) + "'" + Math.floor((((height[0]/5 + 58) * 10) / 10) % 12)} </div>
         <div> {Math.floor(((height[1]/5 + 58) * 10) / 10 / 12) + "'" + Math.floor((((height[1]/5 + 58) * 10) / 10) % 12)} </div>
       </div>
     }

     {tags.length ? tags.map((tag,index) => <div> {index+1} {` ${tag}`} </div> ): 'No Tags Selected'}

   </div>
  </div>

*/



export default CreateCampaign;

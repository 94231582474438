import axios from 'axios'
import { GOOGLE_PLACES_HOST_NAME } from '../../Config'
const GooglePlacesAutoComplete = async (searchTerm, sessionToken) => {
    const response = await axios.get(`${GOOGLE_PLACES_HOST_NAME}/google_places/get_google_places_predictions?searchTerm=${searchTerm}&sessionToken=${sessionToken}`)
    console.log(response.data.predictions, 'response.data.predictions')
    console.log(response)
    const { data } = response;
    const { predictions } = data;
    return predictions;
}

const GooglePlacesGeocode = async (placeID) => {
    const response = await axios.get(`${GOOGLE_PLACES_HOST_NAME}/google_places/get_google_places_geocode?placeID=${placeID}`);
    console.log(response.data)
    const formatted_address = response.data.formatted_address;
    const lat = response.data.geometry.location.lat;
    const lng = response.data.geometry.location.lng;
    let locality;
    let query = response.data.address_components.find(component => component.types.includes('locality'))
    if(query) locality = query.short_name;
    else if(!query) {
        query = response.data.address_components.find(component => component.types.includes('political'))
        if(query) {
            locality = query.short_name;
        }
    }
    return  {
        formatted_address,
        lat,
        lng,
        locality
    };
}

export { GooglePlacesAutoComplete, GooglePlacesGeocode }
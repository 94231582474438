import React, { useState, useEffect, useRef, useContext } from 'react';
import styled from 'styled-components';
import { x } from './icons'
const Background = styled.div`
  display: ${props => props.open ? 'block' : 'none'}; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
`;

const Wrapper = styled.div`
  width: 50%;
  margin: 5% auto; /* 15% from the top and centered */
  max-width: 500px;
  padding: 20px;
  display: grid;
  grid-auto-rows: max-content;
  grid-row-gap: 24px;
  background-color: #fafafa;
  @media (max-width: 600px) {
    margin: 0;
    padding: 0;
    width: 100%;
    min-height: 100vh;
  }
`;


const HeadingWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 60px;
  align-items: center;
  justify-items: center;
  height: 80px;
  background-color: #fff;
  border: solid 1px #e1e1e1;
`;

const Heading = styled.div`
  font-size: 20px;
  font-weight: 700;
  grid-column: 1 / -1;
  grid-row: 1;
`;

const ExitWrapper = styled.div`
    grid-row: 1;
    grid-column: 2;
`;

const ExitImg = styled.img`
  height: 24px;
  width: 18px;
`;

const Modal = ({ open, setOpen, heading, children }) => {
  const innerRef = useRef(null);
    return (
        <Background open={open}>
            <Wrapper ref={open ? innerRef : null} >
                <HeadingWrapper>
                    <Heading> {heading} </Heading>
                    <ExitWrapper onClick={() => open && innerRef ? setOpen(false) : null}> <ExitImg src={x} /> </ExitWrapper>
                </HeadingWrapper>
                {children}

            </ Wrapper> 
        </Background>
    )
}

export default Modal;
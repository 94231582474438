import React from 'react';
import runwayGIF from '../Assets/404gif.gif';
import ThuggerGIF from '../Assets/404thugger.gif';
import './NotFound.css'
const NotFound = () => {
  return (
    <div style={{ display:'grid', gridTemplateRows:'calc(100vh - 70px)', justifyContent:'center' }}>
      <div className="HeaderNotFound"  style={{ display:'grid', gridTemplateColumns:'min-content auto', gridColumnGap: 5}}>
        <div className="HeaderNotFound" style={{   fontWeight: 'bold', justifySelf:'flex-end', lineHeight: '2.4rem', letterSpacing: '.25rem', alignSelf:'flex-end' }}> 404  </div>
        <div className="HeaderNotFound" style={{   fontWeight: 100, lineHeight: '2.4rem', letterSpacing: '.25rem',  alignSelf:'flex-end'}}>  - PAGE NOT FOUND </div>
        <div style={{ gridColumn: '1 / span 2', justifySelf:'center', paddingTop: 20 }}> <img src={ThuggerGIF} /> </div>
      </div>

    </div>
  )
}

export default NotFound;

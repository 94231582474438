import React, { Component } from 'react';
import './Network.css';
import axios from 'axios';
import Loader from './Loader'
import NextButton from '../Assets/CampaignApplicants/next-button.png'
import PreviousButton from '../Assets/CampaignApplicants/previous-button.png'
import { Link } from 'react-router-dom'
import PrivateRoute from './PrivateRoute';
import NetworkModelDetails from './NetworkModelDetails'
import ModelTableView from './ModelsTableView';
import { toast } from "react-toastify";
import CampaignApplicantDetails from './CampaignApplicantDetails'
import { HOST_NAME } from '../Config';
class Network extends Component {
  constructor(props) {
    super(props);
    this.state = {
        loading: false,
        models: [],
        filteredModels: [],
        upper: 10,
        lower: 0,
        searchTerm: ''
      }
  }


  componentDidMount() {
    const user = JSON.parse(localStorage.getItem('user'));
    this.getModelsFromNetwork(user._id);
  }

  getModelsFromNetwork = (_id) => {
    this.setState({ loading: true })
    axios.post(`${HOST_NAME}/api/network/get_models_from_network`, {
      _id
    })
    .then(res => {

      const { models } = res.data;

      this.setState({  loading: false, models, filteredModels: models });

    })
    .catch(err => {
      this.setState({ loading: false })
      console.log(err)
    })
  }

  next = () => {
    const { upper, lower, filteredModels } = this.state;
    if(upper < filteredModels.length) {
      this.setState({ upper: upper + 10, lower: lower + 10 })
    }

  }

  previous = () => {
    const { upper, lower } = this.state;
    if(upper !== 10) {
      this.setState({ upper: upper - 10, lower: lower - 10 })
    }
  }

  filter = (value) => {
    this.setState({ searchTerm: value })
  }

  removeModelFromNetwork = (modelID) => {
    this.setState({ loading: true })
    axios.post(`${HOST_NAME}/api/network/remove_model_from_network`, {
      modelID,
      brandID: this.props.user._id
    })
    .then((res) => {
      this.getModelsFromNetwork(this.props.user._id);
      this.setState({ loading: false})
      toast.success("The Model Has Been Removed From Your Network 🤖.", {
        position: toast.POSITION.BOTTOM_CENTER
      });
    })
    .catch(err => {
          this.setState({ loading: false})
    })
  }

  renderNetworkUpperSection = () => {
      return (
        <div id="NetworkUpperSection" style={{ paddingBottom: 10 }}>
          <div className="ModelNetworkHeader" style={{ justifySelf:'' }}> Model Network </div>
          <div className="ModelNetworkSearch">
            <input
              onChange={(e) => this.filter(e.target.value)}
              style={{ width: 200, height: 24,}}
              placeholder="Who are you looking for?"
            />
          </div>
        </div>
      )
  }

  render() {
    const { models, loading, upper, lower, dateAndTags,   } = this.state;
    let { filteredModels } = this.state;
    const { match } = this.props;
    let searchTerm = this.state.searchTerm.trim().toLowerCase();
    if (searchTerm.length > 0) {
      filteredModels = filteredModels.filter(function(i) {
        const name = i.firstName + " " + i.lastName;
        return name.toLowerCase().match( searchTerm );
      });
    }

    if(loading) return <Loader />
    return (
      <div style={{ backgroundColor:'#F6F8FA', display: 'grid', gridTemplateColumns:'repeat(16,1fr)', minHeight:'calc(100vh - 70px)', gridTemplateRows: 'max-content'  }}>

      {this.renderNetworkUpperSection()}

        <div style={{ gridColumn:'1 / span 16' }}>
          <ModelTableView
            remove={this.removeModelFromNetwork}
            models={models}
            data={filteredModels}
            path={`/network/:modelID`}
            component={CampaignApplicantDetails}
            upper={upper}
            lower={lower}
            next={this.next}
            previous={this.previous}
            {...this.props}
            />
        </div>
      </div>
    )
  }
}

export default Network;

import React from 'react';
import styled from 'styled-components';
import { mail, instagram, twitter } from './icons'
const Wrapper = styled.div`
    background-color: #000;
    height: 339px;
    width: 100%;
    font-family: Trade Gothic;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;
    @media(max-width: 414px) {
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        grid-column-gap: 12px;
    }
`

const BlockOne = styled.div`
    display: grid;
    grid-auto-rows: min-content;
    grid-row-gap: 12px;
    padding 35px 0px 0px 0px;
    @media(max-width: 414px) {
        padding: 35px 12px 0px 12px;
    }
`

const BlockTwo = styled.div`
    align-self: center;
    display: grid;
    grid-auto-rows: min-content;
    grid-row-gap: 32px;
    text-transform: uppercase;
    @media(max-width: 414px) {
        display: none;
    }
`

const BlockThree = styled.div`
    align-self: center;
    display: grid;
    grid-auto-rows: min-content;
    grid-row-gap: 32px;
`

const Text = styled.div`
    font-weight: ${props => props.fontweight};
    font-size: ${props => props.fontsize};
    color: ${props => props.color || '#fff'};
    cursor: ${props => props.cursor};
`
const SocialsWrapper = styled.div`
    display: grid;
    grid-template-columns: min-content min-content min-content;
    grid-column-gap: 12px;
`

const Social = styled.img`
    cursor: pointer;
`
const scroll = (id) => {
    document.querySelector(id).scrollIntoView({
        behavior: 'smooth'
    })
}
const Footer = ({ history }) => {

    return (
        <Wrapper>
            <BlockOne>
                <Text fontweight={'1000'} fontsize={'20px'}> RUNWAYSUPPLY </Text>
                <Text fontsize={'12px'}> COPYRIGHT © 2020. ALL RIGHTS RESERVED </Text>
            </BlockOne>

            <BlockTwo>
                <Text cursor={'pointer'} fontsize={'20px'} fontweight={'bold'} color={'#F4D17A'}> About </Text>
                <Text cursor={'pointer'} fontsize={'15px'} fontweight={'bold'} onClick={() => scroll('.features')}> Features </Text>
                <Text cursor={'pointer'} fontsize={'15px'} fontweight={'bold'} onClick={() => scroll('#pricing')}>Pricing </Text>
                <Text cursor={'pointer'} fontsize={'15px'} fontweight={'bold'} onClick={() => history.push('/modelguidelines')}> Guidelines </Text>
            </BlockTwo>

            <BlockTwo>
                <Text fontsize={'20px'} fontweight={'bold'} color={'#F4D17A'}> Legal </Text>
                <Text fontsize={'15px'} fontweight={'bold'} > Privacy </Text>
                <Text fontsize={'15px'} fontweight={'bold'} >Terms Of Service </Text>
                <Text fontsize={'15px'} fontweight={'bold'} > Licenses </Text>
            </BlockTwo>

            <BlockThree>
                <Text fontsize={'20px'} fontweight={'bold'} color={'#F4D17A'}> CONTACT </Text>
                <Text fontsize={'15px'} fontweight={'bold'} > 714 S. Los Angeles St. 512, Los Angeles, CA 90014 </Text>
                <SocialsWrapper>
                        <Social onClick={() => {window.open('https://instagram.com/runwaysupply', '_blank')}} src={instagram} />
                        <Social onClick={() => {window.open('https://twitter.com/runwaysupply', '_blank')}} src={twitter} />
                        <Social onClick={() => window.location = "mailto:contact@runway.supply"} src={mail} />
                    </SocialsWrapper>
                    <div> </div>

            </BlockThree>
        </Wrapper >
    )
}

export default Footer;
import React, { Component } from 'react';
import axios from 'axios';
import Loader from './Loader';
import { toast } from "react-toastify";
// campaign details
import CampaignsLocationIcon from "../Assets/Campaigns/campaigns-location-icon.png";
import CampaignsDateTimeIcon from "../Assets/CreateCampaign/calendar-icon.png";
import DeadlineIcon from "../Assets/CreateCampaign/deadline-tile-icon.png";
import ApplicantsIcon from "../Assets/CreateCampaign/applicant-tile-icon.png";
import BrandIcon from "../Assets/Modal/login-brand-icon.png";
//basic info
import PencilIcon from "../Assets/CreateCampaign/pencil-tile-icon.png";
import EmailIcon from "../Assets/CreateCampaign/email-tile-icon.png";
import PhoneIcon from "../Assets/CreateCampaign/phone-tile-icon.png";
import BirthdayIcon from "../Assets/CreateCampaign/birthday-tile-icon.png";
import CityIcon from "../Assets/CreateCampaign/city-tile-icon.png";

//navigation
import NextButton from '../Assets/CampaignApplicants/next-button.png'
import PreviousButton from '../Assets/CampaignApplicants/previous-button.png'

//socials
import InstagramIcon from "../Assets/CreateCampaign/instagram-tile-icon.png";
import TwitterIcon from "../Assets/CreateCampaign/twitter-tile-icon.png";
import URLIcon from "../Assets/CreateCampaign/url-tile-icon.png";
import YoutubeIcon from "../Assets/CreateCampaign/youtube-tile-icon.png";
import './CampaignApplicantDetails.css';
import './NetworkModelDetails.css'
class NetworkModelDetails extends Component {
  state = {
    model: false
  }

  componentDidMount() {
    let { models } = this.props;
    if(document.getElementById('ViewApplicantsContainer'))  document.getElementById('ViewApplicantsContainer').style.display = 'none'
    if(document.getElementById('NetworkUpperSection')) document.getElementById('NetworkUpperSection').style.display='none'
    
    const currentIndex = null;
    const model = models.find((model,index) => {
      this.currentIndex = index;
      return model._id === this.props.match.params.modelID
    });
    if(!model) this.props.history.push("/404")
    this.setState({ model, currentIndex  })
  }

  componentWillUnmount() {
    if(document.getElementById('ViewApplicantsContainer'))  document.getElementById('ViewApplicantsContainer').style.display = 'grid'
    if(document.getElementById('NetworkUpperSection')) document.getElementById('NetworkUpperSection').style.display='grid'
  }

  next = () => {
    const { currentIndex } = this.state;
    const { models } = this.props;
    if(currentIndex < models.length - 1) {
      const model = models.find((model, index) => index === currentIndex + 1);
      this.setState({ model, currentIndex: currentIndex + 1 })
    }
  }

  previous = () => {
    const { currentIndex } = this.state;
    const { models } = this.props;
    if(currentIndex > 0) {
      const model = models.find((model, index) => index === currentIndex - 1);
      this.setState({ model, currentIndex: currentIndex - 1 })
    }
  }



  render() {
    const { model, loading } = this.state;
    const { campaign } = this.props;
    const { firstName, lastName, email, phone, dob, city } = model;
    return (
      <div>
        {loading && <Loader />}
        <div className="NetworkModelDetailsContainer">

          <div style={{ gridRow: 7, gridColumn: '4 / span 10' }}> BASIC INFO </div>
          <div className="Basic-Information-Campaign-Applicant-Details" style={{ }}>
            <div style={{ display:'grid', gridTemplateColumns: 'repeat(16,1fr)', border: '1px solid #ccc', backgroundColor:'rgb(255, 255, 255)' }}>
              <div style={{ gridColumn: 2, width:20, alignSelf:'center' }}> <img src={PencilIcon}  style={{width: 25, height: 25}}/> </div>
              <div style={{ gridColumn:'6 / span 12', alignSelf:'center' }}> <input type="text" readonly value={firstName} style={{ width: 'calc(100% - 16px)', height:40, border:'none' }}/> </div>
            </div>
            <div style={{ display:'grid', gridTemplateColumns: 'repeat(16,1fr)', border: '1px solid #ccc',backgroundColor:'rgb(255, 255, 255)' }}>
              <div style={{ gridColumn: 2, width:20, alignSelf:'center' }}> <img src={PencilIcon}  style={{width: 25, height: 25}}/> </div>
              <div style={{ gridColumn:'6 / span 12', alignSelf:'center' }}> <input type="text" readonly value={lastName} style={{ width: 'calc(100% - 16px)', height:40, border:'none' }}/> </div>
            </div>
            <div style={{ display:'grid', gridTemplateColumns: 'repeat(16,1fr)', border: '1px solid #ccc', backgroundColor:'rgb(255, 255, 255)' }}>
              <div style={{ gridColumn: 2, width:20, alignSelf:'center' }}> <img src={EmailIcon}  style={{width: 25, height: 20}}/> </div>
              <div style={{ gridColumn:'6 / span 12', alignSelf:'center' }}> <input type="text" readonly value={email} style={{ width: 'calc(100% - 16px)', height:40, border:'none' }}/> </div>
            </div>
            <div style={{ display:'grid', gridTemplateColumns: 'repeat(16,1fr)', border: '1px solid #ccc',backgroundColor:'rgb(255, 255, 255)' }}>
              <div style={{ gridColumn: 2, width:20, alignSelf:'center' }}> <img src={PhoneIcon}  style={{width: 25, height: 25}}/> </div>
              <div style={{ gridColumn:'6 / span 12', alignSelf:'center' }}> <input type="text" readonly value={phone} style={{ width: 'calc(100% - 16px)', height:40, border:'none' }}/> </div>
            </div>
            <div style={{ display:'grid', gridTemplateColumns: 'repeat(16,1fr)', border: '1px solid #ccc', backgroundColor:'rgb(255, 255, 255)' }}>
              <div style={{ gridColumn: 2, width:20, alignSelf:'center' }}>   <img src={BirthdayIcon}  style={{width: 25, height: 25}}/>  </div>
              <div style={{ gridColumn:'6 / span 12', alignSelf:'center' }}> <input type="text" readonly value={dob} style={{ width: 'calc(100% - 16px)', height:40, border:'none' }}/> </div>
            </div>
            <div style={{ display:'grid', gridTemplateColumns: 'repeat(16,1fr)', border: '1px solid #ccc',backgroundColor:'rgb(255, 255, 255)' }}>
              <div style={{ gridColumn: 2, width:20, alignSelf:'center' }}>  <img src={CityIcon}  style={{width: 25, height: 25}}/> </div>
              <div style={{ gridColumn:'6 / span 12', alignSelf:'center' }}> <input type="text" readonly value={city} style={{ width: 'calc(100% - 16px)', height:40, border:'none' }}/> </div>
            </div>
          </div>

          <div style={{ gridRow: 11, gridColumn:'4 / span 10' }}> Profiles </div>

          <div className="Profiles-Campaiagn-Applicant-Details"  style={{   }}>
            {Object.keys(model).map(key => (
              key === "website" && model[key] ? (
                <div  id="profiles-url" style={{ display:'grid', gridTemplateColumns: 'repeat(16,1fr)', border: '1px solid #ccc', backgroundColor:'rgb(255, 255, 255)' }}>
                  <div style={{ gridColumn: '2 / span 1', alignSelf:'center' }}>  <img src={URLIcon}  style={{width: 25, height: 25}}/> </div>
                  <div style={{ gridColumn:'6 / span 9', alignSelf:'center' }}> <input value={model[key]} style={{ width:120, height:40, border:'none' }}/> </div>
                </div>
              ) : key === "instagram" && model[key] ? (
                <div  id="profiles-instagram" style={{ display:'grid', gridTemplateColumns: 'repeat(16,1fr)', border: '1px solid #ccc',backgroundColor:'rgb(255, 255, 255)' }}>
                  <div style={{ gridColumn: '2 / span 1', alignSelf:'center' }}>  <img src={InstagramIcon}  style={{width: 25, height: 25}}/> </div>
                  <div style={{ gridColumn:'6 / span 9', alignSelf:'center' }}> <input value={model[key]} style={{ width:'100%', overflow:'hidden', height:40, border:'none' }}/> </div>
                </div>
              ) : key === "youtube" && model[key] ? (
                <div  id="profiles-youtube" style={{ display:'grid', gridTemplateColumns: 'repeat(16,1fr)', border: '1px solid #ccc', backgroundColor:'rgb(255, 255, 255)' }}>
                  <div style={{ gridColumn: '2 / span 1', alignSelf:'center' }}>  <img src={YoutubeIcon}  style={{width: 25, height: 25}}/> </div>
                  <div style={{ gridColumn:'6 / span 9', alignSelf:'center' }}> <input value={model[key]} style={{ width:'100%', height:40, border:'none' }} /> </div>
                </div>
              ) : key === "twitter" && model[key] && (
                <div  id="profiles-twitter" style={{ display:'grid', gridTemplateColumns: 'repeat(16,1fr)', border: '1px solid #ccc',backgroundColor:'rgb(255, 255, 255)' }}>
                  <div style={{ gridColumn: '2 / span 1', alignSelf:'center' }}>  <img src={TwitterIcon}  style={{width: 25, height: 25}}/> </div>
                  <div style={{ gridColumn:'6 / span 9', alignSelf:'center' }}> <input value={model[key]} style={{ height:40, border:'none' }} /> </div>
                </div>
              )
            ))}
          </div>

          <div style={{ gridRow: 15, gridColumn:'4 / span 10'}}>
            Photos
          </div>

          <div className="Photos-Campaign-Applicant-Details" style={{  }}>
            {Object.keys(model).map(key => (
              key === "sideProfile" ? <div> <img style={{ width:150, height: 300, borderRadius: 10 }}src={model[key]} /> </div>
              : key === "closeUp" ? <div> <img style={{ width:150, height: 300, borderRadius: 10 }} src={model[key]} /> </div>
              : key === "fullLength" ? <div> <img style={{ width:150, height: 300, borderRadius: 10 }} src={model[key]} /> </div>
              : key === "waistUp" ? <div> <img style={{ width:150, height: 300, borderRadius: 10 }} src={model[key]} /> </div> : null
            ))}
          </div>

          <div style={{ gridRow: 19, gridColumn:'4 / span 4', maxWidth: 200, height:35, marginTop: 60 }}>
            <button onClick={() => {
              this.props.history.push(`${this.props.previousURL}`)
              window.scrollTo(0, 0);
            }} style={{  cursor: 'pointer', width:'100%', height:'100%', backgroundColor: '#24292E', color:'white' }}>
             Back To Network
            </button>
          </div>

          <div style={{ gridRow: 19, gridColumn: '12 / span 4',  height: 35, maxWidth: 200}}>

          </div>

        </div>
      </div>
    )
  }
}

export default NetworkModelDetails;

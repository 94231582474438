import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import { Question, Background, StepControl, UserInput, Button, Text } from '../../index';
import { MaskPhone } from '../functions/index'
import isPhone from 'validator/lib/isMobilePhone'
const Wrapper = styled.div`
    display: grid;
    grid-auto-rows: min-content;
    grid-row-gap: 24px;
`;

const AlreadyAModel = () => {
    const [codeInput, setCodeInput] = useState(false); // do we render the code input or the phone number input
    const [error, setError] = useState(''); // for showing client errors
    const [value, setValue] = useState(''); // users phone number
    const [phone, setPhone] = useState('')
    const renderText = () => {
        if (!codeInput) return <Text> Enter your mobile number </Text>
        else return <Text> Enter your 4 digit code </Text>
    }
    console.log(value)
    const renderButton = () => {
        if (!codeInput) {
            return <Button width={'120px'} onClick={() => {
                console.log(value)
                console.log(value.replace(/[{()}]/g, ''))
                if (isPhone(value.replace(/[- )(]/g,''))) {
                    setCodeInput(true);
                    setError('')
                    setValue('')
                    setPhone(value)
                    console.log(value.replace(/[- )(]/g,''))
                }
                else {
                    setError('Invalid Phone Number')
                }
            }} > Send Code </Button>
        }
        else {
            return <Button width={'120px'} onClick={() => {
                if (false) {
                    setError('')
                }
                else {
                    setError('Invalid Code')
                }
            }} > Apply Now </Button>
        }

    }

    useEffect(() => {
      
    }, [])

    return (
        <Wrapper>
            <Button> Already A Model? </Button>
            {renderText()}
            <UserInput placeholder="(888) 888-8888" id={!codeInput ? 'phone' : null} callback={(value) => setValue(value)} value={value} setError={setError} />
            <Text color={'red'}> {error} </Text>
            {renderButton()}
        </Wrapper>
    )
}

export default AlreadyAModel;
import React, { useEffect } from 'react';
import styled from 'styled-components'
import { CampaignsLocationIcon, CalendarIcon, URLIcon, YoutubeIcon, WaistIcon, VideoIcon, UnderwearIcon, TwitterIcon, SwimIcon, SlimIcon, SideIcon, PhotoshootIcon, PhoneIcon, RunwayIcon, PencilIcon, PlusIcon, PartsIcon, NotifyIcon, MaleIcon, LengthIcon, InstagramIcon, FemaleIcon, EmailIcon, DeadlineIcon, CampaignsDateTimeIcon, BasicInfoIcon, ModelSpecsIcon, ApplicationSettingsIcon, CreateCampaignIcon, ApplicantsIcon, AthleticIcon, BirthdayIcon, CityIcon, CloseUpIcon, } from '../../Assets/CreateCampaign'

const LeftSectionWrapper = styled.div`
    max-width: 100%;
    background-color: #24292E;
    display: grid;
    grid-auto-rows: min-content;
    grid-row-gap: 100px;
    min-height: 100vh;
    height: 100%;
    max-width: 200px;
    ${LeftSectionWrapper} > div {
        padding: 12px 8px;
    }
    @media(max-width: 600px) {
        display: none;
    }
`


const LeftSection = () => {
    useEffect(() => {
        console.log('Hello')
    }, [])
    return (
        <LeftSectionWrapper>
            <div style={{ color: '#fff' }}> Runway Supply </div>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 40px', color: '#fff' }}>
                <div style={{}}> Create Campaign </div>
                <div style={{}}> <img src={CreateCampaignIcon} /> </div>
            </div>
            <div style={{ color: '#fff', }}>
                <img src={BasicInfoIcon} style={{ height: 10 }} /> Basic Information <div style={{ fontSize: 10 }}> Tell us about your campaign </div>
            </div>
            <div style={{ color: 'rgb(255, 255, 255, .3)' }}>
                <img src={ModelSpecsIcon} style={{ height: 10 }} /> Model Specifications
                     <div style={{ fontSize: 10 }}> Who is your ideal model(s) </div>
            </div>
            <div style={{ color: 'rgb(255, 255, 255, .3)' }}>
                <img src={ApplicationSettingsIcon} style={{ height: 10 }} /> Applicants Settings <div style={{ fontSize: 10 }}> What information do you need from your applicants </div>
            </div>
        </LeftSectionWrapper>

    )
}

export default LeftSection;
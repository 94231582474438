import React, { Component } from 'react';
import axios from 'axios';
import './Application.css'
import { GenderAllIcon, CampaignsLocationIcon, CalendarIcon, URLIcon, YoutubeIcon, WaistIcon, VideoIcon, UnderwearIcon, TwitterIcon, SwimIcon, SlimIcon, SideIcon, PhotoshootIcon, PhoneIcon, RunwayIcon, PencilIcon, PlusIcon, PartsIcon, NotifyIcon, MaleIcon, LengthIcon, InstagramIcon, FemaleIcon, EmailIcon, DeadlineIcon, CampaignsDateTimeIcon, BasicInfoIcon, ModelSpecsIcon, ApplicationSettingsIcon, CreateCampaignIcon, ApplicantsIcon, AthleticIcon, BirthdayIcon, CityIcon, CloseUpIcon, } from '../../Assets/CreateCampaign'
import BrandIcon from "../../Assets/Modal/shop-1.svg";
import { toast } from "react-toastify";
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css'; // see installation section above for versions of NPM older than 3.0.0
// If you choose not to use import, you need to assign Cropper to default
// var Cropper = require('react-cropper').default
import isEmail from 'validator/lib/isEmail';
import isPhone from 'validator/lib/isMobilePhone'
import isDate from 'validator/lib/isISO8601'
import isAfter from 'validator/lib/isAfter'
import DatePicker from "react-datepicker";

import NotFound from '../NotFound';
import Loader from '../Loader'

import 'react-dropdown/style.css'

import { HOST_NAME, BRAND_SERVICES_HOST_NAME } from '../../Config';
import { Helmet } from 'react-helmet'
import SecondaryLoader from 'react-loader-spinner'
import LocationRadius from './GoogleMap'
import styled from 'styled-components';
import Introduction from './Introduction'
import ApplicationMeta from './ApplicationMeta';
import BasicInformation from './BasicInformation';
import Gender from './Gender'
import SocialProfiles from './SocialProfiles';
import { Header, SubHeader, MiniHeader } from './shared/index';
import { Button, Text } from '../TypeForm';
import { ApplyWRSA } from '.'
const LocationRadiusWrapper = styled.div`
  display: grid;
  grid-row-gap: 36px;
  justify-content: center;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr
  grid-row-gap: 24px;d
`

const Content = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  grid-row-gap: 36px;
  padding: 0px 0px 100px 0px;
  grid-template-columns: 200px 1fr 200px
  grid-column-gap: 24px;
  @media(max-width: 700px) {
    grid-template-columns: 1fr;
    margin: 0;
    padding: 0px 12px 48px 12px;
  }
  @media(max-width: 350px) {
    padding: 0px 12px 48px 12px;
  }
`
const SubWrapper = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  grid-row-gap: 36px;
  padding: 0px 0px 0px 0px;
  @media(max-width: 700px) {
    padding: 0;
  }
`

const Meta = styled.div`
  @media(max-width: 700px) {
    display: none;
  }
`

const MapElement = styled.div`
  width: 600px;
  height: 100%;
  @media(max-width: 900px) {
    width: 500px;
  }
  @media(max-width: 500px) {
    width: 350px;
  }
  @media(max-width: 350px) {
    width: 300px;
  }
`


class Application extends Component {
  state = {
    campaign: null,
    loading: false,
    sideprofilepreview: '',
    sideprofilecroppedurl: '',
    closeuppreview: '',
    closeupcroppedurl: '',
    fulllengthpreview: '',
    fulllengthcroppedurl: '',
    waistuppreview: '',
    waistupcroppedurl: '',
    profilePicturePreview: '',
    firstName: '',
    lastName: '',
    email: ``,
    phone: '',
    dob: '',
    city: '',
    instagram: '',
    twitter: '',
    youtube: '',
    website: '',
    isDraft: false,
    gender: '',
    options: [],
    geocode: {}
  }

  closeUpRef = React.createRef();
  sideProfileRef = React.createRef();
  waistUpRef = React.createRef();
  fullLengthRef = React.createRef();
  profilePictureRef = React.createRef();

  componentDidMount() {
    this.getCampaignDataByURL();
    localStorage.setItem('campaignURLReroute', this.props.match.params.url);
  }

  getCampaignDataByURL = () => {
    const { url } = this.props.match.params;
    this.setState({ loading: true })
    axios.post(`${BRAND_SERVICES_HOST_NAME}/get_campaign_by_url`, { url })
      .then(res => {
        const { campaign } = res.data;
        if (campaign.live) {
          this.setState({ campaign, loading: false });
        }
        else {
          console.log(this.props.history)
          this.props.history.push("/404")
          this.setState({ loading: false, isDraft: true })
        }
      })
      .catch(err => {
        this.setState({ loading: false })
        console.log(err);
        const { message } = err.response.data;
        if (message) {
          toast.error(message + " 🤖.", {
            position: toast.POSITION.BOTTOM_CENTER
          });
        }
      })
  }

  setInputValue = (key, value) => this.setState({ [key]: value })

  renderLoadingState = () => {
    if (this.state.firstName) { //submission happening
      return (
        <div style={{ minHeight: '100vh', display: 'grid', gridTemplateColumns: 'repeat(16,1fr)', gridTemplateRows: '1fr 1fr' }}>
          <div style={{ alignSelf: 'flex-end', justifySelf: 'center', gridColumn: '4/span 10' }}> <SecondaryLoader type="Oval" color="#24292E" height="50" width="50" />  </div>
          <div style={{ justifySelf: 'center', gridColumn: '4/ span 10', fontSize: 14, fontWeight: 800 }}>
            <div style={{ paddingTop: 30, textAlign: 'center' }}> Submitting your application! Please do not navigate away from this page...  </div>
            <div style={{ paddingTop: 30, textAlign: 'center' }}> You will be redirected to the confirmation page when complete.  </div>
          </div>
        </div>
      )
    }
    else return (
      <div style={{ minHeight: '100vh', display: 'grid', gridTemplateColumns: 'repeat(16,1fr)' }}>
        <Loader />
        <div style={{ alignSelf: 'center', justifySelf: 'center', marginTop: 130, gridColumn: '4/ span 10', fontSize: 14, fontWeight: 800 }}>
          <div> Loading please wait...  </div>
        </div>
      </div>
    )
  }

  renderTagIcon = (tag) => {
    if (tag === "runway") return RunwayIcon
    else if (tag === "swimsuit") return SwimIcon
    else if (tag === "photoshoot") return PhotoshootIcon
    else if (tag === "parts") return PartsIcon
    else if (tag === "underwear") return UnderwearIcon
  }

  render() {
    const {
      isDraft,
      campaign,
      loading,
      profilePicturePreview,
      sideprofilepreview,
      sideprofilecrop,
      sideprofilecroppedurl,
      closeuppreview,
      closeupcroppedurl,
      waistuppreview,
      waistupcroppedurl,
      fulllengthpreview,
      fulllengthcroppedurl,
      firstName,
      lastName,
      phone,
      email,
      instagram,
      twitter,
      website,
      youtube
      
    } = this.state;

    if (loading) return (
      this.renderLoadingState()
    )
    if (isDraft) {
      this.props.history.push("/404")
    }
    if (campaign === null && !loading) return <NotFound />

    return (
      <Wrapper>

        <Helmet>
          <meta
            name='viewport'
            content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'
          />
          <meta property="og:url" content={`runway.supply/apply/${campaign.link}`} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={campaign.title} />
          <meta property="og:description" content={campaign.description} />
          <meta property="og:image" content={campaign.coverphoto} />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={campaign.title} />
          <meta name="twitter:description" content={campaign.description} />
          <meta name="twitter:image" content={campaign.coverphoto} />
        </Helmet>

        <div className="ApplicationCoverPhoto">
          <img style={{ height: 300, width: '100%', objectFit: 'cover' }} src={campaign.coverphoto} />
        </div>


        <Content>
          <Meta> <ApplicationMeta campaign={campaign}/> </Meta>
          <SubWrapper>
            <Introduction renderTagIcon={this.renderTagIcon} campaign={campaign} />

            {campaign.geocode &&
              <LocationRadiusWrapper>
                <SubHeader> Location </SubHeader>
                <Text textalign={'center'}> Exact location will be shared when you are selected for the campaign. </Text>
                <LocationRadius
                  campaign={campaign}
                  googleMapURL={"https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyCqSazjBHnEvLcbDZGyEFe08W01oioibL4"}
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `400px`, justifySelf: 'center' }} />}
                  mapElement={<MapElement/>}
                />
              </LocationRadiusWrapper>
            }

              <ApplyWRSA {...this.props} />
            </SubWrapper>
        </Content>

           

      </Wrapper>
    )
  }
}

export default Application;

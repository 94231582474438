import React from 'react';
import styled from 'styled-components'

const Wrapper = styled.div`
    display: grid;
    grid-row-gap: 24px;
    grid-auto-rows: min-content;
`

const FilterWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, 25px);
    justify-content: center;
`

const Title = styled.div`
    font-weight: 800
    font-size: 16px;
    text-transform: uppercase;
`

const Circle = styled.div`
    background-color: ${props => props.backgroundColor};
    border-radius: 50%;
    height: 50px;
    width: 50px;
    border: ${props => props.selected && 'solid 5px white'};
`

const CircularFiltersWithTitle = ({ title, colors = ['#000', '#f2f2f2', '#e1e1e1','#000', '#f2f2f2', '#e1e1e1'], cb, value }) => {
    return (
        <Wrapper>
            <Title> {title} </Title>
            <FilterWrapper>
                {colors.map(color => { 
                    const selected = color === value;
                    return  <Circle selected={selected} onClick={() =>  {
                        if(selected) cb('')
                        else cb(color)
                    }} backgroundColor={color} />
    })}
            </FilterWrapper>
        </Wrapper>
    )
}

export default CircularFiltersWithTitle

/*

    



        display: grid;
    grid-template-columns: repeat(auto-fit, 30px);
    grid-row-gap: 24px;
    grid-column-gap: 6px;
    max-width: 102px;
    grid-auto-rows: min-content;
    */
import React from 'react';
import styled from 'styled-components';
import { GenderAllIcon, CampaignsLocationIcon, CalendarIcon, URLIcon, YoutubeIcon, WaistIcon, VideoIcon, UnderwearIcon, TwitterIcon, SwimIcon, SlimIcon, SideIcon, PhotoshootIcon, PhoneIcon, RunwayIcon, PencilIcon, PlusIcon, PartsIcon, NotifyIcon, MaleIcon, LengthIcon, InstagramIcon, FemaleIcon, EmailIcon, DeadlineIcon, CampaignsDateTimeIcon, BasicInfoIcon, ModelSpecsIcon, ApplicationSettingsIcon, CreateCampaignIcon, ApplicantsIcon, AthleticIcon, BirthdayIcon, CityIcon, CloseUpIcon, } from '../../Assets/CreateCampaign'
import BrandIcon from "../../Assets/Modal/shop-1.svg";
import { Header, SubHeader, Boxes } from './shared';
import ApplicationMeta from './ApplicationMeta'
const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    grid-row-gap: 36px;
`

const SubWrapper = styled.div`
    display: grid;
    grid-auto-rows: min-content;
    grid-row-gap: 36px;
    justify-content: center;
`

const CampaignDescription = styled.div`
    word-break: break-word;
    white-space: pre-wrap;
    height: 100%;
    width: 100%;
    font-size: 18px;
    height: 100%;
    padding: 0px 0% 0px 0%;
    width: 600px;
    height: 100%;
    @media(max-width: 900px) {
        justify-self: center;
        width: 500px;
      }
      @media(max-width: 500px) {
        width: 350px;
      }
      @media(max-width: 350px) {
 
      }
`

const CampaignHeader = styled.h1`
    font-size: 36px;
    margin: 0;
    padding: 0;
    padding: 24px 0px 12px 0px;
    text-transform: uppercase;
    text-align: center;
    @media(max-width: 600px) {
        font-size: 30px;
    }
`

const Meta = styled.div`
    display: none;
    @media(max-width: 700px) {
        display: block;
    }
`

const OverrideBoxes = styled(Boxes)`
    max-width: 350px;
    justify-self: center;
`


const Introduction = ({ campaign, renderTagIcon}) => {
    return (
        <Wrapper>

            <SubWrapper>
                <CampaignHeader style={{  }}> {campaign.title} </CampaignHeader>
                <Meta> <ApplicationMeta campaign={campaign} /> </Meta>
                <CampaignDescription> {campaign.description} </CampaignDescription>
                <SubHeader style={{}}> What We're Looking For </SubHeader>
                <OverrideBoxes style={{}}>

                    {campaign.gender === "all" ?
                        <div style={{ borderRadius: 5, }}>  <img style={{ width: 75, height: 65, }} src={GenderAllIcon} /> </div> :
                        <div style={{ borderRadius: 5, width: 75, height: 65, display: 'grid', gridTemplateColumns: 'repeat(16,1fr)', gridTemplateRows: '40px 5px 20px', border: 'solid 1px black', backgroundColor: 'rgb(255, 255, 255, .01)' }}>
                            <div style={{ gridRow: 1, gridColumn: '1 / span 16', alignSelf: 'flex-end', justifySelf: 'center' }}> <img src={campaign.gender === "female" ? FemaleIcon : MaleIcon} style={{ width: 25, height: 25 }} /> </div>
                            <div style={{ gridRow: 3, gridColumn: '1 / span 16', justifySelf: 'center', fontSize: 11, textTransform: 'capitalize' }}> {campaign.gender} </div>
                        </div>
                    }


                    <div style={{ borderRadius: 5, width: 75, height: 65, display: 'grid', gridTemplateColumns: 'repeat(16,1fr)', gridTemplateRows: '40px 5px 20px', border: 'solid 1px black', backgroundColor: 'rgb(255, 255, 255, .01)' }}>
                        <div style={{ gridRow: 1, gridColumn: '1 / span 16', alignSelf: 'flex-end', justifySelf: 'center' }}>
                            {
                                campaign.build === 'all' ? 'ALL' :
                                    <img src={campaign.build === "athletic" ? AthleticIcon : campaign.build === "slim" ?
                                        SlimIcon : PlusIcon} style={{ width: 25, height: 25 }} />}
                        </div>
                        <div style={{ gridRow: 3, gridColumn: '1 / span 16', justifySelf: 'center', fontSize: 11 }}> Figure </div>
                    </div>



                    {campaign.tags.length ? campaign.tags.map(tag => (
                        <div style={{ borderRadius: 5, width: 75, height: 65, display: 'grid', gridTemplateColumns: 'repeat(16,1fr)', gridTemplateRows: '40px 5px 20px', border: 'solid 1px black', backgroundColor: 'rgb(255, 255, 255, .01)' }}>
                            <div style={{ gridRow: 1, gridColumn: '1 / span 16', alignSelf: 'flex-end', justifySelf: 'center' }}> <img src={renderTagIcon(tag)} style={{ width: 25, height: 25 }} /> </div>
                            <div style={{ gridRow: 3, gridColumn: '1 / span 16', justifySelf: 'center', fontSize: 11, textTransform: 'capitalize' }}> {tag}</div>
                        </div>
                    )) : null}

                    <div style={{ borderRadius: 5, width: 75, height: 65, display: 'grid', gridTemplateColumns: 'repeat(16,1fr)', gridTemplateRows: '40px 5px 20px', border: 'solid 1px black', backgroundColor: 'rgb(255, 255, 255, .01)' }}>
                        <div style={{ gridRow: 1, gridColumn: '1 / span 16', alignSelf: 'flex-end', justifySelf: 'center' }}>
                            {campaign.height[0] === `4'10` && campaign.height[1] === `6'6` ? 'All' : `${campaign.height[0]} - ${campaign.height[1]}`}
                        </div>
                        <div style={{ gridRow: 3, gridColumn: '1 / span 16', justifySelf: 'center', fontSize: 11 }}> Height </div>
                    </div>

                    <div style={{ borderRadius: 5, width: 75, height: 65, display: 'grid', gridTemplateColumns: 'repeat(16,1fr)', gridTemplateRows: '40px 5px 20px', border: 'solid 1px black', backgroundColor: 'rgb(255, 255, 255, .01)' }}>
                        <div style={{ gridRow: 1, gridColumn: '1 / span 16', alignSelf: 'flex-end', justifySelf: 'center' }}>
                            {campaign.hireLimit}
                        </div>
                        <div style={{ borderRadius: 5, gridRow: 3, gridColumn: '1 / span 16', justifySelf: 'center', fontSize: 11 }}> Models </div>
                    </div>

                </OverrideBoxes>
            </SubWrapper>
        </Wrapper>

    )
}


export default Introduction;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import axios from 'axios';
import { HOST_NAME, TWILIO_HOST_NAME, BRAND_SERVICES_HOST_NAME } from '../../Config';
import { CampaignMeta, ModelBox, Toggle } from './index'
import { CampaignsLocationIcon, CalendarIcon, EmailIcon, DeadlineIcon, CampaignsDateTimeIcon, CityIcon, ApplicantsIcon } from '../../Assets/CreateCampaign'
import { ViewsIcon } from '../../Assets/Campaigns'
import { CircularFiltersWithTitle, CheckboxFilters } from './Filters/index'
import { RippleLoader } from '../../shared'
import { Button } from '../TypeForm'
const Wrapper = styled.div`
    display: grid;
    grid-row-gap: 24px;
    padding: 0px 0px 120px 0px;
    grid-auto-rows: min-content;

`

const MainContentWrapper = styled.div`
    display: grid;
    grid-row-gap: 24px;
    grid-column-gap: 48px;
    grid-template-columns: 1fr;
    padding: 0px 24px 250px 24px;
    grid-template-columns: min-content 1fr;
    @media(max-width: 1024px) {
        grid-template-columns: 1fr;
    }
   
`
// grid-template-columns: auto 1fr;

const FiltersWrapper = styled.div`
    display: grid;
    grid-row-gap: 24px;
    grid-auto-rows: min-content;
    padding: ${props => props.network && '24px 0px 0px 0px'};
    justify-content: center;
    @media(max-width: 1024px) {
        display: none;
    }
`
// remove display none on filters wrapper 

const ModelsWrapper = styled.div`
    display: grid;
    grid-row-gap: 24px;
    grid-auto-rows: min-content;
`

const ModelBoxesWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, 300px);
    grid-column-gap: 24px;
    grid-auto-rows: min-content;
    grid-row-gap: 24px;
    justify-content: center;
`

const Header = styled.h1`
    margin: 0;
    padding: 0;
    font-size: 36px;
    text-align: center;
`

const CoverPhoto = styled.img`
    width: 100%;
    height: 170px;
    object-fit: cover;
`



const CampaignApplicants = ({ match, history, applicationStatus, network = false }) => {
    const { url } = match.params || null // this refrences the campaign url
    const [campaign, setCampaign] = useState({});
    const [applicants, setApplicants] = useState({
        cache: [],
        filtered: [],
    });
    const [loading, setLoading] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const { coverphoto, deadline, startDate, geocode, location, modelsHired, hireLimit, views, title } = campaign || null;
    let locality = null;
    if (geocode && Object.keys(geocode).length) {
        locality = geocode.locality;
    }
    const [filters, setFilters] = useState({
        complexion: '',
        eyeColor: '',
        hairColor: '',
        gender: '',
        date: '',
        starred: '',
    })
    // use effect for fetching applicants
    useEffect(() => {
        const cb = async () => {
            try {
                setLoading(true)
                if (url) {
                    const campaignResponse = await axios.post(`${BRAND_SERVICES_HOST_NAME}/get_campaign_by_url_no_increment`, {
                        url,
                    })

                    setCampaign(campaignResponse.data.campaign)
                }
                else if (!url) setCampaign({})

                if (network) {
                    const modelNetworkResponse = await axios.post(`${TWILIO_HOST_NAME}/get_model_network`, {
                        brandID: JSON.parse(localStorage.getItem('user'))._id,
                    })
                    setApplicants({ cache: modelNetworkResponse.data.applicants, filtered: modelNetworkResponse.data.applicants   })
                }
                else if (applicationStatus === 'open') {
                    const applicantsResponse = await axios.post(`${TWILIO_HOST_NAME}/get_model_applications`, {
                        url,
                    })
                    setApplicants({ cache: applicantsResponse.data.applicants, filtered: applicantsResponse.data.applicants   })
                }
                else if (applicationStatus === 'selected') {
                    const applicantsResponse = await axios.post(`${TWILIO_HOST_NAME}/get_selected_models`, {
                        url,
                    })
                    setApplicants({ cache: applicantsResponse.data.applicants, filtered: applicantsResponse.data.applicants   })
                }

            }
            catch (error) {
                console.log(error)
            }
            finally {
                setLoading(false);
            }


        }
        cb()
        console.log('Called use effect')
    }, [url])

    useEffect(() => {
        const { hairColor, eyeColor, complexion, gender, date } = filters;
        const options = {}
        let filteredCopy = [...applicants.cache];
        if(hairColor) filteredCopy = filteredCopy.filter(applicant => applicant.hairColor === hairColor);
        if(eyeColor) filteredCopy = filteredCopy.filter(applicant => applicant.eyeColor === eyeColor);
        if(complexion) filteredCopy = filteredCopy.filter(applicant => applicant.complexion === complexion);
        if(gender) filteredCopy = filteredCopy.filter(applicant => applicant.gender.toLowerCase() === gender.toLowerCase());
        //setFilteredApplicants(filteredCopy);
        console.log(filteredCopy, 'filtered copy')
        console.log(filters)
        setApplicants({ ...applicants, filtered: filteredCopy })

    },[filters])
    return (
        <Wrapper>
            {!network &&
                <CoverPhoto src={coverphoto} />}
            <MainContentWrapper>
                {loading && <RippleLoader center={true} />}

                <FiltersWrapper network={network}>
                    <Button onClick={() => setFilters({
                        complexion: '',
                        eyeColor: '',
                        hairColor: '',
                        gender: '',
                        date: '',
                        starred: ''
                    })} backgroundColor={"#fff"} color={'#000'}>Clear Filters</Button>
                    <CircularFiltersWithTitle cb={(value) => {
                        setFilters({ ...filters, complexion: value })
                    }} title={'Complexion'} colors={['#553827', '#935934', '#bd804a', '#fee4d7', '#e6a17d', '#be7f5e']} value={filters.complexion} />
                    <CircularFiltersWithTitle cb={(value) => {
                        setFilters({ ...filters, eyeColor: value })
                    }} title={'Eyes'} colors={['#ae9f80', '#553827', '#0f305b', '#6ca580', '#778899', '#2c222b', '#a1caf1']} value={filters.eyeColor} />
                    <CircularFiltersWithTitle cb={(value) => {
                        setFilters({ ...filters, hairColor: value })
                    }} title={'Hair'} colors={['#090806', '#2c222b', '#71635a', '#6a4e42', '#debc99', '#b55239', '#dcd0ba']} value={filters.hairColor} />
                    <CheckboxFilters cb={(value) => {
                        setFilters({ ...filters, gender: value })
                    }} title={'Sex'} attributes={['male', 'female']} value={filters.gender} />
                </FiltersWrapper>
                <ModelsWrapper>
                    <Header> {title} </Header>
                    {!network &&
                        <CampaignMeta items={[
                            [locality || null, CampaignsLocationIcon],
                            [`Deadline: ${new Date(deadline).toLocaleDateString()}`, DeadlineIcon],
                            [`${new Date(startDate).toLocaleDateString()}`, CalendarIcon],
                            [`Hired Models: ${modelsHired}`, ApplicantsIcon],
                            [`Hire Limit: ${hireLimit}`, ApplicantsIcon],
                            [`Views: ${views}`, ViewsIcon]]} />
                    }
                    <Toggle photoIndex={photoIndex} setPhotoIndex={setPhotoIndex} />
                    <ModelBoxesWrapper>
                        {applicants.filtered.map(model => {
                            return <ModelBox key={model._id} filters={filters} network={network} match={match} history={history} photoIndex={photoIndex} model={model} />
                        })}
                    </ModelBoxesWrapper>
                    {!loading && !applicants.cache.length && "No models yet."}
                </ModelsWrapper>
            </MainContentWrapper>


        </Wrapper>
    )
}

export default CampaignApplicants;

// <CheckboxFilters style={{ display: 'none' }} title={'Starred'} attributes={['Yes', 'No']} />
/*                <CheckboxFilters cb={(value) => {
    setFilters({ ...filters, date: value })
}} title={'Date'} attributes={['today', 'oldest', 'newest']} value={filters.date} /> */
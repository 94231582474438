import React, { useState } from 'react';
import styled from 'styled-components';
import { Question, UserInput, Button, Text, FlexStartFlexEnd } from '../../index';
import {FeaturesCircles} from '../../index';
const Wrapper = styled.div`
    display: grid;
    grid-row-gap: 24px;
`
const Step7 = ({ setStep, step, realStep, modelDetails, setDetails, rSC }) => {
    if (step !== realStep) return null;
    const [error, setError] = useState('');
    return (
        <Wrapper>
            <Question step={step} question={'Select your complexion.'} />
            <Text> It is important you provide accurate categorization to increase your selection chances.
Every data point is a chance to stand out.   </Text>
            <FeaturesCircles state={modelDetails.complexion} callback={(complexion) => setDetails({ ...modelDetails, complexion })} values={['#553827','#935934','#bd804a','#fee4d7','#e6a17d','#be7f5e']} objectKey={'complexion'}  />
            <Text color={'red'}> {error} </Text>
            <FlexStartFlexEnd>
                <Button onClick={() => {
                    if (modelDetails.complexion) {
                        setStep(step + 1)
                    }
                    else {
                        setError('Select your complexion.')
                    }

                }} width={'120px'}> Next </Button>
                {rSC()}
            </FlexStartFlexEnd>
          
        </Wrapper>
    )
}

export default Step7;
import React, { useState } from 'react';
import styled from 'styled-components';
const Wrapper = styled.div`
    display: grid;
    position: relative;
`

const Image = styled.img`
    width: 100%;
    max-height: 100vh;
    object-fit: cover;
`

const DivImage = styled.div`
    background: ${props => props.background};
    background-repeat: no-repeat;
    min-height: 100vh;
    background-position: center;
    background-size: contain; 
    display: grid;
    @media(max-width: 1000px) {
        background-size: cover; 
    }
    @media(max-width: 800px) {
        grid-row: 1;
        min-height: 400px;

    }
`;
// cover , auto
const SubWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit,25px);
    grid-column-gap: 24px;
    -ms-flex-pack: center;
    justify-content: center;
    bottom: 10px;
    align-items: flex-end;
    padding: 12px 0px;
`


const Circle = styled.div`
    border-radius: 50%;
    width: 25px;
    height: 25px;
    border:  ${props => props.active === props.currentActive ? 'solid 2px #fff' : 'solid 1px #000'};
    background-color: ${props => props.active !== props.currentActive ? '#fff' : '#000'};
    cursor: pointer;
`
const PhotoSection = ({ model }) => {
    const { waistUp, sideProfile, closeUp, fullLength } = model;
    let photos = [waistUp, sideProfile, closeUp, fullLength];
    const [active, setActive] = useState(() => 0);
    return (
        <DivImage background={`url(${photos[active]})`}>


            <SubWrapper>
                <Circle onClick={() => {
                    setActive(0)
                    console.log('Omar')
                }} active={0} currentActive={active} />
                <Circle onClick={() => setActive(1)} active={1} currentActive={active} />
                <Circle onClick={() => setActive(2)} active={2} currentActive={active} />
                <Circle onClick={() => setActive(3)} active={3} currentActive={active} />
            </SubWrapper>




        </DivImage>
    )
}

export default PhotoSection;
/*

            */
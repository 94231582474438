import React from 'react';
import styled from 'styled-components';
import ViewIcon from '../../Assets/Campaigns/campaigns-view-icon.png'
import UsersIcon from '../../Assets/Campaigns/users-Icon.svg';
const Wrapper = styled.div`
    display: grid;
    grid-template-columns: minmax(14px, 66px) 60px max-content max-content 1fr min-content min-content
    align-items: center;
    grid-column-gap: 40px;
    border-bottom: solid 1px #e1e1e1;
    cursor:pointer;
  @media(max-width: 900px) {
      display: none;
  }
`
const Rank = styled.div`
    font-size: 24px;
    line-height: 12px;
    @media(max-width: 414px) {
        text-align: center;
    }
`

const BrandLogo = styled.img`
    object-fit: cover;
    width: 60px;
    height: 60px;
    border-radius: 50%;
`

const CampaignTitle = styled.h3`
    font-size: 24px;
    @media(max-width: 1000px) {
        width: 100px; // some width
    }
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 300px;
`

const Brand = styled.h4`
    font-size: 24px;
    text-transform: uppercase;
    letter-spacing: 2px
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 250px; // some width
    justify-self: center;
    text-align: center;
`

const Location = styled.h5`
    font-size: 16px;
    color: rgb(154, 154, 154);
    @media(max-width: 414px) {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 50px; // some width
    }
`

const IconWTextWrapper = styled.div`
    display: grid;
    grid-template-columns: min-content min-content;
    grid-column-gap: 12px;
    align-items: center;
    justify-self: center;
`

const MobileCollapse = styled.div`
    display:none;
    @media(max-width: 900px) {
        display: grid;
        grid-auto-rows: 20px;
        grid-row-gap: 10px;
    }

`

const TableItem = ({ campaign, i, history }) => {
    const {
        geocode: {
            locality
        }
    } = campaign || {}

    return (
        <Wrapper onClick={() => history.push(`/apply/${campaign.link}`)}>
            <Rank> {i + 1} </Rank>
            <BrandLogo src={campaign.coverphoto} alt="brandlogo" />
            <CampaignTitle> {campaign.title} </CampaignTitle>
            <Location> {locality && locality} </Location>
            <Brand> {campaign.brand} </Brand>
            <IconWTextWrapper>
                <img src={UsersIcon} />
                <div> {campaign.applicants} </div>
            </IconWTextWrapper>
            <IconWTextWrapper>
                <img src={ViewIcon} />
                <div> {campaign.views} </div>
            </IconWTextWrapper>
        </Wrapper>

    )
}

export default TableItem;
import React, { Component } from "react";
import "./About.css";
import SubmitArrow from '../Assets/LandingPage/submit-icon.png';
import SendIcon from '../Assets/LandingPage/send-icon.png';
import ComingSoonIcon from '../Assets/LandingPage/coming-soon-icon.png';
import CampaignsImage from '../Assets/LandingPage/campaigns-desktop-view.jpg';
import CampaignsMobileImage from '../Assets/LandingPage/campaigns-mobile-desktop-view.jpg';
import CreateCampaignImage from '../Assets/LandingPage/create-campaign-view.jpg';
import CreateCampaignMobileImage from '../Assets/LandingPage/mobile-desktop-create-campaign.jpg';
import AnalyticsIcon from '../Assets/LandingPage/analytics-icon.png';
import NorseCityProjectImage from '../Assets/LandingPage/campaign-icon.png';
import AnalyticsMobileImage from '../Assets/LandingPage/analytics-mobile.jpg'
import NetworkIcon from '../Assets/LandingPage/network-icon.png';
import NetworkImage from '../Assets/LandingPage/model-network-view.jpg';
import MonthlyCampaignPricingModal from '../Assets/LandingPage/SubscriptionBilling.svg';
import SingleCampaignPricingModal from '../Assets/LandingPage/SingleCampaignBilling.svg';
import FooterIcon from '../Assets/LandingPage/footer-icon.png';
import CollageOne from '../Assets/LandingPage/model-mission-1.png';
import CollageTwo from '../Assets/LandingPage/model-mission-2.png';
import CollageThree from '../Assets/LandingPage/model-mission-3.png';
import CollageFour from '../Assets/LandingPage/model-mission-4.png';
import CollageFive from '../Assets/LandingPage/model-mission-5.png';
import CollageSix from '../Assets/LandingPage/model-mission-6.png';
import CollageSeven from '../Assets/LandingPage/model-mission-7.png';
import CollageEight from '../Assets/LandingPage/model-mission-8.png';
import CollageNine from '../Assets/LandingPage/model-mission-9.png';
import CollageTen from '../Assets/LandingPage/model-mission-10.png';
import CollageEleven from '../Assets/LandingPage/model-mission-11.png';
import CollageTwelve from '../Assets/LandingPage/model-mission-12.png';
import CollageThirteen from '../Assets/LandingPage/model-mission-13.png';
import CollageFourteen from '../Assets/LandingPage/model-mission-14.png';
import CollageFifteen from '../Assets/LandingPage/model-mission-15.png';
import CollageSixteen from '../Assets/LandingPage/model-mission-16.png';
import CollageSeventeen from '../Assets/LandingPage/model-mission-17.png';
import CollageeEighteen from '../Assets/LandingPage/model-mission-18.png';
import CollageeNineteen from '../Assets/LandingPage/model-mission-19.png';
import CollageeTwenty from '../Assets/LandingPage/model-mission-20.png';
import FadeIn from 'react-fade-in';
import Fade from 'react-reveal/Fade';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import Zoom from 'react-reveal/Zoom';
import Footer from './Footer';
import { CampaignsMarketplace } from './CampaignsMarketplace';
const CustomForm = ({ status, message, onValidated, inputPlaceholder, web }) => {
  let email;
  const submit = (e) => {
      e.preventDefault();
      if(email && email.value.indexOf("@") > -1) {
      onValidated({
        EMAIL: email.value
      });
    }
  }

  return (
    <React.Fragment>
      <form onSubmit={submit}>
      <input
        id={web ? "webinput" : "mobileinput"}
        style={{}}
        ref={node => (email = node)}
        type="email"
        placeholder={status === "success" ? "Thank You!" : status === "sending" ? "Sending" : status === "sending" ? "Sending..." : status === "error" ? "Please Enter a Valid Email Address." : "Enter Your Email For Updates!"}
      />
        <button
        type="submit" />
      </form>
    </React.Fragment>
  );
};

class About extends Component {

constructor(props) {
      super(props);
      this.state = {
        url: "https://supply.us16.list-manage.com/subscribe/post?u=4c31e9a40b36688cfdbd7c732&amp;id=a2a2c1b697",
    };
      this.clearField = this.clearField.bind(this);
}

clearField() {
  var subscribeForm = document.getElementsByTagName("input");
  /*
   document.getElementById("webinput").value = '';
   document.getElementById("mobileinput").value = '';
   I think this is another way of doing it not sure if it works
  */
  subscribeForm[0].value = '';
  subscribeForm[0].blur();
  subscribeForm[1].value = '';
  subscribeForm[1].blur();
}
  render() {
    return (
      <div className="HomepageGrid">
        <div className="PageOne">
          <div className="ContentPageOne">
            <FadeIn delay={500} transitionDuration={1000}>
              <div className="ContentPageOneHeadingInfo">Model management the way it should be.</div>
              <div className="ContentPageOneSubHeadingInfo">Create, Share, and Manage your modeling campaigns!</div>
            </FadeIn>
            <FadeIn delay={300} transitionDuration={1000}>
              <div className="ContentPageOneEmailInputWeb">
                <div className="ContentPageOneEmailInput">
                  <MailchimpSubscribe
                      url={this.state.url}

                      render={({ subscribe, status, message }) => (
                        <CustomForm
                          web={true}
                          status={status}
                          message={message}
                          onValidated={formData => {
                            subscribe(formData)
                            this.clearField();
                          }}
                        />
                      )}
                    />
                </div>
              </div>
            </FadeIn>
          </div>


          <div className="ContentPageOneSectionTwo">
              <div className="ImagePageOneColumns">
                <FadeIn delay={500} transitionDuration={1000}>
                  <img id="CampaignsImage" className="FullWidth" src={CampaignsImage} />
                </FadeIn>
              </div>
              <div className="ContentPageOneEmailInputMobile">
                <div className="ContentPageOneEmailInput">
                  <FadeIn delay={500} transitionDuration={1000}>
                  <MailchimpSubscribe
                      url={this.state.url}
                      render={({ subscribe, status, message }) => (
                        <CustomForm
                          web={false}
                          status={status}
                          message={message}
                          onValidated={formData => {
                            subscribe(formData)
                            this.clearField();
                          }}
                        />
                      )}
                    />
                  </FadeIn>
                </div>
              </div>
          </div>


        </div>
        {/*
          START OF SECOND PAGE
        */}

        <div id="features" className="PageTwo">
          <div className="ContentPageTwo">
              <div className="IconPageTwo">
                <Zoom>
                <img src={SendIcon} />
                </Zoom>
              </div>
              <div className="ContentPageTwoHeadingInfo"> Create campaigns and attract top talent </div>
              <div> Whether you have a mass following or just starting out. Simply create a campaign, copy your short link and post it across your social media!</div>
          </div>

          <div className="ImageTwo">
            <Fade right >
              <img id="CreateCampaignImage" className="FullWidth" src={CreateCampaignImage} />
            </Fade>
          </div>
        </div>
        {/*
          START OF THIRD PAGE
        */}
        <div className="PageThree">

          <div className="ImagePageThree">
            <Zoom className="ImagePageThree">
              <img id="AnalyticsImage" src={NorseCityProjectImage} />
            </Zoom>
          </div>

          <div className="ContentPageThree">
            <div className="InnerContentPageThree">
              <div className="AnalyticsIcon">
                <Zoom> <img src={AnalyticsIcon} />   </Zoom>
              </div>
              <div className="ContentPageThreeHeadingInfo ">
               Track your campaign with relevant analytics
              </div>
              <div className="ContentPageThreeSubheadingInfo">
                 Stay on top your campaign by tracking views, applicants and number of models hired
              </div>
            </div>
          </div>
        </div>

        {/*
          Start OF FOURTH PAGE
        */}

        <div className="PageFour">
          <div className="PageFourContent">
            <div className="NetworkIcon"> <Zoom> <img src={NetworkIcon} /> </Zoom> </div>
            <div className="ContentPageFourHeadingInfo"> Build your own network of models </div>
            <div> Looking to re-hire models?  Check out all the models you hired in the past and see if they fit your upcoming campaign! </div>
          </div>
          <div className="PageFourImage">
            <div> <Fade right> <img src={NetworkImage} /> </Fade> </div>
          </div>
        </div>

        {/*
          START OF THE FIFTH PAGE
        */}
        <div className="PageFive">
          <div className="PageFiveLeftCollage">
            <div className="CollageLeftOne">  <Fade left> <img src={CollageOne} /> </Fade> </div>
            <div className="CollageLeftTwo"> <Fade left> <img src={CollageTwo} /> </Fade> </div>
            <div className="CollageLeftThree"> <Fade left> <img src={CollageThree} /> </Fade> </div>
            <div className="CollageLeftFour"> <Fade left> <img src={CollageFour} /> </Fade> </div>
            <div className="CollageLeftFive"> <Fade left> <img src={CollageFive} /> </Fade> </div>
            <div className="CollageLeftSix"> <Fade left> <img src={CollageSix} /> </Fade> </div>
            <div className="CollageLeftSeven"> <Fade  left> <img src={CollageSeven} /> </Fade> </div>
            <div className="CollageLeftEight"> <Fade left> <img src={CollageEight} /> </Fade> </div>
            <div className="CollageLeftNine"> <Fade left> <img src={CollageNine} /> </Fade> </div>
            <div className="CollageLeftTen"> <Fade left> <img src={CollageTen} /> </Fade> </div>
            <div className="CollageLeftEleven"> <Fade left> <img src={CollageEleven} /> </Fade> </div>
            <div className="CollageLeftTwelve"> <Fade left> <img src={CollageTwelve} /> </Fade> </div>
          </div>

          <div className="PageFiveMission">
            <div className="OurMissionTextHeading"> Our Mission: </div>
            <div> It's time to create a level playing field for all models, and stop the need for modeling agencies to dictate opportunities. We will empower businesses to create their own talent networks, while being 100% commission free for models. </div>
          </div>








          <div className="PageFiveRightCollage">
            <div className="CollageRightOne"> <Fade right> <img src={CollageThirteen} /> </Fade> </div>
            <div className="CollageRightTwo"> <Fade right> <img src={CollageFourteen} /> </Fade> </div>
            <div className="CollageRightThree"> <Fade right> <img src={CollageFifteen} /> </Fade> </div>
            <div className="CollageRightFour"> <Fade right> <img src={CollageSixteen} /> </Fade> </div>
            <div className="CollageRightFive"> <Fade right> <img src={CollageSeven} /> </Fade> </div>
            <div className="CollageRightSix"> <Fade right> <img src={CollageSeventeen} /> </Fade> </div>
            <div className="CollageRightSeven"> <Fade right> <img src={CollageeEighteen} /> </Fade> </div>
            <div className="CollageRightEight"> <Fade right> <img src={CollageFive} /> </Fade> </div>
            <div className="CollageRightNine"> <Fade right> <img src={CollageOne} /> </Fade>  </div>
            <div className="CollageRightTen"> <Fade right> <img src={CollageTen} /> </Fade>  </div>
            <div className="CollageRightEleven"> <Fade right> <img src={CollageeTwenty} /> </Fade> </div>
          </div>
        </div>


        <div style={{ gridColumn: '1/-1' }}> <CampaignsMarketplace history={this.props.history}/> </div>
        {/*
          START OF THE SIXTH PAGE
        */}
        <div id="pricing" className="PageSix">
          <div className="PageSixContent">
            <div className="PricingHeading">
              Pricing
            </div>

            <div className="PricingSubheader">
              Simple pricing that has everything you need
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: 12 }}>
            <div className="PricingModal">
                <Zoom className="PricingModal"> <img id="PricingModal" src={SingleCampaignPricingModal} /> </Zoom>
              </div>
              <div className="PricingModal">
                <Zoom className="PricingModal"> <img id="PricingModal" src={MonthlyCampaignPricingModal} /> </Zoom>
              </div>
            </div>
          </div>
        </div>

        {/*
          START OF THE FOOTER
        */}

        <Footer history={this.props.history} gridColumn={'1/-1'} gridRow={'inherit'} />

      </div>

    );
  }
}

export default About;

import React from 'react';
import styled from 'styled-components';
import { Button } from '../../shared'
import { check, x } from '../../shared/icons'
const Wrapper = styled.div`
    display: grid;
`

const Header = styled.h2`
    font-size: 24px;
    line-height: 28px;
`
const Body = styled.p`
    font-size: 18px;
    line-height: 22px;
    display: grid;
    grid-row-gap: 12px;
`

const ItemWrapper = styled.div`
    font-size: 16px;
    line-height: 18px;
    display: grid;
    grid-template-columns: min-content 1fr;
    align-items: center;
    grid-column-gap: 12px;
`

const Item = styled.div`
    text-decoration: ${props => props.missing && 'line-through'};
`

const Check = styled.img``

const InfoItem = ({ text, icon, missing }) => (
    <ItemWrapper>
        <Check src={icon} />
        <Item missing={missing}> {text} </Item> 
    </ItemWrapper>
)

const PaymentSection = ({ subscriptionCost, billCustomerMonthly, billCustomerSingleCampaign, loading }) => {
    return (
        <Wrapper>
            <Header> $5 Per Campiagn </Header>
            <Body>  
                <InfoItem icon={x} missing={true} text={`Unlimited Campaigns`}/>
                <InfoItem icon={check} text={`Automated Notifications`}/>
                <InfoItem icon={check} text={`Analytics`}/>
                <InfoItem icon={check} text={`Shareable Link`} />
                <InfoItem icon={check} text={`Chat Support`} />
                <Button disabled={loading} onClick={() => billCustomerSingleCampaign()} width={'100%'}> Purchase Campaign $5 </Button>
            </Body>
            <Header> Purchase Subscription $20 Per Month (Prorated) </Header>
            <Body>
                <InfoItem icon={check} text={`Unlimited Campaigns`}/>
                <InfoItem icon={check} text={`Automated Notifications`}/>
                <InfoItem icon={check} text={`Analytics`}/>
                <InfoItem icon={check} text={`Shareable Link`} />
                <InfoItem icon={check} text={`Chat Support`} />
                <Button onClick={() => billCustomerMonthly()} disabled={loading} backgroundColor={`#000`} color={`white`} width={'100%'}> Subscribe ${subscriptionCost} </Button>
            </Body>
        </Wrapper>
    )
};

export default PaymentSection
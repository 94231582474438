import React, { useEffect } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 5px;
    min-height: 50px;
    max-height: 300px;
    padding: 8px 8px;
    position: absolute;
    bottom: 20px;
    justify-self: center;
    width: 300px;
    z-index: 100px;
    background-color: #fff;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    display: grid;
    align-items: center;
    text-align: center;
    font-size: 20px;
`

const ToastNotification = ({ toast, cb }) => {
    const { open, message } = toast;
    if(!open) return null;
    useEffect(() => {
        const interval = setInterval(() => {
            cb()
            clearInterval(interval);
        }, 3500);  
    }, [open])

    return (
        <Wrapper>
            {message}
        </Wrapper>
    )
}

export default ToastNotification;
import styled from 'styled-components';

const Input = styled.input`
    background: #fff;
    height: 40px;
    border: 1px solid #e1e1e1;
    color: #494949;
    border-radius: 5px;
    padding: 0px 8px;
    font-size: 16px
    min-width: 0px;
    box-sizing: border-box;
`

export default Input;
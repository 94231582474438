import React, { useEffect, useState } from "react";
import Cropper from "react-cropper";
import {
  CampaignsLocationIcon,
  CalendarIcon,
  URLIcon,
  YoutubeIcon,
  WaistIcon,
  VideoIcon,
  UnderwearIcon,
  TwitterIcon,
  SwimIcon,
  SlimIcon,
  SideIcon,
  PhotoshootIcon,
  PhoneIcon,
  RunwayIcon,
  PencilIcon,
  PlusIcon,
  PartsIcon,
  NotifyIcon,
  MaleIcon,
  LengthIcon,
  InstagramIcon,
  FemaleIcon,
  EmailIcon,
  DeadlineIcon,
  CampaignsDateTimeIcon,
  BasicInfoIcon,
  ModelSpecsIcon,
  ApplicationSettingsIcon,
  CreateCampaignIcon,
  ApplicantsIcon,
  AthleticIcon,
  BirthdayIcon,
  CityIcon,
  CloseUpIcon,
} from "../../Assets/CreateCampaign";
import DatePicker from "react-datepicker";
import {
  GooglePlacesAutoComplete,
  GooglePlacesGeocode,
} from "../../shared/functions";
import styled from "styled-components";
import {
  InputWithIcon,
  Input,
  RippleLoader,
  LocationAutoCompleteInput,
} from "../../shared";
import LeftSection from "./LeftSection";
import axios from "axios";
import { GOOGLE_PLACES_HOST_NAME } from "../../Config";
import { format } from "date-fns";
import { Text } from "../TypeForm";
const PageWrapper = styled.div`
  display: grid;
  grid-template-rows: 170px 1fr;
  grid-template-columns: 1fr;
  height: 100%;
  @media (max-width: 600px) {
    grid-column: 1/-1;
  }
`;

const CropperWrapper = styled.div`
  display: grid;
  grid-row: 1;
`;
const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row: 2;
  grid-row-gap: 24px;
  padding: 0px 24px;
`;

const DatePickerWrapper = styled.div`
  width: auto-fit;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  grid-row-gap: 12px;
  max-width: 300px;
`;
const LocationWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 10px;
  width: 300px;
`;

const LocationSelect = styled.div`
  display: grid;
  grid-row-gap: 12px;
  font-size: 12px;
  padding: 8px 8px;
  grid-template-columns: 1fr;
  -webkit-box-shadow: 0px 0px 5px -2px rgba(20, 20, 20, 1);
  -moz-box-shadow: 0px 0px 5px -2px rgba(20, 20, 20, 1);
  box-shadow: 0px 0px 5px -2px rgba(20, 20, 20, 1);
  position: relative;
`;

const Heading = styled.h4`
  font-size: 16px;
  font-weight: 700;
`;

const ButtonWithIcon = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  border: solid 1px #e1e1e1;
  border-radius: 5px;
  padding: 0px 8px;
  background-color: white;
`;
const TextArea = styled.textarea`
  word-break: break-word;
  height: 100%;
  width: 100%;
  font-size: 16px;
  min-height: 250px;
  border: solid 1px #e1e1e1;
`;

const ExampleCustomInput = ({ value, placeholderText, onClick }) => (
  <ButtonWithIcon>
    <img style={{ width: 20, height: 20 }} src={CalendarIcon} />
    <button
      style={{
        border: "none",
        color: !value ? "#999" : "#000",
        minWidth: 120,
        height: 40,
        fontSize: 16,
        backgroundColor: "#fff",
      }}
      onClick={onClick}
    >
      {value ? value : placeholderText}
    </button>
  </ButtonWithIcon>
);
const BasicInformation = ({
  sessionToken,
  coverphoto,
  currentCropData,
  coverPhotoFile,
  _crop,
  coverPhotoRef,
  history,
  setInputValue,
  startDate,
  endDate,
  handleDate,
  handleNextPage,
  title,
  description,
  location,
  setCoverPhoto,
  handleFileUpload,
  submitFile,
  preview,
  match,
}) => {
  useEffect(() => {
    console.log("Use Effect Cover Photo and Preview", coverphoto, preview);
    document.querySelector("html").style.position = "inherit";
    document.querySelector("html").style.overflow = "inherit";
  }, [coverphoto, preview]);
  const editing = match.path.includes("/edit") ? true : false;
  console.log(match);
  return (
    <PageWrapper>
      <CropperWrapper>
        <div
          style={{
            gridColumn: "1/-1",
            gridRow: 1,
            marginLeft: 12,
            width: "max-content",
            height: "min-content",
            cursor: "pointer",
            backgroundColor: "#000",
            alignSelf: "center",
            display: "grid",
            gridAutoRows: "min-content",
          }}
        >
          <input
            style={{
              width: ".1px",
              height: ".1px",
              opacity: 0,
              overflow: "hidden",
              zIndex: "-1",
            }}
            name="coverphoto"
            id="coverphoto"
            type="file"
            onChange={(e) => handleFileUpload(e)}
          />
          <label
            style={{
              zIndex: 999,
              fontSize: ".9em",
              fontWeight: 700,
              color: "white",
              justifySelf: "center",
              alignSelf: "center",
              display: "grid",
              placeItems: "center",
              height: 40,
              cursor: "pointer",
            }}
            for="coverphoto"
          >
            <div
              style={{
                zIndex: 1000,
                width: "max-content",
                height: "100%",
                padding: "0 12px",
                display: "grid",
                backgroundColor: "black",
                placeItems: "center",
                zIndex: 999,
              }}
            >
              {preview
                ? "Click Here To Choose Another Header Photo"
                : "Choose Header Photo"}
            </div>
          </label>
        </div>
        {editing || preview ? (
          <div
            id="coverPhotoCropper"
            style={{
              gridColumn: "1/-1",
              gridRow: 1,
              overflow: "hidden",
              zIndex: 100,
            }}
          >
            <Cropper
              src={preview}
              viewMode={3}
              dragMode={"move"}
              autoCrop={true}
              autoCropArea={Infinity}
              restore={false}
              modal={false}
              guide={false}
              highlight={false}
              cropBoxMovable={false}
              cropBoxResizable={false}
              toggleDragModeOnDblclick={false}
              movable={true}
              zoomable={true}
              style={{ maxHeight: 170, width: "100%", objectFit: "cover" }}
              minContainerHeight={170}
              minCanvasWidth={170}
              minCropBoxWidth={170}
              minContainerWidth={"100%"}
              ref={coverPhotoRef}
              scalable={true}
              crop={() => _crop()}
              aspect={1 / 2}
            />
          </div>
        ) : null}
      </CropperWrapper>

      <Wrapper>
        <Heading> Campaign Title </Heading>
        <Input
          value={title}
          onChange={(e) => setInputValue("title", e.target.value)}
        />
        <Heading style={{ marginBottom: 10 }}>
          {" "}
          Description Of Campaign{" "}
        </Heading>
        <div>
          <TextArea
            value={description}
            onChange={(e) => setInputValue("description", e.target.value)}
            style={{}}
          />
        </div>

        <Heading style={{ whiteSpace: "nowrap" }}> Dates & Times </Heading>

        <DatePickerWrapper>
          <Text>Start Date</Text>
          <DatePicker
            value={startDate}
            selected={startDate}
            style={{ maxWidth: 120, border: "none" }}
            onChange={(e) => handleDate(e, "startDate")}
            showTimeSelect
            dateFormat="MMMM d, yyyy h:mm aa"
            customInput={
              <ExampleCustomInput
                value={startDate}
                placeholderText="Month d, yyyy h:mm"
              />
            }
          />

          <Text> End Date </Text>
          <DatePicker
            value={endDate}
            style={{ maxWidth: 120, border: "none" }}
            selected={endDate}
            onChange={(e) => handleDate(e, "endDate")}
            showTimeSelect
            dateFormat="MMMM d, yyyy h:mm aa"
            customInput={
              <ExampleCustomInput
                value={endDate}
                placeholderText="Month d, yyyy h:mm"
              />
            }
          />
        </DatePickerWrapper>

        <Heading> Location </Heading>
        <LocationWrapper>
          <LocationAutoCompleteInput
            setGeocode={(geocode) => setInputValue("geocode", geocode)}
            setLocation={(location) => setInputValue("location", location)}
            location={location}
          />
        </LocationWrapper>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            padding: "24px 0px",
          }}
        >
          <div style={{ width: 100, height: 35 }}>
            {" "}
            <button
              onClick={() => history.push("/campaigns")}
              style={{
                cursor: "pointer",
                width: "100%",
                height: "100%",
                backgroundColor: "#24292E",
                color: "white",
              }}
            >
              {" "}
              My Campaigns{" "}
            </button>{" "}
          </div>
          <div
            style={{ width: 100, height: 35, justifySelf: "flex-end" }}
            onClick={() => handleNextPage(2)}
          >
            {" "}
            <button
              style={{
                cursor: "pointer",
                width: "100%",
                height: "100%",
                backgroundColor: "#24292E",
                color: "white",
              }}
            >
              {" "}
              Next{" "}
            </button>{" "}
          </div>
        </div>
      </Wrapper>
    </PageWrapper>
  );
};

export default BasicInformation;

/*
 <InputWithIcon
                            icon={CampaignsLocationIcon}
                            value={location}
                            placeholder="Soho, New York"
                            onChange={async (e) => {
                                setInputValue('location', e.target.value)
                            }}
                            onKeyUp={(e) => {
                                if (typingTimer) clearTimeout(typingTimer)
                                const searchTerm = e.target.value
                                setLoading(true)
                                typingTimer = setTimeout(async () => {
                                    const predictions = await GooglePlacesAutoComplete(searchTerm, sessionToken)
                                    console.log(predictions)
                                    setPredictions(predictions)
                                    setLoading(false);
                                }, doneTypingInterval);
                            }}
                            onKeyDown={() => {
                                clearTimeout(typingTimer);
                            }}
                        />
                        <LocationSelect>
                                {loading && <div style={{ display: 'grid', justifyItems: 'center' }}> <RippleLoader relative={true} /> <div> Loading addresses... </div> </div>}
                                {!loading && !predictions.length ? 'Start typing the location and this box will populate your results' : null}

                                {!loading && predictions.map(prediction => <div style={{ cursor:'pointer' }} onClick={async () => {
                                        setInputValue('location', prediction.description)
                                        const geocode = await GooglePlacesGeocode(prediction.place_id)
                                        setInputValue('geocode', {
                                            longitude: geocode.lng,
                                            latitude: geocode.lat ,
                                            formattedAddress: geocode.formatted_address,
                                            placeID: prediction.place_id
                                        })
                                    }}> {prediction.description} </div>)}
                        </LocationSelect>

                        */

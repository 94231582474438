import React, { useState } from 'react';
import styled from 'styled-components';
import { Question, UserInput, Button, Text, FlexStartFlexEnd } from '../../index';
import isPhone from 'validator/lib/isMobilePhone'
const Wrapper = styled.div`
    display: grid;
    grid-row-gap: 24px;
`

function isValidDate(dateString) {
    // First check for the pattern
    if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString))
        return false;

    // Parse the date parts to integers
    var parts = dateString.split("/");
    var day = parseInt(parts[1], 10);
    var month = parseInt(parts[0], 10);
    var year = parseInt(parts[2], 10);

    // Check the ranges of month and year
    if (year < 1000 || year > 3000 || month == 0 || month > 12)
        return false;

    var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Adjust for leap years
    if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
        monthLength[1] = 29;

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
};


const Step3 = ({ setStep, step, realStep, modelDetails, setDetails, rSC }) => {
    if (step !== realStep) return null;
    const [error, setError] = useState('');
    return (
        <Wrapper>
            <Question step={step} question={`Okay ${modelDetails.firstName}, what is your date of birth?`} />
            <UserInput
                value={modelDetails.dob}
                placeholder="MM/DD/YYYY"
                callback={(value) => {
                    setDetails({
                        ...modelDetails,
                        dob: value
                    })
                }} />
            <Text color={'red'}> {error} </Text>
            <FlexStartFlexEnd>
                <Button onClick={() => {
                    var date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
                    if (!(isValidDate(modelDetails.dob))) {
                        setError('Date of birth is required.')
                    }
                    else {
                        setStep(step + 1)
                    }

                }} width={'120px'}> Next </Button>
                {rSC()}
            </FlexStartFlexEnd>

        </Wrapper>
    )
}

export default Step3;
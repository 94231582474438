import React from 'react';
import styled from 'styled-components';
import { Text } from '../../TypeForm'
import { izi, lace, rdcl } from './images'
const Wrapper = styled.div`
    display: grid;
    grid-column-gap: 60px;
    grid-row-gap: 12px;
    background-color: #000;
    padding: 12px 0px 12px 0px;
`
const ImagesWrapper = styled.div`
    display: grid;
    grid-template-columns: min-content min-content min-content;
    grid-column-gap: 217px;
    align-items: center;
    justify-items: center;
    justify-content: center;
    @media(max-width:1000px) {
        grid-column-gap: 100px;
    }
    @media(max-width:500px) {
        grid-column-gap: 50px;
    }
    @media(max-width: 414px) {
        grid-column-gap: 30px;
    }
`

const BrandLogo = styled.img`
    width: 10vw;
    @media(max-width:414px) {
        width: 90px;
    }
`

const TextWrapper = styled.div`
    font-size: 35px;
    @media(max-width:414px) {
        font-size: 25px;
    }
`

const Section2 = () => {
    return (
        <Wrapper>
            <TextWrapper>
                 <Text style={{ fontSize: '1em' }} color={'#fff'} textalign={'center'}> Our Customers </Text> 

            </TextWrapper>
            <ImagesWrapper> 
                <BrandLogo src={izi} />
                <BrandLogo src={lace} />
                <BrandLogo src={rdcl} />
            </ImagesWrapper>
           
        </Wrapper>
    )
}

export default Section2;
import React, { useState, useContext } from 'react';
import styled from 'styled-components'
import { Text, Button } from '../TypeForm/index'
import { Phone, Share } from '../TypeForm/Assets/index';
import { selectModel, removeModelFromNetwork, removeSelectedModel, copyTextToClipboard } from './functions';
import { RippleLoader } from '../../shared/index'
import { GlobalContext } from '../../App';

const Wrapper = styled.div`
    display: grid;
    grid-row-gap: 18px;
    padding: 12px 12px;
    text-transform: uppercase;
    grid-auto-rows: min-content;
`
const Icon = styled.img`
    width: ${props => props.width};
    height: ${props => props.height};
`

const SubWrapper = styled.div`
    display: grid;
    grid-row-gap: 18px;
    padding-left: 12px;
    grid-auto-rows: min-content;
`

const InformationWrapper = styled.div`
    display: grid;
    grid-row-gap: 18px;
    grid-auto-rows: min-content;
    padding-left: 12px;
    @media(max-width: 800px) {
        padding-left: 0;
    }
`

const BIHeading = styled(Text)`
    @media(max-width: 800px) {
        text-align: center;
    }
`

const BIWrapper = styled.div`
    display: grid;
    grid-row-gap: 18px;
    grid-auto-rows: min-content;
    font-size: 18px;
    padding-left: 12px;
    @media(max-width: 800px) {
        grid-template-columns: repeat(auto-fit,110px);
        justify-content: center;
        font-size: 12px;
        border-bottom: solid 2px #000;
        padding-bottom: 12px;
        padding-left: 0;
        grid-column-gap: 6px;
    }
`

const DWrapper = styled(BIWrapper)`
    display: grid;


`

const DSubWrapper = styled.div`
    display: grid;
    grid-template-rows: min-content;
    grid-row-gap: 12px;
    padding-left: 12px;
    @media(max-width: 800px) {
         padding-left: 0;
         justify-items: center;
         justify-content: center;
    }
`

const MWrapper = styled(BIWrapper)`
    @media(max-width: 800px) {
        grid-template-columns: repeat(auto-fit,150px);
        justify-content: center;
        font-size: 12px;
        border-bottom: solid 2px #000;
        padding-bottom: 12px;
    }
`

const MSubWrapper = styled(DSubWrapper)``

const ShareWrapper = styled.div`
    display: grid;
    cursor: pointer;
    @media(max-width: 800px) {
        justify-items: center;
    }
`

const Circle = styled.div`
    border-radius: 50%;
    width: 25px;
    height: 25px;
    background-color: ${props => props.backgroundcolor}
`

const RunwayBranding = styled(Text)`
    height: 60px;
    font-size: 21px;
    @media(max-width: 800px) {
        position: absolute;
        top: 10px;
        left: 10px;
        font-weight: 800;
    }
    cursor: pointer;
`

const ButtonWithLoader = styled(Button)`
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    justify-content: center;
`
function _calculateAge(birthday) { // birthday is a date
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

const BasicInformation = ({ model, viewing, history, match, setModel }) => {
    const { shortID, applicantID, _id, firstName, lastName, dob, geocode, phoneVerified, emailConfirmed, eyeColor, hairColor, complexion, waistMeasurement, heightMeasurement, shoeMeasurement, gender, } = model
    console.log(`the applicantID is ${applicantID}, the application id is ${_id}`)
    const [loading, setLoading] = useState(false);
    console.log(GlobalContext)
    const global = useContext(GlobalContext);
    let brandID = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user'))._id : null;
    const renderBrandComponents = () => {
        let text = null;
        let actionText = null;
        let ComponentArray = [];
        const renderActionButton = () => {
            if (match.params.type === 'selected-applicants' || match.params.url === 'network') {
                return <ButtonWithLoader backgroundColor={'#cf142b'} border={'solid 1px #e1e1e1'} onClick={async () => {
                    if (match.params.type === 'selected-applicants') {
                        //TODO:: remove from selected applicants
                        try {
                            setLoading(true)
                            await removeSelectedModel(match.params.modelID, brandID, match.params.url)
                            localStorage.removeItem('model')
                            history.push(`/campaigns/selected-applicants/${match.params.url}`)
                        }
                        catch (error) {

                        }
                        finally {
                            setLoading(false)
                        }
                    }
                    else if (match.params.url === 'network') {
                        //TODO:: remove from network
                        try {
                            setLoading(true);
                            await removeModelFromNetwork(match.params.modelID, brandID)
                            localStorage.removeItem('model')
                            history.push('/network');
                        }
                        catch (error) {
                            //TODO:: error handle
                            console.log(error)
                        }
                        finally {
                            setLoading(false)
                        }
                    }
                }}>
                    <div> remove </div>
                    {loading && <RippleLoader position={'absolute'} />}
                </ButtonWithLoader>
            }
            else {
                return <ButtonWithLoader onClick={async () => {
                    if (!(model.applicationStatus === 'selected') && !loading) {
                        try {
                            setLoading(true);
                            //id, url, applicationID, brandID
                            console.log({
                                applicantID, 
                                url: match.params.url, 
                                _id, 
                                brandID
                            })
                            const response = await selectModel(applicantID, match.params.url, _id, brandID)
                            setModel(response.data.application);
                            localStorage.setItem('model', JSON.stringify(response.data.application));
                        }
                        catch (error) {
                            //TODO:: error handle
                            console.log(error)
                        }
                        finally {
                            setLoading(false)
                        }
                    }
                }}
                    style={{}} border={'solid 1px #e1e1e1'} backgroundColor={'#05cca0'}>
                    <div style={{ position: 'relative' }}>
                        <div> {model.applicationStatus === 'selected' ? 'Selected' : 'Select'} </div>
                        {loading && <RippleLoader position={'absolute'} />}
                    </div>

                </ButtonWithLoader>
            }
        }
        if (match.params.url === 'network') text = 'Back To Network'
        else text = 'Back To Applicants'
        if (viewing === 'brand') {
            return (
                <React.Fragment>
                    {renderActionButton()}
                    <Button onClick={() => {
                        if (match.params.url === 'network') history.push('/network')
                        else if (match.params.type === 'selected-applicants') history.push(`/campaigns/selected-applicants/${match.params.url}`)
                        else {
                            history.push(`/campaigns/campaign-applicants/${match.params.url}`)
                        }
                    }}>  {text} </Button>
                </React.Fragment>
            )
        }
    }
    return (
        <Wrapper>
            <RunwayBranding onClick={() => history.push('/')}> Runway Supply </RunwayBranding>
            <InformationWrapper>
                <BIHeading fontsize={'18px'}>  Basic Information </BIHeading>
                <BIWrapper>
                    <Text> {firstName} {lastName}, <Text fontsize={'10px'} style={{ display: 'inline-block' }}> {_calculateAge(new Date(dob))}  </Text> </Text>
                    <Text> {geocode.locality} </Text>
                    <Text> Verified: {phoneVerified ? <Icon width={'12px'} height={'12px'} src={Phone} /> : 'N/A'} </Text>
                </BIWrapper>
                <BIHeading fontsize={'18px'}>  Details </BIHeading>
                <DWrapper>
                    <DSubWrapper>
                        <Text> Eyes  </Text>
                        <Circle backgroundcolor={eyeColor} />
                    </DSubWrapper>

                    <DSubWrapper>
                        <Text> Hair </Text>
                        <Circle backgroundcolor={hairColor} />
                    </DSubWrapper>

                    <DSubWrapper>
                        <Text> Complexion </Text>
                        <Circle backgroundcolor={complexion} />
                    </DSubWrapper>
                </DWrapper>
                <MWrapper>
                    <MSubWrapper>
                        <Text> Waist </Text>
                        <Text> {waistMeasurement} </Text>
                    </MSubWrapper>
                    <MSubWrapper>
                        <Text> Height </Text>
                        <Text> {heightMeasurement} </Text>
                    </MSubWrapper>
                    <MSubWrapper>
                        <Text> Shoe </Text>
                        <Text> {shoeMeasurement} </Text>
                    </MSubWrapper>

                    <MSubWrapper>
                        <Text> Sex </Text>
                        <Text> {gender} </Text>
                    </MSubWrapper>




                </MWrapper>
                {viewing === 'public' &&
                    <ShareWrapper onClick={() => { 
                        global.setValue('toast', { open: true, message: 'Copied link to clipboard.' })
                        const host = process.env.NODE_ENV === 'production' ? 'https://runway.supply' : 'http://localhost:3000';
                        copyTextToClipboard(`${host}/${shortID}`)
                    }}>
                        <Text
                            style={{
                                display: 'grid',
                                alignItems: 'center',
                                gridTemplateColumns: 'max-content min-content',
                                gridColumnGap: '12px'
                            }}
                            fontsize={'18px'}
                        >
                            Share
                            <Icon src={Share} style={{ display: 'inlineblock' }} />
                        </Text>

                    </ShareWrapper>
                }
                {renderBrandComponents()}



            </InformationWrapper>
        </Wrapper>

    )
}
export default BasicInformation;
import React from 'react';
import { GenderAllIcon, CampaignsLocationIcon, CalendarIcon, URLIcon, YoutubeIcon, WaistIcon, VideoIcon, UnderwearIcon, TwitterIcon, SwimIcon, SlimIcon, SideIcon, PhotoshootIcon, PhoneIcon, RunwayIcon, PencilIcon, PlusIcon, PartsIcon, NotifyIcon, MaleIcon, LengthIcon, InstagramIcon, FemaleIcon, EmailIcon, DeadlineIcon, CampaignsDateTimeIcon, BasicInfoIcon, ModelSpecsIcon, ApplicationSettingsIcon, CreateCampaignIcon, ApplicantsIcon, AthleticIcon, BirthdayIcon, CityIcon, CloseUpIcon, } from '../../Assets/CreateCampaign'
import BrandIcon from "../../Assets/Modal/shop-1.svg";
import { InputWithIcon, LocationAutoCompleteInput, Input, BasicSelect } from '../../shared'
import Dropdown from 'react-dropdown'
import styled from 'styled-components';
import { Header, SubHeader, MiniHeader, Boxes, InputsWrapper } from './shared';

const Wrapper = styled.div`
  border-radius: 5px;
  display: grid;
  grid-template-columns: min-content 1fr;
  border: 1px solid #e1e1e1;
  padding: 0px 8px;
  grid-column-gap: 8px;
  align-items: center;
  height: 100%;
  grid-auto-rows: min-content;
  max-height: 40px;
`


const BasicInformation = ({ options, dob, setYearOfBirth, setInputValue, city, phone, firstName, lastName, email }) => {
  return (
    <InputsWrapper style={{}}>
      <Wrapper style={{}}>
        <img src={PencilIcon} style={{ width: 20, height: 20 }} />
        <Input onChange={(e) => setInputValue('firstName', e.target.value)} value={firstName} placeholder="First Name" style={{ border: 'none' }} />
      </Wrapper>
      <Wrapper >
        <img src={PencilIcon} style={{ width: 20, height: 20 }} />
        <Input onChange={(e) => setInputValue('lastName', e.target.value)} value={lastName} placeholder="Last Name" style={{ border: 'none' }} />
      </Wrapper>
      <Wrapper >
        <img src={EmailIcon} style={{ width: 20, height: 20 }} />
        <Input onChange={(e) => setInputValue('email', e.target.value)} value={email} placeholder="Email" style={{ border: 'none' }} />
      </Wrapper>
      <Wrapper>
        <img src={PhoneIcon} style={{ width: 20, height: 20 }} />
        <Input onChange={(e) => setInputValue('phone', e.target.value)} value={phone} placeholder="Phone" style={{ border: 'none' }} />
      </Wrapper>
        <BasicSelect setOption={(option) => {
          setInputValue('dob', option)
          //setYearOfBirth(option)
        }} value={dob} options={options} icon={BirthdayIcon} placeholder="Year of Birth" />
        <LocationAutoCompleteInput style={{ border: 'none', gridColumn: '1/-1', width: '100%' }} setLocation={(city) => setInputValue('city', city)} setGeocode={(geocode) => setInputValue('geocode', geocode)} location={city} />
    </InputsWrapper>
  )
}

export default BasicInformation;
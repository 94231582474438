import React from 'react';
import styled from 'styled-components';
import { RightSide, LeftSide } from './index';
const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media(max-width: 414px) {
        grid-template-columns: 1fr;
        grid-row-gap: 50px;
    }
`

const Section6 = ({ ...props }) => {
    return (
        <Wrapper id='pricing'>
            <LeftSide {...props} />
            <RightSide {...props} />
        </Wrapper>
    )
}

export default Section6;
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import Loader from "../Loader";
import { TableItem, TableItemMobile } from ".";
const Wrapper = styled.div`
margin: 0 auto
`;

const TitleWrapper = styled.div``;

const Title = styled.h1`
  white-space: nowrap;
  font-size: 60px;
  text-transform: uppercase;
  text-align: center;
  @media(max-width: 900px) {
    font-size: 40px
    margin-top: 24px;
  }
`;

const SubTitle = styled.h2`
  text-transform: uppercase;
  color: #00e3f1;
  font-weight: 700;
  letter-spacing: 2px;
  text-align: center;
  margin: 0;
  font-size: 16px;
`;
const Table = styled.div`
  display: grid;
  grid-row-gap: 24px;
  margin-top: 24px;
`;

const GeniusTable = ({ title, endpoint, subTitle, ...props }) => {
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await setLoading(true);
      const response = await axios.post(endpoint);
      await setCampaigns(response.data.campaigns);
      await setLoading(false);
      console.log(response.data.campaigns);
    };
    fetchData();
  }, [endpoint]);
  return (
    <Wrapper>
      <TitleWrapper>
        <Title> {title} </Title>
        <SubTitle> {subTitle} </SubTitle>
      </TitleWrapper>
      {loading && <Loader />}
      <Table>
        {campaigns.map((campaign, i) => {
          return (
            <>
              <TableItem
                {...props}
                key={campaign._id}
                i={i}
                campaign={campaign}
              />
              <TableItemMobile
                {...props}
                key={campaign._id}
                i={i}
                campaign={campaign}
              />
            </>
          );
        })}
      </Table>
    </Wrapper>
  );
};

export default GeniusTable;

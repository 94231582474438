import React, {useState} from 'react';
import LoginEmailIcon from "../../Assets/Modal/login-email-icon.png";
import LoginPasswordIcon from "../../Assets/Modal/login-password-icon.png";
import RunwaySupplyIcon from "../../Assets/LandingPage/login-modal-logo.png";
import Modal from "react-responsive-modal";
import { ToastContainer, toast } from "react-toastify";
import { HOST_NAME, BRAND_SERVICES_HOST_NAME } from '../../Config';
import axios from 'axios';
const LoginModal = ({
  history,
  setUser
}) => {
  const [open, setOpen] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const handleLogin = async () => {
    if (!email || !password) {
      //TODO: i will add real messages to the modal to notify the user. this is temporary
      //return;
  }
  try {
      setLoading(true);
      const response = await axios.post(`${BRAND_SERVICES_HOST_NAME}/login`, {
          email,
          password
      })

      let { user } = response.data;
      localStorage.setItem("user", JSON.stringify(user)); //we will save logged in user in localStorage
      setLoading(false);
      setOpen(false);
      setUser(user);
      history.push('/campaigns');
  }
  catch (error) {
    console.log(error)
      toast.error(error.message, {
          position: toast.POSITION.BOTTOM_CENTER
      });
  }
}

  return (
    <Modal open={open} onClose={() =>  {
      setOpen();
      history.push("/");
    }} center>
      <div className="LoginModalGrid">
          <div className="LoginDialogTitle" id="form-dialog-title">
            Runway Supply
          </div>

          <div className="LoginRunwaySupplyIcon">
            <img className="LoginRunwaySupplyIconWidth" src={RunwaySupplyIcon} />
          </div>


          <div className="DialogContentInputContainer LoginInputColumnHandler" style={{ }}>
            <img src={LoginEmailIcon} />
            <input
              placeholder="Email"
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          <div className="DialogContentInputContainer LoginInputColumnHandler" >
            <img src={LoginPasswordIcon} />
            <input
              type="password"
              placeholder="Password"
              onChange={e => setPassword(e.target.value)}
            />
          </div>

          <div className="LoginInputColumnHandler" >
            <button className="RedButton" onClick={async () => {
              await handleLogin();
            }}>Login</button>
          </div>

          <div  className="MiniSubHeaderForModal" style={{ gridRow: 5 }}>
            Forgot Password?{" "}
            <span onClick={() => history.push("/forgot-password")}> Click Here. </span>
          </div>

        </div>

    </Modal>
  );


};

export default LoginModal
/*

  handleLogin = () => {
    const { email, password } = this.state;
    const { setUser, history } = this.props;
    if (!email || !password) {
       //TODO: i will add real messages to the modal to notify the user. this is temporary
      //return;
    }
    this.setState({ loading: true });
    axios
      .post(`${HOST_NAME}/api/auth/login`, {
        email,
        password
      })
      .then(response => {
        let { user } = response.data;
        localStorage.setItem("user", JSON.stringify(user)); //we will save logged in user in localStorage
        this.setState({ open: false, loading: false }, () => {
          setUser(user); // setUser is a function at the root of our App and that component stores user in state
          history.push("/campaigns"); // re route us to campaigns dashboard if user successfuly registered
        });
      })
      .catch(error => {
        console.log(error.response);
        // TODO:
        
        this.setState({ loading: false });

       toast.error(error.response.data.message, {
          position: toast.POSITION.BOTTOM_CENTER
        }); 
      });
  };
  */
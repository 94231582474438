import React from 'react';
import { Question, Background, StepControl, UserInput, Button } from '../../index';
import { RunwaySupplyLogo } from '../../Assets';
import { AlreadyAModel, JoinRunwaySupply } from '..'
import styled from 'styled-components'
const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    grid-column-gap: 10px;
    justify-items: center;
`
const Step0 = ({ setStep, step, realStep, setShowGuidelines }) => {
    if(step !== realStep) return null;
    return (
        <div backgroundColor={'#fff'}>
           <Wrapper>
                <JoinRunwaySupply callback={() => { 
                    setShowGuidelines(true)
                    setStep(step + 1)
                }} />
           </Wrapper>
        </div>
    )
}

export default Step0;
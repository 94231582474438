import React, { useState } from 'react';
import styled from 'styled-components';
import { Text, Button } from './index';

const Wrapper = styled.div`
    display: grid;
    grid-auto-rows: min-content;
    grid-row-gap: 24px;
`

const ImageWrapper = styled.label`
    height: 435px;
    width: 285px;
    border: solid 1px #e1e1e1;
    display: grid;
    text-align: center;
    cursor: pointer;
    grid-auto-rows: min-content;
    grid-row-gap: 24px;
    align-content: center;
    justify-items: center;
`

const Input = styled.input`
    width: .1px;
    height: .1px;
`

const Icon = styled.img`
    width: 24px;
    height: 24px;
`

const Preview = styled.img`
    height: 435px;
    max-width: 285px;
    object-fit: cover;
`

const PhotoUpload = ({ icon, cb, text = "Upload a photo", labelFor, state,  }) => {
    const [preview, setPreview] = useState(state ? state : false);
    const renderPhotoBox = () => {
        if (preview) {
            return <Preview src={preview} />
        }
        else {
            return <React.Fragment>
                        <Icon src={icon} />
                        <Text> {text} </Text>
                        <Input onChange={(e) => {
                            const urlPreview = URL.createObjectURL(e.target.files[0])
                            setPreview(urlPreview);
                            cb(e.target.files[0], urlPreview);
                        }} id={labelFor} type="file" />
                    </React.Fragment>
        }
    }
    const renderText = () => {
        if(preview) return <label for={labelFor}> 
                                <Button 
                                    backgroundColor={'#fff'} 
                                    color={'#000'} 
                                    onClick={() =>  { 
                                        setPreview(false)
                                        cb(false,false)
                                    }}> 
                                    Choose Another Photo 
                                </Button> 
                            </label>
        else return null;
    }
    return (
        <Wrapper>
            <ImageWrapper for={labelFor}>
                {renderPhotoBox()}
            </ImageWrapper >
            {renderText()}
        </Wrapper>

    )
}


export default PhotoUpload;
import React from 'react';
import styled from 'styled-components';

const BG = styled.div`
    background-color: ${props => props.backgroundColor};
    min-height: 100vh;
    display: grid;
    grid-auto-rows: min-content;
    grid-row-gap: 40px;
    align-items: ${props => props.alignItems}
`

const Background = ({ alignItems, backgroundColor, children }) => {
    return (
        <BG alignItems={alignItems} backgroundColor={backgroundColor}>
            {children}
        </BG>
    )
}

export default Background; 
import React, { Component } from 'react';
import axios from "axios";
import Modal from "react-responsive-modal";
import { toast } from "react-toastify";
import LoginPasswordIcon from "../../Assets/Modal/login-password-icon.png";
import RunwaySupplyIcon from "../../Assets/LandingPage/login-modal-logo.png";
import '../ResetPasswordModal.css'
import { HOST_NAME } from '../../Config';
class ResetPassword extends Component {
  state = {
    open: false,
  }

  componentDidMount() {
    /*
      so here they have navigated to a route of reset_password/{token}
      im going to check if they have a valid token, if they do I will allow them to change their password otherwise they will be redirected
    */

    const { token } = this.props.match.params; // ---> this is the token in the url

    axios.post(`${HOST_NAME}/api/auth/validate_token`, { token })
    .then((res) => {
      ;
      this.setState({ open: true })
    })
    .catch((err) => {
      // invalid token
      toast.error("Invalid token, try resetting your password again 😇.", {
        position: toast.POSITION.BOTTOM_CENTER
      });
      this.props.history.push('/')
    })
  }

  handleResetPassword = () => {
    // need to make sure here the password is a certain length too
    const { password } = this.state;

    const { token } = this.props.match.params; // ---> this is the token in the url
    axios.post(`${HOST_NAME}/api/auth/reset_password`, { token, password })
    .then((res) => {
      ;
      toast.success("Your password has been reset 🤩.", {
        position: toast.POSITION.BOTTOM_CENTER
      });
      this.props.history.push('/')
    })
    .catch((err) => {
      // invalid token
      toast.error("Their was an error proccessing your request 🚨, please try again.", {
        position: toast.POSITION.BOTTOM_CENTER
      });
      this.props.history.push('/')
    })
  }

  handleClickOpen = () => {
    this.setState({ open: true, });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  setInputState = (key, value) => {
    this.setState({ [key]: value });
  };

  render() {
    const { open } = this.state;
    return (
      <ResetPasswordModal {...this.props} open={open} handleClickOpen={this.handleClickOpen} handleClose={this.handleClose} setInputState={this.setInputState} handleResetPassword={this.handleResetPassword}/>
    )
  }
}

const ResetPasswordModal = ({ open, handleClickOpen, handleClose, setInputState, handleResetPassword, history }) => {
  return (
    <Modal open={open} onClose={() => {
      handleClose()
    }} center>
      <div className="ResetPasswordModalGrid">
        <div className="ResetPasswordDialogTitle" id="form-dialog-title">
          Reset Your Password
        </div>
        <div className="ResetPasswordRunwaySupplyIcon">
          <img className="RunwaySupplyIconWidth" src={RunwaySupplyIcon} />
        </div>
        <div className="ResetPasswordDialogContent">

          <div className="DialogContentInputContainer">
            <img src={LoginPasswordIcon} />
            <input
              type="password"
              placeholder="Password"
              onChange={e => setInputState("password", e.target.value)}
            />
          </div>
        </div>
        <div className="ResetPasswordDialogActions">
          <button onClick={() => handleResetPassword()}>Reset</button>
        </div>

        <div className="DialogContentTextLogin" style={{ gridRow: 4, gridColumn: '1 / span 16', textTransform:'uppercase' }}>
          Once you reset your password {" "}
          <span onClick={() => history.push("/login")}> Login </span>
        </div>
      </div>

    </Modal>
  )
}

export default ResetPassword;

import React, { useEffect } from 'react';
function useOuterClickNotifier(onOuterClick, innerContainer) {
  useEffect(
    () => {
      document.addEventListener("click", handleClick);
      return () => document.removeEventListener("click", handleClick);

      function handleClick(e) {
        !innerContainer().contains(e.target) && onOuterClick(e);
      }
    },
    [onOuterClick, innerContainer]
  );
}

export default useOuterClickNotifier;

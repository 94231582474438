const HOST_NAME =
  process.env.NODE_ENV === "production" ? "https://api.runway.supply" : ""; // regular ec2 api
const GOOGLE_PLACES_HOST_NAME =
  process.env.NODE_ENV === "production"
    ? "https://slyqdq3g5j.execute-api.us-east-1.amazonaws.com/prod/"
    : "http://localhost:5000";
const TWILIO_HOST_NAME =
  process.env.NODE_ENV === "production"
    ? "https://u5md735imj.execute-api.us-east-1.amazonaws.com/prod"
    : "http://localhost:4999";
const MODEL_CATALOG_HOST_NAME =
  process.env.NODE_ENV === "production"
    ? "https://3l8qsu8yp8.execute-api.us-east-1.amazonaws.com/prod"
    : "http://localhost:5444";
const ANALYTICS_HOST_NAME =
  process.env.NODE_ENV === "production"
    ? "https://odskdt1ei5.execute-api.us-east-1.amazonaws.com/prod"
    : "http://localhost:3050";
const AWS_SERVICES_HOST_NAME =
  process.env.NODE_ENV === "production"
    ? "https://o9ihr4h1w0.execute-api.us-east-1.amazonaws.com/prod"
    : "http://localhost:4090";
const BRAND_SERVICES_HOST_NAME =
  process.env.NODE_ENV === "production"
    ? "https://vjd00bpjbk.execute-api.us-east-1.amazonaws.com/prod"
    : "http://localhost:4091";
export {
  HOST_NAME,
  GOOGLE_PLACES_HOST_NAME,
  TWILIO_HOST_NAME,
  MODEL_CATALOG_HOST_NAME,
  ANALYTICS_HOST_NAME,
  AWS_SERVICES_HOST_NAME,
  BRAND_SERVICES_HOST_NAME,
};

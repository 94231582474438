import styled from 'styled-components';

const SelectableBox = styled.div`
    border-radius: 5px;
    width: 75px;
    height: 65px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 40px 5px 20px;
    border: 1px solid black;
    background-color: ${props => props.selected ? 'rgba(255, 255, 255)' : 'rgba(255, 255, 255, 0.01)'};
`

export default SelectableBox;
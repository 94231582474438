import React, { useEffect } from 'react'
import { CalendarIcon, URLIcon, YoutubeIcon, WaistIcon, VideoIcon, UnderwearIcon, TwitterIcon, SwimIcon, SlimIcon, SideIcon, PhotoshootIcon, PhoneIcon, RunwayIcon, PencilIcon, PlusIcon, PartsIcon, NotifyIcon, MaleIcon, LengthIcon, InstagramIcon, FemaleIcon, EmailIcon, DeadlineIcon, CampaignsDateTimeIcon, BasicInfoIcon, ModelSpecsIcon, ApplicationSettingsIcon, CreateCampaignIcon, ApplicantsIcon, AthleticIcon, BirthdayIcon, CityIcon, CloseUpIcon, } from '../../Assets/CreateCampaign'
import DatePicker from "react-datepicker";
import LeftSection from './LeftSection';
import styled from 'styled-components';
import { InputWithIcon, Input, RippleLoader, LocationAutoCompleteInput } from '../../shared'
import { Text, UserInput } from '../TypeForm'
import { DragAndDropArea, ModelReleaseFormIcon } from './shared';

const Wrapper = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  grid-row-gap: 24px;
  grid-column-gap: 24px;
  min-height: 100vh;
  grid-template-columns:1fr
  padding: 16px 48px;
  background-color: #f6f8fa;
  @media(max-width: 414px) {
    padding: 16px 4px;
  }
  justify-content: center;
`

const SubWrapper = styled(Wrapper)`
  min-height: 0px;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  jutify-self: center;
`

const InputsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
`

const DatePickerWrapper = styled.div`
  min-width: 225px;
  height: 40px;  
  display: grid;
  border: solid 1px #e1e1e1; 
  align-items: center;
  grid-column-gap: 12px;
  grid-template-columns: min-content 1fr;
  background-color: #fff;
  padding: 0px 4px;
  cursor: pointer;
  @media(max-width:414px) {
    min-width: 0;
  }
`

const ModelReleaseWrapper = styled.label`
  display: grid;
  grid-row-gap: 12px;
`

const ApplicantSettings = ({  releaseURL, modelRelease, submitCampaign, handleNextPage, handlePreviousPage, setApplicationSettings, profiles, photos, deadline, handleDate, applicationLimit, hireLimit, setInputValue }) => {
  useEffect(() => {
    /* if (photos.length) {
      photos.forEach(photoprofile => document.getElementById("photos-" + photoprofile).style.backgroundColor = 'rgba(255, 255, 255, 1)')
    }
    if (profiles.length) {
      profiles.forEach(handle => document.getElementById("profiles-" + handle).style.backgroundColor = 'rgba(255, 255, 255, 1)')
    } */
  });
  const renderReleaseText = () => {
    if(modelRelease.file)      {
      return `The current file is ${modelRelease.name}. Click To Upload A Model Release Form PDF.`
    }
    else if(releaseURL) {
      return `The current Model Release PDF on file is ${releaseURL} Click to Upload A Different Model Release Form PDF.`
    }
    else if(!modelRelease.file || !releaseURL)  {
      return 'Click To Upload A Model Release Form PDF'
    }
  }
  return (

    <Wrapper>
      <div style={{ justifySelf: 'center' }}> Application Settings </div>

      <div style={{}}>
        <div> Admin <div style={{ fontSize: 10 }}> Required. You must fill these out.</div> </div>
      </div>

      <SubWrapper>
        <InputsWrapper>
          <Text> Application Limit </Text>
          <InputWithIcon width={'100%'} placeholder="Application Limit" icon={ApplicantsIcon} onChange={(e) => setInputValue('applicationLimit', e.target.value.replace(/\D/, ''))} value={applicationLimit} />
        </InputsWrapper>

        <InputsWrapper>
          <Text> Model hire Limit </Text>
          <InputWithIcon width={'100%'} placeholder="Model Hire Limit" icon={ApplicantsIcon} type="text" pattern="[0-9]*" onChange={(e) => setInputValue('hireLimit', e.target.value.replace(/\D/, ''))} value={hireLimit} />
        </InputsWrapper>

        <InputsWrapper>
          <Text style={{}}> Application Deadline </Text>
          <DatePickerWrapper>
            <img style={{ width: 20, height: 20 }} src={CalendarIcon} />
            <DatePicker
              value={deadline}
              style={{ border: 'none', outline: 'none' }}
              selected={deadline}
              onChange={(e) => handleDate(e, 'deadline')}
              showTimeSelect
              placeholderText="Month d, yyyy h:mm aa"
              dateFormat="MMMM d, yyyy h:mm aa"
              className="datepicker"
            />
          </DatePickerWrapper>
        </InputsWrapper>


      </SubWrapper>


      <ModelReleaseWrapper style={{ display: 'grid',  }} for="model-release">
      <Text>
        Attach Model Release Form - Optional
      </Text>
      <Text>
        PDF Files Only
      </Text>
        <DragAndDropArea>
          <img src={ModelReleaseFormIcon} />
          <Text textalign='center'> 
            {renderReleaseText()}
          </Text>
          
          <input accept="application/pdf" onChange={(e) => {
            const file = e.target.files[0];
            const { name } = file;
            setInputValue('modelRelease', {
              file,
              name,
            })
            console.log(file,name)
          }} style={{ width: 0 }} type="file" id="model-release" />
        </DragAndDropArea>

        <Text> {modelRelease.name} </Text>
      </ModelReleaseWrapper>


      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(16, 1fr)' }}>
        <div onClick={() => handlePreviousPage(2)} style={{ gridColumn: 1, width: 100, height: 35, }}> <button style={{ cursor: 'pointer', width: '100%', height: '100%', backgroundColor: '#24292E', color: 'white' }}> Previous </button> </div>
        <div onClick={() => submitCampaign()} style={{ gridColumn: 16, width: 100, height: 35, }} > <button style={{ cursor: 'pointer', width: '100%', height: '100%', backgroundColor: '#24292E', color: 'white' }}> Confirm </button> </div>
      </div>

    </Wrapper>

  )
}

export default ApplicantSettings;
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { MODEL_CATALOG_HOST_NAME } from '../../Config';
import { Button } from '../TypeForm';

const ModelDetailsWrapper = styled.div`
    display: ${props => props.hover ? 'grid' : 'none'};
    align-content: center;
    grid-auto-rows: min-content;
    grid-row-gap: 12px;
    grid-row: 1;
    grid-column: 1;
    z-index: 1000;
    width: 344px;
    height: 375px;
    @media(max-width: 700px) {
        height: 244px;
        width: 190px;
    }
`

const ModelText = styled.div`
    font-size: 24px;
    color: white;
    text-transform: uppercase;
    text-align: center;
`




const ModelDetails = ({ model, history, hover, getModelCatalog }) => {
    const renderAdminView = () => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.admin === true) {
            return (
                <React.Fragment>
                    <Button style={{  width: '50%', justifySelf: 'center' }}  backgroundColor={'#fff'} color={'#000'} onClick={async (e) => {
                        e.stopPropagation()
                        console.log(user)
                        
                        const response = await axios.post(`${MODEL_CATALOG_HOST_NAME}/handleModelCatalog`, {
                            shortID: model.shortID,
                            token: user.jwt,
                            type: !model.catalog ? true: false
                        });
                        getModelCatalog()
                        console.log(response)
                    }}>
                        {model.catalog ? 'Remove From Catalog' : 'Add To Catalog'}
                      </Button>
                </React.Fragment>
            )
        }
    }
    return (
        <ModelDetailsWrapper hover={hover} onClick={() => history.push(`/${model.shortID}`)}>
            <ModelText>
                {model.geocode.locality}
            </ModelText>
            {renderAdminView()}

        </ModelDetailsWrapper>
    )

}

export default ModelDetails;
import axios from 'axios'
import { TWILIO_HOST_NAME } from '../../Config';

export const selectModel = async (modelID, url, applicationID, brandID) => {
    return axios.post(`${TWILIO_HOST_NAME}/select_model`, {
        id: modelID,
        url,
        applicationID,
        brandID
    })
}

export const getModel = (id) => {
    return axios.post(`${TWILIO_HOST_NAME}/get_model`, {
        id
    })
}

export const getModelApplication = async (shortID, url) => {
    return axios.post(`${TWILIO_HOST_NAME}/get_model_application`, {
        shortID,
        url,
    })
}

export const removeModelFromNetwork = async (shortID, brandID) => {
    return axios.post(`${TWILIO_HOST_NAME}/remove_model_from_network`, {
        shortID,
        brandID
    })
}

export const removeSelectedModel = async (shortID, brandID, url) => {
    return axios.post(`${TWILIO_HOST_NAME}/remove_selected_model`, {
        shortID,
        brandID,
        url
    })
}

export const fallbackCopyTextToClipboard = (text) => {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.position="fixed";  //avoid scrolling to bottom
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
  
    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }
  
    document.body.removeChild(textArea);
  }

  export const  copyTextToClipboard = (text) => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(function() {
      console.log('Async: Copying to clipboard was successful!');
    }, function(err) {
      console.error('Async: Could not copy text: ', err);
    });
  }


import React, { Component, useState } from 'react';
import Modal from "react-responsive-modal";
const DeleteAccountModal = ({ deleteAccount }) => {
  const [open, setOpen] = useState(null);
  return (
    <div>
      <button onClick={() => setOpen(true)}  id="delete-account-button" className="ProfileDeleteInput"> DELETE </button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <div style={{ display:'grid', gridAutoRows: 100 }}>
          <div style={{ fontSize: 32, fontWeight: 100, lineHeight: '2.4rem', color:'red', textAlign:'center' }}> Are you sure? </div>
          <div style={{ paddingLeft:25, paddingRight: 25, textAlign:'center' }}>
            Deleting your account is permanent and cannot be undone. Please confirm deletion.
          </div>

          <div style={{ display:'grid', gridTemplateColumns:'repeat(4, 1fr)', gridColumnGap: 12.5 }}>
            <div style={{ gridColumn:2 }}> <button onClick={() => setOpen(false)}  className="ProfileSaveInput"> KEEP MY ACCOUNT </button> </div>
            <div style={{ gridColumn:3 }}> <button onClick={() => deleteAccount()}  id="delete-account-button" className="ProfileDeleteInput"> DELETE </button> </div>
          </div>

        </div>
      </Modal>
    </div>
  )
}

export default DeleteAccountModal

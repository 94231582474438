import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TriangleRight, TriangleLeft, BlackStar, YellowStar, OutlineYellowStar } from '../../Assets/CampaignApplicants';
import { Mail, Phone } from '../../Assets/Misc';
const Wrapper = styled.div`
    display: grid;
    grid-auto-rows: min-content;
    border-radius: 20px;
    grid-row-gap: 12px;
    background-color: #fff;
    -webkit-box-shadow: 0 10px 6px -6px #777;
       -moz-box-shadow: 0 10px 6px -6px #777;
            box-shadow: 0 10px 6px -6px #777;
    padding: 0px 0px 12px 0px
`
const ImageWrapper = styled.div`
`

const Image = styled.img`
    border-radius: 20px 20px 0px 0px;
    height: 300px;
    width: 100%;
    object-fit: cover;
`

const ModelMetaWrapper = styled.div`
    padding: 0px 6px;
    display: grid;
    grid-row-gap: 6px;
`

const RowOne = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: max-content min-content min-content 1fr
    grid-column-gap: 8px;
`

const Name = styled.span`
    text-transform: uppercase;
    font-weight: 800;
`

const StarsAmount = styled.div`
    font-size: 12px;
`

const Icon = styled.img`
    height: 16px;
    width: 16px;
`

const PhotoControlWrapper = styled.div`
    display: grid;
    justify-content: flex-end;
    grid-template-columns: min-content min-content;
`

const RowFour = styled.div`
    display: grid;
    grid-template-columns: max-content repeat(auto-fill, 30px)
`

const VerificationWrapper = styled.div`
    display: grid;
    align-items:center;
    grid-template-columns: repeat(3, min-content);
    grid-column-gap: 8px;
`

const Subheader = styled.div`
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 800;
`
const ModelBox = ({ model, photoIndex = 0, history, match }) => {
    const {
        createdAt,
        firstName,
        geocode,
        lastName,
        waistUp,
        closeUp,
        fullLength,
        sideProfile,
        shortID,
        waistUpBaseResolution, closeUpBaseResolution, fullLengthBaseResolution,
        sideProfileBaseResolution,
        emailConfirmed,
        phoneVerified,
        filters

    } = model;
    const [queue, setQueue] = useState([waistUp,closeUp,fullLength,sideProfile,].filter(item => item));
    useEffect(() => {
        console.log('use effect in modelbox', photoIndex)
        let queueCopy = [...queue];
        let currentPhoto = queueCopy.shift(); // deque
        queueCopy.push(currentPhoto);
        setQueue(queueCopy);
    }, [photoIndex, filters])
    let locality = null;
    if (geocode && Object.keys(geocode).length) {
        locality = geocode.locality;
    }

    return (
        <Wrapper onClick={() => { 
            if(match.url.includes('selected-applicants')) {
                history.push(`/campaigns/selected-applicants/${match.params.url}/${shortID}`)
            }
            else {
                history.push(`/campaigns/campaign-applicants/${match.params.url || 'network'}/${shortID}`)
            }
        }}>
            <ImageWrapper>
                <Image src={queue[0]} />
            </ImageWrapper>
            <ModelMetaWrapper>
                <RowOne>
                    <Name> {firstName} {' '} </Name>
                    {
                        /* 
                        <StarsAmount> 0 </StarsAmount>
                        <Icon src={OutlineYellowStar} />
                        <PhotoControlWrapper>
                            <Icon src={TriangleLeft} />
                            <Icon src={TriangleRight} />
                        </PhotoControlWrapper>
                        */
                    }

                </RowOne>
                <Subheader> {locality} </Subheader>
                {
                    /*<Subheader> {new Date(createdAt).toLocaleDateString()} </Subheader> */
                }
                <VerificationWrapper>
                    <Subheader> Verified:  </Subheader>
                    { phoneVerified && <Icon src={Phone} /> }
                    { emailConfirmed && <Icon src={Mail} /> }
                </VerificationWrapper>

            </ModelMetaWrapper>

        </Wrapper>
    )
}
export default ModelBox
import React from 'react';
import styled from 'styled-components';
import { Text } from './shared';


const Wrapper = styled.div`
    display: grid; 
`

const SubWrapper = styled.div`
    align-self: center;
    display: grid;
    grid-auto-rows: min-content;
    grid-row-gap: 12px;
`

const YellowWrapper = styled.div`
    background-color: #F4D17A;
    width: 398px;
    height: 530px;
    display: grid;
    justify-self: center;
    grid-row-gap: 40px;
    align-self: center;
`

const BlockOne = styled.div`
    display: grid;
    font-weight: bold;
    grid-auto-rows: min-content;
    grid-row-gap: 19px;
    font-size: 25px;
    align-self: center;
`

const BlockTwo = styled.div`
    font-size: 18px;
    display: grid;
    grid-row-gap: 12px;
`

const Button = styled.div`
    background-color: #fff;
    color: #000;
    text-align: center;
    display: grid;
    align-items: center;
    width: 322px;
    height: 64px;
    justify-self: center;
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
`
const RightSide = ({ history }) => {
    return (
        <Wrapper>
            <SubWrapper>


                <YellowWrapper>
                    <BlockOne>
                        <Text> STANDARD </Text>
                        <Text> $250/month*</Text>
                    </BlockOne>
                    <BlockTwo>
                        <Text> Unlimited Campaigns </Text>
                        <Text> Shareable Campaign Link </Text>
                        <Text>Model Release Forms </Text>
                        <Text> Consolidated Billing </Text>
                        <Text> Campaign Analytics </Text>
                    </BlockTwo>
                    <Button onClick={() => history.push('/register')}> ONBOARD YOUR TEAM</Button>

                </YellowWrapper>
                <Text> *No risk, payment cycle starts after your first live campaign. </Text>
            </SubWrapper>
        </Wrapper>
    )
}

export default RightSide;
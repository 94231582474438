import React, { useState } from 'react';
import styled from 'styled-components';
import { Question, UserInput, Button, Text, SelectDropDown, PhotoUpload, FlexStartFlexEnd } from '../../index';
import { SideProfile } from '../../Assets';
const Wrapper = styled.div`
    display: grid;
    grid-row-gap: 24px;
`

const PhotoWrapper = styled.div`
    justify-self: center;
`
const SideProfilePhoto = ({ setStep, step, realStep, modelDetails, setDetails, rSC }) => {
    if (step !== realStep) return null;
    const [error, setError] = useState('');
    return (
        <Wrapper>
            <Question step={step} question={'Upload your side-profile.'} />
            <Text> Please submit four photos of yourself: full-length, waist-up, close-up, and side-profile. Do your best to replicate the sample images found in the model guidelines.    </Text>
            <PhotoWrapper>
               <PhotoUpload 
               cb={(sideProfile, sideProfilePreview) => setDetails({ ...modelDetails, sideProfile, sideProfilePreview })} 
               state={modelDetails.sideProfilePreview}
               labelFor={'sideProfile'} 
               icon={SideProfile} 
               text={"Click here to upload your side-profile."} />
            </PhotoWrapper>
  
            <Text color={'red'}> {error} </Text>

            <FlexStartFlexEnd>
                <Button onClick={() => {
                    if (modelDetails.sideProfile) {
                        setStep(step + 1)
                    }
                    else {
                        setError('Side-profile is required.')
                    }

                }} width={'120px'}> Next </Button>
                {rSC()}
            </FlexStartFlexEnd>

        </Wrapper>
    )
}

export default SideProfilePhoto;
import React from 'react';
import styled from 'styled-components';
 const Textarea = styled.input`
    display: block;
    width: 100%;
    font-family: inherit;
    color: rgb(60, 60, 60);
    background-color: transparent;
    font-size: 24px;
    line-height: 48px;
    @media(max-width: 900px) {
        font-size: 16px;
    }
    overflow-wrap: break-word;
    resize: none;
    padding: 0px 0px 0px;
    border-radius: 0px;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    transition: all 0.2s ease 0s;
    overflow: hidden;
    border-bottom: solid 3px #333;
    border-radius: 2px;
    height: ${props => props.height || 'fit-content'}
    white-space: nowrap;
 `
 function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return null
  }

const UserInput = ({ value, callback, placeholder = "Type your answer here...", id, height }) => {

    return (
        <Textarea height={height} id={id} value={value} placeholder={placeholder} onChange={(e) => { 
            if(id === "phone") {
                callback(formatPhoneNumber(e.target.value), e.target.value.replace(/\D+/g, ''))
                return;
            }
            callback(e.target.value)
        }} />
    )
}

export default UserInput;

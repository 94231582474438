import React, { Component, useState } from "react";
import "./Campaigns.css";
import CampaignsLiveIcon from "../../Assets/Campaigns/campaigns-live-icon.png";
import CampaignsDraftIcon from "../../Assets/Campaigns/campaigns-draft-icon.png";
import CampaignsModelIcon from "../../Assets/Campaigns/campaigns-model-icon.png";
import CampaignsLocationIcon from "../../Assets/Campaigns/campaigns-location-icon.svg";
import CampaignsViewIcon from "../../Assets/Campaigns/eye-icon.svg";
import CampaignsApplicantsIcon from "../../Assets/Campaigns/users-Icon.svg";
import CampaignsHireStatus from "../../Assets/Campaigns/campaigns-thumbs-up.svg";
import CampaignsSettingsIcon from "../../Assets/Campaigns/campaigns-setting-icon.png";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { Route } from "react-router-dom";
import PrivateRoute from "../PrivateRoute";
import CampaignApplicants from "../CampaignApplicants";
import CampaignSelectedApplicants from "../CampaignSelectedApplicants";
import Loader from "../Loader";
import CampaignSendIcon from "../../Assets/Campaigns/send-email-1.svg";
import { HOST_NAME, BRAND_SERVICES_HOST_NAME } from "../../Config";
import { Modal, Button } from "../../shared";
import { PaymentSection } from ".";
import CreateCampaign from "../CampaignCreation/CreateCampaign";
import styled from "styled-components";
const WithEllipses = styled.div`
  margin-top: 10px;
  padding-left: 15px;
  font-size: 18px;
  max-width: 250px;
`;

const Title = styled(WithEllipses)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
`;

const LocationWithEllipses = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
`;
function kFormatter(num) {
  return num > 999 ? (num / 1000).toFixed(1) + "k" : num;
}
class Campaigns extends Component {
  state = {
    campaigns: null,
    customer: {},
    open: false,
    loading: true,
    currentId: "",
    subscriptionCost: "",
    currentOptionsId: "",
    editing: false,
    viewHiredModels: false,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getCampaigns();
    this.getCustomerObject();
  }

  setOpen = () => this.setState((state) => ({ open: !state.open }));

  getCampaigns = async () => {
    this.setState({ loading: true });
    const user = JSON.parse(localStorage.getItem("user"));
    try {
      const response = await axios.post(
        `${BRAND_SERVICES_HOST_NAME}/get_campaigns`,
        {
          uid: user._id,
        }
      );
      const { campaigns } = response.data;
      this.setState({ campaigns, loading: false });
    } catch (error) {
      console.log(error);
    }
  };

  getCustomerObject = async () => {
    this.setState({ loading: true });
    const user = JSON.parse(localStorage.getItem("user"));
    let { customerId } = user;
    try {
      const response = await axios.post(
        `${BRAND_SERVICES_HOST_NAME}/get_customer_object`,
        {
          customerId,
        }
      );
      const { customer } = response.data;
      this.setState({ loading: false, customer });
    } catch (error) {
      console.log(error);
    }
  };

  handleCreateCampaign = () => {
    this.props.history.push("/create-campaign/1");
  };

  handleGoLive = async (_id) => {
    const { customer, campaigns } = this.state;
    const campaign = campaigns.find((campaign) => campaign._id === _id);
    console.log("current campaign", campaign);
    if (Object.keys(customer).length)
      if (customer.subscriptions.total_count > 0 || campaign.paid) {
        // customer is already a subscriber so call endpoint to make that campaign live
        await this.setCampaignLive(_id);
        await this.getCampaigns();
      } else {
        // the user isnt subscribed so show them the payment modal
        const user = JSON.parse(localStorage.getItem("user"));
        this.setState({ loading: true });
        const response = await axios.post(
          `${BRAND_SERVICES_HOST_NAME}/get_subscription_price`,
          {
            customerId: user.customerId,
            coupon: user.coupon,
          }
        );
        const { cost } = response.data;
        this.setState({
          subscriptionCost: cost,
          currentId: _id,
          loading: false,
          open: true,
        });
      }
  };

  billCustomer = () => {
    const { customer } = this.state;
    const user = JSON.parse(localStorage.getItem("user"));
    this.setState({ loading: true });
    axios
      .post(`${BRAND_SERVICES_HOST_NAME}/bill_customer`, {
        customerId: customer.id,
        email: customer.email,
        coupon: user.coupon,
      })
      .then((res) => {
        this.setState({ loading: false, billed: true });
        toast.success("Thank You For Subscribing 🤖.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
        toast.error("There was an error you have not been billed 🤖.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  };

  billCustomerSingleCampaign = async () => {
    const { customer, currentId } = this.state;
    const user = JSON.parse(localStorage.getItem("user"));
    this.setState({ loading: true });
    try {
      const response = await axios.post(
        `${BRAND_SERVICES_HOST_NAME}/bill_customer_single_campaign`,
        {
          customerId: customer.id,
          email: customer.email,
          coupon: user.coupon,
          campaignID: currentId,
        }
      );
      console.log(response);
      const { campaigns } = response.data;
      this.setState({ campaigns, loading: false, open: false });
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
    }
  };

  billCustomerMonthly = async () => {
    const { customer, currentId } = this.state;
    const user = JSON.parse(localStorage.getItem("user"));
    this.setState({ loading: true });
    try {
      const response = await axios.post(
        `${BRAND_SERVICES_HOST_NAME}//bill_customer_monthly`,
        {
          customerId: customer.id,
          email: customer.email,
          coupon: user.coupon,
          campaignID: currentId,
        }
      );
      console.log(response);
      const { campaigns } = response.data;
      this.setState({ campaigns, loading: false, open: false });
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
    }
  };

  setCampaignLive = async (_id) => {
    try {
      this.setState({ loading: true });
      const response = await axios.post(
        `${BRAND_SERVICES_HOST_NAME}/set_campaign_live`,
        {
          _id,
        }
      );
      const { campaigns } = response.data;
      this.setState({ loading: false, campaigns });
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
    }
  };

  openOptions = (id) => {
    this.setState({ currentOptionsId: id });
    document.getElementById(id).style.display = "none";
    document.getElementById(id + "options").style.display = "grid";
  };

  closeLastOption = () => {
    const { currentOptionsId } = this.state;
    if (currentOptionsId) {
      document.getElementById(currentOptionsId).style.display = "block";
      document.getElementById(currentOptionsId + "options").style.display =
        "none";
      this.setState({ currentOptionsId: "" });
    }
  };

  deleteCampaign = (link) => {
    this.setState({ loading: true });
    axios
      .post(`${BRAND_SERVICES_HOST_NAME}/delete_campaign`, {
        link,
      })
      .then((res) => {
        const { campaigns } = res.data;
        this.setState({ campaigns, loading: false });
        toast.success("Your campaign has been deleted 🤖.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
  };

  closeCampaign = async (link) => {
    this.setState({ loading: true });
    const response = await axios.post(
      `${BRAND_SERVICES_HOST_NAME}/close_campaign`,
      {
        link,
      }
    );
    const { campaigns } = response.data;
    this.setState({ campaigns, loading: false });
  };

  editCampaign = (url) => {
    this.props.history.push(`/create-campaign-edit/${url}/1`);
    this.setState({ editingURL: url, editing: true });
  };

  closeEditing = () => {
    document.getElementById("AuthenticatedNavbarContainer").style.display =
      "grid";
    this.setState({ editing: false }, () => {
      window.scrollTo(0, 0);
      this.getCampaigns();
    });
  };

  // this is good because we save an api call no edits actually went through so just use the campaigns currently stored in state
  discardEdits = () => {
    document.getElementById("AuthenticatedNavbarContainer").style.display =
      "grid";
    this.setState({ editing: false }, () => {
      window.scrollTo(0, 0);
    });
  };

  handleViewHiredModels = () => {
    this.setState((previousState) => ({
      viewHiredModels: !previousState.viewHiredModels,
    }));
  };

  copyToClipboard = (string) => {
    let textarea;
    let result;

    try {
      textarea = document.createElement("textarea");
      textarea.setAttribute("readonly", true);
      textarea.setAttribute("contenteditable", true);
      textarea.style.position = "fixed"; // prevent scroll from jumping to the bottom when focus is set.
      textarea.value = string;

      document.body.appendChild(textarea);

      textarea.focus();
      textarea.select();

      const range = document.createRange();
      range.selectNodeContents(textarea);

      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);

      textarea.setSelectionRange(0, textarea.value.length);
      result = document.execCommand("copy");
    } catch (err) {
      console.error(err);
      result = null;
    } finally {
      document.body.removeChild(textarea);
    }
  };

  render() {
    const {
      campaignID,
      campaigns,
      open,
      loading,
      subscriptionCost,
      editing,
      editingURL,
      discardEdits,
      viewHiredModels,
    } = this.state;
    const { match } = this.props;
    const temp = <div>Hello</div>;
    if (!campaigns) return <Loader />;
    //if(editing) return <Route path={`/create-campaign/edit/${editingURL}`} render={(props) => <CreateCampaign {...props} getCampaigns={this.getCampaigns} editing={editing}  url={editingURL} discardEdits={this.discardEdits} /> } />
    //<EditCampaign discardEdits={this.discardEdits} closeEditing={this.closeEditing} url={editingURL} />
    else {
      return (
        <div>
          <PrivateRoute
            path={`${match.path}/campaign-applicants/:url`}
            getCampaigns={this.getCampaigns}
            endpoint={`/api/campaigns/get_campaign_and_applicants`}
            component={CampaignApplicants}
          />
          <PrivateRoute
            path={`${match.path}/selected-applicants/:url`}
            getCampaigns={this.getCampaigns}
            endpoint={`/api/campaigns/get_campaign_and_hired_applicants`}
            component={CampaignApplicants}
          />
          <div
            onClick={this.closeLastOption}
            id="CampaignsContainer"
            className="CampaignsContainer"
          >
            {loading && <Loader />}
            <div
              onClick={this.handleCreateCampaign}
              className="CreateCampaign"
              style={{}}
            >
              <div>
                {" "}
                <img
                  style={{ height: 28, paddingTop: 6 }}
                  src={CampaignSendIcon}
                />{" "}
              </div>
              <div> CREATE CAMPAIGN </div>
            </div>
            <Modal
              loading={loading}
              open={open}
              setOpen={this.setOpen}
              heading={`Payment Review`}
            >
              <PaymentSection
                loading={loading}
                billCustomerMonthly={this.billCustomerMonthly}
                billCustomerSingleCampaign={this.billCustomerSingleCampaign}
                subscriptionCost={subscriptionCost}
              />
            </Modal>
            <div className="CampaignsThreeColumns">
              {campaigns.length
                ? campaigns.map((meta, index) => (
                    <div key={meta._id} className="CampaignBox">
                      <div style={{ overflow: "hidden" }}>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "max-content 1fr",
                          }}
                        >
                          <Title> {meta.title} </Title>
                          <div
                            style={{
                              justifySelf: "flex-end",
                              paddingTop: 10,
                              paddingRight: 5,
                            }}
                          >
                            {" "}
                            {meta.live ? (
                              <div
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "1fr  2fr",
                                }}
                              >
                                {" "}
                                <div
                                  style={{
                                    justifySelf: "flex-end",
                                    alignSelf: "flex-end",
                                  }}
                                >
                                  {" "}
                                  <img src={CampaignsLiveIcon} />{" "}
                                </div>{" "}
                                <div
                                  style={{
                                    justifySelf: "flex-end",
                                    color: "#05cca0",
                                  }}
                                >
                                  {" "}
                                  LIVE{" "}
                                </div>{" "}
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "1fr  2fr",
                                }}
                              >
                                {" "}
                                <div
                                  style={{
                                    justifySelf: "flex-end",
                                    alignSelf: "flex-end",
                                  }}
                                >
                                  {" "}
                                  <img src={CampaignsDraftIcon} />{" "}
                                </div>{" "}
                                <div
                                  style={{
                                    justifySelf: "flex-end",
                                    paddingLeft: 6,
                                    color: "#9b9b9b",
                                  }}
                                >
                                  {" "}
                                  Draft{" "}
                                </div>{" "}
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "grid",
                            gridColumnGap: 12,
                            gridTemplateColumns: "min-content 1fr",
                            padding: "10px 15px",
                          }}
                        >
                          <div style={{}}>
                            <img
                              style={{ width: 16 }}
                              src={CampaignsModelIcon}
                            />
                          </div>
                          <div style={{ textTransform: "uppercase" }}>
                            {meta.tags.map((tag, index) =>
                              index < 2 ? (
                                <button className="Buttonized-Tags">
                                  {" "}
                                  {`${tag}`}{" "}
                                </button>
                              ) : null
                            )}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "grid",
                            gridColumnGap: 12,
                            gridTemplateColumns: "min-content 1fr",
                            padding: "0px 15px",
                          }}
                        >
                          <div style={{}}>
                            <img
                              style={{ width: 16 }}
                              src={CampaignsLocationIcon}
                            />
                          </div>
                          <LocationWithEllipses style={{}}>
                            {meta.location}
                          </LocationWithEllipses>
                        </div>
                      </div>

                      <div className="CampaignStatistics">
                        <div className="CampaignStatisticsViews">
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "repeat(16, 1fr)",
                            }}
                          >
                            <div
                              style={{
                                justifySelf: "center",
                                gridRow: 1,
                                gridColumn: "1 / -1",
                              }}
                            >
                              {" "}
                              Views{" "}
                            </div>
                            <div
                              style={{
                                display: "grid",
                                gridRow: 2,
                                gridColumn: "1 / -1",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifySelf: "center",
                                  marginRight: 15,
                                  height: 25,
                                }}
                              >
                                <div
                                  style={{
                                    alignSelf: "flex-end",
                                    marginRight: 10,
                                  }}
                                >
                                  {" "}
                                  <img src={CampaignsViewIcon} />{" "}
                                </div>
                                <div
                                  style={{
                                    alignSelf: "center",
                                    color: "#0097FF",
                                    fontSize: 24,
                                  }}
                                >
                                  {" "}
                                  {kFormatter(meta.views)}{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            this.props.history.push(
                              `/campaigns/campaign-applicants/${meta.link}`
                            );
                          }}
                          className="CampaignStatisticsApplicants"
                        >
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "repeat(16, 1fr)",
                            }}
                          >
                            <div
                              style={{
                                justifySelf: "center",
                                gridRow: 1,
                                gridColumn: "1 / -1",
                              }}
                            >
                              {" "}
                              Applicants{" "}
                            </div>
                            <div
                              style={{
                                display: "grid",
                                gridRow: 2,
                                gridColumn: "1 / -1",
                                height: 30,
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifySelf: "center",
                                  marginRight: 30,
                                }}
                              >
                                <div
                                  style={{
                                    alignSelf: "flex-end",
                                    marginRight: 10,
                                  }}
                                >
                                  <img src={CampaignsApplicantsIcon} />
                                </div>
                                <div
                                  style={{
                                    alignSelf: "center",
                                    color: "#0097FF",
                                    fontSize: 24,
                                  }}
                                >
                                  {" "}
                                  {kFormatter(meta.applicants)}{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="CampaignStatisticsShare">
                          <div>
                            <div> {meta.live && "Share"} </div>
                            <div className="CampaignStatisticsShareLink">
                              {meta.live ? (
                                <div className="TextAreaLinkContainer">
                                  <div
                                    onClick={(e) => {
                                      this.copyToClipboard(
                                        process.env.NODE_ENV === "production"
                                          ? `runway.supply/apply/${meta.link}`
                                          : `http://localhost:3000/apply/${meta.link}`
                                      );
                                      toast.success(
                                        "Copied Application Link To Clipboard 🤖.",
                                        {
                                          position:
                                            toast.POSITION.BOTTOM_CENTER,
                                        }
                                      );
                                    }}
                                    className="CampaignStatisticsTextAreaShareLinkTextArea"
                                    style={{
                                      width: 140,
                                      height: 30,
                                      display: "grid",
                                      marginTop: 10,
                                      borderRadius: 5,
                                    }}
                                  >
                                    <button
                                      style={{
                                        alignSelf: "center",
                                        padding: "0px 0px",
                                        fontSize: 8,
                                        border: "none",
                                        height: "100%",
                                        backgroundColor: "#e6e6e6",
                                        cursor: "pointer",
                                      }}
                                    >
                                      {" "}
                                      {process.env.NODE_ENV === "production"
                                        ? `runway.supply/apply/${meta.link}`
                                        : `http://localhost:3000/apply/${meta.link}`}{" "}
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <button
                                  onClick={async () =>
                                    await this.handleGoLive(meta._id)
                                  }
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor: "black",
                                    color: "white",
                                    width: 100,
                                    height: 35,
                                  }}
                                >
                                  {" "}
                                  Go Live{" "}
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            this.handleViewHiredModels();
                            this.props.history.push(
                              `/campaigns/selected-applicants/${meta.link}`
                            );
                          }}
                          className="CampaignStatiscsHireStatus"
                        >
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "repeat(16, 1fr)",
                            }}
                          >
                            <div
                              style={{
                                justifySelf: "center",
                                gridRow: 1,
                                gridColumn: "1 / -1",
                              }}
                            >
                              {" "}
                              Hire Status{" "}
                            </div>
                            <div
                              style={{
                                display: "grid",
                                gridRow: 2,
                                gridColumn: "1 / -1",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifySelf: "center",
                                  marginRight: 30,
                                  height: 30,
                                }}
                              >
                                <div
                                  style={{
                                    alignSelf: "flex-end",
                                    marginRight: 10,
                                  }}
                                >
                                  <img src={CampaignsHireStatus} />
                                </div>

                                <div
                                  style={{
                                    alignSelf: "center",
                                    color: "#0097FF",
                                    fontSize: 20,
                                  }}
                                >
                                  {" "}
                                  {meta.modelsHired} / {meta.hireLimit}{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "repeat(16,1fr)",
                          position: "relative",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            paddingLeft: 10,
                            alignSelf: "center",
                            fontSize: 12,
                            fontWeight: 100,
                          }}
                        >
                          {" "}
                          Created:{" "}
                          {new Date(meta.createdAt)
                            .toUTCString()
                            .slice(0, 16)}{" "}
                        </div>
                        <div
                          id={meta.link}
                          onClick={() => this.openOptions(meta.link)}
                          style={{ gridColumn: 15, alignSelf: "center" }}
                        >
                          {" "}
                          <img src={CampaignsSettingsIcon} />{" "}
                        </div>
                        <div
                          style={{
                            alignItems: "center",
                            display: "none",
                            gridColumn: 16,
                            backgroundColor: "#fff",
                            gridTemplateColumns: "100px",
                            border: "1px solid #D3D3D3",
                          }}
                          id={meta.link + "options"}
                        >
                          <div style={{}}>
                            <div
                              style={{
                                paddingTop: 5,
                                paddingLeft: 5,
                                paddingBottom: 5,
                              }}
                              onClick={() => this.editCampaign(meta.link)}
                            >
                              {" "}
                              Edit{" "}
                            </div>
                            {meta.live && (
                              <div
                                style={{
                                  paddingTop: 5,
                                  paddingLeft: 5,
                                  paddingBottom: 5,
                                }}
                                onClick={() => this.closeCampaign(meta.link)}
                              >
                                {" "}
                                Close{" "}
                              </div>
                            )}
                            <div
                              onClick={() => this.deleteCampaign(meta.link)}
                              style={{
                                color: "red",
                                paddingTop: 5,
                                paddingLeft: 5,
                                paddingBottom: 5,
                              }}
                            >
                              {" "}
                              Delete{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                : null}
            </div>
            {!campaigns.length ? (
              <div
                style={{
                  fontWeight: 400,
                  fontSize: 12,
                  lineHeight: "2em",
                  letterSpacing: ".1rem",
                  gridColumn: "4 / span 10",
                  gridRow: 3,
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    textDecoration: "underline",
                    marginBottom: 37,
                    fontSize: 16,
                  }}
                >
                  {" "}
                  Getting Started!{" "}
                </div>
                1. Click the Create Campaign button.
                <br />
                2. Fill out your campaign details step by step.
                <br />
                3. Submit your first campaign. You will be navigated back to
                this screen! You will not be charged for creating a campaign so
                create as many as you would like.
                <br />
                4. Click the Go Live button on the campaign tile. You can also
                edit or delete your campaign anytime.
                <br />
                5. Congratulations! You campaign is now live. Go ahead and use
                the share link generated on the campaign tile to hire your first
                models!
              </div>
            ) : null}
          </div>
        </div>
      );
    }
  }
}

export default Campaigns;

/*
        <style dangerouslySetInnerHTML={{__html: `
          input[type="color"],
          input[type="date"],
          input[type="datetime"],
          input[type="datetime-local"],
          input[type="email"],
          input[type="month"],
          input[type="number"],
          input[type="password"],
          input[type="search"],
          input[type="tel"],
          input[type="text"],
          input[type="time"],
          input[type="url"],
          input[type="week"],
          select:focus,
          textarea {
            font-size: 16px;
          }

        `}} />

        */

import React, { useState } from 'react';
import styled from 'styled-components'
import CampaignsLocationIcon from '../Assets/Campaigns/campaigns-location-icon.svg';
import { InputWithIcon, RippleLoader } from '.'
import { GooglePlacesAutoComplete, GooglePlacesGeocode } from './functions/GooglePlaces'
import uuid from 'uuid/v4';
const LocationWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
    grid-auto-rows: min-content;
    width: 100%;
`

const LocationSelect = styled.div`
    display: grid;
    grid-row-gap: 12px;
    font-size: 12px;
    padding: 8px 8px;
    grid-template-columns: 1fr;
    -webkit-box-shadow: 0px 0px 5px -2px rgba(20,20,20,1);
    -moz-box-shadow: 0px 0px 5px -2px rgba(20,20,20,1);
    box-shadow: 0px 0px 5px -2px rgba(20,20,20,1);
    position: relative;
`

const LocationAutoCompleteInput = ({ doneTypingInterval = 750, setGeocode, setLocation, setLocality, location }) => {
    const [loading, setLoading] = useState(false);
    const [loadingGeocode, setLoadingGeocode] = useState(false);
    const [predictions, setPredictions] = useState([]);
    const sessionToken = uuid();
    let typingTimer;
    return (
        <LocationWrapper>
            <InputWithIcon
                icon={CampaignsLocationIcon}
                value={location}
                placeholder="Soho, New York"
                onChange={(e) => {
                    console.log('Called set location on the input')
                    setLocation(e.target.value)
                }}
                onKeyUp={(e) => {
                    if (typingTimer) clearTimeout(typingTimer)
                    const searchTerm = e.target.value
        
                    typingTimer = setTimeout(async () => {
                        setLoading(true)
                        try {
                            const predictions = await GooglePlacesAutoComplete(searchTerm, sessionToken)
                            console.log(predictions)
                            setPredictions(predictions)
                        }
                        catch(error) {
                            console.log(error)
                        }
                        finally {
                            setLoading(false);
                        }
                  
                    }, doneTypingInterval);
                }}
                onKeyDown={() => {
                    clearTimeout(typingTimer);
                    setLoading(false)
                }}
            />
            <LocationSelect>
                {loading && <div style={{ display: 'grid', justifyItems: 'center', margin: 0 }}> <RippleLoader style={{ margin: 0 }} relative={true} /> <div> Loading addresses... </div> </div>}
                {!loading && !predictions.length ? 'Start typing your location and when you stop typing this box will populate your results' : null}
                {loadingGeocode && <div style={{ display: 'grid', justifyItems: 'center', margin: 0 }}> <RippleLoader style={{ margin: 0 }} relative={true} /> <div> Please wait while we geocode your location... </div> </div>}
                {!loading && !loadingGeocode && predictions.map(prediction => <div key={prediction.description} style={{ cursor: 'pointer' }} onClick={async () => {
                    try {
                        console.log("Called set location after clicking from the dropdown")
                        setLoadingGeocode(true);
                        const geocode = await GooglePlacesGeocode(prediction.place_id)
                        setLoadingGeocode(false);
                        console.log(geocode)
                        setLocation(prediction.description);
                        setGeocode({
                            longitude: geocode.lng,
                            latitude: geocode.lat,
                            formattedAddress: geocode.formatted_address,
                            placeID: prediction.place_id,
                            locality: geocode.locality
                        })
                    }
                    catch (error) {
                        setLoadingGeocode(false);
                    }

                }}> {prediction.description} </div>)}
            </LocationSelect>
        </LocationWrapper>
    )
}



export default LocationAutoCompleteInput
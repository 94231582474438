import styled from 'styled-components'
export { default as ModelCollageOne } from './ModelCollageOne';
export { default as ModelCollageTwo } from './ModelCollageTwo'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 12px;
  grid-auto-rows: 40px
  @media(max-width:1100px) {
      display: none;
  }
`

export { Wrapper };
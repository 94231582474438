import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, UserInput, Question, Text } from '../TypeForm';
import { sendVerificationCode } from '../TypeForm/ModelOnboard/Phone/Functions';
import { verifyModelSMS } from '../TypeForm/ModelOnboard/VerificationCode/functions';
import { getModel, applyWithRSA } from './Functions';
import { ModelBox } from '../CampaignApplicants/index';
import { X } from '../TypeForm/Assets'
const Wrapper = styled.div`
    display: grid;
    justify-content: center;
`


const Modal = styled.div`
    border-radius: 10px;
    border: solid 1px #979797;
    box-shadow: 5px 5px 15px 5px rgba(0,0,0,0);
    z-index: 100;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: ${props => props.open ? 'grid' : 'none'};
    background-color: #fff;
    padding: 12px 12px;
    grid-auto-rows: min-content;
    grid-row-gap: 24px;
    width: 300px;
    height: auto-fit;
    @media(max-width: 1024px) {
        grid-row-gap: 12px;
    }
    @media(max-width: 414px) {
        max-height: 600px;
        overflow-y: scroll;
    }
`

const HeadingWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-auto-rows:min-content;
    grid-column-gap: 6px;
`

const ApplyWRSA = ({ history, match }) => {
    const [open, setOpen] = useState(0);
    const [phone, setPhone] = useState({
        phone: '',
        phoneFormatted: '',
    });
    const [error, setError] = useState('');
    const [showCode, setShowCode] = useState();
    const [code, setCode] = useState();
    const [loading, setLoading] = useState(false);
    const [model, setModel] = useState({})
    const renderStepOne = () => {
        if (!showCode) {
            return (
                <React.Fragment>
                    <HeadingWrapper>
                        <Text textalign={'center'} color={"#000"} fontsize={'24px'}>Enter the phone number associated with your model profile.</Text>
                        <img onClick={() => setOpen(false)} src={X} />
                    </HeadingWrapper>

                    <Text textalign={'center'} fontsize={'16px'}> You will be sent a verification code to submit your application.</Text>
                    <UserInput height={'40px'} value={phone.phoneFormatted} callback={(phoneFormatted, phone) => setPhone({ phoneFormatted, phone })} id="phone" placeholder="(888) 888 8888" />
                    {error && <Text color="red"> {error} </Text>}
                    {loading && <Text color="blue"> Loading... </Text>}
                    <Button disabled={loading ? true : false} onClick={async () => {
                        try {
                            setError('')
                            setLoading(true);
                            await sendVerificationCode(phone.phone)
                            const mR = await getModel(phone.phone)
                            console.log(mR)
                            setModel(mR.data.model)
                            setShowCode(true)
                        }
                        catch (error) {
                            if (error.response) {
                                setError(error.response.data.message)
                            } else if (error.request) {
                                setError('Error processing your request please try again later')
                            } else {
                                setError(error.message)
                            }
                        }
                        finally {
                            setLoading(false);
                        }

                    }}> Next </Button>
                    <Text color={'#000'} textalign={'center'} fontsize={'24px'}> Don't have a model profile?  </Text>
                    <Text textalign={'center'} fontsize={'14px'}> Click the button below to create your unique model profile which you will use to apply to modeling campaigns. </Text>
                    <Button onClick={() => history.push('/apply')} backgroundColor={'blue'}>
                        Create Model Profile
                    </Button>
                </React.Fragment>
            )
        }
    }

    const renderStepTwo = () => {
        if (showCode && model && Object.keys(model).length) {
            return (
                <React.Fragment>
                    < HeadingWrapper>
                        <ModelBox model={model} photoIndex={0} />
                        <img onClick={() => {
                            setOpen(false)
                            setShowCode(false)
                        }} src={X} />
                    </HeadingWrapper>

                    <Text fontsize={'24px'}>Enter the 4 digit code</Text>
                    <UserInput height={'40px'} value={code} callback={(code) => setCode(code)} placeholder="Type Your Code" />
                    {error && <Text color="red"> {error} </Text>}
                    {loading && <Text color="blue"> Loading... </Text>}
                    <Button disabled={loading ? true : false} onClick={async () => {
                        try {
                            setError('');
                            setLoading(true);
                            await verifyModelSMS(phone.phone, code)
                            await applyWithRSA(phone.phone, code, match.params.url)
                            history.push('/thankyouforapplying')
                        }
                        catch (error) {
                            if (error.response) {
                                setError(error.response.data.message)
                            } else if (error.request) {
                                setError('Error processing your request please try again later')
                            } else {
                                setError(error.message)
                            }
                        }
                        finally {
                            setLoading(false);
                        }
                    }}>
                        Apply Now
                    </Button>

                </React.Fragment>

            )
        }
    }

    return (
        <Wrapper>
            <Button width={'max-content'} padding={'0px 12px'} onClick={() => setOpen(true)}> Apply With RunwaySupply Account </Button>
            <Modal open={open}>
                {renderStepOne()}
                {renderStepTwo()}
            </Modal>

        </Wrapper>

    )
}

export default ApplyWRSA;
import React, { useState } from 'react';
import styled from 'styled-components';
import { Question, UserInput, Button, Text, FlexStartFlexEnd } from '../../index';
import { LocationAutoCompleteInput } from '../../../../shared/index'
const Wrapper = styled.div`
    display: grid;
    grid-row-gap: 24px;
`
const Step4 = ({ setStep, step, realStep, modelDetails, setDetails, rSC }) => {
    if (step !== realStep) return null;
    const [error, setError] = useState('');
    const { city } = modelDetails || null;
    console.log(modelDetails.city, modelDetails)
    return (
        <Wrapper>
            <Question step={step} question={'And where are you based?'} />
            <LocationAutoCompleteInput
                style={{ border: 'none', gridColumn: '1/-1', width: '100%' }}

                setLocation={(city) => setDetails((details) => ({ ...details, city, isValidLocation: true }))}
                setGeocode={(geocode) => setDetails((details) => ({ ...details, geocode, isValidGeocode: true }))}
                location={modelDetails.city}
            />
            <Text color={'red'}> {error} </Text>
            <FlexStartFlexEnd>
                <Button onClick={() => {
                    if (modelDetails.isValidLocation) {
                        setStep(step + 1)
                    }
                    else {
                        setError('Choose a location from the dropdown to continue.')
                    }

                }} width={'120px'}> Next </Button>
                {rSC()}
            </FlexStartFlexEnd>

        </Wrapper>
    )
}

export default Step4;
//
import React, { useState } from 'react';
import styled from 'styled-components'
import { Question, Background, StepControl, UserInput, Button, Text } from '../../index';
import isPhone from 'validator/lib/isMobilePhone'
const Wrapper = styled.div`
    display: grid;
    grid-template-rows: min-content;
    grid-row-gap: 24px;
`;

const JoinRunwaySupply = ({ callback }) => {
    return (
        <Wrapper>
            <Button backgroundColor={'#fff'} color={"black"}> New Model? </Button>
            <Text>  📸 Apply to modeling campaigns in a single click. </Text>
            <Text> 💸 Earn from paid campaigns quickly with digital payments. </Text>
            <Text> 📲 Notifications/alerts about new campaigns and campaign details. </Text>
            <Text> 👀 Beautifully designed profiles that you can use as portfolio.  </Text>
            <Text> ⚡️Join in seconds and get approved if you follow the model guidelines.  </Text>
            <Text> 🎞 Get recommended to clients through our catalogof models.   </Text>
            <Button  onClick={() => callback()}> Click Here To Join Runway Supply </Button>
        </Wrapper>
    )
}

export default JoinRunwaySupply;
import React, {useState} from 'react';
import styled from 'styled-components'
const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, 25px);
    justify-content: center;
`

const Circle = styled.div`
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background-color: ${props => props.backgroundColor};
    border: ${props => props.active && 'solid 5px white'};
`
// pass the value that is clicked into the callback and set the state from the parent component
const FeaturesCircles = ({ values = [], callback, state }) => {
    const defaultActive = values.indexOf(state) || false;
    const [active, setActive] = useState(defaultActive);
    const renderValues = () => {
        return values.map((value, index) => {
            return <Circle onClick={() => { 
                callback(value)
                setActive(index)
            }} active={active === index} backgroundColor={value} />
        })
    }
    return (
        <Wrapper>
            {renderValues()}
        </Wrapper>
    )
}

export default FeaturesCircles;
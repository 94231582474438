import React, { useEffect, useState } from 'react';
import { TimeChart } from './Plots';
import axios from 'axios'
import styled from 'styled-components';
import { ANALYTICS_HOST_NAME } from '../../Config';
const Wrapper = styled.div`
    display:grid;
    grid-template-columns: 1fr;
    margin: 24px 100px;
    grid-row-gap: 24px;
`

const SubWrapper = styled.div`
    display: grid;
    justify-content: center;
`


const CountsWrapper = styled.div`
    display:grid;
    grid-template-columns: repeat(auto-fit, 200px);
    justify-content: center;
    grid-row-gap: 24px;
`


const Heading = styled.div`
    font-size: 24px;
    font-weight: 700;
    text-align: center;
`


const Analytics = () => {
    const maleVsFemale = [
        {title: 'Male', color: "blue", strokeStyle: "dashed"},
        {title: 'Female', color: 'red', strokeDasharray: "1 2 3 4 5 6 7"},
   
      ];
    const [loading, setLoading] = useState(false)
    const [females, setFemales] = useState([])
    const [males, setMales] = useState([])
    const [counts, setCounts] = useState({})
    useEffect(() => {
        const getData = async () => {
            setLoading(true)
            const maleVsFemale = await axios.get(`${ANALYTICS_HOST_NAME}/analytics/get_male_vs_female`)
            const getHiredModels =  await axios.get(`${ANALYTICS_HOST_NAME}/analytics/getHiredModels`);
            const { models: hiredModels, hiredModelsCount } = getHiredModels.data;
            /*

              resultsMale,
            resultsFemale,
            modelsCount: models.length,
            modelsCountFemale: resultsFemale.length,
            modelsCountMale: resultsMale.length,
            modelsThatHaveAppliedToMultipleCampaignsCount,
            */
            console.log(hiredModels)
            console.log(maleVsFemale);
            const { resultsFemale, resultsMale, modelsCount, modelsCountFemale, modelsCountMale, modelsThatHaveAppliedToMultipleCampaignsCount } = maleVsFemale.data;
            setCounts({ hiredModelsCount, modelsCount, modelsCountFemale, modelsCountMale, modelsThatHaveAppliedToMultipleCampaignsCount })
            setFemales(resultsFemale)
            setMales(resultsMale)
            setLoading(false)
        }
        getData()
    }, [])
    if(loading) return 'Loading analytics'
    return (
        <Wrapper>
            <CountsWrapper>
                <SubWrapper>
                    <Heading> Hired Model Count </Heading>
                    <Heading> {counts.hiredModelsCount} </Heading> 
                </SubWrapper>
                <SubWrapper>
                    <Heading> Model Signup Count </Heading>
                    <Heading> {counts.modelsCount} </Heading> 
                </SubWrapper>
                <SubWrapper>
                    <Heading> Model Count Female </Heading>
                    <Heading> {counts.modelsCountFemale} </Heading> 
                </SubWrapper>
                <SubWrapper>
                    <Heading> Model Count Male </Heading>
                    <Heading> {counts.modelsCountMale} </Heading> 
                </SubWrapper>
                <SubWrapper>
                    <Heading> Models Count (Applied to more than 1 campaign) </Heading>
                    <Heading> {counts.modelsThatHaveAppliedToMultipleCampaignsCount} </Heading> 
                </SubWrapper>
            </CountsWrapper>


            <SubWrapper>
                <Heading> Male Vs Female Signups Overtime (Graph) </Heading>
                <TimeChart  tickLabelAngle={45} margin={{bottom: 50}} legend={maleVsFemale} titleX={'Time'} titleY={'Models (M/F)'} colors={['red', 'blue']} data={[females,males]}/>
            </SubWrapper>

        </Wrapper>
    )
}

export default Analytics
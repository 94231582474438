import styled from 'styled-components';


const DragAndDropArea = styled.div`
    display: grid;
    border: solid 1px #e1e1e1;
    width: 100%;
    @media(max-width: 414px) {
        width: 350px;
    }
    justify-self: center;
    height: 400px;
    justify-items: center;
    align-content: center;
    grid-auto-rows:min-content;
    grid-row-gap: 12px;
    background-color: #fff;
`

export default DragAndDropArea;
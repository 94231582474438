import React, { Component } from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import About from "./components/About";
import Navbar from "./components/Navbar/Navbar";
import { StripeProvider } from "react-stripe-elements";
import Campaigns from "./components/Campaigns/Campaigns";
import CreateCampaign from "./components/CampaignCreation/CreateCampaign";
import ResetPasswordModal from "./components/ResetPassword/ResetPasswordModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute";
import Application from "./components/Application/Application";
import Network from "./components/Network";
import Profile from "./components/Profile";
import VerifyEmail from "./components/VerifyEmail";
import ThankYouForApplying from "./components/ThankYouForApplying";
import NotFound from "./components/NotFound";
import { CampaignsMarketplace } from "./components/CampaignsMarketplace";
import axios from "axios";
import { HOST_NAME, BRAND_SERVICES_HOST_NAME } from "./Config";
import { ModelLogin } from "./components/ModelLogin";
import { Analytics } from "./components/Admin";
import { CampaignApplicants } from "./components/CampaignApplicants/index";
import { ModelGuidelines } from "./components/ModelGuidelines/index";
import Signup from "./components/Signup";
import Login from "./components/Login/LoginModal";
import Register from "./components/Signup/RegisterModal";
import ForgotPassword from "./components/ForgotPassword/ForgotPasswordModal";
//import CampaignApplicants from './components/CampaignApplicants/CampaignApplicants'
import "../node_modules/react-vis/dist/style.css";
import ModelForm from "./components/TypeForm/ModelOnboard/ModelForm";
import { ToastNotification } from "./components/TypeForm/index";
import { ModelProfile } from "./components/ModelProfile";
import { ModelCatalog } from "./components/ModelCatalog";
import { LandingV2 } from "./components/LandingV2";
import { NavV2 } from "./components/NavV2";
const NotificationContext = React.createContext();
const GlobalContext = React.createContext();
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: false,
      profilemodal: false,
      applicationViewingID: "",
      application: false,
      campaign: false,
      viewingModelProfile: false,
      toast: {
        open: false,
        message: "",
      },
    };
  }

  _isMounted = false;
  componentDidMount() {
    document.querySelector("html").style.overflow = "inherit";
    // Include the Crisp code here, without the <script></script> tags
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "1511a96b-9dd9-466a-a13d-3da0d47f6470";

    (function() {
      var d = document;
      var s = d.createElement("script");

      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();

    this._isMounted = true;
    const user = localStorage.getItem("user");
    if (user) {
      this.setState({ user: JSON.parse(user) });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // will take either user object or false
  setUser = (user) => {
    if (this._isMounted) {
      this.setState({ user });
    }
  };

  logout = () => {
    if (this._isMounted) {
      localStorage.removeItem("user");
      this.setState({ user: false });
    }
  };

  getUserFromDB = () => {
    //required for brandlogo in navbar
    const user = JSON.parse(localStorage.getItem("user"));
    axios
      .post(`${BRAND_SERVICES_HOST_NAME}/get_user`, {
        _id: user._id,
      })
      .then((res) => {
        this.setState({ user: res.data.user });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleProfileModal = () => {
    this.setState((state) => ({ profilemodal: !state.profilemodal }));
  };

  handleNotifcationModal = () =>
    this.setState((state) => ({ notificationmodal: !state.notificationmodal }));

  setValue = (key, value) => {
    this.setState({ [key]: value });
  };

  getApplication = (applicationID) => {
    axios
      .post(`${HOST_NAME}/api/applications/get_application`, {
        applicationID,
      })
      .then((res) => {
        this.setState({ application: res.data.application });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getCampaign = (url) => {
    axios
      .post(`${BRAND_SERVICES_HOST_NAME}/get_campaign_by_url_no_increment`, {
        url,
      })
      .then((res) => {
        this.setState({ campaign: res.data.campaign });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  render() {
    const {
      user,
      applicationViewingID,
      application,
      campaign,
      viewingModelProfile,
    } = this.state;
    return (
      <div
        style={{}}
        onClick={() => {
          if (this.state.profilemodal) {
            let modal = document.getElementById("user-modal");
            if (modal.style.display === "grid") {
              modal.style.display = "none";
              this.handleProfileModal();
            }
          }

          if (this.state.notificationmodal) {
            let modal = document.getElementById("notification-modal");
            if (modal.style.display === "grid") {
              modal.style.display = "none";
              this.handleNotifcationModal();
            }
          }
        }}
      >
        <StripeProvider
          apiKey={
            process.env.NODE_ENV === "production"
              ? "pk_live_O94YufVTKWk6cxq6uOjtr0Kd"
              : "pk_test_ppPaNATWHXoforUP2fbn9vEn"
          }
        >
          <BrowserRouter>
            <div style={{ fontFamily: "ProximaNova" }}>
              <GlobalContext.Provider
                value={{
                  setValue: this.setValue,
                }}
              >
                <NotificationContext.Provider
                  value={{
                    setValue: this.setValue,
                    applicationViewingID: applicationViewingID,
                    getCampaign: this.getCampaign,
                    getApplication: this.getApplication,
                    application,
                    campaign,
                  }}
                >
                  <Route
                    render={(props) => (
                      <NavV2
                        viewingModelProfile={viewingModelProfile}
                        logout={this.logout}
                        user={user}
                        {...props}
                      />
                    )}
                  />
                </NotificationContext.Provider>
                <Switch>
                  <Route component={NotFound} exact path="/404" />

                  <Route
                    exact
                    path="/model-catalog"
                    render={(props) => (
                      <ModelCatalog {...props} endpoint={`/getModelCatalog`} />
                    )}
                  />
                  <Route
                    exact
                    path="/get-all-models"
                    render={(props) => (
                      <ModelCatalog {...props} endpoint={`/getAllModels`} />
                    )}
                  />
                  <Route
                    exact
                    path="/modelguidelines"
                    render={(props) => (
                      <ModelGuidelines
                        padding={"10px"}
                        cb={() => props.history.push("/apply")}
                      />
                    )}
                  />
                  <PrivateRoute
                    exact
                    path="/campaigns/campaign-applicants/:url"
                    applicationStatus={"open"}
                    component={CampaignApplicants}
                  />
                  <Route
                    exact
                    path="/modeling-campaigns"
                    component={CampaignsMarketplace}
                  />
                  <PrivateRoute
                    exact
                    path="/campaigns/selected-applicants/:url"
                    applicationStatus={"selected"}
                    component={CampaignApplicants}
                  />
                  <PublicRoute
                    exact
                    path="/login"
                    component={Login}
                    setUser={this.setUser}
                  />
                  <PublicRoute
                    exact
                    path="/register"
                    component={Register}
                    setUser={this.setUser}
                  />
                  <PublicRoute
                    exact
                    path="/forgot-password"
                    component={ForgotPassword}
                  />
                  <Route
                    path="/campaigns/:type/:url/:modelID"
                    render={(props) => (
                      <ModelProfile
                        viewing={"brand"}
                        {...props}
                        setValue={this.setValue}
                      />
                    )}
                  />
                  <PrivateRoute path="/campaigns" component={Campaigns} />
                  <PrivateRoute
                    path="/create-campaign-edit/:url/:page"
                    component={CreateCampaign}
                  />

                  <PrivateRoute
                    path="/create-campaign/:page"
                    component={CreateCampaign}
                  />

                  <PrivateRoute
                    user={user}
                    path="/network"
                    network={true}
                    component={CampaignApplicants}
                  />
                  <PrivateRoute
                    exact
                    path="/profile"
                    logout={this.logout}
                    getUserFromDB={this.getUserFromDB}
                    setUser={this.setUser}
                    component={Profile}
                  />
                  <PublicRoute exact path="/apply" component={ModelForm} />
                  <Route exact path="/apply/:url" component={Application} />
                  <Route
                    exact
                    path="/verify_email_brand/:token"
                    render={(props) => (
                      <VerifyEmail
                        {...props}
                        endpoint={`${BRAND_SERVICES_HOST_NAME}/verify_email_brand`}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/verify_email_model/:token"
                    render={(props) => (
                      <VerifyEmail
                        {...props}
                        endpoint={`${BRAND_SERVICES_HOST_NAME}/verify_email_model`}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/ThankYouForApplying"
                    component={ThankYouForApplying}
                  />
                  <Route
                    exact
                    path="/reset_password/:token"
                    component={ResetPasswordModal}
                  />
                  <PublicRoute path="/about" component={About} />
                  <PublicRoute
                    exact
                    path="/model_login"
                    component={ModelLogin}
                  />
                  <Route exact path="/admin/analytics" component={Analytics} />
                  <Route exact path="/" component={LandingV2} />
                  <Route
                    path="/:modelID"
                    render={(props) => (
                      <ModelProfile
                        viewing={"public"}
                        {...props}
                        setValue={this.setValue}
                      />
                    )}
                  />
                  <Route component={NotFound} />
                </Switch>
              </GlobalContext.Provider>
            </div>
          </BrowserRouter>
        </StripeProvider>
        <ToastContainer autoClose={8000} />
        <ToastNotification
          toast={this.state.toast}
          cb={() => this.setValue("toast", false)}
        />
      </div>
    );
  }
}
// legacy home page  <PublicRoute exact path="/"  component={About} />

export default App;
export { NotificationContext, GlobalContext };

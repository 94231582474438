import React from 'react';
import styled from 'styled-components'
import {
  XYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalGridLines,
  LineSeries,
  DiscreteColorLegend,
  makeWidthFlexible,
} from 'react-vis';
import {format} from 'd3-format'

const FlexibleXYPlot = makeWidthFlexible(XYPlot);
const MSEC_DAILY = 86400000;
const SubWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  width: 100%;
`;

const Header = styled.h1`
  grid-column: 1 / -1;
  font-size: 24px;
  font-weight: 700;
  max-width: 400px;
  text-align: center;
`
function TimeChart({ legend, data, colors, titleX, titleY }) {
  return (
    <SubWrapper>
      <FlexibleXYPlot  margin={{bottom: 100}} xType="time" height={800}>
        <HorizontalGridLines />
        <VerticalGridLines />
        <XAxis  tickLabelAngle={-90} title={titleX} />
        <YAxis title={titleY} />
        {data.map((group, i) => <LineSeries strokeWidth={1} data={group} curve={'curveMonotoneX'} opacity={1} stroke={colors[i]} />)}
      </FlexibleXYPlot>
      {legend && <DiscreteColorLegend orientation="horizontal" width={300} items={legend} />}
    </SubWrapper>


  );
}

export default TimeChart;
import React from 'react';
import styled from 'styled-components';
import { Brand, Calendar, Deadline, Location, StartTime } from './Assets'
const Wrapper = styled.div`
    display: grid;
    grid-auto-rows: min-content;
    grid-row-gap: 16px;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 400;
    padding: 0px 8px;

    @media(max-width: 700px) {
      padding: 0;
      grid-column-gap: 16px;
      grid-template-columns: repeat(auto-fill, minmax(150px, max-content));
      justify-content: center

    }
`

const InformationItem = styled.div`
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-column-gap: 16px;
    font-size: 18px;
    align-items: center;
`

const Icon = styled.img`
  height: 25px;
  width: 25px;
  max-height: 25px;
  max-width: 25px;
`
//
const ApplicationMeta = ({ campaign }) => {
  const { brand, geocode, startDate, endDate, deadline, manualStart, manualEnd } = campaign;
  const time = new Date(startDate).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}).replace(/^(?:00:)?0?/, '');
  const timeEnd =  endDate ? new Date(endDate).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}).replace(/^(?:00:)?0?/, '') : '';
  console.log(timeEnd)
  const metaItems = [
    brand, 
    geocode.locality, 
    startDate, 
    `${time} ${timeEnd ? `- ${timeEnd}` : ''}`, 
    deadline
  ];
  const metaIcons = [
    Brand, 
    Location, 
    Calendar, 
    StartTime, 
    Deadline
  ];
  const renderManualItems = () => {
    if(manualStart) return <Item value={`${manualStart} - ${manualEnd}`} icon={metaIcons[3]} />
  }
  return (
    <Wrapper>
      {metaItems.map((item, index) => {
        if (manualStart && index === 4) return null;
        if (manualStart && index === 3) return null;
        if (item && index === 2) return <Item icon={metaIcons[index]} value={`Date ${new Date(item).toLocaleDateString()}`} />
        if (item && index === 4) return <Item icon={metaIcons[index]} value={`Apply By ${new Date(item).toLocaleDateString()}`} />
        if (item) return <Item value={item} icon={metaIcons[index]} />
      })}
      {renderManualItems()}
    </Wrapper>
  )
}

const Item = ({ value, icon }) => {
  return (
    <InformationItem>
      <Icon src={icon} />
      <div> {value} </div>
    </InformationItem>
  )
}

export default ApplicationMeta;;
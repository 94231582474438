import React, { useState } from 'react';
import { Background, Question, StepControl, UserInput, ThankYou } from '../index';
import { ModelCollageOne, ModelCollageTwo } from '../../Collage'
import { ModelGuidelines } from '../../ModelGuidelines/index';
import styled from 'styled-components';
import {
    Step0,
    FirstName,
    LastName,
    Email,
    DOB,
    Location,
    Phone,
    VerificationCode,
    Complexion,
    HairColor,
    EyeColor,
    Gender,
    Height,
    Waist,
    ShoeMeasurement,
    FullLength,
    CloseUp,
    WaistUp,
    SideProfile
} from '.';

const TopWrapper = styled.div`
    display: grid;
    grid-auto-rows: min-content;
`

const MiddleContentWrapper = styled.div`
    display: grid;
    grid-auto-rows: min-content;
    margin: 50px 10% 0px 10%;
    @media(max-width: 900px) {
        margin: 50px 0px 0px 0px
    }
`

const ModelForm = ({ history }) => {
    const [modelDetails, setDetails] = useState(() => ({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        dob: '',
        city: '',
        geocode: '',
        complexion: '',
        hairColor: '',
        eyeColor: '',
        gender: '',
        heightMeasurement: '',
        waistMeasurement: '',
        shoeMeasurement: '',
    }))
    const [step, setStep] = useState(0);
    const [showGuidelines, setShowGuidelines] = useState(false);
    const renderStepControlButtons = () => {
        if (step === 19) return null
        if (step === 17 || step === 18) {
            return <div style={{ position: 'absolute', right: 10, bottom: 10 }}>
                <StepControl renderNext={false} step={step} setStep={setStep} />
            </div>
        }
        if (step !== 0) { // remove or
            return <div style={{ position: 'absolute', right: 10, bottom: 10 }}>
                <StepControl step={step} setStep={setStep} />
            </div>
        }
    }
    return (
        <TopWrapper backgroundColor={'#fff'} >
            <div style={{ cursor: 'pointer', position: 'absolute', top: 10, left: 10, zIndex: 99, width: '100%', textTransform: 'uppercase' }} onClick={() => history.push('/')}> Runway Supply </div>
            <StepWrapper>
                <MiddleContentWrapper>
                        <>
                            <Step0 realStep={0} step={step} setStep={setStep} setShowGuidelines={setShowGuidelines} />
                            <FirstName rSC={renderStepControlButtons} modelDetails={modelDetails} setDetails={setDetails} realStep={1} step={step} setStep={setStep} />
                            <LastName rSC={renderStepControlButtons} modelDetails={modelDetails} setDetails={setDetails} realStep={2} step={step} setStep={setStep} />
                            <Email rSC={renderStepControlButtons} modelDetails={modelDetails} setDetails={setDetails} realStep={3} step={step} setStep={setStep} />
                            <DOB rSC={renderStepControlButtons} modelDetails={modelDetails} setDetails={setDetails} realStep={4} step={step} setStep={setStep} />
                            <Location rSC={renderStepControlButtons} modelDetails={modelDetails} setDetails={setDetails} realStep={5} step={step} setStep={setStep} />
                            <Complexion rSC={renderStepControlButtons} modelDetails={modelDetails} setDetails={setDetails} realStep={6} step={step} setStep={setStep} />
                            <HairColor rSC={renderStepControlButtons} modelDetails={modelDetails} setDetails={setDetails} realStep={7} step={step} setStep={setStep} />
                            <EyeColor rSC={renderStepControlButtons} modelDetails={modelDetails} setDetails={setDetails} realStep={8} step={step} setStep={setStep} />
                            <Gender rSC={renderStepControlButtons} modelDetails={modelDetails} setDetails={setDetails} realStep={9} step={step} setStep={setStep} />
                            <Height rSC={renderStepControlButtons} modelDetails={modelDetails} setDetails={setDetails} realStep={10} step={step} setStep={setStep} />
                            <Waist rSC={renderStepControlButtons} modelDetails={modelDetails} setDetails={setDetails} realStep={11} step={step} setStep={setStep} />
                            <ShoeMeasurement rSC={renderStepControlButtons} modelDetails={modelDetails} setDetails={setDetails} realStep={12} step={step} setStep={setStep} />
                            <FullLength rSC={renderStepControlButtons} modelDetails={modelDetails} setDetails={setDetails} realStep={13} step={step} setStep={setStep} />
                            <CloseUp rSC={renderStepControlButtons} modelDetails={modelDetails} setDetails={setDetails} realStep={14} step={step} setStep={setStep} />
                            <WaistUp rSC={renderStepControlButtons} modelDetails={modelDetails} setDetails={setDetails} realStep={15} step={step} setStep={setStep} />
                            <SideProfile rSC={renderStepControlButtons} modelDetails={modelDetails} setDetails={setDetails} realStep={16} step={step} setStep={setStep} />
                            <Phone rSC={renderStepControlButtons} modelDetails={modelDetails} setDetails={setDetails} realStep={17} step={step} setStep={setStep} />
                            <VerificationCode rSC={renderStepControlButtons} modelDetails={modelDetails} setDetails={setDetails} realStep={18} step={step} setStep={setStep} />
                            <ThankYou history={history} text={'Thank You For Joining Runway Supply'} realStep={19} step={step} />
                        </>
                </MiddleContentWrapper>
            </StepWrapper>
        </TopWrapper>
    )
}

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: auto 1fr auto;
    font-family: ProximaNova;
    grid-column-gap: 12px;
    margin: 100px 0px 0px 0px;
    grid-auto-rows: min-content;
    @media(max-width: 1100px) {
        grid-template-columns: 1fr;
        margin: 20px 20px;
    }
`

const StepWrapper = ({ children }) => {
    return (
        <Wrapper>
            <ModelCollageOne />
            {children}
            <ModelCollageTwo />
        </Wrapper>
    )

}

export default ModelForm;
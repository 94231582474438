import React, { useState } from 'react';
import styled from 'styled-components';
import { Question, UserInput, Button, Text, SelectDropDown, FlexStartFlexEnd } from '../../index';
const Wrapper = styled.div`
    display: grid;
    grid-row-gap: 24px;
`
const shoeMeasurements = ["4", "4.5", "5", "5.5", "6", "6.5", "7", "7.5", "8", "8.5", "9", "9.5", "10", "10.5", "11", "11.5", "12"];
const ShoeMeasurement = ({ setStep, step, realStep, modelDetails, setDetails, rSC }) => {
    if (step !== realStep) return null;
    const [error, setError] = useState('');
    return (
        <Wrapper>
            <Question step={step} question={'What is your shoe size?'} />
            <Text> It is important you provide accurate categorization to increase your selection chances.
Every data point is a chance to stand out.   </Text>

            <SelectDropDown state={modelDetails.shoeMeasurement} callback={(shoeMeasurement) => setDetails({ ...modelDetails, shoeMeasurement })} values={shoeMeasurements} />
            <Text color={'red'}> {error} </Text>
            <FlexStartFlexEnd>
                <Button onClick={() => {
                    if (modelDetails.shoeMeasurement) {
                        setStep(step + 1)
                    }
                    else {
                        setError('Shoe size is required.')
                    }

                }} width={'120px'}> Next </Button>
                {rSC()}
            </FlexStartFlexEnd>

        </Wrapper>
    )
}

export default ShoeMeasurement;
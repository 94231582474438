import React, { useState } from 'react';
import styled from 'styled-components';
import { Question, UserInput, Button, Text, SelectDropDown, FlexStartFlexEnd } from '../../index';
const Wrapper = styled.div`
    display: grid;
    grid-row-gap: 24px;
`
const waistMeasurements = ["21\"", "21.5\"", "22\"", "22.5\"", "23\"", "23.5\""
    , "24\"", "24.5\"", "25\"", "25.5\"", "26\"", "26.5\"", "27\"", "27.5\"", "28\"", "28.5\"", "29\"", "29.5\"", "30\"", "30.5\"", "31\"", "31.5\"", "32\"", "32.5\"", "33\"", "33.5\"", "34\"", "34.5\"", "35\"", "35.5\"", "36\"", "36.5\"", "37\"", "37.5\"", "38\"", "38.5\"", "39\"", "39.5\"", "40\"", "40.5\"", "41\"", "41.5\"", "42\"", "42.5\"", "43\"", "43.5\"", "44\"", "44.5\"", "45\"", "45.5\"", "46\"", "46.5\"", "47\"", "47.5\""
    , "48\"", "48.5\"", "49\"", "49.5\"", "50\""]
const Waist = ({ setStep, step, realStep, modelDetails, setDetails, rSC }) => {
    if (step !== realStep) return null;
    const [error, setError] = useState('');
    return (
        <Wrapper>
            <Question step={step} question={'What is your waist measurement.'} />
            <Text> It is important you provide accurate categorization to increase your selection chances.
Every data point is a chance to stand out.   </Text>

            <SelectDropDown state={modelDetails.waistMeasurement} callback={(waistMeasurement) => setDetails({ ...modelDetails, waistMeasurement })} values={waistMeasurements} />
            <Text color={'red'}> {error} </Text>
            <FlexStartFlexEnd>
                <Button onClick={() => {
                    if (modelDetails.waistMeasurement) {
                        setStep(step + 1)
                    }
                    else {
                        setError('Waist is required.')
                    }

                }} width={'120px'}> Next </Button>
                {rSC()}
            </FlexStartFlexEnd>

        </Wrapper>
    )
}

export default Waist;
import React, { Component } from 'react'

// /campaigns/campaign-applicants/:url/:applicationID
// /network/:modelID
import { Link, Route } from 'react-router-dom';
import PrivateRoute from '../components/PrivateRoute';
import Loader from './Loader';
import { toast } from "react-toastify";
//campaign info
import CampaignsLocationIcon from "../Assets/Campaigns/campaigns-location-icon.png";
import CampaignsDateTimeIcon from "../Assets/CreateCampaign/calendar-icon.png";
import DeadlineIcon from "../Assets/CreateCampaign/deadline-tile-icon.png";
import ApplicantsIcon from "../Assets/CreateCampaign/applicant-tile-icon.png";
//socials
import YoutubeIcon from "../Assets/CreateCampaign/youtube.svg";
import URLIcon from "../Assets/CreateCampaign/url-tile-icon.png";
import TwitterIcon from "../Assets/CreateCampaign/twitter.svg";
import InstagramIcon from "../Assets/CreateCampaign/instagram.svg";

import NextButton from '../Assets/CampaignApplicants/next-button.png'
import PreviousButton from '../Assets/CampaignApplicants/previous-button.png'
import './ModelsTableView.css'

import CampaignApplicantDetails from './CampaignApplicantDetails';
class ModelsTableView extends Component {
  state = {

  }

  renderSelectModelButton = () => {
    if(this.props.match.path.includes("/campaigns")) {
      return   (
        <div style={{  gridColumn: '2 / span 14', justifySelf:'flex-end' }}>
           <button onClick={this.props.hireApplicants} style={{ width:100, height:36, backgroundColor:'#05CCA0', color:'white' }}> Select </button>
        </div>
      )
    }
    else return null;
  }

  renderCheckbox = (application) => {
    if(!this.props.match.path.includes("/network")) {
      return (
        <label style={{ width:'100%', height:'100%' }}>
          <input onChange={(e) => {
              if(this.props.path.includes('/campaign')) {
                this.props.handleCheckbox(e)
              }
            }
          }
          name={`${application.applicantID}`}
          checked={this.props[`${application.applicantID}`] || application.applicationStatus === "hired"}
          type="checkbox" disabled={application.applicationStatus === "hired" || this.props.path.includes("/network")} style={{  }} />
        </label>
      )
    }
  }

  renderEmptyState = () => {
    const { loading, data, models } = this.props; // data on network is filtered models , models is all models, in general data is models
    const { path } = this.props.match;
      if(loading) {
        return <Loader />
      }
      else if(!data.length && models && !models.length &&  path === "/network")  {
        return (
            <div style={{ marginLeft: 10, marginTop: 10, gridColumn: '4 / span 10'}}>
              <div style={{ fontSize: 12, lineHeight: '2em', letterSpacing: '.1rem' }}>
                Your hired models will live here. Share your campaigns to grow your network!
              </div>
            </div>
        )
      }
      else if(!data.length &&  path === "/network")  {
        return (
            <div style={{ marginLeft: 10, marginTop: 10, gridColumn: '4 / span 10'}}>
              <div style={{ fontSize: 12, lineHeight: '2em', letterSpacing: '.1rem' }}>
                No model was found.
              </div>
            </div>
        )
      }
      else if(!data.length && path.includes("/campaign-applicants")) {
        return (
          <div style={{ fontSize: 12, lineHeight: '2em', letterSpacing: '.1rem',  marginLeft: 10, marginTop: 10, gridColumn: '4 / span 10'}}> Your campaigns applicants will live here. </div>
        )
      }
      else if(!data.length && path.includes("/selected-applicants")) {
        return (
          <div style={{ fontSize: 12, lineHeight: '2em', letterSpacing: '.1rem',  marginLeft: 10, marginTop: 10, gridColumn: '4 / span 10'}}> Your selected applicants will live here. </div>
        )
      }
  }

  renderNestedRoute = () => {
    const { data, component, models, campaign, lower, upper, next, previous } = this.props;
    const { path } = this.props.match;
    if(data.length && path.includes('/network')) {
        return <PrivateRoute
                exact
                path={`/network/:modelID`}
                component={component}
                data={models}
                applications={this.props.data}
                campaign={campaign}
                previousURL={this.props.match.url}
                getCampaignAndApplicants={this.props.getCampaignAndApplicants}
                endpoint={``}

                />
    }
    else if(data.length && path.includes('/campaign-applicants')) {

      return <PrivateRoute
              exact
              path={`/campaigns/campaign-applicants/:url/:applicationID`}
              component={CampaignApplicantDetails}
              data={data}
              campaign={campaign}
              previousURL={this.props.match.url}
              getCampaignAndApplicants={this.props.getCampaignAndApplicants}
              endpoint={`/api/applications/get_application`}
              />
    }
    else if(data.length && path.includes('/selected-applicants')) {
      return <PrivateRoute
              exact
              path={`/campaigns/selected-applicants/:url/:applicationID`}
              component={CampaignApplicantDetails}
              data={data}
              campaign={campaign}
              previousURL={this.props.match.url}
              getCampaignAndApplicants={this.props.getCampaignAndApplicants}
              endpoint={`/api/applications/get_application`}
              />
    }
  }

  render() {
    const { data, component, campaign, loading, path, models, remove, lower, upper, next, previous } = this.props;
    const { handleCheckbox } = this.props; //coming from campaign-applicants
    console.log(this.props)
      return (
        <div>
          {this.renderNestedRoute()}

          <div id="ViewApplicantsContainer" className="ViewApplicantsContainerNetwork" onClick={() => {
            let modal = document.getElementById(`${this.state.currentApplication}-modal`)

            if(modal && modal.style.display === 'grid') {
              modal.style.display = 'none';
              this.setState({ currentApplication: '' })
            }

          }}>



            <div className="Campaign-Applicants-Table-Headers" style={{ marginBottom: 10, gridRow: 1 }}>
              <div className="Campaign-Applicants-Full-Name-Header" style={{ gridRow: 2 }}> Full Name </div>
              <div className="Campaign-Applicants-Date-Applied-Header" style={{ gridRow: 2 }}> Date Applied </div>
              <div style={{ gridRow: 2 }} className="Campaign-Applicants-Table-Campaign-Count"> Campaigns </div>
              <div style={{ gridRow: 2 }} className="Campaign-Applicants-Table-City"> City </div>
              <div style={{ gridRow: 2 }}> Gender </div>
              <div className="Campaign-Applicants-Socials-Header" style={{ gridRow: 2 }}> Socials </div>
              <div className="NextAndPreviousSection" style={{ }}>
                <div style={{ gridColumn: 1 }}> <img className="Campaign-Applicants-PreviousButtonSize" onClick={previous} src={PreviousButton} /> </div>
                <div style={{ gridColumn: 3 }} >  <img className="CampaignApplicants-NextButtonSize" onClick={next} src={NextButton} />  </div>
              </div>
            </div>

            <div className="Campaign-Applicants-Models-Container" style={{ gridRow: 2 }}>
              {data.length > 0 && data.map((application,index) => {
                const {
                  firstName,
                  lastName,
                  createdAt,
                  totalApplications,
                  city,
                  email,
                  dob,
                  fullLength,
                  sideProfile,
                  closeUp,
                  waistUp,
                  twitter, youtube, instagram, website,
                  applicationStatus,
                  gender,
                  profilePicture,
                  totalCampaigns,
                } = application;


                if(firstName && index >= lower && index < upper) {
                  return (
                    <div key={application._id} className="Campaign-Applicants-Table" style={{ }} >
                      <div style={{ alignSelf:'center', width:30,   }}>
                        {this.renderCheckbox(application)}
                      </div>
                      <div className="Campaign-Applicants-Model-NEP" style={{  display:'grid', gridTemplateRows: '30px 30px', gridTemplateColumns:'50px auto' }}>
                        <div className="Campaign-Applicants-Full-Name" style={{ }}> {`${firstName} ${lastName}`}  </div>
                        <div className="Campaign-Applicants-Email" style={{ gridColumn: 2, gridRow: 2,  alignSelf:'center' }}> {email} </div>
                        <div style={{ alignSelf:'center', gridColumn:'1 / span 2', gridRow: '1 / span 2' }}>
                          <img style={{ borderRadius: '50%', height:40, width:40}} src={profilePicture ? profilePicture : fullLength ? fullLength : sideProfile ? sideProfile : closeUp ? closeUp : waistUp ? waistUp : null} />
                        </div>
                      </div>
                      <div className="Campaign-Applicants-Date-Applied" style={{ alignSelf:'center' }}> {new Date(createdAt).toLocaleDateString()}  </div>
                      <div  className="Campaign-Applicants-Table-Campaign-Count" style={{ alignSelf:'center' }}> {totalCampaigns} </div>
                      <div style={{ alignSelf:'center' }}> {city} </div>
                      <div className="Campaign-Applicants-Gender" style={{ alignSelf:'center' }}> {gender === "male" ? 'M' : 'F'} </div>
                      <div className="Campaign-Applicants-Socials-Section" style={{ }}>
                        {twitter && <a href={`https://twitter.com/${twitter}`} rel="noopener noreferrer" target="_blank"> <img className="Campaign-Applicant-Social-Icon" src={TwitterIcon} /> </a>}
                        {youtube && <a href={`https://youtube.com/user/${youtube}`} rel="noopener noreferrer" target="_blank"> <img className="Campaign-Applicant-Social-Icon"  src={YoutubeIcon} /> </a>}
                        {instagram && <a href={`https://instagram.com/${instagram}`} rel="noopener noreferrer" target="_blank"> <img className="Campaign-Applicant-Social-Icon" src={InstagramIcon} /> </a> }
                        {website && <a href={website.includes("//") ? website : `//${website}`} rel="noopener noreferrer" target="_blank"> <img className="Campaign-Applicant-Social-Icon" src={URLIcon} /> </a>}
                      </div>
                      <div className="Campaign-Applicants-Options-Section" style={{ alignSelf:'center', justifySelf:'center' }}>
                        <div onClick={() => {
                          this.setState({ currentApplication: application._id }, () => {
                            const currentApp = document.getElementById(`${application._id}-modal`);
                            currentApp.style.display === "grid" ? currentApp.style.display = "none" : currentApp.style.display = "grid";
                          })
                        }} > ••• </div>
                        <div className="Campaign-Applicants-Modal-Styles" style={{  }} id={`${application._id}-modal`} onClick={() => console.log("CLICKED " + application._id)}>
                          <Link to={`${this.props.match.url}/${application._id}`}> <div className="Campaign-Applicants-Model-Details-Option" style={{ }} onClick={() => console.log()}> Details </div> </Link>
                          <div  className="Campaign-Applicants-Model-Decline-Option" style={{  }} onClick={() => remove(application._id)}> Decline </div>
                        </div>
                      </div>
                    </div>
                  )
                }
              })}
            </div>
            {this.renderEmptyState()}
            {this.renderSelectModelButton()}






          </div>
        </div>
    )
  }
}

export default ModelsTableView;

import React from "react";
import styled from "styled-components";
import ViewIcon from "../../Assets/Campaigns/campaigns-view-icon.png";
import UsersIcon from "../../Assets/Campaigns/users-Icon.svg";
const Wrapper = styled.div`
    display: none;
    @media(max-width: 900px) {
        display: grid;
        grid-template-columns: minmax(14px, 30px) 60px minmax(60px,1fr) min-content min-content;
        align-items: center;
        grid-column-gap: 10px;
        border-bottom: solid 1px #e1e1e1;
        padding: 12px 6px;
        cursor:pointer;
    }
`;
const Rank = styled.div`
  font-size: 24px;
  line-height: 12px;
  @media (max-width: 414px) {
    text-align: center;
  }
`;

const BrandLogo = styled.img`
  object-fit: cover;
  width: 60px;
  height: 60px;
`;

const CampaignTitle = styled.h3`
    font-size: 24px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    margin: 0;
`;

const Brand = styled.h4`
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 2px
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 0;
    max-width: 100%;
`;

const Location = styled.h5`
  font-size: 16px;
  color: rgb(154, 154, 154);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  margin: 0;
`;

const IconWTextWrapper = styled.div`
  display: grid;
  grid-template-columns: min-content min-content;
  grid-column-gap: 12px;
  align-items: center;
  justify-self: center;
`;

const MobileCollapse = styled.div`
  display: none;
  @media (max-width: 900px) {
    display: grid;
    grid-auto-rows: min-content;
    grid-row-gap: 10px;
    justify-items: center;
    text-align: center;
  }
`;

const TableItemMobile = ({ campaign, i, history }) => {
      const {
        geocode: {
            locality
        }
    } = campaign || {}
  return (
    <Wrapper onClick={() => history.push(`/apply/${campaign.link}`)}>
      <Rank> {i + 1} </Rank>
      <BrandLogo src={campaign.coverphoto} alt="brandlogo" />
      <MobileCollapse>
        <CampaignTitle> {campaign.title} </CampaignTitle>
        <Location> {locality && locality} </Location>
        <Brand> {campaign.brand} </Brand>
      </MobileCollapse>
      <IconWTextWrapper>
        <img src={UsersIcon} />
        <div> {campaign.applicants} </div>
      </IconWTextWrapper>
      <IconWTextWrapper>
        <img src={ViewIcon} />
        <div> {campaign.views} </div>
      </IconWTextWrapper>
    </Wrapper>
  );
};

export default TableItemMobile;

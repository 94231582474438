import React from 'react';
import Fade  from 'react-fade-in';
import CollageOne from '../../Assets/LandingPage/model-mission-1.png';
import CollageTwo from '../../Assets/LandingPage/model-mission-2.png';
import CollageThree from '../../Assets/LandingPage/model-mission-3.png';
import CollageFour from '../../Assets/LandingPage/model-mission-4.png';
import CollageFive from '../../Assets/LandingPage/model-mission-5.png';
import CollageSix from '../../Assets/LandingPage/model-mission-6.png';
import CollageSeven from '../../Assets/LandingPage/model-mission-7.png';
import CollageEight from '../../Assets/LandingPage/model-mission-8.png';
import CollageNine from '../../Assets/LandingPage/model-mission-9.png';
import CollageTen from '../../Assets/LandingPage/model-mission-10.png';
import CollageEleven from '../../Assets/LandingPage/model-mission-11.png';
import CollageTwelve from '../../Assets/LandingPage/model-mission-12.png';
import CollageThirteen from '../../Assets/LandingPage/model-mission-13.png';
import CollageFourteen from '../../Assets/LandingPage/model-mission-14.png';
import CollageFifteen from '../../Assets/LandingPage/model-mission-15.png';
import CollageSixteen from '../../Assets/LandingPage/model-mission-16.png';
import CollageSeventeen from '../../Assets/LandingPage/model-mission-17.png';
import CollageeEighteen from '../../Assets/LandingPage/model-mission-18.png';
import CollageeNineteen from '../../Assets/LandingPage/model-mission-19.png';
import CollageeTwenty from '../../Assets/LandingPage/model-mission-20.png';
const Collage = () => {
    return (
        <div className="PageFive">
        <div className="PageFiveLeftCollage">
        <div className="CollageLeftOne">  <Fade left> <img src={CollageOne} /> </Fade> </div>
        <div className="CollageLeftTwo"> <Fade left> <img src={CollageTwo} /> </Fade> </div>
        <div className="CollageLeftThree"> <Fade left> <img src={CollageThree} /> </Fade> </div>
        <div className="CollageLeftFour"> <Fade left> <img src={CollageFour} /> </Fade> </div>
        <div className="CollageLeftFive"> <Fade left> <img src={CollageFive} /> </Fade> </div>
        <div className="CollageLeftSix"> <Fade left> <img src={CollageSix} /> </Fade> </div>
        <div className="CollageLeftSeven"> <Fade  left> <img src={CollageSeven} /> </Fade> </div>
        <div className="CollageLeftEight"> <Fade left> <img src={CollageEight} /> </Fade> </div>
        <div className="CollageLeftNine"> <Fade left> <img src={CollageNine} /> </Fade> </div>
        <div className="CollageLeftTen"> <Fade left> <img src={CollageTen} /> </Fade> </div>
        <div className="CollageLeftEleven"> <Fade left> <img src={CollageEleven} /> </Fade> </div>
        <div className="CollageLeftTwelve"> <Fade left> <img src={CollageTwelve} /> </Fade> </div>
        </div>

        <div className="PageFiveMission">
        <div className="OurMissionTextHeading"> Our Mission: </div>
        <div> It's time to create a level playing field for all models, and stop the need for modeling agencies to dictate opportunities. We will empower businesses to create their own talent networks, while being 100% commission free for models. </div>
        </div>








        <div className="PageFiveRightCollage">
        <div className="CollageRightOne"> <Fade right> <img src={CollageThirteen} /> </Fade> </div>
        <div className="CollageRightTwo"> <Fade right> <img src={CollageFourteen} /> </Fade> </div>
        <div className="CollageRightThree"> <Fade right> <img src={CollageFifteen} /> </Fade> </div>
        <div className="CollageRightFour"> <Fade right> <img src={CollageSixteen} /> </Fade> </div>
        <div className="CollageRightFive"> <Fade right> <img src={CollageSeven} /> </Fade> </div>
        <div className="CollageRightSix"> <Fade right> <img src={CollageSeventeen} /> </Fade> </div>
        <div className="CollageRightSeven"> <Fade right> <img src={CollageeEighteen} /> </Fade> </div>
        <div className="CollageRightEight"> <Fade right> <img src={CollageFive} /> </Fade> </div>
        <div className="CollageRightNine"> <Fade right> <img src={CollageOne} /> </Fade>  </div>
        <div className="CollageRightTen"> <Fade right> <img src={CollageTen} /> </Fade>  </div>
        <div className="CollageRightEleven"> <Fade right> <img src={CollageeTwenty} /> </Fade> </div>
        </div>
        </div>
    )
}

export default Collage;
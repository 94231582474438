import React, { useEffect, useState } from 'react';
import styled from 'styled-components'
import { brand, model, rightarrow, leftarrow, rdcl } from './images';
const Wrapper = styled.div`
    display: grid;
    padding: 0px 50px;
    grid-row-gap: 24px;
    font-family: Trade Gothic;
    @media(max-width: 414px) {
      max-width: 414px;
    }
`

const FirstSection = styled.div`
    display: grid;
    justify-self: center;
    width: 700px;
    font-size: 35px;
    grid-row-gap: 12px;
    @media(max-width: 414px) {
        width: 300px;
    }
`;

const SecondSection = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 86px;
    @media(max-width: 414px) {
        grid-template-columns: 1fr;
    }
`;

const SecondSectionRightWrapper = styled.div`
    display: grid;
    grid-auto-rows: min-content;
    font-size: 40px;
    padding: 40px 0px 0px 0px;
    position: relative;
    grid-row-gap: 80px;
    @media(max-width: 414px) {
        font-size: 25px;
    }

`

const SecondSectionSmallTextWrapper = styled.div`
    display: grid;
    grid-auto-rows: min-content;
    font-size: 30px;
    grid-row-gap: 5px;
`

const Text = styled.div`
    font-size: ${props => props.fontsize};
    font-weight: ${props => props.fontweight};
    text-align: ${props => props.textalign};)

`

const StyledImageWrapper = styled.div`
    display: grid;
    grid-template-columns: 40px 1fr 40px;
    grid-template-rows: 40px 1fr 40px;
    overflow: hidden;
    max-width: 450px;
    @media(max-width: 414px) {
        height: 250px;
        grid-template-columns: 40px 1fr 10px;
        grid-template-rows: 40px 1fr 10px;
    }
`


const ImageWrapper = styled.div`
    grid-column: ${props => props.gridcolumn};
    grid-row: ${props => props.gridrow};
    height: 100%;
`;

const Image = styled.img`
    height: 100%;
    object-fit: cover;
`

const YellowDiv = styled.div`
    background-color: #F4D17A;
    width: ${props => props.width};
    height: 100%;
    grid-row: ${props => props.gridrow};
    grid-column: ${props => props.gridcolumn};
`

const TestimonialControlWrapper = styled.div`
    display: grid;
    grid-template-columns: 73px 73px;
    grid-column-gap: 68px;
    justify-self: flex-end;
    position: absolute;
    bottom: 0;
    @media(max-width: 414px) {
        bottom: -100px;
    }

`

const ArrowWrapper = styled.div`
    background-color: #000;
    display: grid;
    justify-items: center;
    align-items: center;
    height: 73px;

`

const Section5 = () => {
    const photos = [brand, model, rdcl]
    const [index, setIndex] = useState(1)
    const totalTestimonials = 3;
    const renderTestimonial = () => {
        if (index === 1) return <IZI />
        if (index === 2) return <SARAH />
        if (index === 3) return <RDCL />
    }
    return (
        <Wrapper>
            <FirstSection>
                <Text textalign={'center'} fontweight={'bold'} fontsize={'1em'}> Who do we serve? </Text>
                <Text textalign={'center'} fontsize={'.5em'} >Creative Directors, Brand Managers, Casting Leads, Designers and Photographers for fashion, cosmetic and lifestyle brands  </Text>
            </FirstSection>
            <SecondSection>
                <StyledImageWrapper>
                    <YellowDiv gridrow={'2/4'} gridcolumn={'1/3'} />
                    <ImageWrapper gridrow={'1/3'} gridcolumn={'2/4'}>
                        <Image src={photos[index - 1]} />
                    </ImageWrapper>


                </StyledImageWrapper>
                <SecondSectionRightWrapper>
                    {renderTestimonial()}
                    <TestimonialControlWrapper>
                        <ArrowWrapper onClick={() => {
                            if (index === 1) setIndex(3);
                            else setIndex((idx => idx - 1));
                        }}>
                            <img src={leftarrow} />
                        </ArrowWrapper>
                        <ArrowWrapper onClick={() => {

                            if (index === 3) setIndex(1);
                            else setIndex((idx => idx + 1));
                        }}>
                            <img src={rightarrow} />
                        </ArrowWrapper>
                    </TestimonialControlWrapper>
                </SecondSectionRightWrapper>
            </SecondSection>

        </Wrapper>

    )
}

const IZI = () => {
    return (
        <React.Fragment>
            <Text fontweight={'1000'} fontsize={'1em'}> “I was surprised how well our campaign went. The girls were amazing to work with, and RunwaySupply removed all the anxiety I was having about finding models last minute.” </Text>
            <SecondSectionSmallTextWrapper>
                <Text fontweight={'bold'}> - Angel Z.</Text>
                <Text fontsize={'.75em'} fontweight={'normal'}> Creative Director </Text>
                <Text fontsize={'.75em'} fontweight={'normal'}> izi.com </Text>
            </SecondSectionSmallTextWrapper>
        </React.Fragment>
    )

}

const SARAH = () => {
    return (
        <React.Fragment>

            <Text fontweight={'1000'} fontsize={'1em'}> "The campaign went great. The selection process was effortless and real time updates definitely improved my experience. Thank you for having me." </Text>
            <SecondSectionSmallTextWrapper>
                <Text fontweight={'bold'}> - Sarah S.</Text>
                <Text fontsize={'.75em'} fontweight={'normal'}> Model </Text>
                <Text fontsize={'.75em'} fontweight={'normal'}> Wilhelmina </Text>
            </SecondSectionSmallTextWrapper>
        </React.Fragment>

    )
}

const RDCL = () => {
    return (
        <React.Fragment>
            <Text fontweight={'1000'} fontsize={'1em'}> “Toronto is such a creative city, and RunwaySupply allows my team to reach local talent fast and maintain relationships for all our future campaigns.” </Text>
            <SecondSectionSmallTextWrapper>
                <Text fontweight={'bold'}> - Ali R.</Text>
                <Text fontsize={'.75em'} fontweight={'normal'}> Founder </Text>
                <Text fontsize={'.75em'} fontweight={'normal'}> rdcltoronto.com </Text>
            </SecondSectionSmallTextWrapper>
        </React.Fragment>
    )
}
export default Section5;
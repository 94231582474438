import React, { useState } from 'react';
import styled from 'styled-components';
import { Question, UserInput, Button, Text, FlexStartFlexEnd } from '../../index';
import { FeaturesCircles } from '../../index';
const Wrapper = styled.div`
    display: grid;
    grid-row-gap: 24px;
`
const Step9 = ({ setStep, step, realStep, modelDetails, setDetails, rSC }) => {
    if (step !== realStep) return null;
    const [error, setError] = useState('');
    return (
        <Wrapper>
            <Question step={step} question={'Select your eye color.'} />
            <Text> It is important you provide accurate categorization to increase your selection chances.
Every data point is a chance to stand out.   </Text>
            <FeaturesCircles state={modelDetails.eyeColor} callback={(eyeColor) => setDetails({ ...modelDetails, eyeColor })} values={['#ae9f80', '#553827', '#0f305b', '#6ca580', '#778899', '#2c222b', '#a1caf1']} />
            <Text color={'red'}> {error} </Text>
            <FlexStartFlexEnd>
                <Button onClick={() => {
                    if (modelDetails.eyeColor) {
                        setStep(step + 1)
                    }
                    else {
                        setError('Select your eye color.')
                    }

                }} width={'120px'}> Next </Button>
                {rSC()}
            </FlexStartFlexEnd>
        </Wrapper>
    )
}

export default Step9;
import React, { Component } from "react";
import "./Profile.css";
import { CardElement, injectStripe, Elements } from "react-stripe-elements";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "./Loader";
import isEmail from "validator/lib/isEmail";
import DeleteAccountModal from "./DeleteAccountModal";
import DefaultBrandLogo from "../Assets/Nav/default-brand-logo.png";
import {
  HOST_NAME,
  BRAND_SERVICES_HOST_NAME,
  AWS_SERVICES_HOST_NAME,
} from "../Config";
const img =
  "https://scontent-sjc3-1.cdninstagram.com/vp/e15eceb2847abac2bd455433dc174ee8/5CC90E4C/t51.2885-15/e35/49933470_509324082926956_7208771734846260996_n.jpg?_nc_ht=scontent-sjc3-1.cdninstagram.com";
class Profile extends Component {
  state = {
    loading: false,
    token: {},
    customer: false,
    updatingCard: false,
  };
  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    this.setState({ loading: true });
    axios
      .all([this.getCustomerObject(user), this.getUserFromDB()])
      .then(
        axios.spread((customerResponse, userResponse) => {
          // Both requests are now complete

          const { customer } = customerResponse.data;
          let current_period_end;
          if (customer.subscriptions.data.length) {
            current_period_end =
              customer.subscriptions.data[0].current_period_end;
          } else {
            current_period_end = false;
          }

          let name,
            id,
            valid,
            percent_off = null;
          if (
            customer.subscriptions.data.length &&
            customer.subscriptions.data[0].discount
          ) {
            name = customer.subscriptions.data[0].discount.coupon.name;
            id = customer.subscriptions.data[0].discount.coupon.id;
            valid = customer.subscriptions.data[0].discount.coupon.valid;
            percent_off =
              customer.subscriptions.data[0].discount.coupon.percent_off;
          }
          let userFromDB = userResponse.data.user;

          let d = new Date(0); // The 0 there is the key, which sets the date to the epoch
          if (current_period_end) {
            d.setUTCSeconds(current_period_end);
          }
          let amount = 0;
          this.getCustomerInvoice(user, id)
            .then((res) => {
              const { amount_due } = res.data.invoice;
              this.setState({
                loading: false,
                customer,
                user: userFromDB,
                brandname: userFromDB.brandname,
                email: userFromDB.email,
                coupon: name ? name : "N/A",
                percentoff: percent_off ? percent_off : "N/A",
                amount: current_period_end ? amount_due : 0,
                current_period_end: current_period_end
                  ? d.getMonth() + 1 + "/" + d.getDate() + "/" + d.getFullYear()
                  : "N/A",
              });
            })
            .catch((error) => {
              this.getUserFromDB()
                .then((res) => {
                  const { user } = res.data;
                  this.setState({
                    loading: false,
                    user,
                    brandname: user.brandname,
                    email: user.email,
                    coupon: user.coupon,
                    amount: 0,
                    current_period_end: "n/a",
                    percentoff: 0,
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
              this.setState({ loading: false });
              console.log(error);
            });

          //const { amount } = customer.subscriptions.data[0].items.data[0].plan; this doesnt include discount
        })
      )
      .catch((err) => {
        this.setState({ loading: true });
        console.log(err);
        this.getUserFromDB()
          .then((res) => {
            const { user } = res.data;
            this.setState({
              loading: false,
              user,
              brandname: user.brandname,
              email: user.email,
              coupon: user.coupon,
              amount: 0,
              current_period_end: "n/a",
            });
          })
          .catch((err) => {
            console.log(err);
          });
      });
  }

  getUserFromDB = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return await axios.post(`${BRAND_SERVICES_HOST_NAME}/get_user`, {
      _id: user._id,
    });
  };

  getCustomerInvoice = (user, coupon) => {
    let { customerId } = user;
    return axios.post(`${BRAND_SERVICES_HOST_NAME}/get_customer_invoice`, {
      customerId,
      coupon,
    });
  };

  getCustomerObject = (user) => {
    let { customerId } = user;
    return axios.post(`${BRAND_SERVICES_HOST_NAME}/get_customer_object`, {
      customerId,
    });
  };

  cancelSubscription = () => {
    const { customer } = this.state;
    const { id } = customer.subscriptions.data[0];
    this.setState({ loading: true });
    axios
      .post(`${BRAND_SERVICES_HOST_NAME}/cancel_subscription`, {
        id,
      })
      .then((res) => {
        this.setState({ loading: false });

        this.getCustomerObject(this.state.user).then((res) => {
          const { customer } = res.data;
          this.setState({ customer, amount: "0", current_period_end: "N/A" });
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
  };

  updateCard = (token) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { customerId } = user;
    axios
      .post(`${BRAND_SERVICES_HOST_NAME}/update_customer_card`, {
        customerId,
        token,
      })
      .then((res) => {
        toast.success("Updated card information 🤖.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error updating card 🤖.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  };

  updateBrandName = () => {
    const { brandname } = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    user.brandname = brandname;
    axios
      .post(`${BRAND_SERVICES_HOST_NAME}/update_brand_name`, {
        brandname,
        _id: user._id,
      })
      .then((res) => {
        toast.success("Updated brandname 🤖.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        localStorage.setItem("user", user);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error updating brandname 🤖.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  };

  updateEmail = () => {
    const { email } = this.state;
    const user = JSON.parse(localStorage.getItem("user"));
    axios
      .post("/api/profile/update_brand_email", {
        email,
        _id: user._id,
        customerId: user.customerId,
      })
      .then((res) => {
        toast.success("Updated email 🤖.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error updating email. That email is already in use 🤖.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  };

  updatePassword = () => {
    const { password } = this.state;
    const user = JSON.parse(localStorage.getItem("user"));
    axios
      .post(`${HOST_NAME}/api/profile/update_brand_password`, {
        password,
        _id: user._id,
      })
      .then((res) => {
        toast.success("Updated password 🤖.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error updating password 🤖.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  };

  updateBrandLogo = async (e) => {
    try {
      const formData = new FormData();
      const user = JSON.parse(localStorage.getItem("user"));

      if (e.target.files && e.target.files.length > 0) {
        const reader = new FileReader();

        this.setState({ brandlogo: e.target.files[0] });
        formData.append("brandlogo", e.target.files[0]);
        reader.addEventListener("load", () => {
          this.setState({ brandlogopreview: reader.result });
        });
        reader.readAsDataURL(e.target.files[0]);
      }
      const brandLogo = e.target.files[0];
      // this.setState({ loading: true })

      const signedResponse = await axios.post(
        `${AWS_SERVICES_HOST_NAME}/create_signed_url_brand_logo`,
        {
          _id: user._id,
          brandname: user.brandname,
          fileType: brandLogo.type,
          filename: brandLogo.name,
        }
      );
      console.log(signedResponse, brandLogo);
      const { url } = signedResponse.data;

      const upload = await axios.put(url, brandLogo, {
        headers: {
          "Content-Type": brandLogo.type,
          "x-amz-acl": "public-read",
        },
      });
      const response = await this.getUserFromDB();
      const { user: newUser } = response.data;
      this.props.setUser(newUser);
      localStorage.setItem("user", JSON.stringify(newUser));
    } catch (error) {
      console.log(error);
    }

    //        this.setState({ user: r.data.user, loading: false })
    // localStorage.setItem("user", JSON.stringify(r.data.user))
  };
  /*


   */
  save = (token) => {
    if (token.id) {
      this.updateCard(token);
    }
    const { brandname, email, user, password } = this.state;
    if (brandname !== user.brandname) {
      new Promise((resolve, reject) => {
        this.updateBrandName();
        if (resolve) {
          this.getUserFromDB()
            .then((res) => this.setState({ user: res.data.user }))
            .catch((err) => console.log(err));
        }
      });
    }

    if (email && email !== user.email) {
      if (isEmail(email)) {
        new Promise((resolve, reject) => {
          this.updateEmail();
          resolve(true);
        }).then(() => {
          this.getUserFromDB()
            .then((res) => this.setState({ user: res.data.user }))
            .catch((err) => console.log(err));
        });
      } else {
        toast.error("Invalid email 🤖.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    }

    if (password) {
      if (password.length >= 6) {
        this.updatePassword();
        this.setState({ password: "" });
      } else {
        toast.error("Password must be atleast 6 characters 🤖.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    }
  };

  deleteAccount = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    axios
      .post(`${HOST_NAME}/api/profile/delete_brand_account`, {
        _id: user._id,
      })
      .then((res) => {
        localStorage.removeItem("user");
        this.props.logout();
        this.props.history.push("/");
        toast.success("Your account has been deleted 🤖.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      })
      .catch((err) => {
        toast.error("There was an error deleting your account 🤖.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  };

  setValue = (key, value) => this.setState({ [key]: value });

  displayCard = () => {
    if (!this.state.updatingCard) {
      return (
        <div
          style={{
            gridRow: 4,
            gridColumn: "1 / span 16",
            backgroundColor: "#f7f9fb",
            padding: 8,
          }}
        >
          <div style={{ gridColumn: "1 / span 16" }}>
            <div> Card Number </div>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, max-content)",
              gridColumnGap: 20,
            }}
          >
            <div
              style={{ textDecoration: "underline" }}
              onClick={() => this.setState({ updatingCard: true })}
            >
              {" "}
              Update Card{" "}
            </div>
            <div style={{ fontSize: 12 }}>
              {" "}
              {this.state.customer
                ? `xxxx${this.state.customer.sources.data[0].last4}`
                : null}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ gridRow: 4, gridColumn: "1 / span 16" }}>
          <div style={{ gridColumn: "1 / span 16" }}>
            <div> Update Your Card Number </div>
          </div>
          <div style={{ gridColumn: "1 / span 16" }}>
            <Elements>
              <StripeCheckoutForm save={this.save} setValue={this.setValue} />
            </Elements>
          </div>
        </div>
      );
    }
  };
  render() {
    const {
      brandlogopreview,
      customer,
      user,
      loading,
      brandname,
      email,
      coupon,
      percentoff,
      amount,
      current_period_end,
      password,
    } = this.state;
    return (
      <div className="ProfileGridContainer">
        {loading && <Loader />}
        <div
          id="account-section-1"
          style={{
            gridRow: 2,
            gridColumn: "4 / span 4",
            display: "grid",
            gridTemplateColumns: "repeat(16, 1fr)",
            gridTemplateRows: "20px 20px 80px 20px 20px 50px",
          }}
        >
          <div style={{ fontSize: 18, gridRow: 1 }}> ACCOUNT </div>
          <div
            style={{ gridRow: 3, gridColumn: "1 / span 16", display: "flex" }}
          >
            <div>
              <img
                src={
                  user
                    ? brandlogopreview
                      ? brandlogopreview
                      : user.brandLogo
                      ? user.brandLogo
                      : DefaultBrandLogo
                    : null
                }
                style={{
                  borderRadius: "50%",
                  width: 80,
                  minWidth: 80,
                  maxHeight: 80,
                  minHeight: 80,
                  objectFit: "cover",
                }}
              />
            </div>
            <div
              style={{
                marginLeft: 10,
                textDecoration: "underline",
                alignSelf: "center",
              }}
            >
              <label for="brandlogo"> Upload New Brand Logo </label>
              <input
                accept="image/*"
                style={{
                  width: ".1px",
                  height: ".1px",
                  opacity: 0,
                  overflow: "hidden",
                  zIndex: "-1",
                }}
                name="brandlogo"
                id="brandlogo"
                type="file"
                onChange={(e) => this.updateBrandLogo(e)}
              />
            </div>
          </div>
          <div style={{ gridRow: 5, gridColumn: "1 / span 16" }}>
            Change Password
          </div>
          <div style={{ gridRow: 6, gridColumn: "1 / span 16" }}>
            <input
              type="password"
              value={password}
              onChange={(e) => this.setValue("password", e.target.value)}
              style={{ width: "100%" }}
              className="ProfileInput"
              placeholder="Enter new password..."
            />
          </div>
        </div>
        <div
          id="account-section-2"
          style={{
            gridRow: 2,
            gridColumn: "10 / span 4",
            display: "grid",
            gridTemplateRows: "40px 20px 50px 30px 20px 50px",
          }}
        >
          <div style={{ gridRow: 2 }}>
            <div> Brand Name </div>
          </div>
          <div style={{ gridRow: 3 }}>
            <input
              onChange={(e) => this.setValue("brandname", e.target.value)}
              value={brandname}
              style={{ width: "100%" }}
              className="ProfileInput"
              placeholder="Enter Brand Name..."
            />
          </div>

          <div style={{ gridRow: 5 }}>
            <div> Email </div>
          </div>
          <div style={{ gridRow: 6 }}>
            <input
              onChange={(e) => this.setValue("email", e.target.value)}
              value={email}
              style={{ width: "100%" }}
              className="ProfileInput"
              placeholder="Enter Email..."
            />
          </div>
        </div>

        <div
          id="billing-section-one"
          style={{
            gridRow: 4,
            gridColumn: "4 / span 4",
            display: "grid",
            gridTemplateColumns: "repeat(16, 1fr)",
            gridTemplateRows: "15px 20px 30px max-content 0px 20px 30px 30px",
          }}
        >
          <div style={{ gridRow: 2, gridColumn: "1 / span 16" }}>
            <div style={{ fontSize: 20 }}> BILLING </div>
          </div>

          {this.displayCard()}

          <div
            style={{
              alignSelf: "center",
              gridRow: 7,
              gridColumn: "1 / span 16",
            }}
          >
            <div> Next Billing Date: {current_period_end}</div>
          </div>

          <div
            style={{
              alignSelf: "center",
              gridRow: 8,
              gridColumn: "1 / span 16",
            }}
          >
            <div> Amount Due: {amount / 100 ? amount / 100 : 0}$ USD </div>
          </div>

          <div style={{ gridRow: 10, gridColumn: "1 / span 10" }}>
            {this.state.customer &&
            this.state.customer.subscriptions.data.length ? (
              <div
                style={{ textDecoration: "underline" }}
                onClick={this.cancelSubscription}
              >
                {" "}
                CANCEL SUBSCRIPTION{" "}
              </div>
            ) : null}
          </div>
        </div>

        <div
          id="billing-section-two"
          style={{
            gridRow: 4,
            gridColumn: "10 / span 4",
            display: "grid",
            gridTemplateColumns: "repeat(16,1fr)",
            gridTemplateRows: "60px 20px 20px 30px 40px 60px",
          }}
        >
          <div style={{ gridRow: 2, gridColumn: "1 /span 16" }}>
            <div style={{ fontSize: 20 }}> Discount </div>
          </div>
          <div
            style={{
              gridRow: 4,
              gridColumn: "1 /span 16",
              display: "grid",
              gridTemplateColumns: "repeat(16,1fr)",
            }}
          >
            <div style={{ gridColumn: "1 / span 16" }}> Code: {coupon} </div>
            <div style={{ marginTop: 5, gridColumn: "1 / span 16" }}>
              {" "}
              Percent Off: {percentoff}{" "}
            </div>
          </div>
          <div
            id="save-section"
            style={{ gridColumn: "4 / span 10", gridRow: 6 }}
          >
            <div>
              {" "}
              <button
                id="save-button"
                className="ProfileSaveInput"
                onClick={this.save}
              >
                {" "}
                SAVE{" "}
              </button>{" "}
            </div>
          </div>
        </div>

        <div
          id="dividerTwo"
          style={{
            gridRow: 5,
            alignSelf: "flex-end",
            backgroundColor: "black",
            height: 1,
            gridColumn: " 4 /span 10",
          }}
        />

        <div
          id="delete-section-one"
          style={{
            gridColumn: "4 / span 4",
            gridRow: 6,
            display: "grid",
            gridTemplateColumns: "repeat(16, 1fr)",
            gridTemplateRows: "30px 20px 30px max-content 30px",
          }}
        >
          <div style={{ gridRow: 2, gridColumn: "1 /span 16" }}>
            DELETE ACCOUNT
          </div>
          <div style={{ gridRow: 4, gridColumn: "1 /span 16" }}>
            {" "}
            All contact, campaign, billing data will be deleted.{" "}
          </div>
          <div style={{ gridRow: 5, gridColumn: "1 /span 16" }}>
            {" "}
            This action cannot be undone.{" "}
          </div>
        </div>

        <div
          id="delete-section-two"
          style={{
            gridRow: 5,
            gridColumn: "10 / span 4",
            display: "grid",
            gridTemplateColumns: "repeat(16, 1fr)",
            gridTemplateRows: "80px 60px",
          }}
        >
          <div style={{ gridColumn: "4 / span 10", gridRow: 2 }}>
            {" "}
            <DeleteAccountModal deleteAccount={this.deleteAccount} />{" "}
          </div>
        </div>
      </div>
    );
  }
}

class CardSection extends React.Component {
  componentDidMount() {}
  render() {
    return (
      <div className="ProfileCardContainer">
        <CardElement
          style={{
            base: {
              fontSize: "12px",
              textTransform: "uppercase",
              fontFamily: "inherit",
              fontWeight: "bold",
            },
          }}
          onReady={(el) => {}}
        />
      </div>
    );
  }
}

class CheckoutForm extends React.Component {
  componentDidMount() {
    const button = document.getElementById("save-button");
    button.addEventListener("click", this.handleSubmit, false);
  }

  handleSubmit = (ev) => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();

    // Within the context of `Elements`, this call to createToken knows which Element to
    // tokenize, since there's only one in this group.

    this.props.stripe
      .createToken({})
      .then(({ token }) => {
        if (token.id) {
          this.props.save(token);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // register("tok_visa"); use this for testing if you dont want to type 4242424242 over and over
    // However, this line of code will do the same thing:
    //
    // this.props.stripe.createToken({type: 'card', name: 'Jenny Rosen'});

    // You can also use createSource to create Sources. See our Sources
    // documentation for more: https://stripe.com/docs/stripe-js/reference#stripe-create-source
    //
    // this.props.stripe.createSource({type: 'card', owner: {
    //   name: 'Jenny Rosen'
    // }});
  };

  render() {
    return <CardSection />;
  }
}

const StripeCheckoutForm = injectStripe(CheckoutForm);
export default Profile;

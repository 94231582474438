import styled from 'styled-components';

export const MiniHeader = styled.h6`
    font-size: 12px;
    font-weight: 100;
    color: #c3c3c3; 
    text-transform: capitalize;
    text-align: center;
    margin: 0;
    padding: 0;
`

export const SubHeader = styled.h2`
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin: 0;
  padding: 0;
`
export const Header = styled.h1`
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  margin: 0;
  padding: 0;
`
export const Boxes = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, 75px);
    grid-column-gap: 24px;
    text-align: center;
    justify-content: center;
    grid-row-gap: 24px;
`

export const InputsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    grid-auto-rows: min-content;
    @media(max-width: 600px) {
      grid-template-columns: 1fr;
    }
`
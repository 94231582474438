import React, {useState} from 'react';
import styled from 'styled-components'
import { Down } from './Assets';

const Wrapper = styled.div`
    display: grid;
    cursor: pointer;
    max-width: 400px;
`

const SubWrapper = styled.div`
    display: grid;
    justify-content: center;
    border: solid 1px #e1e1e1;
    grid-template-columns: 1fr min-content;
    height: 40px;
    cursor: pointer;
    align-items: center;
    padding: 0px 16px;
    border-radius: 5px;
`

const Select = styled.div`
    display: ${props => props.active && 'grid' || 'none'};
    grid-row-gap: 12px;
    grid-auto-rows: min-content;
    border: solid 1px #e1e1e1;
    border-radius: 5px;
    padding: 12px 16px;
    max-height: 200px;
    min-height:200px;
    overflow-y: scroll;
`

const Option = styled.div`
    color: black;
    font-size: 14px;
`

// pass the value that is clicked into the callback and set the state from the parent component
const SelectDropDown = ({ values = [], state, callback, }) => {
    const [active, setActive] = useState(false);
    // const [hover, setHover] = useState(false); todo implement hover on select option
    const [currentOption, setOption] = useState(state || '');
    const renderValues = () => {
        return values.map((value, index) => {
            return <Option onClick={() => { 
                callback(value)
                setActive(false)
                setOption(value);
            }} active={active === index}> {value} </Option>
        })
    }
    return (
    <Wrapper>
        <SubWrapper onClick={() => setActive(true)}> 
            <Option>
               {currentOption}
            </Option>
            <img src={Down} />
        </SubWrapper>
        <Select active={active}> 
            {renderValues()}
        </Select>
    </Wrapper>

    )
}

export default SelectDropDown;
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { MODEL_CATALOG_HOST_NAME } from '../../Config';
import ModelDetails from './ModelDetails'
const Wrapper = styled.div`
    padding: 12px;
    @media(max-width: 1000px) {
        padding: 4px;
    }
`

const ModelCatalogWrapper = styled.div`
    display: grid;
    grid-auto-rows: min-content;
    grid-row-gap: 12px;
    grid-column-gap: 2px;
    grid-template-columns: repeat(auto-fit, 344px);
    justify-content: center;
    @media(max-width: 700px) {
        grid-template-columns: repeat(auto-fit, 190px);
    }

`

const ModelBox = styled.a`
    display: grid;
    grid-auto-rows: 375px 24px;
    grid-row-gap: 12px;
    position: relative;
    cursor: pointer;
    @media(max-width: 700px) {
        grid-auto-rows: 244px 24px;
    }
 `

 const ModelName = styled.div`
    color: #808080;
    font-family: brandongrotesque-regular!important;
    text-decoration: none;
    display: block;
    padding-left: 0;
    font-weight: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
    grid-row: 2;
    grid-column: 1;
    text-align: center;
 `

 const ModelImageWrapper = styled.div`
    grid-row: 1;
    grid-column: 1;
    -webkit-filter: ${props => props.hover ? 'brightness(30%)' : 'inherit'};
    z-index: 10;
    ${ModelImageWrapper} > img {
        height: 375px;
        width: 344px;
        object-fit: contain;
        z-index: 5;
        @media(max-width: 700px) {
            height: 244px;
            width: 190px;
        }

    }
 `

const ModelCatalog = ({ history, endpoint}) => {
    const [models, setModels] = useState([]);
    const getModelCatalog = async () => {
        const models = (await axios.get(`${MODEL_CATALOG_HOST_NAME}${endpoint}`)).data.models
        console.log(models)
        setModels(models);
    }
    useEffect(() => {
        getModelCatalog();
    }, [])
    const renderCatalog = () => {
        return models.map((model, index) => {
            return <ModelBoxComponent onClick={() => history.push(`/${model.shortID}`)} getModelCatalog={getModelCatalog} key={model.shortID} history={history} model={model} />
        })
    }
    return (
        <Wrapper>
            <ModelCatalogWrapper>
                {renderCatalog()}
            </ModelCatalogWrapper>
        </Wrapper>

    )
}

const ModelBoxComponent = ({ model, history, getModelCatalog }) => {
    const [hover, setHover] = useState(false);
    return (
        <ModelBox onMouseEnter={() => {
            setHover(true)
        }}
            onMouseLeave={() => {
                setHover(false)
            }}
        >
            <ModelImageWrapper hover={hover}>
                <img src={model.fullLength} />
            </ModelImageWrapper>
            <ModelName onClick={() => history.push(`/${model.shortID}`)}> {model.firstName} {model.lastName} </ModelName>
            <ModelDetails hover={hover} model={model} history={history} getModelCatalog={getModelCatalog} />
        </ModelBox>
    )

}

export default ModelCatalog;
import React from "react";
import FooterIcon from '../Assets/LandingPage/footer-icon.png';
import "./About.css";
import styled from 'styled-components';
const FooterWrapper = styled.div`
    background-color: rgb(210, 227, 230);
    display: grid;
    grid-row: ${props => props.gridRow};
    grid-column: ${props => props.gridColumn};
    @media(min-width: 320px) {
        display: grid;
        grid-template-columns: 1fr;
        padding: 10px 10px;
        grid-row-gap: 12px;
        justify-items: center;
        text-align: center;
        padding: 40px
    }
    @media(min-width: 1400px) {
        grid-template-columns: repeat(4, minmax(250px, 1fr) );
        padding: 100px 200px;
        justify-items: center;
    }

    @media(min-width: 1000px) {
        display: grid;
        grid-template-columns: repeat(4, minmax(100px, 1fr) );
        padding: 100px 200px;
        justify-items: center;
    }
    

`

const S1 = styled.div`
    display: grid;
    grid-row-gap: 12px;
`

const S1Title = styled.h1`
    letter-spacing: 0.57px;
    line-height: 24px;
    font-size: 24px;
    font-style: bold;
    margin-bottom: 15px;
`

const BasicWrapper = styled.div`
    display: grid;
    grid-row-gap: 12px;
    grid-auto-rows: min-content;
`

const FooterItem = styled.a`
&:nth-child(1) {
    font-weight: 700
}
cursor: pointer;
`

const Footer = ({ gridRow, gridColumn, history }) => {
  return (
    <FooterWrapper gridColumn={gridColumn} gridRow={gridRow}>
      <S1>
        <div>
          {" "}
          <img src={FooterIcon} />{" "}
        </div>
        <S1Title> RUNWAY SUPPLY © 2019</S1Title>
        <div style={{ textAlign:'left' }}> Model management as it should be. </div>
      </S1>

      <BasicWrapper>
        <FooterItem onClick={() => {
          history.push('/')
        }}> About </FooterItem>
        <FooterItem  onClick={() => {
          document.querySelector('.PageFive').scrollIntoView()
        }}> Our Mission </FooterItem>
        <FooterItem onClick={() => {
          document.querySelector('#features').scrollIntoView()
        }}> Features </FooterItem>
        <FooterItem onClick={() => {
          document.querySelector('#PricingModal').scrollIntoView()
        }}> Pricing </FooterItem>
         <FooterItem onClick={() => {
         history.push('/apply')
        }}> Modeling </FooterItem>
      </BasicWrapper>

      <BasicWrapper >
        <FooterItem> Legal </FooterItem>
        <FooterItem> Terms Of Service </FooterItem>
        <FooterItem> Privacy Policy </FooterItem>
      </BasicWrapper>

      <BasicWrapper>
        <FooterItem target="_blank" rel="noopener noreferrer" href="https://instagram.com/runwaysupply"> Get In Touch </FooterItem>
        <FooterItem target="_blank" rel="noopener noreferrer" href="https://instagram.com/runwaysupply"> Instagram </FooterItem>
        <FooterItem target="_blank" rel="noopener noreferrer" href="https://instagram.com/runwaysupply"> Contact </FooterItem>
      </BasicWrapper>
    </FooterWrapper>
  );
};

export default Footer;
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: grid;
    grid-template-rows: 1fr 4px;
    width: auto;
    font-size: 18px;
`

const Anchor = styled.div`
    cursor: pointer;
    align-self: center;
    justify-self: center;
    font-size: ${props => props.fontsize};
    text-transform: capitalize;
    font-family: Trade Gothic;
    font-weight: bold;
    color: ${props => props.color ? props.color : props.selected ? '#000' : '#A9A9A9'};
`

const YellowBorder = styled.div`
    height: 4px;
    width: 103px;
    background-color: #F4D17A;
    align-self: flex-end;
    display: ${props => props.selected ? 'block' : 'none'};
    justify-self: center;
`

const NavItem = ({ text, setter, setterValue, color, onClick, fontsize }) => {
    const isSelected = text === setterValue;
    return (
        <Wrapper onClick={() => { 
            setter(text)
            if(onClick) onClick();
        }}>
            <Anchor fontsize={fontsize} color={color} selected={isSelected}> {text} </Anchor>
            <YellowBorder selected={text === setterValue} />
        </Wrapper>

    )
}

export default NavItem;
import React from 'react';
import styled from 'styled-components';
import Section1 from './Section1/Section1';
import Section2 from './Section2/Section2';
import Section3 from './Section3/Section3'
import Section4 from './Section4/Section4';
import Section5 from './Section5/Section5';
import Section6 from './Section6/Section6';
import { FooterV2 } from '../FooterV2';
import { TradeGothicNormal } from '../../fonts/index'
const Wrapper = styled.div`
    display: grid;
    font-family: Trade Gothic !important;
    grid-row-gap: 200px;
    padding: 0px 0px 0px 0px;
`

const LandingV2 = ({ ...props }) => {
    return (
        <Wrapper>
            <Section1 {...props} />
            <Section2 />


            <Section3 />
            <Section4 />
            <Section5 />
            <Section6 {...props} />
            <FooterV2 {...props} />
        </Wrapper>
    )
}

export default LandingV2;
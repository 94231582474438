import React, { Component, useContext } from 'react';
import axios from 'axios';
import Loader from './Loader';
import { toast } from "react-toastify";
// campaign details

import CalendarIcon from "../Assets/CreateCampaign/calendar-3.svg"
import CampaignsLocationIcon from '../Assets/Campaigns/campaigns-location-icon.svg';
import DeadlineIcon from "../Assets/CreateCampaign/deadline-icon.svg";
import BrandIcon from "../Assets/Modal/shop-1.svg";
import TimeClockIcon from "../Assets/CreateCampaign/time-clock-circle.svg"

//basic info
import PencilIcon from "../Assets/CreateCampaign/pencil-1.svg";
import EmailIcon from "../Assets/CreateCampaign/email-action-unread.svg";
import PhoneIcon from "../Assets/CreateCampaign/phone.svg";
import BirthdayIcon from "../Assets/CreateCampaign/party-confetti.svg";
import CityIcon from "../Assets/CreateCampaign/modern-architecture-twin-building.svg";

//navigation
import NextButton from '../Assets/CampaignApplicants/next-button.png'
import PreviousButton from '../Assets/CampaignApplicants/previous-button.png'

//socials
import InstagramIcon from "../Assets/CreateCampaign/instagram.svg";
import TwitterIcon from "../Assets/CreateCampaign/twitter.svg";
import URLIcon from "../Assets/CreateCampaign/hyperlink-circle.svg";
import YoutubeIcon from "../Assets/CreateCampaign/youtube.svg";
import './CampaignApplicantDetails.css';
import './CampaignApplicants.css'
import Lightbox from 'react-image-lightbox';
import { NotificationContext } from '../App'
import { HOST_NAME } from '../Config';

class CampaignApplicantDetails extends Component {
  state = {
    application: false,
    lightboxOpen: false,
    photoIndex: 0,
    currentImage: false ,
    url:''
  }


  componentDidMount() {
    let { data } = this.props;
    let application;
        let allImages = []

    if(document.getElementById('ViewApplicantsContainer'))  document.getElementById('ViewApplicantsContainer').style.display = 'none'
    if(document.getElementById('SelectedApplicantsContainer')) document.getElementById('SelectedApplicantsContainer').style.display= 'none'
    if(document.getElementById('CampaignApplicantsUpperSection')) document.getElementById('CampaignApplicantsUpperSection').style.display = 'none';
    if(document.getElementById('NetworkUpperSection')) document.getElementById('NetworkUpperSection').style.display='none'
    console.log("APPLICANT DETAILS REMOUNTED")


      if(this.props.match.params.applicationID) {
        application = data.find((app,index) => {
          return app._id === this.props.match.params.applicationID
        });
      }

      if(this.props.match.params.modelID) {
        application = data.find((app,index) => {
          return app._id === this.props.match.params.modelID
        })
      }


      if(application)
      Object.keys(application).map((key,index) => (
        key === "sideProfileBaseResolution" ? allImages.push(application[key])
        : key === "closeUpBaseResolution" ? allImages.push(application[key])
        : key === "fullLengthBaseResolution" ? allImages.push(application[key])
        : key === "waistUpBaseResolution" ? allImages.push(application[key]) : null
      ))





    const currentIndex = null;



    this.setState({ application: application, currentIndex, allImages  })

  }

  componentWillUnmount() {
    if(document.getElementById('ViewApplicantsContainer'))  document.getElementById('ViewApplicantsContainer').style.display = 'grid'
    if(document.getElementById('SelectedApplicantsContainer')) document.getElementById('SelectedApplicantsContainer').style.display= 'grid'

    if(document.getElementById('CampaignApplicantsUpperSection')) document.getElementById('CampaignApplicantsUpperSection').style.display = 'grid';
    if(document.getElementById('NetworkUpperSection')) document.getElementById('NetworkUpperSection').style.display='grid'
    this.props.setValue('application',false)
    this.props.setValue('campaign',false)
  }

  next = () => {
    const { currentIndex } = this.state;
    const { data } = this.props;
    if(this.props.notificationApplication) this.props.setValue('application', false)
    if(currentIndex < data.length - 1) {
      const application = data.find((app, index) => index === currentIndex + 1);
      let allImages = []
      if(application)
      Object.keys(application).map((key,index) => (
        key === "sideProfileBaseResolution" ? allImages.push(application[key])
        : key === "closeUpBaseResolution" ? allImages.push(application[key])
        : key === "fullLengthBaseResolution" ? allImages.push(application[key])
        : key === "waistUpBaseResolution" ? allImages.push(application[key]) : null
      ))
      this.setState({ application, currentIndex: currentIndex + 1, allImages })
    }
  }

  previous = () => {
    const { currentIndex } = this.state;
    const { data } = this.props;
    if(currentIndex > 0) {
      if(this.props.notificationApplication) this.props.setValue('application', false)
      const application = data.find((app, index) => index === currentIndex - 1);
      this.setState({ application, currentIndex: currentIndex - 1 })
    }
  }

  hireApplicants = () => {
    const { application } = this.state;
    const { campaign } = this.props;
    if(campaign.modelsHired >= campaign.hireLimit) {
      toast.warning("You have reached the hire limit for this campaign 🤖.", {
        position: toast.POSITION.BOTTOM_CENTER
      });
      return;
    }
    const user =  JSON.parse(localStorage.getItem('user'))
    this.setState({ loading: true })
    axios.post(`${HOST_NAME}/api/campaigns/hire_applicants`, {
      modelIDS: [application.applicantID],
      applicationIDS: [application._id],
      campaignID: campaign._id,
      brandID: user._id,
      tags: campaign.tags,
      campaignTitle: campaign.title,
      brand: campaign.brand
    })
    .then(res => {
      this.setState({ loading: false })
      this.props.getCampaignAndApplicants();

      toast.success("Model Hired 🤖.", {
        position: toast.POSITION.BOTTOM_CENTER
      });
    })
    .catch(err => {
      this.setState({ loading: false })
      console.log(err)
      toast.error("Failed To Hire Model 🤖.", {
        position: toast.POSITION.BOTTOM_CENTER
      });
    })

  }

  handleLightBox = (currentImage, photoIndex) => {

    const { allImages } = this.state;
    for(let i = 0; i < allImages.length; i++) {
      if(allImages[i] === currentImage) {
        this.setState((state) => ({ lightboxOpen: !state.lightboxOpen, currentImage, photoIndex: i}) )
        break;
      }
    }

  }


  renderCampaignDetails = (campaign, notificationCampaign) => {
    const dynamicCampaign = notificationCampaign ? notificationCampaign : campaign
    if(this.props.match.params.applicationID) {
      return (
      <React.Fragment>
        <div style={{ gridColumn:'1 / -1' }}>
          <img
            style={{ width: "100%", height:'100%', overflow: "hidden", objectFit: "cover" }}
            src={dynamicCampaign.coverphoto}
          />
        </div>

        <div style={{ gridColumn:'1 / -1', textAlign:'center', fontSize: 24, fontWeight: 800 }}>
          {dynamicCampaign.title}
        </div>
        <div style={{ }} className="Campaign-Applicants-Campaign-Details" >
            <div className="Campaign-Applicants-Campaign-Details-Item One">
              <div style={{  }}> <img src={BrandIcon} style={{ width:25, height: 25 }}/> </div>
              <div style={{  }}> {dynamicCampaign.brand} </div>
            </div>
            <div className="Campaign-Applicants-Campaign-Details-Item Two">
              <div style={{ gridRow:1, }}> <img src={CampaignsLocationIcon} style={{ width: 18, height: 20 }}/> </div>
              <div style={{ gridRow: 1, }}> {dynamicCampaign.location} </div>
            </div>
            <div className="Campaign-Applicants-Campaign-Details-Item Three" >
              <div style={{ gridRow:1, }}> <img src={CalendarIcon} style={{ width: 18, height: 20 }}/> </div>
              <div style={{ gridRow: 1, }}> {new Date(dynamicCampaign.startDate).toLocaleDateString()} </div>
            </div>
            <div className="Campaign-Applicants-Campaign-Details-Item Four" >
              <div style={{  }}> <img src={TimeClockIcon} style={{ width: 18, height: 20 }}/> </div>
              <div style={{ whiteSpace:'no-wrap' }}> {new Date(dynamicCampaign.startDate).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})} </div>
            </div>
            <div className="Campaign-Applicants-Campaign-Details-Item Five" >
              <div style={{  }}> <img src={DeadlineIcon} style={{ width: 18, height: 20 }}/> </div>
              <div style={{  }}> Deadline {new Date(dynamicCampaign.deadline).toLocaleDateString()} </div>
            </div>
          </div>
        </React.Fragment>
      )
    }
  }

  renderModelNetworkUpperSection = (application) => {
    if(this.props.match.url === `/network/${this.props.match.params.modelID}` && application) {
      return (
        <div style={{ paddingTop: 20, fontSize: 24, fontWeight: 800, gridColumn: '1/-1', justifySelf:'center', textTransform:'capitalize'}}>
          {`${application.firstName} ${application.lastName}`}
        </div>
      )
    }
  }

  renderActionableButtons = (application) => {
    if(this.props.match.url === `/network/${this.props.match.params.modelID}` && application) {
      return (
        <div style={{  gridColumn:'4 / span 3', minWidth: 100, height:35, marginTop: 60   }}>
          <button onClick={() => {
            this.props.history.push(`${this.props.previousURL}`)
            window.scrollTo(0, 0);
          }} style={{  cursor: 'pointer', width:'100%', height:'100%', backgroundColor: '#24292E', color:'white' }}>
           Back To Network
          </button>
        </div>
      )
    }
    if(this.props.match.url.includes('/campaign-applicants')) {
      return (
        <React.Fragment>
          <div style={{  gridColumn:'4 / span 3', minWidth: 100, height:35, marginTop: 60   }}>
            <button onClick={() => {
              this.props.history.push(`${this.props.previousURL}`)
              window.scrollTo(0, 0);
            }} style={{  cursor: 'pointer', width:'100%', height:'100%', backgroundColor: '#24292E', color:'white' }}>
             Back To Campaign
            </button>
          </div>

          <div style={{  gridColumn: '11 / span 3',  height: 35, minWidth: 100, marginTop: 60 }}>
            <button onClick={this.hireApplicants} style={{ cursor: 'pointer', width:'100%', height:'100%', backgroundColor:'#05CCA0', color:'white' }}>
              Select
            </button>
          </div>
        </React.Fragment>
      )
    }

    if(this.props.match.url.includes('/selected-applicants')) {
      return (
        <React.Fragment>
          <div style={{  gridColumn:'4 / span 3', minWidth: 100, height:35, marginTop: 60   }}>
            <button onClick={() => {
              this.props.history.push(`${this.props.previousURL}`)
              window.scrollTo(0, 0);
            }} style={{  cursor: 'pointer', width:'100%', height:'100%', backgroundColor: '#24292E', color:'white' }}>
             Back To Models
            </button>
          </div>


        </React.Fragment>
      )
    }
  }

  renderApplicantDetails = (application, notificationApplication) => {
    const dynamicApplication = notificationApplication ? notificationApplication : application
    const { firstName, lastName, email, phone, dob, city } = dynamicApplication;
    return (
      <React.Fragment>
        <div style={{  gridColumn: '4 / span 10' }}> Basic Information</div>
        <div className="Basic-Information-Campaign-Applicant-Details" style={{ }}>
          <div className="ButtonContainer">
            <div className="ButtonIcon"> <img src={PencilIcon} /> </div>
            <div className="ButtonText"> <button  style={{ height:40, border:'none', backgroundColor:'#fff' }}> {firstName}</button></div>
          </div>
          <div className="ButtonContainer">
            <div className="ButtonIcon"> <img src={PencilIcon}  /> </div>
            <div className="ButtonText"> <button style={{ height:40, border:'none', backgroundColor:'#fff'  }}> {lastName} </button> </div>
          </div>
          <div className="ButtonContainer">
            <div className="ButtonIcon"> <img src={EmailIcon}  /> </div>
            <div className="ButtonText"> <button  style={{  height:40, border:'none', backgroundColor:'#fff'  }}> {email} </button> </div>
          </div>
          <div className="ButtonContainer">
            <div className="ButtonIcon"> <img src={PhoneIcon}  /> </div>
            <div className="ButtonText"> <button style={{ height:40, border:'none', backgroundColor:'#fff'  }}> {phone} </button></div>
          </div>
          <div className="ButtonContainer">
            <div className="ButtonIcon">   <img src={BirthdayIcon}  />  </div>
            <div className="ButtonText"> <button style={{  height:40, border:'none', backgroundColor:'#fff'  }}> {dob} </button>  </div>
          </div>
          <div className="ButtonContainer">
            <div className="ButtonIcon">  <img src={CityIcon}  /> </div>
            <div className="ButtonText"> <button style={{ height:40, border:'none', backgroundColor:'#fff'  }}> {city} </button> </div>
          </div>
        </div>

        <div style={{  gridColumn:'4 / span 10' }}> Social Media Profiles </div>

        <div className="Profiles-Campaiagn-Applicant-Details"  style={{   }}>
          {Object.keys(dynamicApplication).map(key => (
            key === "website" && dynamicApplication[key] ? (
              <a href={`${dynamicApplication[key]}`} rel="noopener noreferrer" target="_blank" id="profiles-url" className="ButtonContainer">
                <div className="ButtonIcon">
                  <img src={URLIcon}  />
                </div>
                <div className="ButtonText"> <button style={{ height:40, border:'none', backgroundColor:'#fff'  }}> {dynamicApplication[key]} </button> </div>
              </a>
            ) : key === "instagram" && dynamicApplication[key] ? (
              <a href={`https://instagram.com/${dynamicApplication[key]}`} rel="noopener noreferrer" target="_blank" id="profiles-instagram" className="ButtonContainer">
                <div className="ButtonIcon">
                  <img src={InstagramIcon} />
                 </div>
                <div className="ButtonText"> <button  style={{ height:40, border:'none', backgroundColor:'#fff'  }}> {dynamicApplication[key]} </button></div>
              </a>
            ) : key === "youtube" && dynamicApplication[key] ? (
              <a href={`https://youtube.com/user/${dynamicApplication[key]}`} rel="noopener noreferrer" target="_blank" id="profiles-youtube" className="ButtonContainer">
                <div className="ButtonIcon"> <img src={YoutubeIcon} /> </div>
                <div className="ButtonText"> <button  style={{ height:40, border:'none', backgroundColor:'#fff'  }}> {dynamicApplication[key]} </button></div>
              </a>
            ) : key === "twitter" && dynamicApplication[key] && (
              <a  href={`https://twitter.com/${dynamicApplication[key]}`} rel="noopener noreferrer" target="_blank" id="profiles-twitter" className="ButtonContainer">
                <div className="ButtonIcon"> <img src={TwitterIcon}  /> </div>
                <div className="ButtonText"> <button  style={{ height:40, border:'none', backgroundColor:'#fff'  }}> {dynamicApplication[key]} </button> </div>
              </a>
            )
          ))}
        </div>

        <div style={{ gridColumn:'4 / span 10', display:'none'}} id="PhotoHeader">
          Models Photos
        </div>

        <div className="Photos-Campaign-Applicant-Details"  style={{  }}>
          {Object.keys(dynamicApplication).map((key) => {
            if(key === "sideProfile" || key === "closeUp" || key === "fullLength" || key === "waistUp") {
              if(document.getElementById('PhotoHeader'))
              document.getElementById('PhotoHeader').style.display='block'
            }
            return  key === "sideProfile" ? <div onClick={() => this.handleLightBox(dynamicApplication["sideProfileBaseResolution"])}> <img style={{ width:150, height: 300, borderRadius: 10 }}src={dynamicApplication[key]} /> </div>
            : key === "closeUp" ? <div onClick={() => this.handleLightBox(dynamicApplication["closeUpBaseResolution"])}> <img style={{ width:150, height: 300, borderRadius: 10 }} src={dynamicApplication[key]} /> </div>
            : key === "fullLength" ? <div onClick={() => this.handleLightBox(dynamicApplication["fullLengthBaseResolution"])}> <img style={{ width:150, height: 300, borderRadius: 10 }} src={dynamicApplication[key]} /> </div>
            : key === "waistUp" ? <div onClick={() => this.handleLightBox(dynamicApplication["waistUpBaseResolution"])}> <img style={{ width:150, height: 300, borderRadius: 10 }} src={dynamicApplication[key]} /> </div> : null
          })}
        </div>
      </React.Fragment>
    )
  }

  render() {
    const { application, loading, currentImage, allImages, photoIndex, lightboxOpen } = this.state;
    const { campaign } = this.props;
    const { firstName, lastName, email, phone, dob, city } = application;
    return (

      <NotificationContext.Consumer>
      {value => (


        <div style={{ backgroundColor:'#F6F8FA', minHeight:'100vh' }}>
          {loading && <Loader />}
          { lightboxOpen && <Lightbox
              onCloseRequest={() => this.setState({ lightboxOpen: false })}
              mainSrc={allImages[photoIndex]}
              nextSrc={allImages[(photoIndex + 1) % allImages.length]}
              prevSrc={allImages[(photoIndex + allImages.length - 1) % allImages.length]}
              onMoveNextRequest={() =>
                   this.setState({
                     photoIndex: (photoIndex + 1) % allImages.length,
                   })
                 }
                 onMovePrevRequest={() =>
                 this.setState({
                   photoIndex: (photoIndex + allImages.length - 1) % allImages.length,
                 })
               }
            /> }
          <div
          className={
            this.props.match.url === `/network/${this.props.match.params.modelID}`
            ? 'CampaignApplicantDetailsContainerNetwork'
            : "CampaignApplicantDetailsContainer"
          }
           style={{  }}>
            {this.renderModelNetworkUpperSection(application)}
            {this.renderCampaignDetails(campaign,value.campaign)}

            <div style={{ display:'grid', gridTemplateColumns:'10px 20px 10px', marginLeft:40, gridColumn:'4 / span 10', justifySelf:'flex-end', marginRight:'20px'  }}>
              <div style={{ gridColumn: 1 }}> <img onClick={this.previous} src={PreviousButton} /> </div>
              <div style={{ gridColumn: 3 }}>  <img onClick={this.next} src={NextButton} />  </div>
            </div>
            {this.renderApplicantDetails(application, value.application)}


            {this.renderActionableButtons(application)}

          </div>
        </div>
        )}
        </NotificationContext.Consumer>

    )
  }
}

export default props => ( <NotificationContext.Consumer>
        {({setValue, application}) => {
           return <CampaignApplicantDetails {...props} setValue={setValue} notificationApplication={application} />
        }}
      </NotificationContext.Consumer>
)

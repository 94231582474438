import React, { useEffect } from 'react';
import { CalendarIcon, YoutubeIcon, WaistIcon, VideoIcon, UnderwearIcon, TwitterIcon, SwimIcon, SlimIcon, SideIcon, PhotoshootIcon, PhoneIcon, RunwayIcon, PencilIcon, PlusIcon, PartsIcon, NotifyIcon, MaleIcon, LengthIcon, InstagramIcon, FemaleIcon, EmailIcon, DeadlineIcon, CampaignsDateTimeIcon, BasicInfoIcon, ModelSpecsIcon, ApplicationSettingsIcon, CreateCampaignIcon, ApplicantsIcon, AthleticIcon, BirthdayIcon, CityIcon, CloseUpIcon, } from '../../Assets/CreateCampaign'
import Slider, { createSliderWithTooltip } from 'rc-slider';
import styled from 'styled-components'
const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-auto-rows: min-content;
  grid-row-gap: 12px;
  min-height: 100vh;
  height: 100%;
  background-color: #f6f8fa;
`;
const marks = {
  0: `min`,
  20: `5'2`,
  40: `5'6`,
  60: `5'10`,
  80: `6'2`,
  100: {
    style: {

    },
    label: `max`,
  },
};

const ModelSpecifications = ({ setInputValue, handleNextPage, onSliderChange, setModelSpec, handlePreviousPage, build, height, gender, tags }) => {
  useEffect(() => {
    if (build) {
      let div = document.getElementById("build-" + build);
      div.style.backgroundColor = 'rgba(255, 255, 255, 1)';
    }
    if (gender) {
      let div = document.getElementById("gender-" + gender);
      div.style.backgroundColor = 'rgba(255, 255, 255, 1)';
    }
    if (tags.length) {
      tags.forEach(tag => document.getElementById("tags-" + tag).style.backgroundColor = 'rgba(255, 255, 255, 1)')
    }
  });
  return (


    <Wrapper>
      <div style={{ gridRow: 2, textAlign: 'center', gridColumn: '1 / span 16' }}> Model Specifications </div>
      <div style={{ gridRow: 4, gridColumn: '3 / span 14' }}> Model Gender <div style={{ fontSize: 10, marginTop: 5 }}> Select All That Apply </div> </div>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3,1fr)', gridRow: 6, gridColumn: 3, gridColumnGap: 20 }}>
        <div id="gender-female" onClick={(e) => setModelSpec(e)} style={{ borderRadius: 5, width: 75, height: 65, display: 'grid', gridTemplateColumns: 'repeat(16,1fr)', gridTemplateRows: '40px 5px 20px', border: 'solid 1px black', backgroundColor: 'rgb(255, 255, 255, .01)' }}>
          <div style={{ gridRow: 1, gridColumn: '1 / span 16', alignSelf: 'flex-end', justifySelf: 'center' }}> <img src={FemaleIcon} style={{ width: 25, height: 30 }} /> </div>
          <div style={{ gridRow: 3, gridColumn: '1 / span 16', justifySelf: 'center', fontSize: 11 }}> Female </div>
        </div>
        <div id="gender-male" onClick={(e) => setModelSpec(e)} style={{ borderRadius: 5, width: 75, height: 65, display: 'grid', gridTemplateColumns: 'repeat(16,1fr)', gridTemplateRows: '40px 5px 20px', border: 'solid 1px black', backgroundColor: 'rgb(255, 255, 255, .01)' }}>
          <div style={{ gridRow: 1, gridColumn: '1 / span 16', alignSelf: 'flex-end', justifySelf: 'center' }}> <img src={MaleIcon} style={{ width: 25, height: 30 }} /> </div>
          <div style={{ gridRow: 3, gridColumn: '1 / span 16', justifySelf: 'center', fontSize: 11 }}> Male </div>
        </div>
        <div id="gender-all" onClick={(e) => setModelSpec(e)} style={{ borderRadius: 5, width: 75, height: 65, display: 'grid', gridTemplateColumns: 'repeat(16,1fr)', gridTemplateRows: '40px 5px 20px', border: 'solid 1px black', backgroundColor: 'rgb(255, 255, 255, .01)' }}>
          <div style={{ gridRow: 1, gridColumn: '1 / span 16', alignSelf: 'flex-end', justifySelf: 'center', fontSize: 12 }}> All </div>
        </div>
      </div>
      <div style={{ gridRow: 8, gridColumn: 3 }}> Model Build <div style={{ fontSize: 10 }}> Select all that apply. </div></div>

      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(16, minmax(50px,1fr))', gridRow: 10, gridColumn: '3 / span 3' }}>
        <div id="build-athletic" onClick={(e) => setModelSpec(e)} style={{ borderRadius: 5, width: 75, height: 65, gridColumn: 1, display: 'grid', gridTemplateColumns: 'repeat(16,1fr)', gridTemplateRows: '40px 5px 20px', border: 'solid 1px black', backgroundColor: 'rgb(255, 255, 255, .01)' }}>
          <div style={{ gridRow: 1, gridColumn: '1 / span 16', alignSelf: 'flex-end', justifySelf: 'center' }}> <img src={AthleticIcon} style={{ width: 25, height: 25 }} /></div>
          <div style={{ gridRow: 3, gridColumn: '1 / span 16', justifySelf: 'center', fontSize: 11 }}> Athletic </div>
        </div>
        <div id="build-plus" onClick={(e) => setModelSpec(e)} style={{ borderRadius: 5, width: 75, height: 65, gridColumn: 3, display: 'grid', gridTemplateColumns: 'repeat(16,1fr)', gridTemplateRows: '40px 5px 20px', border: 'solid 1px black', backgroundColor: 'rgb(255, 255, 255, .01)' }}>
          <div style={{ gridRow: 1, gridColumn: '1 / span 16', alignSelf: 'flex-end', justifySelf: 'center' }}> <img src={PlusIcon} style={{ width: 25, height: 25 }} /></div>
          <div style={{ gridRow: 3, gridColumn: '1 / span 16', justifySelf: 'center', fontSize: 11 }}> Plus </div>
        </div>
        <div id="build-slim" onClick={(e) => setModelSpec(e)} style={{ borderRadius: 5, marginTop: 10, width: 75, height: 65, gridColumn: 1, display: 'grid', gridTemplateColumns: 'repeat(16,1fr)', gridTemplateRows: '40px 5px 20px', border: 'solid 1px black', backgroundColor: 'rgb(255, 255, 255, .01)' }}>
          <div style={{ gridRow: 1, gridColumn: '1 / span 16', alignSelf: 'flex-end', justifySelf: 'center' }}> <img src={SlimIcon} style={{ width: 25, height: 25 }} /> </div>
          <div style={{ gridRow: 3, gridColumn: '1 / span 16', justifySelf: 'center', fontSize: 11 }}> Slim </div>
        </div>
        <div id="build-all" onClick={(e) => setModelSpec(e)} style={{ borderRadius: 5, marginTop: 10, width: 75, height: 65, gridColumn: 3, display: 'grid', gridTemplateColumns: 'repeat(16,1fr)', gridTemplateRows: '40px 5px 20px', border: 'solid 1px black', backgroundColor: 'rgb(255, 255, 255, .01)' }}>
          <div style={{ gridRow: 1, gridColumn: '1 / span 16', alignSelf: 'flex-end', justifySelf: 'center', fontSize: 12 }}> All </div>
        </div>
      </div>

      <div style={{ gridRow: 14, gridColumn: 3 }}> Height </div>
      <div style={{ gridRow: 16, gridColumn: 3, width: 200 }}>
        <Slider.Range
          defaultValue={[40, 60]}
          trackStyle={{ height: 10 }}
          handleStyle={{
            color: 'black',
            height: 28,
            width: 28,
            marginLeft: -14,
            marginTop: -9,

          }}
          marks={marks}
          railStyle={{ height: 10 }}

          onChange={(e) => onSliderChange(e) || console.log(e)}
          onAfterChange={(e) => onSliderChange(e)}
          value={height}
          allowCross={false}
        />
      </div>

      <div style={{ marginTop: 10, display: 'grid', gridTemplateColumns: 'repeat(16, minmax(50px,1fr))', gridRow: 18, gridColumn: '3 / span 3' }}>
        <div id="height-all" onClick={(e) => setModelSpec(e)} style={{ borderRadius: 5, width: 75, height: 65, gridColumn: 1, display: 'grid', gridTemplateColumns: 'repeat(16,1fr)', gridTemplateRows: '40px 5px 20px', border: 'solid 1px black', backgroundColor: 'rgb(255, 255, 255, .01)' }}>
          <div style={{ gridRow: 1, gridColumn: '1 / span 16', alignSelf: 'flex-end', justifySelf: 'center', fontSize: 12 }}> All </div>

        </div>
      </div>

      <div style={{ gridRow: 20, gridColumn: '3 / span 14' }}> Tags <div style={{ fontSize: 10, marginTop: 5 }}> Select all that apply </div> </div>

      <div className="CreateCampaignsPageTwoTagsSection" style={{}}>
        <div id="tags-runway" onClick={(e) => setModelSpec(e)} style={{ borderRadius: 5, width: 75, height: 65, display: 'grid', gridTemplateColumns: 'repeat(16,1fr)', gridTemplateRows: '40px 5px 20px', border: 'solid 1px black', backgroundColor: 'rgb(255, 255, 255, .01)' }}>
          <div style={{ gridRow: 1, gridColumn: '1 / span 16', alignSelf: 'flex-end', justifySelf: 'center' }}> <img src={RunwayIcon} style={{ width: 25, height: 25 }} /> </div>
          <div style={{ gridRow: 3, gridColumn: '1 / span 16', justifySelf: 'center', fontSize: 11 }}> Runway </div>
        </div>
        <div id="tags-photoshoot" onClick={(e) => setModelSpec(e)} style={{ borderRadius: 5, width: 75, height: 65, display: 'grid', gridTemplateColumns: 'repeat(16,1fr)', gridTemplateRows: '40px 5px 20px', border: 'solid 1px black', backgroundColor: 'rgb(255, 255, 255, .01)' }}>
          <div style={{ gridRow: 1, gridColumn: '1 / span 16', alignSelf: 'flex-end', justifySelf: 'center' }}> <img src={PhotoshootIcon} style={{ width: 30, }} /> </div>
          <div style={{ gridRow: 3, gridColumn: '1 / span 16', justifySelf: 'center', fontSize: 11 }}> Photoshoot </div>
        </div>
        <div id="tags-underwear" onClick={(e) => setModelSpec(e)} style={{ borderRadius: 5, width: 75, height: 65, display: 'grid', gridTemplateColumns: 'repeat(16,1fr)', gridTemplateRows: '40px 5px 20px', border: 'solid 1px black', backgroundColor: 'rgb(255, 255, 255, .01)' }}>
          <div style={{ gridRow: 1, gridColumn: '1 / span 16', alignSelf: 'flex-end', justifySelf: 'center' }}>  <img src={UnderwearIcon} style={{ width: 25, height: 30 }} />  </div>
          <div style={{ gridRow: 3, gridColumn: '1 / span 16', justifySelf: 'center', fontSize: 11 }}> Underwear </div>
        </div>
        <div id="tags-swimsuit" onClick={(e) => setModelSpec(e)} style={{ borderRadius: 5, width: 75, height: 65, display: 'grid', gridTemplateColumns: 'repeat(16,1fr)', gridTemplateRows: '40px 5px 20px', border: 'solid 1px black', backgroundColor: 'rgb(255, 255, 255, .01)' }}>
          <div style={{ gridRow: 1, gridColumn: '1 / span 16', alignSelf: 'flex-end', justifySelf: 'center' }}> <img src={SwimIcon} style={{ width: 25, height: 25 }} />  </div>
          <div style={{ gridRow: 3, gridColumn: '1 / span 16', justifySelf: 'center', fontSize: 11 }}> Swimsuit </div>
        </div>
        <div id="tags-parts" onClick={(e) => setModelSpec(e)} style={{ borderRadius: 5, width: 75, height: 65, display: 'grid', gridTemplateColumns: 'repeat(16,1fr)', gridTemplateRows: '40px 5px 20px', border: 'solid 1px black', backgroundColor: 'rgb(255, 255, 255, .01)' }}>
          <div style={{ gridRow: 1, gridColumn: '1 / span 16', alignSelf: 'flex-end', justifySelf: 'center' }}> <img src={PartsIcon} style={{ width: 25, height: 25 }} /> </div>
          <div style={{ gridRow: 3, gridColumn: '1 / span 16', justifySelf: 'center', fontSize: 11 }}> Parts </div>
        </div>
      </div>

      <div style={{ gridColumn: '3 / span 14', gridRow: 24, display: 'grid', gridTemplateColumns: 'repeat(16, 1fr)' }}>
        <div onClick={() => handlePreviousPage(1)} style={{ gridColumn: '1 / span 3', width: 100, height: 35, }}> <button style={{ cursor: 'pointer', width: '100%', height: '100%', backgroundColor: '#24292E', color: 'white' }}> Previous </button> </div>
        <div onClick={() => handleNextPage(3)} style={{ gridColumn: '12 / span 2', width: 100, height: 35, }}> <button style={{ cursor: 'pointer', width: '100%', height: '100%', backgroundColor: '#24292E', color: 'white' }}> Next </button> </div>
      </div>


    </Wrapper>

  )
}

export default ModelSpecifications;
import React, { useState } from 'react';
import styled from 'styled-components';
import { Question, UserInput, Button, Text, FlexStartFlexEnd } from '../../index';
import { FeaturesCircles, OptionsBoxes } from '../../index';
const Wrapper = styled.div`
    display: grid;
    grid-row-gap: 24px;
`
const Step10 = ({ setStep, step, realStep, modelDetails, setDetails, rSC }) => {
    if (step !== realStep) return null;
    const [error, setError] = useState('');
    return (
        <Wrapper>
            <Question step={step} question={'Select your sex.'} />
            <Text> It is important you provide accurate categorization to increase your selection chances.
Every data point is a chance to stand out.   </Text>

            <OptionsBoxes state={modelDetails.gender} callback={(gender) => setDetails({ ...modelDetails, gender })} values={['Male', 'Female']} />

            <Text color={'red'}> {error} </Text>
            <FlexStartFlexEnd>
                <Button onClick={() => {
                    if (modelDetails.gender) {
                        setStep(step + 1)
                    }
                    else {
                        setError('Select your sex.')
                    }

                }} width={'120px'}> Next </Button>
                {rSC()}
            </FlexStartFlexEnd>

        </Wrapper>
    )
}

export default Step10;
import React from 'react';
import styled from 'styled-components';
import { GenderAllIcon, CampaignsLocationIcon, CalendarIcon, URLIcon, YoutubeIcon, WaistIcon, VideoIcon, UnderwearIcon, TwitterIcon, SwimIcon, SlimIcon, SideIcon, PhotoshootIcon, PhoneIcon, RunwayIcon, PencilIcon, PlusIcon, PartsIcon, NotifyIcon, MaleIcon, LengthIcon, InstagramIcon, FemaleIcon, EmailIcon, DeadlineIcon, CampaignsDateTimeIcon, BasicInfoIcon, ModelSpecsIcon, ApplicationSettingsIcon, CreateCampaignIcon, ApplicantsIcon, AthleticIcon, BirthdayIcon, CityIcon, CloseUpIcon, } from '../../Assets/CreateCampaign'
import BrandIcon from "../../Assets/Modal/shop-1.svg";
import { Header, SubHeader, Boxes } from './shared';
const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    grid-auto-rows: min-content;
`
const GenderWrapper = styled.div`
    border-radius: 5px;
    width: 75px;
    height: 65px;
    display: grid; 
    grid-template-rows: 40px 5px 20px;
    border: solid 1px black;
    backgroundColor: rgba(255,255,255,1);
`

const Gender = ({ setGender }) => {
    return (
        <Wrapper style={{}}>
            <SubHeader> Choose Your Gender </SubHeader>
            <Boxes>
                <GenderWrapper id="gender-female" onClick={(e) => setGender(e)}>
                    <div id="gender-female" onClick={(e) => setGender(e)} style={{ gridRow: 1, alignSelf: 'flex-end', justifySelf: 'center' }}> 
                        <img id="gender-female" onClick={(e) => setGender(e)} src={FemaleIcon} style={{ width: 25, height: 30 }} /> 
                    </div>
                    <div id="gender-female" onClick={(e) => setGender(e)} style={{ gridRow: 3,  justifySelf: 'center', fontSize: 11, height: '100%' }}> 
                        Female 
                    </div>
                </GenderWrapper>
                <GenderWrapper id="gender-male" onClick={(e) => setGender(e)}>
                    <div id="gender-male" onClick={(e) => setGender(e)} style={{ gridRow: 1,  alignSelf: 'flex-end', justifySelf: 'center' }}> 
                        <img id="gender-male" onClick={(e) => setGender(e)} src={MaleIcon} style={{ width: 25, height: 30 }} /> 
                    </div>
                    <div id="gender-male" onClick={(e) => setGender(e)} style={{ gridRow: 3,  justifySelf: 'center', fontSize: 11, height: '100%' }}>
                         Male 
                    </div>
                </GenderWrapper>
            </Boxes>
        </Wrapper>
    )
}

export default Gender;
import React, { useState } from 'react';
import styled from 'styled-components';
import { Question, UserInput, Button, Text, SelectDropDown, PhotoUpload, FlexStartFlexEnd } from '../../index';
import { CloseUp } from '../../Assets';
const Wrapper = styled.div`
    display: grid;
    grid-row-gap: 24px;
`

const PhotoWrapper = styled.div`
    justify-self: center;
`
const CloseUpPhoto = ({ setStep, step, realStep, modelDetails, setDetails, rSC }) => {
    if (step !== realStep) return null;
    const [error, setError] = useState('');
    return (
        <Wrapper>
            <Question step={step} question={'Upload your close-up profile.'} />
            <Text> Please submit four photos of yourself: full-length, waist-up, close-up, and profile. Do your best to replicate the sample images found in the model guidelines.    </Text>
            <PhotoWrapper>
                <PhotoUpload
                    cb={(closeUp, closeUpPreview) => setDetails({ ...modelDetails, closeUp, closeUpPreview })}
                    state={modelDetails.closeUpPreview}
                    labelFor={'closeUp'}
                    icon={CloseUp}
                    text={"Click here to upload your close-up profile."} />
            </PhotoWrapper>

            <Text color={'red'}> {error} </Text>
            <FlexStartFlexEnd>
                <Button onClick={() => {
                    if (modelDetails.closeUp) {
                        setStep(step + 1)
                    }
                    else {
                        setError('Close-up is required.')
                    }

                }} width={'120px'}> Next </Button>
                {rSC()}
            </FlexStartFlexEnd>

        </Wrapper>
    )
}

export default CloseUpPhoto;
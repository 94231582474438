import React from 'react';
import styled from 'styled-components';
import Authenticated from './Authenticated';
import Unauthenticated from './Unauthenticated';
const Wrapper = styled.div`
    font-family: Trade Gothic !important;
`


const NavV2 = ({ history, user, logout, ...props }) => {
    if (
        props.location.pathname.includes('/apply') ||
        props.viewingModelProfile ||
        props.location.pathname.includes("/create-campaign") ||
        props.location.pathname.includes("apply") ||
        props.location.pathname.includes('campaigns/edit')
      ) return null;
    return (
        <Wrapper>
            {Object.keys(user).length ? <Authenticated logout={logout} user={user} history={history} /> : <Unauthenticated user={user} history={history} />}

        </Wrapper>
    )
}

export default NavV2;

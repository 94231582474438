import React from 'react';
import styled from 'styled-components';
import { Text } from '../../TypeForm'
import { campaigns, payments, notifications, discoverability, release, reviews } from './icons'
const Wrapper = styled.div`
    display: grid;
    padding: 0px 12px;
    justify-content: center;
    @media(max-width:414px) {
    }
`

const SubWrapper = styled.div`
    display: grid;
    grid-row-gap: 24px;
`

const BoxesSectionWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 94px;
    grid-column-gap: 25px;
    @media(max-width: 1200px) {
        grid-template-columns: 1fr 1fr;
    }
    @media(max-width: 800px) {
        grid-template-columns: 1fr;
    }
}`
const InfoBox = styled.div`
    width: 373px;
    height: 376px;
    border-radius: 10px;
    border: solid 1px #e1e1e1;
    display: grid;
    grid-template-rows: 1fr .5fr 2fr;
    align-content: center;
    grid-row-gap: 12px;
    padding: 0px 24px;
    background: #F9F9F9;
    @media(max-width: 414px) {
        width: 279px;
        justify-self: center;
    }
`

const TextWrapper = styled.div`
    display: grid;
    grid-row-gap: 12px;
    font-size: 35px;
    @media(max-width:414px) {
        font-size: 32px;
    }
`

const Section3 = () => {
    const icons = [campaigns, payments, notifications, discoverability, release, reviews]
    const headings = ['Create Campaigns', 'Automated Payments', 'Notifications', 'Discoverability', 'Model Release Forms', 'Reviews & Feedback']
    const descriptions = ['Create campaigns for advertisements, runway shows, commercials and more in minutes.', 'We pay all your talent as soon as the campaign ends. Pay for each campaign through one consolidated invoice.', 'Automatically alert models of changes to the campaign. Get notified of model confirmations, cancelations and payment.', 'Invite models from our curated catalog or share your campaigns on social platforms to source your own.', 'Track status of model release forms out for e-signature by campaign.', 'View model hire history and brands they’ve worked with. Leave feedback on how the campaign went.']
    return (
        <Wrapper className="features">
            <SubWrapper>

                <TextWrapper>
                    <Text color={'#000'} fontweight={'bold'} style={{ fontSize: '1em' }}>
                        Power Features
                </Text>
                    <Text color={'#000'} fontweight={'normal'} style={{ fontSize: '.5em' }}>
                        Features that you'll love and need at the same time
                </Text>
                </TextWrapper>
                <BoxesSectionWrapper>
                    {descriptions.map((item, i) => {
                        return (
                            <InfoBox key={i}>
                                <img style={{ alignSelf: 'center' }} src={icons[i]} />
                                <Text fontsize={'25px'} fontweight={'bold'}> {headings[i]} </Text>
                                <Text fontsize={'20px'} fontweight={'normal'}> {descriptions[i]} </Text>
                            </InfoBox>
                        )
                    })}
                </BoxesSectionWrapper>
            </SubWrapper>

        </Wrapper>
    )
}

export default Section3;
import axios from 'axios'
import { TWILIO_HOST_NAME } from '../../Config';
export const getModel = (phone) => {
    return axios.post(`${TWILIO_HOST_NAME}/get_model`, {
        phone
    })
}
export const applyWithRSA = (phone, code, campaignURL) => {
    return axios.post(`${TWILIO_HOST_NAME}/apply`, {
        phone,
        code,
        campaignURL
    })
}
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    !localStorage.getItem('user')
      ? <Component {...rest} {...props} />
      : <Redirect to='/campaigns' />
  )} />
)

export default PublicRoute;

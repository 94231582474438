import React from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Circle } from "react-google-maps"
import styled from 'styled-components'
import { Header, Subheader } from './shared'
const Wrapper = styled.div`
  display: grid;
  grid-auto-rows: auto;

`
const LocationRadius = withScriptjs(withGoogleMap(({ campaign, isMarkerShown = false }) => {
  const { geocode } = campaign;
  const { latitude, longitude } = geocode;
  const options = {
    strokeColor: '#FFF',
    strokeOpacity: 1,
    strokeWeight: 8,
    fillColor: '#FF4C4C',
    fillOpacity: 0.80,
  }
  return (
    <GoogleMap
      defaultZoom={13}
      defaultCenter={{ lat: latitude, lng: longitude }}
      width={'400px'}
    >
      {isMarkerShown && <Marker position={{ lat: latitude, lng: longitude }} />}
      <Circle
        radius={1000}
        center={{ lat: latitude, lng: longitude }}
        options={options}

      />
    </GoogleMap>
  )

}

))
export default LocationRadius
import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
    cursor: pointer;
    opacity: ${props => props.opacity};
    border-radius: 5px;
    border: ${props => props.border || 'solid 1px #000000'};
    background-color: ${props => props.backgroundColor || '#000000'};
    color: ${props => props.color || '#fff'}
    height: ${props => props.height || '40px'}
    display: grid;
    align-content: center;
    justify-content: center;
    text-transform: uppercase;
    @media (max-width: 900px) {
        font-size: 12px;
      }
    font-size: 16px;
    font-family: ${props => props.fontfamily || 'ProximaNova'};
    font-weight: ${props => props.fontweight || 600};
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.57px;
    width: ${props => props.width};
    max-width: ${props => props.maxwidth};
    padding: ${props => props.padding || '0px'};

`;

export default Button;
import React, { useState } from 'react';
import styled from 'styled-components';
import { Question, UserInput, Button, Text, FlexStartFlexEnd } from '../../index';
const Wrapper = styled.div`
    display: grid;
    grid-row-gap: 24px;
`
const Email = ({ setStep, step, realStep, modelDetails, setDetails, rSC }) => {
    if (step !== realStep) return null;
    const [error, setError] = useState('');
    return (
        <Wrapper>
            <Question step={step} question={'What is your email?'} />
            <UserInput value={modelDetails.email} callback={(value) => {
                setDetails({
                    ...modelDetails,
                    email: value
                })
            }} />
            <Text color={'red'}> {error} </Text>
            <FlexStartFlexEnd>
                <Button onClick={() => {
                    if (modelDetails.email) {
                        setStep(step + 1)
                    }
                    else {
                        setError('Email is required.')
                    }

                }} width={'120px'}> Next </Button>
                {rSC()}
            </FlexStartFlexEnd>
        </Wrapper>
    )
}

export default Email;
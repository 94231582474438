import React from "react";
import Loader from "react-loader-spinner";
const L = props => (
  <div
    style={{
      position: "fixed",
      zIndex: 999,
      height: "50px",
      width: "50px",
      overflow: "visible",
      margin: "auto",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0
    }}
  >
    {" "}
    <div style={{ }}>
      {" "}
      <Loader type="Oval" color="#24292E" height="50" width="50" />{" "}
    </div>{" "}
  </div>
);

export default L;

import React from 'react';
import styled from 'styled-components';
const Wrapper = styled.div`
    display: grid;
    grid-row-gap: 12px;
    text-transform: uppercase; 
    grid-auto-rows: min-content;
`


const CheckboxWrapper = styled.div`
    display: grid;
    grid-template-columns: min-content max-content;
    grid-row-gap: 6px;
    grid-column-gap: 6px;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    grid-auto-rows: min-content;
`

const Check = styled.input`

`

const Header = styled.h6`
    font-weight: 800;
    font-size: 20px;
    margin: 0;
    padding: 0;
`

const CheckboxFilters = ({ title, attributes = ['male','female'], value = "", cb }) => {
    return (
        <Wrapper>
            <Header> {title} </Header>
            <CheckboxWrapper>
                {attributes.map(attr => {
                    const checked = value.toLowerCase() === attr.toLowerCase();
                    return (
                        <React.Fragment>
                            <Check onClick={() => { 
                                if(checked) cb('')
                                else cb(attr) 
                            }} checked={checked} type="checkbox" />
                            <div> {attr} </div>
                        </React.Fragment>
                     
                    )
                })}                
            </CheckboxWrapper>
        </Wrapper>

    )
}

export default CheckboxFilters
import React, { useState } from 'react';
import styled from 'styled-components'
import { Text } from './index';
const Wrapper = styled.div`
    display: grid;
    grid-template-rows: min-content;
    grid-row-gap: 24px;
    justify-content: center;
`
const ChoiceWrapper = styled.div`
    border: solid 1px #e1e1e1;
    height: 60px;
    width: 200px;
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-column-gap: 24px;
    align-items: center;
    border-radius: 20px;
    padding: 0px 24px;
    cursor: pointer;
    background-color: ${props => props.active && '#333'}
`

const LetterBox = styled.div`
    display: grid;
    justify-content: center;
    align-content: center;
    border-radius: 10px;
    width: 25px;
    height: 25px;
    background-color: ${props => props.active && props.backgroundColor || '#fff'};
    border: ${props => props.active && 'solid 3px #333' || 'solid 1px #e1e1e1'};
`
// pass the value that is clicked into the callback and set the state from the parent component
const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I']
const Selects = ({ values = [], callback, state }) => {
    const defaultActive = values.indexOf(state) || false;
    const [active, setActive] = useState(defaultActive);
    const renderValues = () => {
        return values.map((value, index) => {
            return <ChoiceWrapper 
                        onClick={() => {
                            callback(value)
                            setActive(index)
                        }} 
                        active={Number(active) === Number(index)} 
                     
                    > 
                        <LetterBox backgroundColor={'#fff'}> {alphabet[index]} </LetterBox> 
                        <Text color={Number(active) === Number(index) ? '#fff' : '#333'}> {value} </Text> 
                 </ChoiceWrapper>
        })
    }
    return (
        <Wrapper>
            {renderValues()}
        </Wrapper>
    )
}

export default Selects;

/*

            */
import React, { useState } from 'react';
import ComingSoonIcon from "../../Assets/LandingPage/coming-soon-icon.png";
import RunwaySupplyIcon from "../../Assets/LandingPage/login-modal-logo.png";

import LoginCardIcon from "../../Assets/Modal/login-card-icon.png";
import LoginBrandIcon from "../../Assets/Modal/shop-1.svg";
import LoginCouponIcon from "../../Assets/Modal/coupon-cut.svg";
import LoginEmailIcon from "../../Assets/Modal/login-email-icon.png";
import LoginPasswordIcon from "../../Assets/Modal/lock-1.svg";
import { CardElement, injectStripe, Elements } from "react-stripe-elements";
import Modal from "react-responsive-modal";
import { toast } from "react-toastify";
import Loader from "../Loader";
import isEmail from "validator/lib/isEmail";
import axios from 'axios';
import { HOST_NAME, BRAND_SERVICES_HOST_NAME, AWS_SERVICES_HOST_NAME } from '../../Config';
import BrandLogoUploadModal from "../BrandLogoUploadModal";
const RegisterModal = ({
  history,
  setUser
}) => {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [uploading, setUploading] = useState(false);
  const [brandLogo, setBrandLogo] = useState(false);
  const [brandLogoPreview, setBrandLogoPreview] = useState(false);
  const [thisUser, setThisUser] = useState(false);
  const getUserFromDB = async id => {
    return axios.post(`${BRAND_SERVICES_HOST_NAME}/get_user`, {
      _id: id
    });
  };

  const uploadBrandLogo = async () => {
    const formData = new FormData();

    formData.append("_id", thisUser._id);
    formData.append("brandname", thisUser.brandname);
    formData.append("brandlogo", brandLogo);

    setLoading(true)
    try {
      const signedResponse = await axios
        .post(`${AWS_SERVICES_HOST_NAME}/create_signed_url_brand_logo`, {
          _id: thisUser._id,
          brandname: thisUser.brandname,
          fileType: brandLogo.type,
          fileName: brandLogo.name
        })
        const { url } = signedResponse.data;

        const upload = await axios.put(url, brandLogo, {
          headers: {
            "Content-Type": brandLogo.type,
            "x-amz-acl": "public-read"
          }
        })


      const r = await getUserFromDB(thisUser._id)
      setLoading(false)
      localStorage.setItem("user", JSON.stringify(r.data.user));
      setUser(r.data.user); // setUser is a function at the root of our App and that component stores user in state
      this.props.history.push("/campaigns"); // re route us to campaigns dashboard if user successfuly registered
    }
    catch (error) {

      setLoading(false)
      console.log(error);
    }
  };
  const setLogoFunc = e => {
    if (e.target.files && e.target.files.length > 0) {
      setBrandLogo(e.target.files[0])
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setBrandLogoPreview(reader.result)
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const register = async (token) => {
    const { brandname, email, password, cpassword, coupon } = formData || '';
    let exit = false;
    if (!brandname) {
      toast.error("Missing Brand Name 🤖.", {
        position: toast.POSITION.BOTTOM_CENTER
      });
      exit = true;
    }

    if (!email) {
      toast.error("Missing Email 🤖.", {
        position: toast.POSITION.BOTTOM_CENTER
      });
      exit = true;
    }

    if (email && !isEmail(email)) {
      toast.error("Invalid Email 🤖.", {
        position: toast.POSITION.BOTTOM_CENTER
      });
      exit = true;
    }

    if (password && password.length < 6) {
      toast.error("Password must be atleast 6 characters 🤖.", {
        position: toast.POSITION.BOTTOM_CENTER
      });
      exit = true;
    }

    if (password !== cpassword) {
      toast.error("Passwords do not match 🤖.", {
        position: toast.POSITION.BOTTOM_CENTER
      });
      exit = true;
    }

    if (!token) {
      toast.error("Invalid credit card 👻.", {
        position: toast.POSITION.BOTTOM_CENTER
      });
      exit = true;
    }

    if (exit) {
      return;
    }

    if (!brandname || !email || !password || !cpassword || !token) {
      //TODO: Need a error message here.
      return;
    }

    setLoading(true);
    try {
      const response = await axios
        .post(`${BRAND_SERVICES_HOST_NAME}/register`, {
          brandname,
          email,
          password,
          cpassword,
          coupon: coupon ? coupon.toUpperCase() : null,
          token
        })

      let { user } = response.data;
      //localStorage.setItem("user", JSON.stringify(user)); //we will save logged in user in localStorage
      setOpen(false)
      setLoading(false)
      setUser(user)
      localStorage.setItem('user', JSON.stringify(user));
      setThisUser(user);
      setUploading(true);


    }
    catch (error) {
      console.log(error);
      const { message } = error.response.data;
      setLoading(false)
      // TODO: create a error message on why registration failed
      toast.error(message, {
        position: toast.POSITION.BOTTOM_CENTER
      });

    };
  };
  if (uploading) return <BrandLogoUploadModal setUser={setUser} uploadBrandLogo={uploadBrandLogo} skipBrandLogo={() => {
    setUploading(false)
    history.push('/campaigns')
  }} user={thisUser} loading={loading} history={history} setBrandLogoPreview={setLogoFunc} brandLogoPreview={brandLogoPreview} />
  return (
    <Modal open={open} onClose={() => {
      setOpen(false)
      history.push("/")
    }} center>
      <div className="ModalGrid">
        <div className="DialogTitle" id="form-dialog-title">
          Join Runway Supply
        </div>
        <div className="RegistrationRunwaySupplyIcon">
          <img className="RunwaySupplyIconWidth" src={RunwaySupplyIcon} />
        </div>
        {!loading && (
          <div className="DialogContent">
            <div className="DialogContentInputContainer">
              <img style={{ height: 30 }} src={LoginBrandIcon} />
              <input
                placeholder="Brand Name"
                onChange={e => setFormData({ ...formData, "brandname": e.target.value })}
                value={formData.brandname}
              />
            </div>
            <div className="DialogContentInputContainer">
              <img src={LoginEmailIcon} />
              <input
                placeholder="Email"
                onChange={e => setFormData({ ...formData, "email": e.target.value })}
                value={formData.email}
              />
            </div>
            <div className="DialogContentInputContainer">
              <img style={{ height: 30 }} src={LoginPasswordIcon} />
              <input
                type="password"
                placeholder="Password"
                onChange={e => setFormData({ ...formData, "password": e.target.value })}
                value={formData.password}
              />
            </div>
            <div className="DialogContentInputContainer">
              <img style={{ height: 30 }} src={LoginPasswordIcon} />
              <input
                type="password"
                placeholder="Confirm Password"
                onChange={e => setFormData({ ...formData, "cpassword": e.target.value })}
                value={formData.cpassword}
              />
            </div>
            <div className="DialogContentInputContainer">
              <img style={{ height: 30 }} src={LoginCouponIcon} />
              <input
                placeholder="Coupon Code"
                onChange={e => setFormData({ ...formData, "coupon": e.target.value })}
                value={formData.coupon}
              />
            </div>
            <Elements>
              <StripeCheckoutForm register={register} />
            </Elements>
          </div>
        )}

        {!loading && (
          <div className="DialogContentTextLogin">
            Already a have an account?{" "}
            <span onClick={() => history.push("/login")}> Login </span>
          </div>
        )}

        {!loading && (
          <div className="DialogContextTextSignup">
            By signing up you accept our terms of service. Plans rebill unless
            cancelled. You will not be charged until you have launched your
            first campaign
          </div>
        )}
        {loading && <Loader />}
      </div>
    </Modal>
  );
};

class CardSection extends React.Component {
  render() {
    return (
      <div className="CardContainer">
        <CardElement
          style={{
            base: {
              fontSize: "16px",
              textTransform: "uppercase",
              fontFamily: "inherit",
              fontWeight: "bold"
            }
          }}
        />
      </div>
    );
  }
}

class CheckoutForm extends React.Component {
  handleSubmit = ev => {
    const { register } = this.props;
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();

    // Within the context of `Elements`, this call to createToken knows which Element to
    // tokenize, since there's only one in this group.

    this.props.stripe.createToken({}).then(({ token }) => {

      register(token);
    });

    // register("tok_visa"); use this for testing if you dont want to type 4242424242 over and over
    // However, this line of code will do the same thing:
    //
    // this.props.stripe.createToken({type: 'card', name: 'Jenny Rosen'});

    // You can also use createSource to create Sources. See our Sources
    // documentation for more: https://stripe.com/docs/stripe-js/reference#stripe-create-source
    //
    // this.props.stripe.createSource({type: 'card', owner: {
    //   name: 'Jenny Rosen'
    // }});
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <CardSection />
        <div style={{ marginTop: 20 }} className="DialogActions">
          <button type="submit">Join Now</button>
        </div>
      </form>
    );
  }
}

const StripeCheckoutForm = injectStripe(CheckoutForm);

export default RegisterModal;

import React, { Component } from 'react';
import axios from 'axios'
import Loader from './Loader';
import NotFound from './NotFound';
import ThuggerGIF from '../Assets/404thugger.gif';
import './ThankYouForApplying.css'
class ThankYouForApplying extends Component {
  render() {
    return (
      <div style={{ display:'grid', gridTemplateRows:'calc(100vh - 70px)', justifyContent:'center' }}>
        <div style={{ display:'grid', gridTemplateColumns:'max-content', gridColumnGap: 5, gridAutoRows: 50, marginTop: 'calc(100vh - 70px - 80vh)'}}>
          <div className="Thank-You-For-Applying-Main-Heading" style={{  fontWeight: 100, lineHeight: '2.4rem', letterSpacing: '.25rem',  alignSelf:'center', textAlign:'center'  }}>
            THANK YOU FOR APPLYING
          </div>
          <div className="Thank-You-For-Applying-Sub-Heading" style={{ fontWeight: 50, lineHeight: '2.4rem', letterSpacing: '.125rem',  alignSelf:'center'  }}>
            <div> IF THIS IS YOUR FIRST TIME APPLYING VERIFY YOUR EMAIL </div>
          </div>
          <div className="Thank-You-For-Applying-Sub-Heading" style={{ textAlign:'center', fontWeight: 50, lineHeight: '2.4rem', letterSpacing: '.125rem' }}> BY FOLLOWING THE LINK WE HAVE SENT YOU </div>
        </div>
      </div>
    )
  }
}

export default ThankYouForApplying

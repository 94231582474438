import ThuggerGIF from '../../Assets/404thugger.gif';
import styled from 'styled-components'
import React, { useEffect } from 'react';
import { Text } from './index';
const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    grid-auto-rows: min-content;
    grid-row-gap: 24px;
`

const GIF = styled.img``
const ThankYou = ({ text = 'Thank You For Joining Runway Supply', step, realStep, history }) => {
    useEffect(() => {
        let interval;
        if(step === realStep  && localStorage.getItem('campaignURLReroute')) {
             interval = setInterval(() => {
                history.push(`/apply/${localStorage.getItem('campaignURLReroute')}`);
            },2000)
        }
        return () => {
            if(interval)
            clearInterval(interval);
        }
    },[step])
    if(step === realStep) {
        return (
            <Wrapper>
                <Text fontsize={'24px'}> {text} </Text>
            </Wrapper>
        )
    }
    return null;

}
export default ThankYou
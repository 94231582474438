import axios from 'axios';
import { TWILIO_HOST_NAME } from '../../../../Config';

export const verifyModelSMS = async (phone, code) => {
  const response = await axios.post(`${TWILIO_HOST_NAME}/verify_model_sms`, {
    phone,
    code
  })
  console.log(response)
}

export const sendVerificationCode = async (phone) => {
    const response = await axios.get(`${TWILIO_HOST_NAME}/twilio/get_code/?number=${phone}`)
    console.log(response)
}

export const saveModel = async (urls, modelDetails) => {
  const {
        firstName,
        lastName,
        email,
        phone,
        dob,
        city,
        geocode,
        complexion,
        hairColor,
        eyeColor,
        gender,
        heightMeasurement,
        waistMeasurement,
        shoeMeasurement,
      } = modelDetails;
      const data = await axios.post(`${TWILIO_HOST_NAME}/save_model`, {
        urls,
        firstName,
        lastName,
        email,
        phone,
        dob,
        city,
        geocode,
        complexion,
        hairColor,
        eyeColor,
        gender,
        heightMeasurement,
        waistMeasurement,
        shoeMeasurement
      })
      console.log(data);



}
export const uploadImagesSignedUrl = async (
  files,
  modelName,
) => {
  const urls = await fillURLS(files, modelName);
  console.log(urls)
  return urls;
};



const fillURLS = async (files, modelName) => {
  let temp = [];
  for (let i = 0; i < files.length; i++) {
    if (files[i]) {
      // create the signed url
        const d1 = await axios
        .post(`${TWILIO_HOST_NAME}/twilio/create_signed_url`, {
          modelName,
          filename: files[i].name,
          fileType: files[i].type
        })
        await temp.push(d1.data.getURL);
        // actually put the photo in s3
        const d2 = await axios.put(d1.data.url, files[i], {
          headers: {
            "Content-Type": files[i].type,
            "x-amz-acl": "public-read"
          }
        })
    }
  }
  return temp;
}
import React, { useState } from 'react';
import styled from 'styled-components';
import { Question, UserInput, Button, Text, SelectDropDown, PhotoUpload, FlexStartFlexEnd } from '../../index';
import { FullLength } from '../../Assets';
const Wrapper = styled.div`
    display: grid;
    grid-row-gap: 24px;
`

const PhotoWrapper = styled.div`
    justify-self: center;
`
const FullLengthPhoto = ({ setStep, step, realStep, modelDetails, setDetails, rSC }) => {
    if (step !== realStep) return null;
    const [error, setError] = useState('');
    return (
        <Wrapper>
            <Question step={step} question={'Upload your full length profile.'} />
            <Text> Please submit four photos of yourself: full-length, waist-up, close-up, and profile. Do your best to replicate the sample images found in the model guidelines.    </Text>
            <PhotoWrapper>
                <PhotoUpload
                    cb={(fullLength, fullLengthPreview) => setDetails({ ...modelDetails, fullLength, fullLengthPreview })}
                    state={modelDetails.fullLengthPreview}
                    labelFor={'fullLength'}
                    icon={FullLength}
                    text={"Click here to upload your full-length profile."} />
            </PhotoWrapper>

            <Text color={'red'}> {error} </Text>
            <FlexStartFlexEnd>
                <Button onClick={() => {
                    if (modelDetails.fullLength) {
                        setStep(step + 1)
                    }
                    else {
                        setError('Full-length is required.')
                    }

                }} width={'120px'}> Next </Button>
                {rSC()}
            </FlexStartFlexEnd>

        </Wrapper>
    )
}

export default FullLengthPhoto;
import React, { useState } from 'react';
import styled from 'styled-components';
import { Question, UserInput, Button, Text, SelectDropDown, FlexStartFlexEnd } from '../../index';
const Wrapper = styled.div`
    display: grid;
    grid-row-gap: 24px;
`
const heights = ["5' 1''", "5' 1.5''", "5' 2''", "5' 2.5''", "5' 3''", "5' 3.5''", "5' 4''", "5' 4.5''", "5' 5''", "5' 5.5''", "5' 6''", "5' 6.5''", "5' 7", "5' 7.5''", "5' 8''", "5' 8.5''", "5' 9''", "5' 9.5''", "5' 10''", "5' 10.5''", "5' 11''", "5' 11.5''", "6' 0''", "6' 0.5''", "6' 1''", "6' 1.5''", "6' 2''", "6' 2.5''", "6' 3''", "6' 3.5''", "6' 4''", "6' 4.5''", "6' 5''", "6' 5.5''", "6' 6''", "6' 6.5''", "6' 7''", "6' 7.5''", "6' 8''", "6' 8.5''", "6' 9''", "6' 10''", "6' 10.5''", "6' 11''"]
const Height = ({ setStep, step, realStep, modelDetails, setDetails, rSC }) => {
    if (step !== realStep) return null;
    const [error, setError] = useState('');
    return (
        <Wrapper>
            <Question step={step} question={'What is your height measurement?'} />
            <Text> It is important you provide accurate categorization to increase your selection chances.
Every data point is a chance to stand out.   </Text>

            <SelectDropDown state={modelDetails.heightMeasurement} callback={(heightMeasurement) => setDetails({ ...modelDetails, heightMeasurement })} values={heights} />
            <Text color={'red'}> {error} </Text>
            <FlexStartFlexEnd>
                <Button onClick={() => {
                    if (modelDetails.heightMeasurement) {
                        setStep(step + 1)
                    }
                    else {
                        setError('Height is required.')
                    }

                }} width={'120px'}> Next </Button>
                {rSC()}
            </FlexStartFlexEnd>

        </Wrapper>
    )
}

export default Height;
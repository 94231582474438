import React, { Component, useState } from "react";
import Modal from "react-responsive-modal";
import RunwaySupplyIcon from "../Assets/LandingPage/login-modal-logo.png";
import BrandLogoUploadIcon from "../Assets/Modal/brand-logo-upload-Icon.png";
import Loader from "./Loader";
const BrandLogoUploadModal = ({
  setUser,
  uploadBrandLogo,
  skipBrandLogo,
  user,
  loading,
  history,
  setBrandLogoPreview,
  brandLogoPreview,
}) => {
  const [open, setOpen] = useState(true);
  return (
    <Modal open={user.brandLogo ? false : open} showCloseIcon={false}>
      {loading ? <Loader /> : null}
      <div
        style={{
          display: "grid",
          gridTemplateRows: "300px 100px",
          gridTemplateColumns: "repeat(16, 1fr)",
        }}
      >
        <div
          style={{
            gridRow: 1,
            gridColumn: "1 / span 3",
            justifySelf: "center",
            paddingTop: 30,
          }}
        >
          <img className="RunwaySupplyIconWidth" src={RunwaySupplyIcon} />
        </div>

        <div
          style={{
            gridRow: 1,
            gridColumn: "4 / span 10",
            justifySelf: "center",
          }}
        >
          BRAND LOGO UPLOAD
        </div>

        <div
          onClick={() => {
            document.getElementById("brandLogoUpload").click();
          }}
          style={{
            gridRow: 1,
            gridColumn: "4 / span 10",
            gridRow: 1,
            placeSelf: "center",
          }}
        >
          <img
            style={{ width: 200, display: brandLogoPreview ? "none" : "block" }}
            src={BrandLogoUploadIcon}
          />
          <img
            style={{
              objectFit: "cover",
              width: 200,
              height: 200,
              display: brandLogoPreview ? "block" : "none",
              borderRadius: "50%",
            }}
            src={brandLogoPreview}
          />
        </div>

        <div
          style={{
            gridColumn: "1 / -1",
            gridRow: 2,
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            gridColumnGap: 25,
          }}
        >
          <input
            accept="image/*"
            style={{
              width: ".1px",
              height: ".1px",
              opacity: 0,
              overflow: "hidden",
              zIndex: "-1",
            }}
            name="brandlogo"
            id="brandLogoUpload"
            type="file"
            onChange={(e) => setBrandLogoPreview(e)}
          />
          <div style={{ gridColumn: 2 }}>
            <button
              style={{ minWidth: 100 }}
              className="ProfileSaveInput"
              onClick={() => {}}
            >
              Confirm Upload
            </button>
          </div>
          <div style={{ gridColumn: 3 }}>
            {" "}
            <button
              onClick={() => {
                localStorage.setItem("user", JSON.stringify(user));
                setOpen(false);
                setUser(user); // setUser is a function at the root of our App and that component stores user in state
                history.push("/campaigns"); // re route us to campaigns dashboard if user successfuly registered
              }}
              style={{ backgroundColor: "#ffae42", minWidth: 100 }}
              className="ProfileSaveInput"
            >
              Skip
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default BrandLogoUploadModal;

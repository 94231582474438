import React from 'react';
import { Input } from '.';
import styled from 'styled-components'

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: min-content 1fr;
    padding: 0px 8px;
    align-items: center;
    border: solid 1px #e1e1e1;
    background-color: #fff;
    border-radius: 5px;
    height: ${props => props.height || '40px'};
    width: ${props => props.width};
`
const InputWithIcon = ({ icon, placeholder, disabled = false, value, onChange, onKeyUp, onKeyDown }) => {
    return (
        <Wrapper>
            <img src={icon} style={{ width: 20, height: 20 }} />
            <Input style={{ border: 'none', padding: '0px 24px' }} disabled={disabled} placeholder={placeholder} value={value} onChange={onChange} onKeyUp={onKeyUp} onKeyDown={onKeyDown} />
        </Wrapper>
    )
}

export default InputWithIcon
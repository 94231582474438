import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, 200px);
    justify-content: center;
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    height: max-content;
    max-width: 648px;
    margin: 0 auto;
    justify-items: center;
    @media(max-width: 1024px) {
        grid-template-columns: 1fr 1fr;
    }
    @media(max-width: 414px) {
        justify-items: inherit;
        grid-template-columns: repeat(auto-fit, min-max(150px, max-content));
    }
`

const Item = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 12px;
    align-=items: center;

`

const Icon = styled.img`
    height: 20px;
    width: 20px;
`

const CampaignMeta = ({ items }) => {
    return (
        <Wrapper>
            {items.map(item => <Item> <Icon src={item[1]} /> <div> {item[0]} </div> </Item> )}
        </Wrapper>
    )
}

export default CampaignMeta;
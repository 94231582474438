import React, { useState } from 'react';
import styled from 'styled-components';
import { Question, UserInput, Button, Text, FlexStartFlexEnd } from '../../index';
import {FeaturesCircles} from '../../index';
const Wrapper = styled.div`
    display: grid;
    grid-row-gap: 24px;
`
const Step8 = ({ setStep, step, realStep, modelDetails, setDetails, rSC }) => {
    if (step !== realStep) return null;
    const [error, setError] = useState('');
    return (
        <Wrapper>
            <Question step={step} question={'Select your hair color.'} />
            <Text> It is important you provide accurate categorization to increase your selection chances.
Every data point is a chance to stand out.   </Text>
            <FeaturesCircles state={modelDetails.hairColor} callback={(hairColor) => setDetails({ ...modelDetails, hairColor })} values={['#090806', '#2c222b', '#71635a','#6a4e42','#debc99','#b55239', '#dcd0ba']} />
            <Text color={'red'}> {error} </Text>
            <FlexStartFlexEnd>
                <Button onClick={() => {
                    if (modelDetails.hairColor) {
                        setStep(step + 1)
                    }
                    else {
                        setError('Select your hair color.')
                    }

                }} width={'120px'}> Next </Button>
                {rSC()}
            </FlexStartFlexEnd>
          
        </Wrapper>
    )
}

export default Step8;
import React from 'react';
import styled from 'styled-components';

const RunwaySupply = styled.h1`
    text-transform: uppercase;
    font-weight: 1000;
    font-size: 20px;
    align-self: center;
    color: ${props => props.color};
`

const Logo = ({ color }) => {
    return (
        <RunwaySupply color={color}> RunwaySupply </RunwaySupply>
    )
}

export default Logo;
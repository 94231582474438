import React from 'react';
import styled from 'styled-components'; 
import { Text } from './shared';

const Wrapper = styled.div`
    height: 838px;
    display: grid;
    justify-items: center;
    align-items: center;
    background-color: rgba(244, 209, 122, 0.26);
    font-family: Trade Gothic;
    @media(max-width: 414px) {
        width: 414px;
    }
`

const WhiteWrapper = styled.div`
    background-color: #fff;
    display: grid;
    grid-row-gap: 12px;
    width: 398px;
    height: 530px;
    padding: 20px 40px;
    @media(max-width: 414px) {
        width: 300px;
        padding: 20px 20px;
    }
`

const SubItemsWrapper = styled.div`
    display: grid;
    grid-row-gap: 12px;
    font-size: 18px;
`


const Button = styled.div`
    background-color: #000;
    color: #fff;
    text-align: center;
    display: grid;
    align-items: center;
    width: 322px;
    height: 64px;
    justify-self: center;
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
    @media(max-width: 414px) {
        width: 100%;
    }

`
const LeftSide = ({ history }) => {
    return (
        <Wrapper>
            <WhiteWrapper>
                <Text alignself={'center'} fontweight={'bold'} fontsize={'25px'}> ENTERPRISE </Text>
                <SubItemsWrapper>
                    <Text> All Standard Features + </Text>
                    <Text> Model Catalog Access </Text>
                    <Text> Deeper Campaign Analytics </Text>
                    <Text> SSO </Text>
                    <Text> Provisioning Access </Text>
                    <Text> Net Terms </Text>
                    <Text> Premium Support </Text>
                    <Text> And More! </Text>
                </SubItemsWrapper>
                <Button onClick={() => { 
                    window.location = "mailto:sales@runway.supply"
                }}> ONBOARD YOUR TEAM </Button>
            </WhiteWrapper>
        </Wrapper>
    )
}

export default LeftSide;
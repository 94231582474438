import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { Logo, Signup } from './';
import NavItem from './NavItem';
import Hamburger from '../../Assets/Nav/hamburger.svg'
import useOuterClickNotifier from '../../shared/functions/useOuterClickNotifier';
const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: solid 1px #e5e5e5;
    padding: 0px 0px 0px 68px;
    @media(max-width: 414px) {
        padding: 0px 0px;
    }
`

const AnchorWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    @media(max-width: 414px) {
        display: none;
    }
`

const HamburgerWrapper = styled.div`
    display: none;
    @media(max-width: 414px) {
        display: block;
        justify-self: flex-end;
        align-self: center;
        grid-column: 2;
        grid-row: 1;
        padding-right: 25px;
    }
`

const LogoWrapper = styled.div`
    display: grid;
    grid-row: 1;
    @media(max-width: 414px) {
        justify-items: center;
        grid-column: 1 / -1;
    }
`

const Menu = styled.div`
    background-color: #fff;
    border: solid 1px #e1e1e1;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1;
    height: 100%;   
    width: 200px;
    display: ${props => props.open ? 'grid' : 'none'};
    place-items: center;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    grid-row-gap: 12px;
    padding: 25px 0px 0px 0px;
    font-size: 30px;


`

const MenuItem = styled.div`
    color: #000;

`

const UnAuthenticated = ({ history }) => {
    const [selectedAnchor, setSelectedAnchor] = useState('For Brands');
    const [open, setOpen] = useState(false);
    const innerBoundaryRef = useRef(null);
    useOuterClickNotifier(
        e => { if(open) setOpen(false) },
        () => innerBoundaryRef.current
    );
    return (
        <Wrapper>
            <LogoWrapper>
               <Logo />
            </LogoWrapper>
            <AnchorWrapper>
                <NavItem setter={setSelectedAnchor} setterValue={selectedAnchor} text={'For Brands'} fontweight={'bold'} />
                <NavItem onClick={() => history.push('/apply')} setter={setSelectedAnchor} setterValue={selectedAnchor} text={'For Models'} fontweight={'bold'} />
                <Signup history={history} />
            </AnchorWrapper>
            <HamburgerWrapper ref={innerBoundaryRef} onClick={() => setOpen(true)}>
                <img src={Hamburger} onClick={() => setOpen(true) || console.log('clicked')} />
            </HamburgerWrapper>
            <Menu open={open} ref={innerBoundaryRef}>
            <NavItem onClick={() => history.push('/login')} setter={setSelectedAnchor} setterValue={selectedAnchor} text={'Login'} fontweight={'bold'} />
                <NavItem onClick={() => history.push('/register')} setter={setSelectedAnchor} setterValue={selectedAnchor} text={'Register'} fontweight={'bold'} />
                <NavItem onClick={() => history.push('/')} setter={setSelectedAnchor} setterValue={selectedAnchor} text={'For Brands'} fontweight={'bold'}/>
                <NavItem onClick={() => history.push('/apply')} setter={setSelectedAnchor} setterValue={selectedAnchor} text={'For Models'} fontweight={'bold'} />
                <NavItem onClick={() => history.push('/modelguidelines')} setter={setSelectedAnchor} setterValue={selectedAnchor} text={'Model Guidelines'} fontweight={'bold'} />

                

            </Menu>
        </Wrapper>

    )
}

export default UnAuthenticated;
import React, { useState } from 'react';
import styled from 'styled-components';
import { Question, UserInput, Button, Text, FlexStartFlexEnd } from '../../index';
import { LocationAutoCompleteInput } from '../../../../shared/index'
import isPhone from 'validator/lib/isMobilePhone'
import { TWILIO_HOST_NAME } from '../../../../Config';
import { uploadImagesSignedUrl, saveModel, sendVerificationCode } from './Functions';
import axios from 'axios';
const Wrapper = styled.div`
    display: grid;
    grid-row-gap: 24px;
`
const Step5 = ({ setStep, step, realStep, modelDetails, setDetails, rSC }) => {
    if (step !== realStep) return null;
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    return (
        <Wrapper>
            <Question step={step} question={'And what is your mobile number?'} />
            <Text> We’ll send you a verification code to make sure you can
always access your account and notify you about campaign details/changes. </Text>
            <UserInput
                value={modelDetails.phoneFormatted}
                id="phone" placeholder="(888) 888-8888" callback={(phoneFormatted, phone) => setDetails({ ...modelDetails, phoneFormatted, phone })}
                setError={setError}
            />

            {loading ? <Text color="blue"> Loading... </Text> : null}
            <Text color={'red'}> {error} </Text>
            <FlexStartFlexEnd>
                <Button onClick={async () => {
                    if (modelDetails.phone.match(/\d/g).length === 10) {
                        const { fullLength, closeUp, waistUp, sideProfile, } = modelDetails;
                        try {
                            //todo potentially just save urls on model details and avoid hitting the endpoint twice
                            let urls = null;
                            setLoading(true)
                            urls = await uploadImagesSignedUrl([fullLength, closeUp, waistUp, sideProfile], `${modelDetails.firstName}_${modelDetails.lastName}`)
                            //setDetails({ ...modelDetails, urls })
                            console.log('check what this is ', { ...modelDetails })
                            await saveModel(urls, { ...modelDetails })
                            await sendVerificationCode(modelDetails.phone)
                            setStep(step + 1)
                        }
                        catch (error) {
                            if (error.response) {
                                /*
                                 * The request was made and the server responded with a
                                 * status code that falls out of the range of 2xx
                                 */
                                console.log(error.response.data);
                                console.log(error.response.status);
                                console.log(error.response.headers);
                                setError(error.response.data.message)
                            } else if (error.request) {
                                /*
                                 * The request was made but no response was received, `error.request`
                                 * is an instance of XMLHttpRequest in the browser and an instance
                                 * of http.ClientRequest in Node.js
                                 */
                                console.log(error.request);
                                setError('Error processing your request please try again later')
                            } else {
                                // Something happened in setting up the request and triggered an Error
                                console.log('Error', error.message);
                                setError(error.message)
                            }
                        }
                        finally {
                            setLoading(false);
                        }



                    }
                    else {
                        setError('Please enter a valid phone number.')
                    }

                }} width={'120px'}> Next </Button>
                {rSC()}
            </FlexStartFlexEnd>

        </Wrapper>
    )
}

export default Step5;

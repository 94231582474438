import styled from 'styled-components';
const Button = styled.button`
    width: ${props => props.width && props.width || '120px'};
    height: ${props => props.height && props.height || '40px'};
    background-color: ${props => props.backgroundColor === "submit" && '#ff4742;' || props.backgroundColor && props.backgroundColor || '#fff'};
    color: ${props => props.color && props.color || '#000'};
    border: ${props => props.border && props.border || 'solid 1px #e1e1e1'};
    border-radius: ${props => props.borderRadius && props.borderRadius || '5px'}
    font-size: ${props => props.fontSize && props.fontSize || '16px'};
    font-weight: ${props => props.fontWeight}
    cursor: pointer;
`
export default Button;
import React from 'react';
import styled from 'styled-components';
import { Repeat } from '../../Assets/Misc';
const ToggleWrapper = styled.div`
    display: grid;
    grid-template-columns: max-content min-content;
    justify-content: center;
    background: #fff;
    border: solid 1px #e1e1e1;
    width: auto-fit;
    border-radius: 5px;
    justify-self: flex-end;
    padding: 4px 8px;
    align-items: center;
    cursor: pointer;
`

const Icon = styled.img`

`

const Toggle = ({ photoIndex, setPhotoIndex }) => {
    return (
        <React.Fragment>
            <ToggleWrapper onClick={() => setPhotoIndex(photoIndex + 1)}>
                <div> Toggle Photos </div>
                <Icon src={Repeat} />
            </ToggleWrapper>
        </React.Fragment>

    )
}

export default Toggle;
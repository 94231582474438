import React, { useState } from 'react';
import Modal from "react-responsive-modal";
import { toast } from "react-toastify";
import RunwaySupplyIcon from "../../Assets/LandingPage/login-modal-logo.png";
import LoginEmailIcon from "../../Assets/Modal/login-email-icon.png";
import axios from 'axios';
import { BRAND_SERVICES_HOST_NAME } from '../../Config';
const ForgotPasswordModal = ({
  history
}) => {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('')
  const handleForgotPassword = async () => {
    /*
      if email exists
      hit api/auth/forgotpassword
      send them the email with token
      they copy and paste the token to their url
      a call is made to /auth/reset_password/{token}
      if valid token reset password otherwise error message
    */
    try {
      setLoading(true)
      const res = await axios.post(`${BRAND_SERVICES_HOST_NAME}/reset_password_request`, { email })
      setLoading(false)
      toast.success("An email has been sent to reset your password 👻.", {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
    catch (error) {
      console.log(error)
      setLoading(false);
      toast.error(
        "We could not find an account registered to that email 🤖.",
        {
          position: toast.POSITION.BOTTOM_CENTER
        }
      );
    }
  };
  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen()
        history.push("/")
      }}
      center
    >
      <div className="ForgotPasswordModalGrid">
        <div className="ForgotPasswordDialogTitle" id="form-dialog-title">
          Reset Password
        </div>
        <div className="ForgotPasswordRunwaySupplyIcon">
          <img
            className="ForgotPasswordRunwaySupplyIconWidth"
            src={RunwaySupplyIcon}
          />
        </div>
        <div className="ForgotPasswordDialogContent">
          <div className="DialogContentInputContainer">
            <img src={LoginEmailIcon} />
            <input
              placeholder="Email"
              onChange={e => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="ForgotPasswordDialogActions">
          <button onClick={() => handleForgotPassword()}>Reset</button>
        </div>
        <div className="DialogContentTextLogin" style={{ gridRow: 4, gridColumn: '1 / span 16', textTransform: 'uppercase', fontSize: 14 }}>
          Back to
          <span onClick={() => history.push("/login")}> Login </span>
        </div>
      </div>
    </Modal>
  );
};

export default ForgotPasswordModal

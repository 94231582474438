import React from 'react';
import styled from 'styled-components';
import RightSide from './RightSide';
import LeftSide from './LeftSide.js'
const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 60px;
    min-height: 750px;
    padding: 60px 12px 0px 12px;
    @media(max-width:414px) {
        grid-template-columns: 1fr 1fr;
        min-height: 550px;

        grid-column-gap: 12px;
    }

`

const RightWrapper = styled.div`
    display: grid;
    grid-auto-columns: 100px;
`

const Section1 = ({...props}) => {
    return (
        <Wrapper>
            <LeftSide {...props} />
            <RightSide  />
        </Wrapper>
    )
}

export default Section1;
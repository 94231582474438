import React, { useState } from 'react';
import './Navbar.css';
import ComingSoonIcon from '../../Assets/LandingPage/coming-soon-icon.png'
import DefaultBrandLogo from '../../Assets/Nav/default-brand-logo.png'
import DefaulProfilePictureIcon from '../../Assets/Nav/default-profile-picture-icon.png'
import { Link } from 'react-router-dom'
import Signup from '../Signup';
import NotifcationBell from '../../Assets/Nav/notification-bell-icon.svg';
import io from 'socket.io-client'
import axios from 'axios';
import { slide as Menu } from 'react-burger-menu'
import { HOST_NAME } from '../../Config';
import MenuIcon from '../../Assets/Nav/menu-Icon.svg'
import Hamburger from '../../Assets/Nav/hamburger.svg'
import { NotificationContext } from '../../App'
import styled from 'styled-components';
import { Button } from '../TypeForm'
import { X } from '../TypeForm/Assets'
const img = 'https://scontent-sjc3-1.cdninstagram.com/vp/e15eceb2847abac2bd455433dc174ee8/5CC90E4C/t51.2885-15/e35/49933470_509324082926956_7208771734846260996_n.jpg?_nc_ht=scontent-sjc3-1.cdninstagram.com'
const Navbar = (props) => {
  if (
    props.location.pathname.includes('/apply') ||
    props.viewingModelProfile ||
    props.location.pathname.includes("/create-campaign") ||
    props.location.pathname.includes("apply") ||
    props.location.pathname.includes('campaigns/edit')
  ) return null;
  let goToFeatures = () => {
    if (document.getElementById('features')) document.getElementById('features').scrollIntoView({ behavior: "smooth" });
  }

  let goToPricing = () => {
    if (document.getElementById('pricing')) document.getElementById('pricing').scrollIntoView({ behavior: "smooth" });

  }

  let goToCampaignMarketplace = () => {
    if (document.getElementById('CampaignsMarketplace')) document.getElementById('CampaignsMarketplace').scrollIntoView({ behavior: "smooth" });
  }



  return (
    <React.Fragment>
      {props.user ? <NavbarAuthenticated {...props} /> : <NavbarUnprotected goToCampaignMarketplace={goToCampaignMarketplace} goToFeatures={goToFeatures} goToPricing={goToPricing} {...props} />}
    </React.Fragment>
  )
}

class NavbarAuthenticated extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notification: false,
      notifications: [],
      active: 7,
      open: false,

    }
    /*
    const socket = process.env.NODE_ENV === 'production' ? io.connect('https://api.runway.supply', { transports: ['websocket'] }) : io.connect('ws://localhost:9090', { transports: ['websocket'] })
    //io.connect(process.env.NODE_ENV === 'production' ?  (, { transports: ['websocket'] }) :  );
    socket.on('connection', () => {

    })

    socket.on('notification', (data) => {
      if(props.user && data.user._id === props.user._id) {
        this.setNotification(data.notification);
      }


    }) */
  }

  componentDidMount() {
    if (this.props.location.pathname.includes("/network")) this.setState({ active: 9 })
    // get notifications here
    /* axios.post(`${HOST_NAME}/api/notifications/get_new_notifications`, {
      brandID: this.props.user._id
    })
      .then((res) => {
        let { notifications } = res.data;
        notifications.concat(this.state.notifications)
        this.setState({ notifications })
      }) */
  }

  setNotification = (notification) => {
    const { notifications } = this.state;
    notifications.push(notification)
    this.setState((state) => ({ notification: true, notifications }))
  }

  handleViewedNotifications = (id) => {
    axios.post(`${HOST_NAME}/api/notifications/set_notifications_status_read`, {
      notificationID: id
    })
      .then(res => {

        let { notifications } = this.state;
        notifications = notifications.filter((notification) => notification._id !== id);

        this.setState({ notifications })
      })
      .catch(err => {
        console.log(err)
      })
  }

  clearNotifications = () => {
    axios.post(`${HOST_NAME}/api/notifications/clear_all_notifications`, {
      brandID: this.props.user._id
    })
      .then((res) => {

        this.setState({ notifications: [] })
      })
      .catch(err => {
        console.log(err);
      })
  }
  removeSelectedLink = () => {
    console.log(document.getElementById('Campaigns-Link'))
    if (document.getElementById('Campaigns-Link') && !document.getElementById('Campaigns-Link').style.display || this.props.location.pathname.includes("/profile")) {
      return 'none'
    }
    else return 'block';
  }

  renderGridColumn = () => {
    console.log(document.getElementById('Campaigns-Link'))

    if (this.props.location.pathname.includes("/campaigns") && window.innerWidth > 600) {
      return 7
    }
    else if (this.props.location.pathname.includes("/network") && window.innerWidth > 600)
      return 9
    else return false;
  }

  select = () => {
    this.setState({ open: false })
  }

  handleStateChange(state) {
    this.setState({ open: state.isOpen })
  }

  render() {
    const { notifications } = this.state;

    console.log(this.props.location.pathname.includes("apply"));
    if (this.props.location.pathname.includes("/apply")) return null;
    return (
      <div className="AuthenticatedNavbarContainer" id="AuthenticatedNavbarContainer">
        <div className="Logo"> <Link to="/"> Runway Supply </Link> </div>
        <div className="CampaignsLink" id="Campaigns-Link" style={{ textAlign: 'center' }}>
          <Link to="/campaigns"> Campaigns </Link>
        </div>


        <div className="RunwaySupplyMobileHeaderLink"> {this.props.location.pathname.includes("/network") ? 'Model Network' : 'RUNWAYSUPPLY'} </div>

        <div
          id="Selected-Link"
          style={{
            display: this.renderGridColumn() !== false ? "block" : "none",
            gridColumn: this.renderGridColumn(),
            gridRow: 1,
            backgroundColor: "#FFCC66",
            borderRadius: 7,
            width: "100%",
            height: 7,
            alignSelf: "flex-end"
          }}
        />


        <div className="NetworkLink" style={{ textAlign: 'center' }}>
          <Link to="/network"> Network </Link>
        </div>
        <div className="NotificationsLink">
          <div style={{ display: 'grid', gridTemplateColumns: '27px 15px', gridColumnGap: 1 }}>
            <div onClick={() => {

              let modal = document.getElementById('notification-modal');

              if (modal) {
                this.props.handleNotifcationModal();
                modal.style.display === "grid" ? modal.style.display = "none" : modal.style.display = "grid";
              }

            }}> <img src={NotifcationBell} /> </div>
            <div style={{ display: notifications.length ? 'block' : 'none', backgroundColor: 'red', width: 18, height: 14, borderRadius: 10, fontSize: 11, color: 'white', textAlign: 'center' }}>
              {notifications.length ? notifications.length : null}
            </div>
          </div>

        </div>

        <div
          className="Notifications-Modal-Container"
          style={{

            display: "none",
            backgroundColor: "#fff",
            gridTemplateColumns: 'repeat(1,max-content)',
            border: ".1px solid black",
            position: 'absolute',
            justifySelf: 'center',
            zIndex: 1000,
            gridAutoRows: 'auto',
            gridRowGap: 5,
            top: 70
          }}
          id={`notification-modal`}
        >
          {notifications.length ?
            <div onClick={this.clearNotifications} style={{ justifySelf: 'flex-end', color: 'red', gridRow: 1, paddingRight: 10 }}>
              Clear
               </div> : null
          }

        </div>

        <div className="NavPhoto">
          <div onClick={() => {
            let modal = document.getElementById('user-modal');

            if (modal) {
              this.props.handleProfileModal();
              modal.style.display === "grid" ? modal.style.display = "none" : modal.style.display = "grid";
            }
          }}>
            <img src={this.props.user.brandLogo ? this.props.user.brandLogo : DefaultBrandLogo} alt="Account" />
          </div>
          <div
            style={{
              marginBottom: "-45px",
              marginRight: "-10px",
              alignItems: "center",
              display: "none",
              backgroundColor: "#fff",
              gridTemplateColumns: "100px",
              border: ".5px solid black",
              position: 'absolute'
            }}
            id={`user-modal`}
          >
            <div style={{ paddingTop: 7, paddingLeft: 5, paddingBottom: 2, }} onClick={() => this.props.history.push("/profile")}> Account </div>
            <div style={{ paddingTop: 7, paddingLeft: 5, paddingBottom: 2, color: 'red' }} onClick={() => this.props.logout()}> Logout </div>
          </div>
        </div>
        <Menu customBurgerIcon={<img src={MenuIcon} />} isOpen={this.state.open} style={{ height: '100%' }} onStateChange={(state) => this.handleStateChange(state)}>

          <div className="Hamburger-Navigation-Links" style={{ display: 'grid' }}>
            <div style={{ alignSelf: 'center', paddingLeft: 10 }}>
              <div style={{ display: 'grid', gridTemplateColumns: '100px max-content' }}>

                <div>
                  <img className="BrandLogoMobile" src={this.props.user.brandLogo ? this.props.user.brandLogo : DefaultBrandLogo} alt="Brand Logo" />
                </div>
                <div style={{ alignSelf: 'center', textTransform: 'uppercase' }}>
                  {this.props.user.brandname}
                </div>
              </div>

            </div>
            <div className="Hamburger-Navigation-Links-Border-Item-One">
              <div onClick={() => this.setState({ open: false })} style={{ display: 'grid', alignSelf: 'center', height: '100%', alignItems: 'center' }}>
                <Link to="/campaigns" style={{ textTransform: 'capitalize', }}> Campaigns </Link>
              </div>
            </div>
            <div className="Hamburger-Navigation-Links-Border-Item-General">
              <div onClick={() => this.setState({ open: false })} style={{ display: 'grid', alignSelf: 'center', height: '100%', alignItems: 'center' }}>
                <Link to="/network" style={{ textTransform: 'capitalize' }}>  Network </Link>
              </div>
            </div>
            <div className="Hamburger-Navigation-Links-Border-Item-General" style={{}}>
              <div onClick={() => this.setState({ open: false })} style={{ display: 'grid', alignSelf: 'center', height: '100%', alignItems: 'center' }}>
                <Link to={`/profile`} style={{ textTransform: 'capitalize' }}>  Account </Link>
              </div>
            </div>
            <div className="Hamburger-Navigation-Links-Border-Item-Last" style={{}}>
              <div style={{ display: 'grid', alignSelf: 'center', height: '100%', alignItems: 'center' }}>
                <div onClick={() => this.props.logout()} style={{ textTransform: 'inherit', color: '#ff3232', }}>  Logout </div>
              </div>
            </div>
            <div style={{ justifySelf: 'center', alignSelf: 'center' }}> RUNWAY SUPPLY v1.0</div>
          </div>
        </Menu>
      </div>
    )
  }
}

const NavbarContainerAbout = styled.div`
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(100px, 1fr) );
    grid-row-gap: 12px;
    padding: 20px 15px
    grid-auto-rows: min-content;
    justify-items: center;
    align-items: center;
    @media(max-width: 750px) {
      display: none;
    }
`;

//REGULAR LOGO STYLES
const Logo = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  font-size: 20px;
`;





const SubItem = styled.div`
  justify-self: center;
  cursor: pointer;
  @media(max-width: 750px) {
    font-size: 20px;
    border-top: solid 1px #e1e1e1;
    justify-self: left;
    width: 100%;
    display: grid;
    align-items: center;
    height: 60px;
    text-align: center;
  }
`;




const NavbarUnprotected = (props) => {
  return (
    <>
      <NBUWeb {...props} /> 
      <NBUMobile {...props} />
    </>
  )
}

const NBUWeb = (props) => {
  return (
    <NavbarContainerAbout>
      <Logo> <Link to="/"> Runway Supply </Link> </Logo>
        <SubItem onClick={async () => {
          await props.history.push('/')
          await props.goToFeatures()
        }}>  Features </SubItem>
        <SubItem onClick={async () => {
          await props.history.push('/')
          await props.goToCampaignMarketplace()
        }}> Campaigns </SubItem>
        <SubItem onClick={async () => {
          await props.history.push('/')
          await props.goToPricing()
          }}> Pricing </SubItem>
        <SubItem onClick={() => props.history.push('/apply')}> Models </SubItem>
        <SubItem onClick={() => props.history.push('/modelguidelines')}> Model Guidelines </SubItem>
        <Button width={'80px'} height={'30px'} backgroundColor={'#FFF'} color={'#000'} onClick={() => props.history.push('/register')}> Sign up </Button>
    </NavbarContainerAbout>
  )
}

const NBUMobile = (props) => {
  const [open, setOpen] = useState(false);
  return (
    <UnprotectedWrapper>
    <Logo> <Link to="/"> Runway Supply </Link> </Logo>
    <SideNav open={open}>
      <img onClick={() => setOpen(false)} style={{ position: 'absolute', right: '10px', top: '5px' }} src={X} />
      <SubItem onClick={async () => {
        await props.history.push('/')
        await props.goToFeatures()
      }}>  Features </SubItem>
      <SubItem onClick={async () => {
        await props.history.push('/')
        await props.goToCampaignMarketplace()
      }}> Campaigns </SubItem>
      <SubItem onClick={async () => {
        await props.history.push('/')
        await props.goToPricing()
        }}> Pricing </SubItem>
      <SubItem onClick={() => props.history.push('/apply')}> Models </SubItem>
      <SubItem onClick={() => props.history.push('/modelguidelines')}> Model Guidelines </SubItem>
    </SideNav>
    <Button width={'80px'} height={'30px'} backgroundColor={'#FFF'} color={'#000'} onClick={() => props.history.push('/register')}> Sign up </Button>
    <HamburgerWrapper onClick={() => setOpen(!open)} src={Hamburger} />

  </UnprotectedWrapper>
  )
}

const UnprotectedWrapper = styled.div`
  display: grid;
  grid-auto-rows: 80px;
  align-items: center;
  grid-template-columns: 1fr 80px minmax(min-content,60px);
  grid-column-gap: 24px;
  padding: 12px;
  @media(min-width:750px) {
    display: none;
  }
`

const SideNav = styled.div`
  @media(max-width: 750px) {
    display: ${props => props.open ? 'grid' : 'none'};
    grid-auto-rows: min-content;
    width: 250px;
    position: fixed;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0.1, 0.1, 0.1, 0.1) 20px 4px 20px 0px;
    border: solid 1px #e1e1e1;
    overflow-x: hidden;
    height: 100%;
    min-height: 100vh;
    top: 0px;
    right: 0px;
    z-index: 1000;
    transition: all 0.3s ease 0s;
    padding: 0px 0px;
    align-items: center;
    & ${SideNav}:last-child {
      border-bottom: solid 1px #e1e1e1;
    }
  }

  display: none;
`

const HamburgerWrapper = styled.img`
  justify-self: center;
`



export default Navbar;

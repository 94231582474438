import React from 'react';

import styled from 'styled-components'

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    & ${Wrapper}:nth-child(2) {
        justify-self: flex-end;
    }
`

// takes two children
const FlexStartFlexEnd = ({ children }) => {
    return (
        <Wrapper>
            {children}
        </Wrapper>
    )

}

export default FlexStartFlexEnd;
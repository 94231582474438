import React from 'react';
import styled from 'styled-components'

const Text = styled.div`
    font-size: ${props => props.fontsize || '14px'};
    font-weight: ${props => props.fontweight || '600'};
    color: ${props =>  props.color || '#454545'};
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    word-break: break-word;
    text-align: ${props => props.textalign};
    height: ${props => props.height};
    padding: ${props => props.padding};
    grid-column: ${props => props.gridColumn};
    grid-row: ${props => props.gridRow};
    font-family: ${props => props.fontFamily};
`

export default Text;
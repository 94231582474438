import React, { useState } from 'react';
import styled from 'styled-components';
import { Question, UserInput, Button, Text, FlexStartFlexEnd } from '../../index';
import axios from 'axios';
import { TWILIO_HOST_NAME } from '../../../../Config';
import { verifyModelSMS } from './functions';
const Wrapper = styled.div`
    display: grid;
    grid-row-gap: 24px;
`
const VerificationCode = ({ setStep, step, realStep, modelDetails, setDetails, rSC }) => {
    if (step !== realStep) return null;
    const [error, setError] = useState('');
    return (
        <Wrapper>
            <Question step={step} question={'Enter your verification code.'} />
            <UserInput
                value={modelDetails.code}
                callback={(value) => {
                    setDetails({
                        ...modelDetails,
                        code: value
                    })
                }} />
            <Text color={'red'}> {error} </Text>
            <FlexStartFlexEnd>
                <Button onClick={async () => {
                    try {
                        await verifyModelSMS(modelDetails.phone, modelDetails.code)
                        /*
                        axios.post(`${TWILIO_HOST_NAME}/verify_model_sms`, {
                            phone: modelDetails.phone,
                            code: modelDetails.code
                        }) */
                        setStep(step + 1);
                    }
                    catch (error) {
                        if (error.response) {
                            /*
                             * The request was made and the server responded with a
                             * status code that falls out of the range of 2xx
                             */
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                            setError(error.response.data.message)
                        }
                        else if (error.request) {
                            /*
                             * The request was made but no response was received, `error.request`
                             * is an instance of XMLHttpRequest in the browser and an instance
                             * of http.ClientRequest in Node.js
                             */
                            console.log(error.request);
                            setError('Error processing your request please try again later')
                        } else {
                            // Something happened in setting up the request and triggered an Error
                            console.log('Error', error.message);
                            setError(error.message)
                        }
                    }

                }} width={'120px'}> Next </Button>
                {rSC()}
            </FlexStartFlexEnd>


        </Wrapper>
    )
}

export default VerificationCode;


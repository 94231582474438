import React from "react";
import "./RegisterModal.css";
import ComingSoonIcon from "../Assets/LandingPage/coming-soon-icon.png";
import RunwaySupplyIcon from "../Assets/LandingPage/login-modal-logo.png";

import { CardElement, injectStripe, Elements } from "react-stripe-elements";
import axios from "axios";
import Modal from "react-responsive-modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import isEmail from "validator/lib/isEmail";
import Loader from "./Loader";
import BrandLogoUploadModal from "./BrandLogoUploadModal";
import { Route, Link } from "react-router-dom";
import PublicRoute from "./PublicRoute";
import RegisterModal from "./Signup/RegisterModal";
import LoginModal from "./Login/LoginModal";
import ForgotPasswordModal from "./ForgotPassword/ForgotPasswordModal";
import LoginSignup from "../Assets/LandingPage/Login_SignUp.png";
import { HOST_NAME, BRAND_SERVICES_HOST_NAME } from "../Config";
import { Button } from "./TypeForm";
class FormDialog extends React.Component {
  state = {
    open: false,
    brandname: "",
    email: ``, //${Math.random() * 100000}@gmail.com
    password: "",
    cpassword: "",
    coupon: "",
    login: false,
    forgotpassword: false,
    loading: false,
    uploading: false,
  };

  handleClickOpen = () => {
    this.setState({ open: true, login: false });
  };

  getUserFromDB = (id) => {
    return axios.post(`${BRAND_SERVICES_HOST_NAME}//get_user`, {
      _id: id,
    });
  };

  setBrandLogoPreview = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      this.setState({ brandLogo: e.target.files[0] });
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ brandLogoPreview: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  render() {
    const { loading, uploading, user, brandLogoPreview } = this.state;

    return (
      <div>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          input[type="color"],
          input[type="date"],
          input[type="datetime"],
          input[type="datetime-local"],
          input[type="email"],
          input[type="month"],
          input[type="number"],
          input[type="password"],
          input[type="search"],
          input[type="tel"],
          input[type="text"],
          input[type="time"],
          input[type="url"],
          input[type="week"],
          select:focus,
          textarea {
            font-size: 16px;
          }

        `,
          }}
        />
        <div onClick={() => this.props.history.push("/register")}>
          <Button
            width={"80px"}
            onClick={this.handleClickOpen}
            height={"30px"}
            backgroundColor={"#FFF"}
            color={"#000"}
            onClick={() => this.props.history.push("/register")}
          >
            {" "}
            Sign up{" "}
          </Button>
        </div>
        <PublicRoute component={RegisterModal} path="/register" />
        <PublicRoute component={LoginModal} path="/login" />

        <PublicRoute component={ForgotPasswordModal} path="/forgot-password" />
      </div>
    );
  }
}

export default FormDialog;

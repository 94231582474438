
import React from 'react';
import styled from 'styled-components';
import { GenderAllIcon, CampaignsLocationIcon, CalendarIcon, URLIcon, YoutubeIcon, WaistIcon, VideoIcon, UnderwearIcon, TwitterIcon, SwimIcon, SlimIcon, SideIcon, PhotoshootIcon, PhoneIcon, RunwayIcon, PencilIcon, PlusIcon, PartsIcon, NotifyIcon, MaleIcon, LengthIcon, InstagramIcon, FemaleIcon, EmailIcon, DeadlineIcon, CampaignsDateTimeIcon, BasicInfoIcon, ModelSpecsIcon, ApplicationSettingsIcon, CreateCampaignIcon, ApplicantsIcon, AthleticIcon, BirthdayIcon, CityIcon, CloseUpIcon, } from '../../Assets/CreateCampaign'
import BrandIcon from "../../Assets/Modal/shop-1.svg";
import { Header, SubHeader, MiniHeader, Boxes, InputsWrapper } from './shared';
import { Input } from '../../shared'
const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    grid-auto-rows: min-content;
`

const SubWrapper = styled.div`
  border-radius: 5px;
  display: grid;
  grid-template-columns: min-content 1fr;
  border: 1px solid #e1e1e1;
  padding: 0px 8px;
  grid-column-gap: 8px;
  align-items: center;
  height: 100%;
  grid-auto-rows: min-content;
  max-height: 40px;
`




const SocialProfiles = ({ campaign, setInputValue, instagram, twitter, youtube, website }) => {
    return (
        <Wrapper>
            {campaign.profiles.length ?
                <div>
                    <SubHeader> Share Your Social Media Profiles </SubHeader>
                    <MiniHeader style={{}}> Username only, no special characters. </MiniHeader>
                    <MiniHeader style={{}}> Optional </MiniHeader>
                </div>
                : null}

            <InputsWrapper style={{}}>
                {campaign.profiles.map(profile => (
                    profile === "url" ? (
                        <SubWrapper id="profiles-url">
                            <img src={URLIcon} style={{ width: 20, height: 20 }} />
                            <Input value={website} onChange={(e) => setInputValue('website', e.target.value)} placeholder="URL" style={{ border: 'none' }}  />
                        </SubWrapper>
                    ) : profile === "instagram" ? (
                        <SubWrapper id="profiles-instagram" >
                            <img src={InstagramIcon} style={{ width: 20, height: 20 }} />
                            <Input value={instagram} onChange={(e) => setInputValue('instagram', e.target.value)} placeholder="Instagram" style={{ border: 'none' }}  />
                        </SubWrapper>
                    ) : profile === "youtube" ? (
                        <SubWrapper id="profiles-youtube" >
                            <img src={YoutubeIcon} style={{ width: 20, height: 20 }} />
                            <Input value={youtube} onChange={(e) => setInputValue('youtube', e.target.value)} placeholder="Youtube" style={{ border: 'none' }} />
                        </SubWrapper>
                    ) : profile === "twitter" && (
                        <SubWrapper id="profiles-twitter" >
                            <img src={TwitterIcon} style={{ width: 20, height: 20 }} />
                            <Input value={twitter} onChange={(e) => setInputValue('twitter', e.target.value)} placeholder="Twitter" style={{ border: 'none' }} />
                        </SubWrapper>
                    )
                ))}
            </InputsWrapper>
        </Wrapper>

    )
}

export default SocialProfiles;
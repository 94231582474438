import React from 'react';
import styled from 'styled-components';
import { Text } from '../../TypeForm'
const Wrapper = styled.div`
    display: grid;
    align-content: center;
    grid-row-gap: 24px;
    justify-content: center;
    @media(max-width: 414px) {
        
    }
`

const ButtonWrapper = styled.div`
    display: grid;
    grid-template-columns: 149px 149px;
    grid-column-gap: 53px;
    @media(max-width: 414px) {
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 24px;
        grid-column-gap: 12px;
    }
`

const Button = styled.button`
    width: 149px;
    height: 54px;
    background-color: #fff;
    font-weight: normal;
    border: solid 1px #000;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    font-style: regular;
    @media(max-width:414px) {
        font-size: 10px;
        width: 90px;
        height: 30px;
    }
`

const TextWrapper = styled.div`
    font-size: 30px;
    @media(max-width: 414px) {
        font-size: 25px;
        display: grid;
        grid-row-gap: 12px; 
    }
`

const LeftSide = ({ history }) => {
    return (
        <Wrapper>
            <TextWrapper>
              <Text fontweight={'bolder'} style={{ fontSize: '1.3em'}} >  Welcome to RunwaySupply </Text>
              <Text fontweight='normal' style={{ fontSize: '.6em'}}> Create modeling campaigns, hire talent and manage your network all in one place. </Text>
            </TextWrapper>
           
            <ButtonWrapper>
                <Button onClick={() => history.push('/register')}> Join as Brand </Button>
                <Button onClick={() => history.push('/apply')}> Join as Model </Button> 
            </ButtonWrapper>

        </Wrapper>
    )
}

export default LeftSide;
import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, BlurredBackground, Input } from '../../shared';
import { Collage } from '.'
import { sendMagicLink } from './Functions';
const Wrapper = styled.div`
    display: grid;
    margin-top: 220px;
    grid-template-rows: 100px;
    height: 100%;
    justify-items:center;
`;

const SubWrapper = styled.div`
    display: grid;
    grid-row-gap: 16px;
`


const ModelLogin = () => {
    const [email, setEmail] = useState('');
    return (
        <div>
            <Collage />
            <BlurredBackground open={true}>
                <Wrapper>
                    <SubWrapper>
                    <Input onChange={(e) => setEmail(e.target.value)} placeholder="you@example.com" />
                    <Button onClick={() => sendMagicLink({
                        email
                    })} width={'200px'} fontSize={'14px'} border={'none'} fontWeight={800} color={'#fff'} backgroundColor={'submit'}> Send Magic Link </Button>
                    </SubWrapper>
                </Wrapper>
            </BlurredBackground>
        </div>
    )
}

export default ModelLogin;
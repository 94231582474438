import React from 'react';

import styled from 'styled-components';
import { StepArrow } from './Assets/index';
const QuestionWrapper = styled.div`
    display: grid;
    grid-auto-rows: min-content;
    grid-template-columns: 1fr;
    
`

const SQ = styled.div`
    color: white;
    font-size: 24px;
    font-weight: unset;
    font-size: ${props => props.questionSize || '24px'};
    line-height: 32px;
    color: rgb(60, 60, 60);
`

const StepWithIconWrapper = styled.div`
    display: grid;
    grid-template-columns: min-content min-content 1fr;
    grid-column-gap: 6px
    align-items: center;
`

const SubText = styled.div`
    color: rgb(68, 68, 68);
`

const Question = ({ question, questionSize, subtext, step, icon = StepArrow }) => {
    return (
        <QuestionWrapper>
            <SQ questionSize={questionSize}>
                <StepWithIconWrapper>
                    <div> {step} </div>
                    <img src={icon} alt="arrow" />
                    {question}
                </StepWithIconWrapper>
            </SQ>
            <SubText>
                {subtext}
            </SubText>
        </QuestionWrapper>
    )
}

export default Question;
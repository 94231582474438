import React from 'react';
import styled from 'styled-components';
import { one, two, three, four } from './images'

const Wrapper = styled.div`
    display: grid;
    grid-auto-columns: 100px;
    grid-auto-rows: 100px;
    justify-content: center;
    @media(max-width: 1300px) {
        grid-auto-columns: 100px;
        grid-auto-rows: 100px;
    }
    @media(max-width: 500px) {

    }
    @media(max-width:800px) {
        grid-template-columns: 1fr;
        & ${ImageWrapper}:nth-child(n+2)  {
            display: none;
        }
 
        & ${ImageWrapper}:first-child  {
            grid-row: 1 !important;
        }
    }

`

const ImageWrapper = styled.div`
    display: grid;
    grid-row: ${props => props.gridRow};
    grid-column: ${props => props.gridColumn};
    @media(max-width:414px) {
        width: 100%;
    }

`
const Image = styled.img`
    width: ${props => props.width || '100%'};
    height: ${props => props.height || '100%'};
    justify-self: ${props => props.justifySelf || 'inherit'}
    @media(max-width: 1300px) {

    }
    @media(max-width: 414px) {
        width: 150px;
        justify-self: center;
    }

`


const RightSide = () => {
    return (
        <Wrapper>
            <ImageWrapper gridRow={'2/6'} gridColumn={'1/3'}>
                <Image height={'591px'} width={'191px'} src={one} />
            </ImageWrapper>
            <ImageWrapper gridRow={'1/4'} gridColumn={'3/6'}>
                <Image justifySelf={'center'} height={'344px'} width={'191px'} src={two} />
            </ImageWrapper>
            <ImageWrapper gridRow={'5/7'} gridColumn={'4/6'}>
                <Image height={'344px'} width={'191px'} src={three} />
            </ImageWrapper>
            <ImageWrapper gridRow={'5/7'} gridColumn={'4/6'}>
                <Image height={'344px'} width={'191px'} src={three} />
            </ImageWrapper>
        </Wrapper>
    )
}

export default RightSide;